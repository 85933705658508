
import { post } from "../../adapters/xhr";
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getItemRoute } from "../../Routes";
import Profil_Docs_List from "./Documents/Profil_Docs_List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faCogs, faUserLock } from "@fortawesome/free-solid-svg-icons";
import Profil_Former_Info from "./Former/Profil_Former_Info";
import ProfilWidget from "../../tools/components/ProfilWidget/ProfilWidget";
import { date_sql_to_str } from "../../tools/Dates";
import { Col, FormGroup, Row } from "react-bootstrap";

const Profil_My = () => {
    
    const account = useSelector((state)=>state.account);
    const history = useHistory();

    const [info, setInfo] = useState({});

    const link_update = getItemRoute('profil_my_update');
    const link_passwrd = getItemRoute('profil_my_password');
    const link_docs = getItemRoute('profil_my_former_space');

    
    const params = new URLSearchParams();
    params.append('tkn', account.info.tkn);
    
    const getData = () => {
        post("profil", "mesinfos", params).then(function (xhr) {
            if(('status' in xhr) && (xhr.status===200)) {
                // console.log(xhr.data);
                xhr.data.sexe = (xhr.data.sexe === 'h') ? 'Mr.' : 'Mme.';
                setInfo(xhr.data);
            }
        }).catch(function(error){
            // console.log('Error', error);
        }).then(function (){
            // console.log('END');
        });
    };

    useEffect(()=>{
        getData();
    }, []);
    
    const goto = (togo) => history.push({pathname:togo, state:{back:true}});

    return (
        <div className='layout_without_center' style={{paddingTop:30}}>
            <Row className='headerContentPart' >
                <div className='flex_between'>
                    <div className='title'>Mon profil</div>
                    <div>
                        <ProfilWidget width_percent={100} profil={info} without_border transparent without_info/>
                    </div>
                    <div className="buttons_table">
                        <div className='width_as_content'>
                            <div className='button_stand button_stand_relative backleft' onClick={() => goto(link_update)} data-tooltip='Informations'> 
                                <FontAwesomeIcon icon={faCogs}/>
                            </div>
                            <div className='button_stand button_stand_relative backleft' onClick={() => goto(link_passwrd)} data-tooltip='Changer le mot de passe' data-left='150'>
                                <FontAwesomeIcon icon={faUserLock}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>

            
            <div className='is_table'>
                <div>
                    <div className=''>
                        
                    
                    <div className='show_info_as_form' >
                        <Row >
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Nom</div>
                                    <div className='profil_show_item_value'><b>{info.sexe}</b> {info.nom}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Prénom</div>
                                    <div className='profil_show_item_value'>{info.pnom}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Email</div>
                                    <div className='profil_show_item_value'>{info.email}</div>
                                </div>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Date naissance</div>
                                    <div className='profil_show_item_value'>{(info.nais_dt)}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Lieu naissance</div>
                                    <div className='profil_show_item_value'>{info.nais_li}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Adresse</div>
                                    <div className='profil_show_item_value'>{info.adresse}</div>
                                </div>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Code postale</div>
                                    <div className='profil_show_item_value'>{info.cp}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Ville</div>
                                    <div className='profil_show_item_value'>{info.ville}</div>
                                </div>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Tel</div>
                                    <div className='profil_show_item_value'>{info.tel}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Fix</div>
                                    <div className='profil_show_item_value'>{info.fix}</div>
                                </div>
                            </FormGroup>
                        </Row>
                    </div>
                </div>
                <div className='is_block'>
                    <div className='button_stand button_bg' onClick={() => goto(link_docs)}>Espace Formateur</div>
                    <Profil_Former_Info account={account} profil_key={account.info.tkn} />
                    <Profil_Docs_List account={account} profil_key={account.info.tkn} with_title={true}/>
                </div>
            </div>
        </div>
            
        </div>
    );
}
 
export default Profil_My;