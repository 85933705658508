import { NavLink, useLocation } from "react-router-dom";

const SideBarConnected = ({ routes, account }) => {
    const location = useLocation();
    
    return ( 
        <div className="SideBarDiv">
            
            {
                routes.map((prop, key) => {
                    if(!(typeof prop == 'object')){
                        return null;
                    }else if('component' in prop){
                        return <NavLink className={'side_bar_button '+(location.pathname == prop.layout + prop.path ? 'side_bar_button_selected' : '')} 
                                style={{display:'block'}}
                                key={key}
                                to={prop.layout + prop.path}
                                >{prop.name}</NavLink>
                    }else{
                        return null;
                    }
                })
            }
        </div>
     );
}
 
export default SideBarConnected;