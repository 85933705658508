import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { check_is_200, post, processParams } from "../../../../adapters/xhr";
import { arraysEqual } from "../../../../tools/Arrays";
import { show_paragraph } from "../../../../tools/HInput";
import SesEnq_Form from "./Form/SesEnq_Form";
import Survey_Satisf_Reply_Component from "./Survey_Satisf_Reply_Component";

const Survey_Satisf_Reply_Form = () => {

    const account = useSelector((state) => state.account);
    
    const { session_key, survey_key } = useParams();

    return <Survey_Satisf_Reply_Component 
                account_tkn={account.info.tkn} 
                account_ctr={account.ctr.key} 
                session_key={session_key}
                survey_key={survey_key}
             />
}

 
export default Survey_Satisf_Reply_Form;