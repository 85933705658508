import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Question_Group_Form from "./Question_Group_Form";

const Question_Group_List = ({items, setItems, is_quiz, typeList=[]}) => {

    const setTitle = (indice, e) => {
        setItems(items.map((item, key) => {
            if(key==indice){
                item.titre = e.target.value;
            }
            return item;
        }));
    };

    const removeItem = (indice) => {
        setItems(items.filter((item, key) => key != indice));
    };

    const addGroup = (indice) => {
        setItems(items.concat({
            titre:'',
            questions:[]
        }));
    };

    const setQuestions = (indice, questions) => {
        setItems(items.map((item, key) => {
            if(key==indice){
                item.questions = questions;
            }
            return item;
        }));
    };

    return ( <div>
        {items && items.map((item, key) => 
                <Question_Group_Form key={key}
                    group={item}
                    removeGroup={()=>removeItem(key)}
                    renameGroup={(e)=>setTitle(key, e)}
                    setQuestions={(e)=>setQuestions(key, e)}
                    is_quiz={is_quiz}
                    typeList={typeList}
                />)}
            <div className='button_stand' onClick={()=>addGroup()}>
                <FontAwesomeIcon icon={faPlus} /> Ajouter un groupe
            </div>
    </div> );
}
 
export default Question_Group_List;