import { Button, Modal } from "react-bootstrap";

const InfoPopUp = ({ showModal, hideModal, titleModal, messageText, messageFunction = ()=>{}, cancelText, submitForm, okText, okAction=(e)=>{}, params, size, secondText, secondAction=()=>{} }) => {
    return ( 
        <Modal show={showModal} onHide={hideModal} dialogClassName={"modal-"+size+"w"}>
            <Modal.Header closeButton>
                <Modal.Title>{titleModal}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {messageText && messageText}
                {messageFunction()}
            </Modal.Body>
            <Modal.Footer>
                { okText && <Button variant="primary" type={submitForm && 'submit'} form={submitForm} onClick={() => okAction(params)}>{okText}</Button> }
                { secondText && <Button variant="primary" onClick={() => secondAction(params)}>{secondText}</Button> }
                { cancelText && <Button variant="secondary" onClick={hideModal}>{cancelText}</Button> }
            </Modal.Footer>
        </Modal>
     );
}
 
export default InfoPopUp;