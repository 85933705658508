import { useEffect, useState } from "react";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { check_is_200, popup_show_file, post, processParams, progressMessage } from "../../../adapters/xhr";
import { useHistory, useParams } from "react-router-dom";
import Survey_Synthese_Show from "./Survey_Synthese_Show";

const Survey_Synthese = () => {

    const account = useSelector((state) => state.account);
    const history = useHistory();
    const { center_key, survey_key } = useParams();
    
    const [survey, setSurvey] = useState([]);
    const [groups, setGroups] = useState([]);
    const [nbr_reponse, setNbr_reponse] = useState(false);

    const [form_by, setForm_by] = useState(null);
    const [form_dd, setForm_dd] = useState('');
    const [form_df, setForm_df] = useState('');
    const [filterOption, setFilterOption] = useState([]);
    
    const [isloading, setIsloading] = useState(false);
    const [the_params, setThe_params] = useState({});

    const getData = () => {
        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            srv: survey_key,
            fdd: form_dd,
            fdf: form_df,
        };
        
        setThe_params(params);

        if(form_by && 'value' in form_by && form_by.value!='')
        {
            let by_val = form_by.value.split('.');
            if(by_val.length > 1)
            {
                params.fby = by_val[0];
                params.fvl = by_val[1];
            }
        }
        
        setIsloading(true);
        post('srvsyn', 'grps', processParams(params))
        .then(xhr => {
            if(check_is_200(xhr)) 
            {
                if('survey' in xhr.data)
                {
                    
                    setSurvey(xhr.data.survey || {});
                    
                    
                    if(filterOption.length == 0)
                    {
                        let opts = [{value:'', label:'Tous'}];
                        let ses = xhr.data.sessions || [];
                        if(ses.length > 0)
                            opts = opts.concat([{label:'Sessions', options:ses}]);
                        
                        let prg = xhr.data.programs || [];
                        if(prg.length > 0)
                            opts = opts.concat([{label:'Programmes', options:prg}]);
                            
                        let frs = xhr.data.formers || [];
                        if(frs.length > 0)
                            opts = opts.concat([{label:'Formateurs', options:frs}]);
                     
                        setFilterOption(opts);
                    }

                    setGroups(xhr.data.groups || [])
                    setNbr_reponse(xhr.data.nbr_reps || false);
                    setIsloading(false);
                }
            }else{
                setIsloading(false);
            }
        }).catch(function (error){
            setIsloading(false);
        });
    }

    const go_download = () => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        post('srvsyn', 'gtfl', processParams(the_params))
        .then(function (xhr)
        {
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé </b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        
        }).catch(function (err){
            toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    }
    
    useEffect(() => {
        getData();
    }, []);

    

    return ( <div>
            <div className='headerContentPart' >
                <div className='flex_between'>
                    <div className='title'>Enquête</div>
                    <div className=''>
                        {/* <div className='button_stand' onClick={() => go_download()}>
                            <FontAwesomeIcon icon={faFileDownload}/>
                        </div> */}
                        {/* <div className='button_stand' onClick={() => {}}>
                            <FontAwesomeIcon icon={faDownload}/>
                        </div> */}
                    </div>
                </div>
            </div>
            <Form className='show_info_as_form'>
                <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Titre</div>
                            <div className='profil_show_item_value'>{survey.titre || ''}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Type</div>
                            <div className='profil_show_item_value'>{survey.type || ''}</div>
                        </div>
                    </FormGroup>
                </Row>
            </Form>
            <hr/>
            <h3>Synthèse</h3>
            <Survey_Synthese_Show 
                    groups={groups}
                    nbr_reponse={nbr_reponse}
                    filterOption={filterOption}
                    isloading={isloading}
                    getData={getData}
                    go_download={go_download}
                    form_by={form_by}
                    setForm_by={setForm_by}
                    form_dd={form_dd}
                    setForm_dd={setForm_dd}
                    form_df={form_df}
                    setForm_df={setForm_df}
                />
        </div> );
}
 
export default Survey_Synthese;