import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, Form, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";

const Question_valeurs = ({ list, setChoix, type, setType, typeList }) => {

    const [list_sggst, setList_sggst] = useState(list);
    
    const add_suggest = () => {
        setList_sggst(list_sggst.concat({ titre:'', correct:false }));
    }

    const delete_suggest = (key) => {
        setList_sggst(list_sggst.filter((itm, keyi) => keyi != key));
    }

    const onChange_suggestion_titre = (e, key) => {
        const newSugst = list_sggst.map((itm, itmk) => itmk != key ? itm : {...itm, titre:e.target.value });
        setList_sggst(newSugst);
    }

    useEffect(() => {
        setChoix(list_sggst)
    }, [list_sggst]);

    return (
        <div>
        <Row>
            <FormGroup as={Col} xl={1}>
                <FormLabel>Type</FormLabel>
            </FormGroup>
            <FormGroup as={Col}>
                <FormControl as='select' value={type} onChange={(e)=>setType(e)} required>
                    <option value=''>Choisissiez</option>
                    <optgroup label='Questions notées'>
                        {typeList.filter(item => item.is_n).map((item, key) => <option key={key} value={item.v}>{item.n}</option>)}
                    </optgroup>
                    <optgroup label='Questions non notées'>
                        {typeList.filter(item => !item.is_n).map((item, key) => <option key={key} value={item.v}>{item.n}</option>)}
                    </optgroup>
                </FormControl>
                <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
            </FormGroup>
            {type=='l' && <FormGroup as={Col} className='div_according'>
                
                <Row>
                    <FormGroup as={Col} style={{margin:0}}>
                        Suggestion{list_sggst.length>1?'s':''} 
                        <div className='button_stand' onClick={(e) => add_suggest()}>
                            <FontAwesomeIcon icon={faPlus}/>
                        </div>
                    </FormGroup>
                </Row>

                <FormGroup as={Col}  style={{margin:0}}>
                {list_sggst.map((item, key) => (
                    <Row key={key}>
                        <FormGroup as={Col} sm={1} style={{margin:0, 'textAlign':'right'}}>
                            <div className='button_stand' onClick={e => delete_suggest(key)} style={{marginTop:2}}><FontAwesomeIcon icon={faTimes}/></div>
                        </FormGroup>
                        <FormGroup as={Col} sm={11} style={{margin:0}}>
                            <FormControl type='text' value={item.titre} onChange={(e)=>onChange_suggestion_titre(e, key)} required/>
                        </FormGroup>
                    </Row>
                ))}

                
                
            </FormGroup></FormGroup>}
        </Row>
        </div>
    )
};
 
export default Question_valeurs;