import { Col, Row, FormGroup } from "react-bootstrap"
import { check_is_200, post, processParams } from "../../../adapters/xhr";
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { handle_change_input, show_paragraph } from "../../../tools/HInput";

const Profil_Former_Info = ({ account }) => {
    
    const [validated, setValidated] = useState(false);
    const [ttl, setTtl] = useState('');
    const [keys, setKeys] = useState('');
    const [num, setNum] = useState('');
    const [desc, setDesc] = useState('');

    const getData = () => {
        const params = {
            tkn: account.info.tkn
        };
        post("frm", "get", processParams(params)).then(function (xhr) {
            if(check_is_200(xhr)) {
                if('info' in xhr.data)
                {
                    setTtl(xhr.data.info.titre || '');
                    setNum(xhr.data.info.n_activite || '');
                    setKeys(xhr.data.info.mots || '');
                    setDesc(xhr.data.info.presentation || '');
                }
            }
        }).catch(function(error){
            // console.log('Error', error);
        }).then(function (){
            // console.log('END');
        });
    };

    useEffect(()=>{
        getData();
    }, []);
    



    return (ttl ?
            <div>
                <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Titre</div>
                            <div className='profil_show_item_value'>{ttl}</div>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>N° de déclaration d’activité</div>
                            <div className='profil_show_item_value'>{num}</div>
                        </div>
                    </FormGroup>
                </Row>
                
                <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Mots clés</div>
                            <div className='profil_show_item_value'>{keys}</div>
                        </div>
                    </FormGroup>
                </Row>
                
                <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item_title'>Présentation</div>
                        {show_paragraph(desc)}
                    </FormGroup>
                </Row>
            </div>
    :'');
} 
export default Profil_Former_Info;