import { faCog, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { check_is_200, downloadBlob, post, processParams, progressMessage } from "../../../adapters/xhr";

const Profil_Docs_List = ({ account, profil_key, with_title }) => {
    
    let progressShow = 0;
    const history = useHistory();
    const [documents, setDocuments] = useState([]);

    // console.log('profil_key', profil_key);

    const getDocuments = () => {
        post('prfdoc', 'get', processParams({
            tkn: account.info.tkn,
            usr: profil_key,
        }))
        .then(function (xhr){
            if(check_is_200(xhr)) {
                // console.log(xhr.data);
                
                if('lst' in xhr.data){
                    setDocuments(xhr.data.lst);
                }
            }else{
                
            }
        }).catch(function (error){
            
        });
    }


    useEffect(()=>{
        getDocuments();
    }, []);
    
    const getFile = (documentSession) => {
        progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        post('prfdoc', 'download', processParams({
            tkn: account.info.tkn,
            usr: profil_key,
            doc: documentSession.key,
        }), {
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                progressShow = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                toast.loading(progressMessage(progressShow), { id: toastId, });
            },
        })
        .then(function (response){
            // console.log(response.data);
            toast.success(<b>Fichier téléchargé</b>, { id: toastId, });
            downloadBlob(response.data, documentSession.name+'.'+documentSession.ext);
        }).catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const show_standart = () => {
        return <div>{documents.map((doc, key) => {
            return (
                <div key={key} className='div_file' onClick={() => getFile(doc)}>
                    <FontAwesomeIcon
                        icon={faPaperclip}
                        style={{marginRight:'10px'}}
                    />
                    {doc.name}
                </div>
                );

        })}</div>;
    }

    return (<div>
        {(documents.length>0) && <div>
            
        {with_title == true && <h5>Documents</h5>}
        {show_standart()}
        {/* <Toaster
                    position="bottom-right"
                    reverseOrder={false}        /> */}
        </div>} </div>);
}

export default Profil_Docs_List;