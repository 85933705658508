import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Col, FormGroup, Row, FormControl, InputGroup } from "react-bootstrap";
import { getPositionOfLineAndCharacter } from "typescript";
import { post, processParams } from "../../../../adapters/xhr";
import { handle_change_input } from "../../../../tools/HInput";
import ProfilWidget from "../../../../tools/components/ProfilWidget/ProfilWidget";
import { getItemRoute } from "../../../../Routes";
import Pagination_normal from "../../../../tools/components/Pagination/Pagination_normal";

const Stagiaire_Search = () => {

    const [search_by, setSearch_by] = useState('');

    const account = useSelector((state)=>state.account);
    const history = useHistory();

    const location = useLocation();

    const [page_current, setPage_current] = useState(1);
    const [page_last, setPage_last] = useState(0);
    const [list, setList] = useState([]);

    const get_result = (page_num) => {
        // setList([]);
        let params = processParams({
            tkn:account.info.tkn, 
            ctr:account.ctr.key,
            src:search_by,
            cur:page_num
        });
        post('profil', 'search', params)
        .then(function (xhr)
        {
            if('search' in xhr.data)
            {
                setList(xhr.data.search.list || []);
                setPage_current(xhr.data.search.curp || 1);
                setPage_last(xhr.data.search.nbrp || 1);
            }
        })
        .catch(function (err){

        });
    };

    const get_info = (key_s) => {
        location.state.lookfor = 't';
    
        history.push({
            pathname:getItemRoute('center_member')+key_s,
            state:location.state
        });
    }

    return ( <div>
        <Row className='headerContentPart' >
            <FormGroup as={Col} xl={3} className='title'>Ajouter un stagiaire</FormGroup>
            <FormGroup as={Col} >
                <InputGroup className="search">
                    <InputGroup.Text className="icon">
                        <FontAwesomeIcon
                            icon={faSearch}
                        /> 
                    </InputGroup.Text>
                    <FormControl
                        placeholder="Chercher"
                        aria-label="Chercher"
                        aria-describedby="filtrate"
                        type='text' 
                        value={search_by} 
                        className="text_zone"
                        onChange={e => handle_change_input(e, setSearch_by)}
                    />
                </InputGroup>
            </FormGroup>
            <FormGroup as={Col} >
                <div className='button_stand' onClick={e=>get_result(1)}>
                    <FontAwesomeIcon icon={faSearch}/>
                </div>
            </FormGroup>
        </Row>
        
        {list.map((item, key) => <div key={key}><ProfilWidget width_percent={100} key={key} profil={item} action={() => get_info(item.key)}/></div>)}
        <Pagination_normal 
            current_page={page_current}
            last_page={page_last}
            go_to={get_result}
        />
    </div> );
}
 
export default Stagiaire_Search;