import { faCog, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { check_is_200, downloadBlob, post, processParams, progressMessage } from "../../../adapters/xhr";
import { getItemRoute } from "../../../Routes";

const Former_Docs_List = ({ account, center_key, profil_key, with_title, can_update }) => {
    
    let progressShow = 0;
    const history = useHistory();
    const [documents, setDocuments] = useState([]);

    // console.log('profil_key', profil_key);

    const getDocuments = () => {
        post('mbrdoc', 'get', processParams({
            ctr: center_key,
            mbr: profil_key
        }))
        .then(function (xhr){
            if(check_is_200(xhr)) {
                if('lst' in xhr.data){
                    setDocuments(xhr.data.lst);
                }
            }else{
                
            }
        }).catch(function (error){
            
        });
    }


    useEffect(()=>{
        getDocuments();
    }, []);
    
    const getFile = (docm) => {
        progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        post('prfdoc', 'download', processParams({
            ctr: center_key,
            mbr: profil_key,
            doc: docm.key,
        }), {
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                progressShow = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                toast.loading(progressMessage(progressShow), { id: toastId, });
            },
        })
        .then(function (response){
            toast.success(<b>Fichier téléchargé</b>, { id: toastId, });
            downloadBlob(response.data, docm.name+'.'+docm.ext);
        }).catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const show_standart = () => {
        return <div>{documents.map((doc, key) => {
            return (
                <div key={key} className='div_file' onClick={() => getFile(doc)}>
                    <FontAwesomeIcon
                        icon={faPaperclip}
                        style={{marginRight:'10px'}}
                    />
                    {doc.name}
                </div>
                );

        })}</div>;
    }
    const goUpdatePage = () => {
        history.push({
            pathname: getItemRoute('center_member_doc_form')+center_key+'/'+profil_key,
            state:{back:true},
        });
    }

    const get_update_button = () => {
        return <div className='button_stand'>
                <FontAwesomeIcon 
                    icon={faCog}
                    onClick={() => goUpdatePage()}
                    />
                </div>
    }

    return (
        !(documents.length>0 || can_update) ? <span/> : <div className='is_block'>
        <h5>{with_title == true ? 'Document(s) partagé(s) du centre' : ''}{can_update && get_update_button()}</h5>
        {show_standart()}
        {/* <Toaster
                    position="bottom-right"
                    reverseOrder={false}        /> */}
        </div>
        );
}

export default Former_Docs_List;