import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Form, Col, Row, FormGroup } from "react-bootstrap";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { check_is_200, post, processParams } from "../../adapters/xhr";
import { getItemRoute } from "../../Routes";
import InfoPopUp from "../../tools/components/popups/InfoPopUp";
import ProfilWidget from "../../tools/components/ProfilWidget/ProfilWidget";
import { show_paragraph } from "../../tools/HInput";

const Info_ctr = () => {

    const account = useSelector((state)=>state.account);

    const [info, setInfo] = useState({
        nom:'', adresse:'', cp:'', ville:'', tel:'', fix:'', logo:'', path:'', 
        url:'', email:'', desc:'', loi:'', nsiren:'', nsiret:'', status:'', juridique:'', 
    });
    
    const [can_update, setCan_update] = useState(false);
    const [is_resp, setIs_resp] = useState(false);
    const [responsable, setResponsable] = useState('');
    const [role_modal, setRole_modal] = useState(false);
    const [role_modal_valid, setRole_modal_valid] = useState(false);
    const [my_roles, setMy_roles] = useState([]);

    const history = useHistory();
    
    const getData = () => {
        const params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            wrsp: 1,
            wrls: 1,
        });
        
        post("center", "info", params).then(function (xhr) {
            if(check_is_200(xhr)) {
                // console.log(xhr.data);
                
                setMy_roles(xhr.data.roles || []);
                if('is_rsp' in xhr.data){
                    setCan_update(xhr.data.is_rsp);
                    setIs_resp(xhr.data.is_rsp);
                }
                if('rsp' in xhr.data){
                    setResponsable(xhr.data.rsp);
                }
                setInfo(xhr.data);
            }
        }).catch(function(error){
            // console.log('Error', error);
        }).then(function (){
            // console.log('END');
        });
    };

    useEffect(()=>{
        getData();
        // console.log(location.state);

    }, []);

    const goUpdatePage = () => {
        if(can_update)
        {
            history.push({
                pathname:getItemRoute("center_update"),
                state:{back:true}
            })
        }
    }

    const role_modal_show = () => setRole_modal(true);
    const role_modal_hide = () => setRole_modal(false);
    
    const role_modal_form = () => {
        return (
            <div>
                <Form id='actions_in_frm' noValidate onSubmit={role_modal_send}>
                    {my_roles.map((item, key)=> <div key={key}>
                    <Form.Check 
                                type="checkbox" 
                                label={item.n}
                                checked={item.act}
                                onChange={(e) => change_role(e, key)}
                            />
                    </div>)}
                </Form>
            </div>
        );
    }

    const change_role = (e, kitem) => { 
        let newr = my_roles.map((item, key) => {
            if(key==kitem)
            {
                item.act = e.target.checked;
            }
            return item;
        });
        
        setMy_roles(newr);
    }

    const role_modal_send = (event) => {
        event.preventDefault();

        const form = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            pos: my_roles
        };
        
        let params = processParams(form);
        const toastId = toast.loading();

        post("center", "racts", params)
        .then(xhr => {
            if(check_is_200(xhr)) {
                // console.log(xhr.data);

                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
                }
            }else{
                toast.error(<b>Problème d'envoi!</b>, { id: toastId, });
            }
        }).catch(function(error){
            toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
        }).then(function (){
            role_modal_hide();
        });
    }

    const info_resp = <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title min_with_100'>Gérant</div>
                            <div className='profil_show_item_value flex_between without_padding' style={{paddingBottom : 0}}>
                                <ProfilWidget 
                                        profil={responsable} 
                                        size={is_resp ? 35 : 25} 
                                        without_border={true}
                                    />
                                    {is_resp && <div className='button_stand without_margin' onClick={() => role_modal_show()}>
                                        <FontAwesomeIcon icon={faCog} />
                                        Mes rôles
                                    </div>}
                            </div>
                        </div>
                    </FormGroup>;

    return ( 
        <div className='headerContentPart'>
            <div className='flex_between'>
                <div className='title'>A propos</div>
                {can_update && <div className=''>
                    <div className='button_stand' onClick={() => goUpdatePage()}>
                            <FontAwesomeIcon icon={faCog} />
                        </div>
                    {/* {is_resp && <div className='button_stand' onClick={() => role_modal_show()}>
                            Mes rôles dans le centre
                        </div>} */}
                </div>}
            </div>
            <br/>
            <Form>
                <FormGroup  as={Col}>
                    <div className="show_info_as_form">
                    <Row>
                        <FormGroup as={Col}>
                            <div className='profil_show_item'>
                                <div className='profil_show_item_title min_with_100'>Nom du centre :</div>
                                <div className='profil_show_item_value'>{info.nom}</div>
                            </div>
                        </FormGroup>
                        {!info.appelation && info_resp}
                    </Row>
                    {info.appelation && <Row>
                        <FormGroup as={Col}>
                            <div className='profil_show_item'>
                                <div className='profil_show_item_title min_with_100'>Appelation Commerciale :</div>
                                <div className='profil_show_item_value'>{info.appelation}</div>
                            </div>
                        </FormGroup>
                        {info_resp}
                    </Row>}
                    <Row>
                        <FormGroup as={Col}>
                            <div className='profil_show_item'>
                                <div className='profil_show_item_title min_with_100'>N° de déclaration d’activité:</div>
                                <div className='profil_show_item_value'>{info.ndecact}</div>
                            </div>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <div className='profil_show_item'>
                                <div className='profil_show_item_title min_with_100'>Catégorie :</div>
                                <div className='profil_show_item_value'>{info.categorie}</div>
                            </div>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <div className='profil_show_item'>
                                <div className='profil_show_item_title min_with_100'>Catégorie juridique :</div>
                                <div className='profil_show_item_value'>{info.juridique}</div>
                            </div>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup as={Col}>
                            <div className='profil_show_item'>
                                <div className='profil_show_item_title min_with_100'>N° SIREN :</div>
                                <div className='profil_show_item_value'>{info.nsiren}</div>
                            </div>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <div className='profil_show_item'>
                                <div className='profil_show_item_title min_with_100'>N° TVA intracommunautaire :</div>
                                <div className='profil_show_item_value'>{info.ntvain}</div>
                            </div>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <div className='profil_show_item'>
                                <div className='profil_show_item_title min_with_100'>N° SIRET :</div>
                                <div className='profil_show_item_value'>{info.nsiret}</div>
                            </div>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup as={Col}>
                            <div className='profil_show_item'>
                                <div className='profil_show_item_title min_with_100'>Téléphone :</div>
                                <div className='profil_show_item_value'>{info.tel}</div>
                            </div>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <div className='profil_show_item'>
                                <div className='profil_show_item_title min_with_100'>Fix :</div>
                                <div className='profil_show_item_value'>{info.fix}</div>
                            </div>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <div className='profil_show_item'>
                                <div className='profil_show_item_title min_with_100'>Email :</div>
                                <div className='profil_show_item_value'>{info.email}</div>
                            </div>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup as={Col}>
                            <div className='profil_show_item'>
                                <div className='profil_show_item_title min_with_100'>Adresse :</div>
                                <div className='profil_show_item_value'>{info.adresse}</div>
                            </div>
                        </FormGroup>
                        <FormGroup as={Col} xl={2}>
                            <div className='profil_show_item'>
                                <div className='profil_show_item_title min_with_100'>Code postal :</div>
                                <div className='profil_show_item_value'>{info.cp}</div>
                            </div>
                        </FormGroup>
                        <FormGroup as={Col} xl={3}>
                            <div className='profil_show_item'>
                                <div className='profil_show_item_title min_with_100'>Ville :</div>
                                <div className='profil_show_item_value'>{info.ville}</div>
                            </div>
                        </FormGroup>
                    </Row> 
                    </div>
                </FormGroup>
            </Form>

            {account.ctr.logo && <div className='apropos_logo'>
                <img src={account.ctr.logo}/>
            </div>}

            <Form className="is_block padding_top_20" hidden>
                <Row>
                    <FormGroup  as={Col} xl="3" className=''>
                        {account.ctr.logo && <div className='apropos_logo'>
                            <img src={account.ctr.logo}/>
                        </div>}
                        <center>
                        <ProfilWidget profil={responsable}  title='Gérant'/>
                        </center>
                        
                    </FormGroup>
                    <FormGroup  as={Col} className='centre_info_block'  style={{paddingLeft:10, paddingTop:10}}>
                        <Row>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title min_with_100'>Catégorie juridique :</div>
                                    <div className='profil_show_item_value'>{info.juridique}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title min_with_100'>N° de déclaration d’activité :</div>
                                    <div className='profil_show_item_value'>{info.ndecact}</div>
                                </div>
                            </FormGroup>
                        </Row>   
                        <Row>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title min_with_100'>Adresse :</div>
                                    <div className='profil_show_item_value'>{info.adresse}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title min_with_100'>Code postal :</div>
                                    <div className='profil_show_item_value'>{info.cp}</div>
                                </div>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title min_with_100'>N° SIRET :</div>
                                    <div className='profil_show_item_value'>{info.nsiret}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title min_with_100'>Ville :</div>
                                    <div className='profil_show_item_value'>{info.ville}</div>
                                </div>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title min_with_100'>N° SIREN :</div>
                                    <div className='profil_show_item_value'>{info.nsiren}</div>
                                </div>
                            </FormGroup>
                        </Row>  
                    </FormGroup>
                </Row>
            </Form><br/>
            
            <div className='profil_show_item is_block'>
                <div className='section_title_two'><div className='title_section'>Description</div></div>
                {show_paragraph(info.desc)}
            </div>

            <div className='profil_show_item is_block'>
                <div className='section_title_two'><div className='title_section'>Loi interne</div></div>
                {show_paragraph(info.loi)}
            </div>

            {is_resp && <InfoPopUp
                        showModal={role_modal}
                        hideModal={role_modal_hide}
                        titleModal="Mes rôles dans le centre"
                        messageFunction={role_modal_form}
                        okText='Enregistrer'
                        submitForm='actions_in_frm'
                        cancelText='Annuler'
                    />}
        </div>
     );
}
 
export default Info_ctr;