import { round } from "./math";

export function arraysEqual(a, b){
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i of a) 
    {
        if (!b.includes(i)) return false;
    }
    return true;
}

export function array_average(arr, precision='') 
{
    if( ! ( Array.isArray(arr) && arr.length>0 )) return 0;

    let avg = 0;
    for (var num of arr) avg += num;
    avg = avg / arr.length;
    
    if(precision == '') return avg;
    else
    {
        if(avg - parseInt(avg) == 0) return avg;
        return parseFloat( round(avg, precision) );
    } 
}