import { Button, Modal } from "react-bootstrap";

const DeleteConfirmation = ({ showModal, hideModal, titleModal, confirmModal, params, message, messageComplement, confirmMessage, cancelMessage }) => {
    return ( 
        <Modal show={showModal} onHide={hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>{titleModal ? titleModal : 'Confirmation de Suppression'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="alert alert-danger">{message}</div>
                {messageComplement}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => confirmModal(params)}>
                    {confirmMessage ? confirmMessage : 'Supprimer'}
                </Button>
                <Button variant="outline-secondary" onClick={hideModal}>
                    {cancelMessage ? cancelMessage : 'Annuler'}
                </Button>
            </Modal.Footer>
        </Modal>
     );
}
 
export default DeleteConfirmation;