import { useEffect, useState } from "react";
import { Form, Col, Row, FormGroup, FormControl, FormLabel, Button, Spinner, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus, faPlusCircle, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { check_is_200, post, processParams, progressMessage } from "../../adapters/xhr";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getItemRoute } from "../../Routes";
import LoadingComponent from "../../tools/components/loading/LoadingComponent";
import { handle_change_checkbox, handle_change_input_item } from "../../tools/HInput";
import ConfirmationModel from "../../tools/components/popups/ConfirmationModel";
import RequiredItem from "../../tools/RequiredItem";
import { CustomDatePicker, js_date_to_sql_date, js_date_to_str_date } from "../../tools/Dates";

const Session_Form = (props) => {
    
    const history = useHistory();
    const { session_key } = useParams();
    const account = useSelector((state) => state.account);
    
    const [is_update_form, setis_update_form] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const [heures, setHeures] = useState([]);

    const [horaires, setHoraires] = useState([]);

    const [program, setProgram] = useState('');
    const [programList, setProgramList] = useState([]);
    const [former, setFormer] = useState('');
    const [formerList, setFormerList] = useState([]);
    const [admin, setAdmin] = useState('');
    const [adminList, setAdminList] = useState([]);
    const [nbrmax, setNbrmax] = useState('');
    const [descr, setDescr] = useState('');
    const [descr_c, setDescr_c] = useState('');
    const [actam, setActam] = useState('');
    const [contact, setContact] = useState('');
    const [adress, setAdress] = useState('');
    const [voi_n, setVoi_n] = useState('');
    const [voi_t, setVoi_t] = useState('');
    const [voi, setVoi] = useState('');
    const [cp, setCp] = useState('');
    const [ville, setVille] = useState('');
    const [batim, setBatim] = useState('');
    const [escl, setEscl] = useState('');
    const [etag, setEtag] = useState('');
    const [locls, setlocls] = useState('');
    const [autosign, setAutosign] = useState(false);
    
    const [update_session, setUpdate_session] = useState('');
    const [update_session_state, setUpdate_session_state] = useState('');
    const [session_open, setSession_open] = useState('');
    
    
    
    useEffect(()=>{
        setis_update_form(props.location.state != null && 'upd' in props.location.state);
        // console.log('is_update_form', is_update_form);
        if(session_key)
        { 
            get_session_info();
        }
        else 
        {
            get_list_programmes();
            get_formers_list();
        }
        
    }, []);
    
    const get_formers_list = (frm='', adm='') => {
        let params = processParams({
            tkn:account.info.tkn, 
            ctr:account.ctr.key, 
            frm,
            adm,
            bid:1
        });
        post("member", "former_member", params)
        .then(function (xhr){
            if(check_is_200(xhr)) {
                // console.log(xhr.data);
                if('f' in xhr.data) setFormerList(xhr.data.f);
                if('a' in xhr.data) setAdminList(xhr.data.a);
                if('check_f' in xhr.data && !xhr.data.check_f) setFormer('');
                if('check_a' in xhr.data && !xhr.data.check_a) setAdmin('');
            }
        }).catch(function (error){
            
        });
    }
    
    const get_list_programmes = () => {
        let params = processParams({tkn:account.info.tkn, ctr:account.ctr.key, opt: ['jttl']});
        post("prgm", "list", params)
        .then(function(xhr){
            if(check_is_200(xhr))
            {
                if('list' in xhr.data) setProgramList(xhr.data.list);
            }
            else
            {

            }
        });
    }

    const get_session_info = () => {
        post('session', 'fiche', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            prm: 1,
            opt: ['lstprg']
        }))
        .then(function (xhr){
            if(check_is_200(xhr)) {
                if('session' in xhr.data)
                {
                    const iSession = xhr.data.session;
                    // console.log(iSession);

                    setSession_open(iSession.open);
                    
                    if('prm' in iSession)
                    {
                        if('upd_s' in iSession.prm) setUpdate_session(iSession.prm.upd_s);
                        if('u_opn' in iSession.prm) setUpdate_session_state(iSession.prm.u_opn);
                    }

                    setDescr(iSession.desc_txt);
                    setDescr_c(iSession.desc_cnv);
                    setActam(iSession.act_am);
                    setFormer(iSession.former);
                    setAdmin(iSession.admin);
                    setNbrmax(iSession.nbrmax);
                    setHoraires(iSession.times.map(e => {
                        e.dt_s_frm = new Date(e.dt_s);
                        e.dt_e_frm = new Date(e.dt_e);
                        return e;
                    }));
                    setContact(iSession.contact);
                    setVille(iSession.ville);
                    setCp(iSession.cp);
                    setAdress(iSession.adress);
                    setVoi_n(iSession.voi_n);
                    setVoi_t(iSession.voi_t);
                    setVoi(iSession.voi);
                    setBatim(iSession.batim);
                    setEscl(iSession.escl);
                    setEtag(iSession.etag);
                    setlocls(iSession.locls);
                    setAutosign(iSession.trn_sgn || false);
                    get_formers_list(iSession.former, iSession.admin);
                }
            }else{
                
            }
        }).catch(function (error){
            
        });
    }

    const add_horaire_date = () => {
        const newhoraires = horaires.concat({
           dt_s:'',
           dt_e:'',
           dt_s_frm:'',
           dt_e_frm:'',
           times:[{
               days:[],
               tm_s:'',
               tm_e:'',
           }]
        });
        setHoraires(newhoraires);
    }

    const add_horaire_time = (index) => {
        if(index in horaires){

            let newhoraires = [...horaires];
            newhoraires[index].times = newhoraires[index].times.concat({
                days:[],
                tm_s:'',
                tm_e:'',
            });
            setHoraires(newhoraires);
        }
    }

    const remove_horaire_time = (indexD, indexT) => {
        if((indexD in horaires) && (indexT in horaires[indexD].times))
        {
            let newhoraires = [...horaires];
            newhoraires[indexD].times = newhoraires[indexD].times.filter((item, key)=>key!=indexT);
            // console.log(newhoraires[indexD].times.length);
            // console.log(newhoraires[indexD].times);
            
            if(newhoraires[indexD].times.length < 1)
            {
                remove_horaire(indexD);
            }
            else
            {
                setHoraires(newhoraires);
            }
        }
    }

    const remove_horaire = (index) => {
        if(index in horaires){
            setHoraires(horaires.filter((hr, key)=>key!=index));
        }
    }

    const changeHandle = (e, iSet) => {
        iSet(e.target.value);
    }

    const onChangeHorairesDate = (e, indice, attr) => {
        const newHoraires = horaires.map((heure, key) => {
            if(key==indice)
            {
                heure['dt_'+attr+'_frm'] = e;
                heure['dt_'+attr] = js_date_to_sql_date(e);
            }
            return heure;
        });
        setHoraires(newHoraires);
    };

    const onChangeHorairesDay = (e, indexD, indexT, day) => {
        
        const newHoraires = horaires.map((horr_itm, keyD) => {
            if(keyD == indexD){
                
                horr_itm.times.map((time_itm, keyT)=>{
                    if(keyT == indexT){
                        if(e.target.checked){
                            time_itm.days = time_itm.days.concat(day);
                        }else{
                            time_itm.days = time_itm.days.filter(function(dy) { return dy !== day });
                        }
                    }
                    return time_itm;
                });
            }
            return horr_itm;
        });
        setHoraires(newHoraires);
    };

    const onChangeHorairesTime = (e, indexD, indexT, attr) => {
        const newHoraires = horaires.map((horr_itm, keyD) => {
            if(keyD == indexD){
                horr_itm.times.map((time_itm, keyT)=>{
                    if(keyT == indexT){
                        time_itm[attr] = e.target.value;
                    }
                    return time_itm;
                });
            }
            return horr_itm;
        });
        setHoraires(newHoraires);
    };

    const goback = () => history.goBack();
    const gobacktolist = () => history.push({
                                                pathname: getItemRoute('center_sessions'),
                                                state:{back:true},
                                            });
     
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        if(form.checkValidity() === true){
            
            setLoading(true);

            const toastId = toast.loading("Envoi");
            let params = {  program, horaires, former, admin, nbrmax, descr, descr_c, actam,
                            contact, adress, voi_n, voi_t, voi, cp, ville, batim, escl, etag, locls, autosign,
                            tkn : account.info.tkn,
                            ctr : account.ctr.key,
                        };
            if(session_key){
                params.sky = session_key;
            }

            post('session', 'save', processParams(params))
            .then(function (xhr){
                if(check_is_200(xhr)) {
                    if(('save' in xhr.data) && ('msg' in xhr.data)){
                        
                        if(xhr.data.save){
                            toast.success(<b>Données enregistrées !!</b>, { id: toastId, });
                            let key_session = session_key;
                            if(!session_key && ('sid' in xhr.data)){
                                key_session = xhr.data.sid;
                            }
                            setTimeout(() => { 
                                if(is_update_form){
                                    goback();
                                }else{
                                    history.push({
                                        pathname: getItemRoute('center_session_fiche')+key_session,
                                        state:{back:true},
                                    });
                                }
                            }, 1000);
                        }else{
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });        
                            setLoading(false);
                        }
                    }else{
                        toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                        setLoading(false);
                    }
                }else{
                    toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                    setLoading(false);
                }
            }).catch(function (error){
                toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                setLoading(false);
            });
        }
        setValidated(true);

    }

    const saveSession = () => {
        
    }

    const get_form_days = () => {
        return [
                { n:"Lundi",    i:'1'},
                { n:"Mardi",    i:'2'},
                { n:"Mercredi", i:'3'},
                { n:"Jeudi",    i:'4'},
                { n:"Vendredi", i:'5'},
                { n:"Samedi",   i:'6'},
                { n:"Dimanche", i:'0'},
            ];
    };
    
    const GetFormHeures = ({ horaire, indice }) => {
        return (
            <div key={indice}>
                <table style={{'width':'100%'}}>
                    <tbody>
                        <tr>
                            <td>
                                <div className='button_stand' onClick={()=>remove_horaire(indice)}>
                                    <FontAwesomeIcon 
                                        icon={ faTrash }
                                        className='text-danger'
                                    />
                                </div>
                            </td>
                            <td>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Période</td>
                                            <td>
                                                <FormGroup as={Col}>
                                                    <CustomDatePicker
                                                        selected={horaire.dt_s_frm}
                                                        onChange={(e)=>onChangeHorairesDate(e, indice, 's')}
                                                        selectsStart
                                                        startDate={horaire.dt_s_frm}
                                                        endDate={horaire.dt_e_frm}
                                                        maxDate={horaire.dt_e_frm}
                                                    />
                                                    <RequiredItem condition={horaire.dt_s}/>
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <FormGroup as={Col}>
                                                    <CustomDatePicker
                                                        selected={horaire.dt_e_frm} 
                                                        onChange={(e)=>onChangeHorairesDate(e, indice, 'e')}
                                                        selectsEnd
                                                        startDate={horaire.dt_s_frm}
                                                        endDate={horaire.dt_e_frm}
                                                        minDate={horaire.dt_s_frm}
                                                    />
                                                    <RequiredItem condition={horaire.dt_e}/>
                                                </FormGroup>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                {horaire.times.map((dtms, indiceT)=>
                                    <table key={indiceT}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className='button_stand' onClick={()=>remove_horaire_time(indice, indiceT)}>
                                                        <FontAwesomeIcon 
                                                            icon={ faTrash }
                                                            className='text-danger'
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <FormGroup as={Col}>
                                                        <div>
                                                            {get_form_days().map((fday, keyd) => <Form.Check key={keyd} inline type="checkbox"  label={fday.n}    checked={dtms.days.includes(fday.i)} onChange={(e)=>onChangeHorairesDay(e, indice, indiceT, fday.i)} />)}
                                                        </div>
                                                        <RequiredItem condition={dtms.days != ''} message='Veuillez séléctionner au moins un jour' />
                                                    </FormGroup>
                                                </td>
                                                <td>De</td>
                                                <td>
                                                    <FormGroup as={Col}>
                                                        <FormControl type="time" value={dtms.tm_s} onChange={(e)=>onChangeHorairesTime(e, indice, indiceT, 'tm_s')} required></FormControl>
                                                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                                                    </FormGroup>
                                                </td>
                                                <td>Â</td>
                                                <td>
                                                    <FormGroup as={Col}>
                                                        <FormControl type="time" value={dtms.tm_e} onChange={(e)=>onChangeHorairesTime(e, indice, indiceT, 'tm_e')} required></FormControl>
                                                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                                                    </FormGroup>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>)}

                                <div className='button_stand' onClick={()=>add_horaire_time(indice)}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </div>
                            </td>
                            

                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }



    const form_session = () => {
        return (
            <div>
                {session_fields()}
            </div>
        )
    }

    

    

    const session_fields = () => {
        return (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    {!session_key && <FormGroup as={Col}>
                        <FormLabel>Programme</FormLabel>
                        <FormControl as="select" value={program} onChange={(e) => changeHandle(e, setProgram)} required>
                            {!program && <option value=''>Choisissiez</option>}
                            {programList.map((prg, key) => <option key={key} value={prg.key}>{prg.titre}</option>)}
                        </FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>}

                    <FormGroup as={Col}>
                        <FormLabel>Formateur</FormLabel>
                        <FormControl as="select" value={former} onChange={(e) => changeHandle(e, setFormer)} required>
                            {!former && <option value=''>Choisissiez</option>}
                            {formerList.map((frm, key) => <option key={key} value={frm.key}>{frm.nom} {frm.pnom}</option>)}
                        </FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Responsable administratif</FormLabel>
                        <FormControl as="select" value={admin} onChange={(e) => changeHandle(e, setAdmin)} required>
                            {!admin && <option value=''>Choisissiez</option>}
                            {adminList.map((frm, key) => <option key={key} value={frm.key}>{frm.nom} {frm.pnom}</option>)}
                        </FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Nombre maximum de stagiaires</FormLabel>
                        <FormControl type="number" value={nbrmax} onChange={(e) => changeHandle(e, setNbrmax)} required/>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Contact</FormLabel>
                        <FormControl type="text" value={contact} onChange={(e) => changeHandle(e, setContact)}></FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Ville</FormLabel>
                        <FormControl type="text" value={ville} onChange={(e) => changeHandle(e, setVille)}></FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Code postale</FormLabel>
                        <FormControl type="text" value={cp} onChange={(e) => changeHandle(e, setCp)}></FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Adresse</FormLabel>
                        <FormControl type="text" value={adress} onChange={(e) => changeHandle(e, setAdress)}></FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Voie</FormLabel>
                        <FormControl type="text" value={voi} onChange={(e) => changeHandle(e, setVoi)}></FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Numéro de voie</FormLabel>
                        <FormControl type="text" value={voi_n} onChange={(e) => changeHandle(e, setVoi_n)}></FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Type de voie</FormLabel>
                        <FormControl type="text" value={voi_t} onChange={(e) => changeHandle(e, setVoi_t)}></FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Bâtiment</FormLabel>
                        <FormControl type="text" value={batim} onChange={(e) => changeHandle(e, setBatim)}></FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Escalier</FormLabel>
                        <FormControl type="text" value={escl} onChange={(e) => changeHandle(e, setEscl)}></FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Êtage</FormLabel>
                        <FormControl type="text" value={etag} onChange={(e) => changeHandle(e, setEtag)}></FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Localisation</FormLabel>
                        <FormControl type="text" value={locls} onChange={(e) => changeHandle(e, setlocls)}></FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <InputGroup className="mb-3">
                            <Form.Check inline type="checkbox" label="Activation de la signature numérique par les stagiaires" checked={autosign} onChange={(e)=>handle_change_checkbox(e, setAutosign)} />
                        </InputGroup>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col} xl={1} style={{paddingTop:10}}>
                        <FormLabel>Horaires</FormLabel>
                    </FormGroup>
                    <FormGroup as={Col}>
                        {horaires && horaires.map((hrr, key) => <GetFormHeures key={key} horaire={hrr} indice={key} />)}
                        <div className='button_stand' onClick={()=>add_horaire_date()}>
                            <FontAwesomeIcon icon={faPlus} />
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Description</FormLabel>
                        <FormControl as="textarea"  value={descr} onChange={(e) => changeHandle(e, setDescr)}></FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Action à mener pour bon deroulement</FormLabel>
                        <FormControl as="textarea"  value={actam} onChange={(e) => changeHandle(e, setActam)}></FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Message supplémentaire du convocation des stagiaires</FormLabel>
                        <FormControl as="textarea"  value={descr_c} onChange={(e) => changeHandle(e, setDescr_c)}></FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                {!loading && <Button type="submit">Enregistrer</Button>}
                {loading && <Button variant="primary" disabled>
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>}
            </Form>
        );
    }

    return ( 
        <div>
            <Row className='headerContentPart' >
                <div className='flex_between'>
                    <div className='title'>
                        { session_key && "Modification de la session" }
                        { !session_key && "Nouvelle session" }
                    </div>
                </div>
            </Row>
            
            {session_key ? (update_session == '' && <LoadingComponent />) : form_session()}
            
            {update_session === true && form_session()}
            {update_session === false && <div>Vous n'avez pas le droit de modifier ou créer une session ! </div>}
        </div>
     );
}
 
export default Session_Form;