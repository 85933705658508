import { useEffect, useState } from "react";
import { Button, Col, Form, Row, FormControl, FormGroup, FormLabel, Spinner } from "react-bootstrap"
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { useHistory, useParams } from 'react-router-dom';
import { check_is_200, post, processParams, progressMessage } from "../../../../adapters/xhr";
import { faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Session_Survey_Frm = () => {

    const account = useSelector((state) => state.account);
    const history = useHistory();

    const { session_key, survey_key } = useParams();

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    let is_update = survey_key != undefined;
    
    const [survey, setSurvey] = useState({});
    const [titre, settitre] = useState('');
    const [items, setitems] = useState([]);
    const [affres, setAffres] = useState('s');
    
    const affichage_result_list = [
        {v:'s', n:'Aprés la validation de chaque stagiaire'},
        {v:'a', n:'Aprés la validation de tout les stagiaires'}
    ];
    
    const handleChange = (e, fSet) => {
        fSet(e.target.value);
    }

    const goBack = () => {
        history.goBack();
    }

    const getData = () => {
        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            srv: survey_key,
            opt: ['upd']
        };

        post('sessrv', 'form', processParams(params))
        .then(xhr => {
            if(check_is_200(xhr)) {
                // console.log(xhr.data);
                if('survey' in xhr.data && 'state' in xhr.data && xhr.data.state)
                {
                    let surv = xhr.data.survey;
                    setSurvey(surv);
                    settitre(surv.titre || '');
                    setAffres(surv.shr || '');
                    setitems(surv.questions || []);
                }
            }else{
                
            }
        }).catch(function (error){
            
        });
    }

    useEffect(() => {
        if(is_update) getData();
    }, []);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        let progressShow = 0;

        if(form.checkValidity() === true){
            setLoading(true);
            
            let info = {titre, items, affres,
                tkn: account.info.tkn,
                ctr: account.ctr.key,
                ses: session_key,
                elc: survey.clme || '',
            };

            if(is_update && 'key' in survey)
            {
                info['srv'] = survey.key;
            }
            
            // console.log(info);
            
            let params = processParams(info);
            
            const toastId = toast.loading(progressMessage(progressShow));
            
            post("survey", "sve", params)
            .then(function(xhr){
                if(check_is_200(xhr)) 
                {
                    // console.log(xhr.data);
                    if(('save' in xhr.data) && ('msg' in xhr.data))
                    {
                        if(xhr.data.save){
                            toast.success(<b>Données enregistrées !!</b>, { id: toastId, });
                            setTimeout(() => { 
                                goBack();
                            }, 1000);
                        }else{
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });        
                        }
                    }else{
                        toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                    }
                }else{
                    toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                }
            }).catch(function(error){
                toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
            }).then(function() {
                setLoading(false);
            });
        }
        setValidated(true);
    };

    const onChange_question = (e, indice) => {
        const newItems = items.map((item, key) => {
            if(key==indice){
                item.text = e.target.value;
            }
            return item;
        });
        setitems(newItems);
    };

    
    const updateSuggest = (indice, suggestions) => {
        const question = items[indice];
        question.suggestions = suggestions;
    }

    const GetQuestionList = ({ list, updateSuggest, indice }) => {
        const [list_sggst, setList_sggst] = useState(list);
        
        const add_suggest = () => {
            setList_sggst(list_sggst.concat({ text:'', correct:false }));
        }

        const onChange_suggestion_reponse = (indice, checked) => {
            const new_suggst = list_sggst[indice];
            const newlist_sggst = list_sggst.map((suggst, ind) => ind!=indice ? suggst : {...new_suggst, correct:!checked});
            setList_sggst(newlist_sggst);
        }
    

        const delete_suggest = (key) => {
            setList_sggst(list_sggst.filter((itm, keyi) => keyi != key));
        }

        const onChange_suggestion_text = (e, key) => {
            const newSugst = list_sggst.map((itm, itmk) => itmk != key ? itm : {...itm, text:e.target.value });
            setList_sggst(newSugst);
        }

        useEffect(() => {
            updateSuggest(indice, list_sggst)
        }, [list_sggst]);

        return (
            <div>
            <Row>
                <FormGroup as={Col}  xl={1} style={{margin:0}}>
                    Suggestion(s)
                </FormGroup>
                <FormGroup as={Col}  style={{margin:0}}>
                    
                    {list_sggst.map((item, key) => (
                    <Row key={key}>
                        <FormGroup as={Col} xl={1} style={{margin:0, 'textAlign':'right'}}>
                            <div className='button_stand' onClick={e => delete_suggest(key)} style={{marginTop:2}}><FontAwesomeIcon icon={faTimes}/></div>
                        </FormGroup>
                        <FormGroup as={Col} style={{margin:0}}>
                            <FormControl type='text' value={item.text} onChange={(e)=>onChange_suggestion_text(e, key)} required/>
                        </FormGroup>
                        <FormGroup as={Col} style={{margin:0}}>
                        <Form.Check 
                            type="checkbox"
                            label={ item.correct ? 'La bonne réponse' :'Sélectionner' }
                            variant={ item.correct ? 'success' : 'light'}
                            checked={ item.correct }
                            onChange={(e) => onChange_suggestion_reponse(key, (e.currentTarget.value == 'on')==item.correct)}
                        />
                            {/* <ToggleButton
                                key={key}
                                type="checkbox"
                                variant={ item.correct ? 'success' : 'light'}
                                value={!key}
                                checked={ item.correct }
                                onChange={(e) => updateAnswer(indice, e.currentTarget.value, list_sggst)}
                            >{ item.correct ? 'La bonne réponse' :'Sélectionner' }</ToggleButton> */}
                        </FormGroup>
                        
                    </Row>
                ))}
                    <Row>
                        <FormGroup as={Col} xl={1} style={{margin:0, 'textAlign':'right'}}>
                            <div className='button_stand' onClick={(e) => add_suggest()}>
                                <FontAwesomeIcon icon={faPlus}/>
                            </div>
                        </FormGroup>
                    </Row>
                    
                </FormGroup>
            </Row>
            </div>
        )
    }

    const GetFormQuestion = ( question, indice ) => {
        return (
            <Row key={indice}>
                <FormGroup style={{width:50}}>
                    <div className='button_stand'>
                        <FontAwesomeIcon 
                            icon={ faTimes }
                            className='text-danger'
                            onClick={()=>removeItem(indice)}
                            />
                    </div>
                </FormGroup>
                
                <FormGroup as={Col}>
                    <Row>
                        <FormGroup as={Col}>
                            <FormControl as='textarea' value={question.text} onChange={(e)=>onChange_question(e, indice)} required/>
                            <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                        </FormGroup>
                    </Row>
                    <GetQuestionList list={question.suggestions} updateSuggest={updateSuggest} indice ={indice} />
                </FormGroup>
            </Row>
        )
    }

    const addItem = () => {
        const newItems = items.concat({
            text:'',
            suggestions:[]
        });
        setitems(newItems);
    };
    const removeItem = (indice) => {
        const newItems = items.filter((v, i) => i !== indice);
        setitems(newItems);
    }

    const survey_items_show = () => {
        return (
            <div>
                Questions<br/>
                {items && items.map((question, key) => {
                        return GetFormQuestion(question, key);
                    })}
                <div className='button_stand' onClick={()=>addItem()}>
                    <FontAwesomeIcon icon={faPlus} /> Ajouter
                </div>
            </div>
        )
    }

    //////// 

    //////// 
    
    const role_list = [ 
        {n:'Secrétaire', v:'s'},
        {n:'Comptable', v:'c'},
        {n:'Formateur', v:'f'},
    ];

    return ( 
        <div>
            <Row className='headerContentPart' >
                <FormGroup as={Col}  className='title'>{is_update?'Modification':'Création'} d'un QCM</FormGroup>
            </Row>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Titre</FormLabel>
                        <FormControl type='text' value={titre} onChange={(e)=>handleChange(e, settitre)} required/>
                        <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Afficher le résultat</FormLabel>
                        <FormControl as="select" value={affres} onChange={(e)=>{handleChange(e, setAffres)}} custom>
                            {affichage_result_list.map((opt, key) => (
                                    <option key={key} value={opt.v}>{opt.n}</option>
                                ))}
                        </FormControl>
                    </FormGroup>
                </Row>
                {survey_items_show()}
                {!loading && <Button type="submit">Enregistrer</Button>}
                {loading && <Button variant="primary" disabled>
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>}
                {/* <Toaster
                    position="bottom-right"
                    reverseOrder={false}        /> */}
            </Form>
        </div>
    );
}
 
export default Session_Survey_Frm;