import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Spinner, FormGroup, FormControl, Row, FormLabel } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { post, processParams } from "../../adapters/xhr";
import { handle_change_input } from "../../tools/HInput";

const Profil_Form_Pass = () => {

    const account = useSelector((state) => state.account);
    const history = useHistory();
    const [validated, setvalidated] = useState(false);
    const [pass_cur, setpass_cur] = useState('');
    const [pass_new, setpass_new] = useState('');
    const [pass_new_c, setpass_new_c] = useState('');
    
    const [is_ident, setis_ident] = useState('');
    const [is_load, setis_load] = useState(false);

    const handleSubmit = (e) => {
        const form = e.currentTarget;
        e.preventDefault();

        const params = {
            tkn: account.info.tkn,
            cps: pass_cur,
            nps: pass_new
        };
        // console.log('params', params);
        if(form.checkValidity() === true && pass_new===pass_new_c){
            const toastId = toast.loading('Enregistrement');
            setis_load(true);
            
            post('profil', 'update_psw', processParams(params))
            .then(function (xhr){
                setis_load(false);
                if(('status' in xhr) && (xhr.status===200)){
                    if(('state' in xhr.data) && ('msg' in xhr.data)){
                        
                        if(xhr.data.state){
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setTimeout(() => {
                                history.goBack();
                            }, 2000);
                        }else{
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                    }else{
                        toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                    }

                }
            })
            .catch(function(error){
                setis_load(false);
                toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                // console.log('Error', error);
            });
        }
        setvalidated(true);
    }

    const update_pass = (ind, e, sets) => {
        handle_change_input(e, sets);
        let pass_n = pass_new;
        let pass_c = pass_new_c;
        
        if(ind==1) pass_n = e.target.value;
        if(ind==2) pass_c = e.target.value;

        if(pass_c!='' && pass_n!=''){
            setis_ident(pass_c==pass_n);
        }else setis_ident('');
    }

    return ( <div className='layout_without_center'>
        <h1 className='pageTitle'>Modifier votre mot de passe</h1>
        <Form noValidate validated={validated} onSubmit={handleSubmit} className='is_block'>
            <Row>
                <FormGroup as={Col}>
                    <FormLabel>Mot de passe actuel</FormLabel>
                    <FormControl type='password' value={pass_cur} onChange={(e)=> handle_change_input(e, setpass_cur)} required/>
                    <FormControl.Feedback type="invalid">Veuillez saisir le mot de passe current</FormControl.Feedback>
                </FormGroup>
            </Row>
            <Row>
                <FormGroup as={Col}>
                    <FormLabel>Le nouveau mot de passe</FormLabel>
                    <FormControl type='password' value={pass_new} onChange={(e)=> update_pass(1, e, setpass_new)} required/>
                    <FormControl.Feedback type="invalid">Veuillez saisir le mot de passe current</FormControl.Feedback>
                </FormGroup>
            </Row>
            <Row>
                <FormGroup as={Col}>
                    <FormLabel>Re-taper le nouveau mot de passe</FormLabel>
                    <FormControl type='password' value={pass_new_c} onChange={(e)=> update_pass(2, e, setpass_new_c)} required/>
                    <FormControl.Feedback type="invalid">Veuillez saisir le mot de passe current</FormControl.Feedback>
                </FormGroup>
            </Row>
            {is_ident===true && <div>sont identiques</div>}
            {is_ident===false && <div>ne sont pas identiques</div>}
            {!is_load && <Button type="submit">Enregistrer</Button>}
            {is_load && <Button variant="primary" disabled>
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>}
        </Form>
    </div> );
}
 
export default Profil_Form_Pass;