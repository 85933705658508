import { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Form, FormLabel, Row, FormGroup, FormControl, Spinner } from "react-bootstrap"
import { handle_change_input, handle_change_input_item } from "../../../../tools/HInput";
import { check_is_200, post, processParams } from "../../../../adapters/xhr";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

const Stagiaire_Form = () => {

    const history = useHistory();
    let { session_key } = useParams();
    const account = useSelector((state) => state.account);

    const [trainee_nom, setTrainee_nom] = useState('');
    const [trainee_pnom, setTrainee_pnom] = useState('');
    const [trainee_nais_dt, setTrainee_nais_dt] = useState('');
    const [trainee_nais_li, setTrainee_nais_li] = useState('');
    const [trainee_tel, setTrainee_tel] = useState('');
    const [trainee_ville, setTrainee_ville] = useState('');
    const [trainee_email, setTrainee_email] = useState('');
    const [trainee_cp, setTrainee_cp] = useState('');
    const [trainee_adresse, setTrainee_adresse] = useState('');
    const [trainee_devis, setTrainee_devis] = useState('');

    const [devis_list, setDevis_list] = useState([]);
    
    const [loading, setLoading] = useState();
    const [validated, setValidated] = useState(false);

    const getDevis = () => {
        post('ctrdev', 'listafs', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
        }))
        .then(function (xhr){
            if(check_is_200(xhr)) {
                if('list' in xhr.data)
                {
                    setDevis_list(xhr.data.list);
                }
            }
        }).catch(function (err){
            
        })
    }

    useEffect(() => getDevis(), []);

    const handleSubmit = (e) => {
        const form = e.currentTarget;
        e.preventDefault();
        
        if( form.checkValidity() === true ) {
            setLoading(true);
            const toastId = toast.loading("Envoi");
            let params = {  
                            tkn : account.info.tkn,
                            ctr : account.ctr.key,
                            ses : session_key,
                            dev : trainee_devis,
                            frm : {
                                nom:trainee_nom, 
                                pnom:trainee_pnom, 
                                em:trainee_email, 
                                tel:trainee_tel, 
                                ns_d:trainee_nais_dt, 
                                ns_v:trainee_nais_li,
                                cp:trainee_cp,
                                adr:trainee_adresse,
                                vil:trainee_ville
                            }
                        };
            post('trainee', 'save', processParams(params))
            .then(function (xhr){
                if(check_is_200(xhr)) {
                    if(('save' in xhr.data) && ('msg' in xhr.data)){
                        console.log(xhr.data);
                        if(xhr.data.save){
                            toast.success(<b>Données enregistrées !!</b>, { id: toastId, });
                            setLoading(false);
                            // setTimeout(() => { 
                            history.goBack();
                            // }, 1000);
                        }else{
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });        
                            setLoading(false);
                        }
                    }else{
                        toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                        setLoading(false);
                    }
                }else{
                    toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                    setLoading(false);
                }
            }).catch(function (error){
                toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                setLoading(false);
            });
        }
        setValidated(true);
    }

    const goback = () => {
        history.goBack();
    }

    return ( <div>
        <Row className='headerContentPart' >
            <FormGroup as={Col} xl={6} className='title'>Créer un stagiaire</FormGroup>
        </Row>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
                <FormGroup as={Col}>
                    <FormLabel>Nom</FormLabel>
                    <FormControl type='text' value={trainee_nom} onChange={(e) => handle_change_input(e, setTrainee_nom)} required></FormControl>
                    <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                </FormGroup>
                <FormGroup as={Col}>
                    <FormLabel>Prénom</FormLabel>
                    <FormControl type='text' value={trainee_pnom} onChange={(e) => handle_change_input(e, setTrainee_pnom)} required></FormControl>
                    <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                </FormGroup>
                <FormGroup as={Col}>
                    <FormLabel>Devis</FormLabel>
                    <FormControl as='select' value={trainee_devis} onChange={(e)=>handle_change_input(e, setTrainee_devis)} required>
                        <option value=''>Liste des devis</option>
                        {devis_list.map((item, key) => <option key={key} value={item.r}>{item.n}, {item.c}</option>)}
                    </FormControl>
                </FormGroup>
            </Row>
            <Row>
                <FormGroup as={Col}>
                    <FormLabel>Email</FormLabel>
                    <FormControl type='text' value={trainee_email} onChange={(e) => handle_change_input(e, setTrainee_email)} required></FormControl>
                    <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                </FormGroup>
                <FormGroup as={Col}>
                    <FormLabel>Tel</FormLabel>
                    <FormControl type='text' value={trainee_tel} onChange={(e) => handle_change_input(e, setTrainee_tel)}></FormControl>
                    <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                </FormGroup>
            </Row><Row>
                <FormGroup as={Col}>
                    <FormLabel>date de naissance</FormLabel>
                    <FormControl type='date' value={trainee_nais_dt} onChange={(e) => handle_change_input(e, setTrainee_nais_dt)}></FormControl>
                    <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                </FormGroup>
                <FormGroup as={Col}>
                    <FormLabel>ville de naissance</FormLabel>
                    <FormControl type='text' value={trainee_nais_li} onChange={(e) => handle_change_input(e, setTrainee_nais_li)}></FormControl>
                    <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                </FormGroup>
            </Row>
            <Row>
                <FormGroup as={Col}>
                    <FormLabel>code postale</FormLabel>
                    <FormControl type='text' value={trainee_cp} onChange={(e) => handle_change_input(e, setTrainee_cp)}></FormControl>
                    <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                </FormGroup>
                <FormGroup as={Col}>
                    <FormLabel>adresse</FormLabel>
                    <FormControl type='text' value={trainee_adresse} onChange={(e) => handle_change_input(e, setTrainee_adresse)}></FormControl>
                    <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                </FormGroup>
                <FormGroup as={Col}>
                    <FormLabel>ville</FormLabel>
                    <FormControl type='text' value={trainee_ville} onChange={(e) => handle_change_input(e, setTrainee_ville)}></FormControl>
                    <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                </FormGroup>
            </Row>
            {!loading && <Button type="submit">Enregistrer</Button>}
            {loading && <Button variant="primary" disabled>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                <span className="sr-only">Loading...</span>
            </Button>}
            {/* <Toaster
                    position="bottom-right"
                    reverseOrder={false}        /> */}
        </Form>
    </div> );
}
 
export default Stagiaire_Form;