import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { check_is_200, post, processParams } from "../../../../adapters/xhr";
import { getItemRoute } from "../../../../Routes";
import { date_sql_to_str } from "../../../../tools/Dates";

const Presence_Trainee_Session = ({center_key, session_key, account, can_update, with_title, class_title, class_content }) => {

    const history = useHistory();
    const [seances, setSeances] = useState([]);
    const [showRubrique, setShowRubrique] = useState(false);

    const getPresences = () => {
        let params = {
            tkn: account.info.tkn,
            ctr: center_key,
            ses: session_key
        };

        post('trnsgn', 'getmyprss', processParams(params))
        .then(function (xhr) {
            if(check_is_200(xhr))
            {
                // console.log(xhr.data);
                setShowRubrique(xhr.data.state || false);
                setSeances(xhr.data.list || []);
            }
            else
            {
                
            }
        }).catch(function (error){
            
        });
    }

    useEffect(()=>{
        getPresences();
    }, []);

    const go_sign = (key_seance) => {
        history.push({
            pathname: getItemRoute('seance_signature')+key_seance,
            state:{back:true},
        });
    }

    return ( showRubrique ? <div>
        <div className='profil_show_item is_block'>
            <div className={class_title}>
                {with_title == true ? 
                    <div className='section_title_two'>
                        <div className='title_section'>Signatures manquantes</div>
                    </div>
                : ''}
            </div>
            {seances.map((inp, key)=>{
                return  <table className='button_stand_tbl' key={key} onClick={(e)=>go_sign(inp['key'])}>
                    <tbody>
                        <tr>
                            <td>{date_sql_to_str(inp['dat'])}</td>
                            <td>{inp['hrd']}</td>
                            <td>{inp['hre']}</td>
                            <td><FontAwesomeIcon icon={faPencilAlt}/></td>
                        </tr>
                    </tbody>
                    </table>;
            })}
        </div> 
    </div>  : null);
}
 
export default Presence_Trainee_Session;