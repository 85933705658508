import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { check_is_200, post, processParams } from "../../adapters/xhr";
import { getItemRoute } from "../../Routes";
import Session_Item from "./Session_Item";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, FormGroup, FormControl, InputGroup } from "react-bootstrap";
import { handle_change_input } from "../../tools/HInput";
import DataTable from "react-data-table-component";
import { filter_search_check_item, sort_function_by } from "../../tools/DataTableOpt";
import { date_sql_to_str } from "../../tools/Dates";

const Session_List = () => {

    const account = useSelector((state) => state.account);
    const history = useHistory();
    const link_add = getItemRoute('center_session_form');
    
    const [sessions, setSessions] = useState([]);
    const [filter_by, setFilter_by] = useState('');
    const currentDate = (new Date()).toISOString().split('T')[0]

    const getData = () => {
        const params = processParams({
            tkn : account.info.tkn,
            ctr : account.ctr.key,
        });

        post('session', 'list', params)
        .then(function (xhr)
        {
            if(check_is_200(xhr)) 
            {
                let sesss = xhr.data.list || [];
                for(let i in sesss){
                    let inf = getState(sesss[i]);
                    sesss[i]['etata'] = inf.text;
                    sesss[i]['etata_cls'] = inf.clss;
                }
                setSessions(sesss);
            }
            else
            {
            }
        }).catch(function (err){

        });
    };

    useEffect(() => {
        getData();
    }, []);

    const add_action = () => {
        history.push({
            pathname:link_add,
            state:{back:true}
        });
    }

    const getState = (session) => {
        
        if(!Array.isArray(session.times)) return {text:'', clss:''};

        let min_date = '';
        let max_date = '';

        for(let hrr of session.times)
        {
            if(min_date == '' || hrr.dt_s < min_date ) min_date = hrr.dt_s;
            if(max_date == '' || hrr.dt_e > max_date ) max_date = hrr.dt_e;
        }
        
        let text = '';
        let clss = '';

        if(min_date == '')
        {
            
        }
        else if(currentDate < min_date)
        {
            text = 'à venir';
            clss = 'session_item_a_venir';
        }
        else if(currentDate > max_date)
        {
            text = 'passée';
            clss = 'session_item_passer';
        }
        else
        {
            text = 'en cours';
            clss = 'session_item_en_cour';
        }
        
        // text += ':: '+currentDate+' - '+min_date+' - '+max_date;

        return {text, clss};
    }

    const go_to_session = (session_key) => {
        history.push({
            pathname:getItemRoute('center_session_fiche')+session_key,
            state:{back:true}
        });
    }
    
    const columns = [
        { name: 'Programme', selector: row => row.prog_name, sortable: true,},
        { name: 'Semaine', maxWidth:'110px', selector: row => row.semaine, sortable: true,},
        { name: 'Du', maxWidth:'10px', selector: row => date_sql_to_str(row.date_s), sortable: true, sortFunction:sort_function_by('date_s'),},
        { name: 'Au', maxWidth:'10px', selector: row => date_sql_to_str(row.date_e), sortable: true, sortFunction:sort_function_by('date_e'),},
        { name: 'Devis', maxWidth:'15%', selector: row => row.devis_lst.join(), sortable: true,},
        { name: 'Clients', maxWidth:'15%', selector: row => row.clients_lst.join(), sortable: true,},
        { name: 'Etat', selector: row => row.action, sortable: true, maxWidth:'120px', cell:row => <div style={{backgroundColor:session_state_color(row), width:'100%', padding:10}}>{row.etata.toUpperCase()}</div>},
        { name: 'Action', maxWidth:'10px', selector: row => <div className='button_stand' onClick={()=>go_to_session(row.key)}><FontAwesomeIcon icon={faInfo}/></div>, sortable: true},
        // { name: 'Action', selector: row => row.action, sortable: true, minWidth:1, cell:row => <div style={{width:180}}>{getActions(row)}</div>},
    ];

    const filteredData = sessions.filter(item => {
        let filter_key = filter_by.toLocaleLowerCase();
        return filter_search_check_item(filter_key, item, 
            ['prog_name', 'week', 'date', 'devis_nbr', 'clients_nbr', 'etata']);
    });

    const session_state_color = (sess) => {
        switch(sess.etata)
        {
            case 'à venir': return 'rgba(63, 195, 128, 0.3)';
            case 'passée': return 'rgba(248, 148, 6, 0.3)';
            case 'en cours': return 'rgba(242, 38, 19, 0.3)';
        }
        return '';
    }

    return ( <div>
        <Row className='headerContentPart' >
            <div className='flex_between'>
                <div className='title'>Session(s)</div>
                {sessions.length > 0 && <InputGroup className="search width_50">
                                            <InputGroup.Text className="icon">
                                                <FontAwesomeIcon
                                                    icon={faSearch}
                                                /> 
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Filtrer"
                                                aria-label="Filtrer"
                                                aria-describedby="filtrate"
                                                type='text' 
                                                value={filter_by} 
                                                className="text_zone"
                                                onChange={e => handle_change_input(e, setFilter_by)}
                                            />
                                        </InputGroup>}
                {link_add!=='/' && <div><div className='float_right button_stand' onClick={() => add_action()}>
                    <FontAwesomeIcon icon={faPlus} />
                </div></div>}
            </div>
        </Row>

        <DataTable
                pagination
                fixedHeader
                columns={columns}
                data={filteredData}
                // conditionalRowStyles={conditionalRowStyles}
                paginationPerPage={40}
                paginationRowsPerPageOptions={[40, 60, 100]}
                noDataComponent="Aucune session n'est enregistrée !"
                paginationComponentOptions={{
                                                rowsPerPageText: 'Sessions par page',
                                                rangeSeparatorText: 'sur',
                                                selectAllRowsItem: true,
                                                selectAllRowsItemText: 'Tous',
                                            }}

            />
        
    </div> );
}
 
export default Session_List;