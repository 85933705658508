import { useEffect, useState } from "react";
import { Form, Button, FormGroup, FormLabel, FormControl } from "react-bootstrap"
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";

import { check_is_200, post, processParams } from "../../../adapters/xhr/index.js";
import useFormInput from "../../../tools/components/useFormInput.js";
import { handle_change_input } from "../../../tools/HInput.js";

const Form_reset_pass = ({ return_con_form, link_key }) => {

    const { user_key } = useParams();
    
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    const [pass_new, setpass_new] = useState('');
    const [pass_new_c, setpass_new_c] = useState('');
    const [is_ident, setis_ident] = useState('');    

    const handleSubmit = (event) => {
        event.preventDefault();

        const form = event.currentTarget;

        if (form.checkValidity() && pass_new == pass_new_c) {
            checkConnexion({'psw': pass_new, 'key': link_key});
        }
        setValidated(true);

    };


    const checkConnexion = (params) => {
        setLoading(true);
        const toastId = toast.loading('Rénitialisation');
        
        post("profil", "inips", processParams(params)).then(function (xhr) {
            if(check_is_200(xhr)){
                // console.log(xhr.data);
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        setTimeout(function () {
                            return_con_form();
                        }, 2000);
                    }
                    else 
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
                }
            }
        }).catch(function(error){
            toast.error(<b>Problème de vérification</b>, { id: toastId, });
        }).then(function (){
            setLoading(false);
        });
    }

    const checkkey = () => {
        if (typeof user_key !== 'undefined'){
            checkConnexion({'rky': user_key});
        }
    }

    useEffect(() => checkkey(),[])
    
    const update_pass = (ind, e, sets) => {
        handle_change_input(e, sets);
        let pass_n = pass_new;
        let pass_c = pass_new_c;
        
        if(ind==1) pass_n = e.target.value;
        if(ind==2) pass_c = e.target.value;

        if(pass_c!='' && pass_n!=''){
            setis_ident(pass_c==pass_n);
        }else setis_ident('');
    }

    return ( 
        <div>
            <h1 className='pageTitle'>Réinitialisation du mot de passe</h1>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className='centerForm'>
                <FormGroup>
                    <FormLabel>Nouveau mot de passe</FormLabel>
                    <FormControl type="password" value={pass_new} onChange={(e)=> update_pass(1, e, setpass_new)} required />
                    <FormControl.Feedback type="invalid">
                        Veuillez saisir le nouveau mot de passe
                    </FormControl.Feedback>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Retaper le mot de passe</FormLabel>
                    <FormControl type="password" value={pass_new_c} onChange={(e)=> update_pass(2, e, setpass_new_c)} required />
                    <FormControl.Feedback type="invalid">
                        Veuillez saisir le nouveau mot de passe
                    </FormControl.Feedback>
                </FormGroup>
                
                {is_ident===true && <div>sont identiques</div>}
                {is_ident===false && <div>ne sont pas identiques</div>}

                {!loading && <Button variant="primary" type="submit">
                    Enregistrer
                </Button>}
                <Button variant="primary" onClick={() => return_con_form()}>
                    Annuler
                </Button>
            </Form>
        </div>
     );
}
 
export default Form_reset_pass;