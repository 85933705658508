import { faCheckCircle, faCheckDouble, faEnvelope, faEraser, faRemoveFormat } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams, useHistory } from 'react-router-dom';
import SignatureCanvas from "react-signature-canvas";
import { check_is_200, post, processParams } from "../../adapters/xhr";
import LoadingComponent from "../../tools/components/loading/LoadingComponent";
import { date_sql_to_str } from "../../tools/Dates";

const Signer = () => {

    const account = useSelector((state) => state.account);

    const history = useHistory()
    const sigCanvas = useRef();
    const [info, setInfo] = useState({});
    const [access, setAccess] = useState(false);
    const [message, setMessage] = useState('');
    const [is_signed, setIs_signed] = useState(false);
    
    let { signkey } = useParams();

    const getInfo = () => {

        let params = { ksg : signkey };
        post('trnsgn', 'infsgn', processParams(params))
        .then(function (xhr) {
            if(check_is_200(xhr))
            {
                setInfo(xhr.data.info || []);
                setMessage(xhr.data.msg || []);
                setAccess(xhr.data.state || false);
            }
            else
            {
                
            }
        }).catch(function (error) {
            
        });
        // console.log('getIngo', signkey);
    }

    useEffect(()=>{
        getInfo();
    }, []);

    const sendSignature = () => { 
        if(sigCanvas.current.isEmpty())
        {
            toast.error("Veuillez signer !");
        }
        else 
        {
            const img = sigCanvas.current.getTrimmedCanvas().toDataURL("text/json");
            
            const toastId = toast.loading("Envoi");
            
            post('trnsgn', 'mkqp', processParams({ tkn: account.info.tkn, ksg : signkey, img:img }))
            .then(function (xhr) {
                if(check_is_200(xhr))
                {
                    if(('save' in xhr.data) && ('msg' in xhr.data))
                    {
                        if(xhr.data.save){
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setIs_signed(true);
                            // history.goBack();
                        }else{
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                    }else{
                        toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                }
            }).catch(function (error){
                toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
            });
        }
    }

    return ( <div className='signature_content'>
        <h1>Signature de présence</h1>
        {access===false && <div className="alert alert-danger">{message}</div>}
        {access=='' && <LoadingComponent />}
        {('session' in info) && <div>
                                <b>Session </b> {info.session || ''}<br/>
                                <b>Séance </b>  du <b>{info.date ? date_sql_to_str(info.date) : ''}</b>, de <b>{info.hr_d || ''}</b> à <b>{info.hr_f || ''}</b><br/>
                                {is_signed && <h1><br/>Présence marqué<br/><FontAwesomeIcon icon={faCheckCircle} color="green" style={{fontSize:'100px', marginTop:'20px'}}/></h1>}
                                {!is_signed && <div>
                                    <SignatureCanvas
                                        penColor="black"
                                        canvasProps={{ className: "sigCanvas" }}
                                        ref={sigCanvas}
                                    />
                                    <div className='button_stand' onClick={() => sigCanvas.current.clear()}>
                                        <FontAwesomeIcon icon={faEraser}/> 
                                         Nettoyer
                                    </div>
                                    <div className='button_stand' onClick={() => sendSignature()}>
                                        <FontAwesomeIcon icon={faEnvelope}/> 
                                         Envoyer
                                    </div>
                                </div>}
                            </div>}
        <br/>
        {(account.info.tkn || '') == '' && <Toaster
            position="bottom-center"
            reverseOrder={false}
            />}
    </div> );
}
 
export default Signer;