import { faArrowRight, faCog, faFileDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Form, Col, Row, FormGroup } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { check_is_200, downloadBlob, popup_show_file, post, processParams, progressMessage } from "../../../adapters/xhr";
import { getItemRoute } from "../../../Routes";
import DeleteConfirmation from "../../../tools/components/popups/DeleteConfirmation";
import TextQ from "../../../tools/components/Texts/TextQ";
import { handle_change_input, show_paragraph } from "../../../tools/HInput";

const Program_Fiche = () => {

    const account = useSelector((state) => state.account );
    const history = useHistory();
    const { center_key, program_key } = useParams();

    let is_creation = program_key == undefined;

    const [title, setTitle] = useState('');
    const [refer, setRefer] = useState('');
    const [nbrhrs, setNbrhrs] = useState('');
    const [prix, setPrix] = useState('');
    const [objectif, setObjectif] = useState('');
    const [description, setDescription] = useState('');
    const [attestation, setAttestation] = useState('');

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    
    const goback = () => history.goBack();

    const getData = () => {
        const params = processParams({
            tkn:account.info.tkn, 
            ctr: center_key,
            prg: program_key
        });
        post("prgm", "info", params).then(function (xhr) {
            if(check_is_200(xhr)) {
                const info = xhr.data.program;
                setTitle(info.titre || '');
                setRefer(info.reference || '');
                setObjectif(info.objectif || '');
                setPrix(info.prix || []);
                setNbrhrs(info.nbr_heures || []);
                setDescription(info.description || '');
                setAttestation(info.attestation || '');
            }
        }).catch(function(error){
            // console.log('Error', error);
        }).then(function (){
            // console.log('END');
        });
    };

    useEffect(() => {
        getData();
    }, []);


    
    const go_update = () => {
        history.push({
            pathname:getItemRoute('center_program_update')+center_key+'/'+program_key,
            state:{back:true}
        });
    }

    const go_delete = (prog) => setDisplayConfirmationModal(true);

    const hideConfirmationModal = () => setDisplayConfirmationModal(false);

    const act_delete = () => {
        hideConfirmationModal();

        let params = processParams({
            tkn:account.info.tkn, 
            ctr:center_key,
            prg:program_key
        });

        const toastId = toast.loading('Envoi');

        post("prgm", "delete", params)
        .then(function (xhr) {
            if(check_is_200(xhr)) {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        goback();
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else 
                {
                    toast.error(<b>Problème d'envoi des données !</b>, { id: toastId, });
                }
            }else{
                toast.error(<b>Problème de suppression !</b>, { id: toastId, });
            }
            
        }).catch(function(error){
            toast.error(<b>Problème de suppression !</b>, { id: toastId, });
        });
    }

    const go_download = () => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        post('prgm', 'getfile', processParams({
            tkn: account.info.tkn,
            ctr: center_key,
            prg: program_key
        }))
        .then( xhr => {
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé </b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        }).catch(function (err){
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }
    
    return ( <div>
            <Row className='headerContentPart' >
                <div className='flex_between'>
                    <div className='title'>Programme</div>
                    <div className=''>
                        <div className='button_stand' onClick={() => go_download()}>
                            <FontAwesomeIcon icon={faFileDownload}/>
                        </div>
                        <div className='button_stand' onClick={() => go_update()}>
                            <FontAwesomeIcon icon={faCog}/>
                        </div>
                        <div className='button_stand' onClick={() => go_delete()}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </div>
                    </div>
                </div>
            </Row>

            <Form className='show_info_as_form'>
                <Row>
                    {refer!='' && <FormGroup as={Col} md={2}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Référence</div>
                            <div className='profil_show_item_value'>{title}</div>
                        </div>
                    </FormGroup>}
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Titre</div>
                            <div className='profil_show_item_value'>{title}</div>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Objectif</div>
                            <div className='profil_show_item_value'>{objectif}</div>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Nombre d'heure{nbrhrs > 1 && 's'} / stagiaire</div>
                            <div className='profil_show_item_value'>{nbrhrs}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Prix H.T. / heure</div>
                            <div className='profil_show_item_value'>{prix}</div>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup  as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Description</div>
                            <div className='profil_show_item_value withoutstyle'>{show_paragraph(description)}</div>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <div className='profil_show_item'>
                        <div className='profil_show_item_title'>Attestation</div>
                        <div className='profil_show_item_value withoutstyle'>{show_paragraph(attestation)}</div>
                    </div>
                </Row>
            </Form>
        
            <DeleteConfirmation 
                        showModal={displayConfirmationModal} 
                        confirmModal={act_delete}
                        hideModal={hideConfirmationModal} 
                        message={"Êtes-vous sûr de vouloir supprimer le programme \""+title+"\" ?"}
                    />
    </div> );
}
 
export default Program_Fiche;