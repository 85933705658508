import { useEffect, useState } from "react";
import { Row, FormGroup, Button, Col, FormControl, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import { check_is_200, downloadBlob, popup_show_file, post, processParams, progressMessage } from "../../../../adapters/xhr";
import { handle_change_file, handle_change_input } from "../../../../tools/HInput";
import toast, { Toaster } from "react-hot-toast";
import parse from 'html-react-parser';
import DeleteConfirmation from "../../../../tools/components/popups/DeleteConfirmation";
import { getItemRoute } from "../../../../Routes";
import { faCog, faCogs, faFile, faInfo, faPencilAlt, faPlus, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import { filter_search_check_item } from "../../../../tools/DataTableOpt";

const Session_Survey_Upd = () => {

    const history = useHistory();
    const { session_key } = useParams();
    const account = useSelector((state)=>state.account);
    const [filter_by, setFilter_by] = useState('');

    

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [survey_to_remove, setsurvey_to_remove] = useState({});
    const hideConfirmationModal = () => { setDisplayConfirmationModal(false); }

    const [validated, setValidated] = useState(false);
    const [importFile, setImportFile] = useState('');
    const [session, setSession] = useState({});
    const [surveys, setsurveys] = useState([]);
    const [surveysProg, setSurveysProg] = useState([]);


    const getSurveys = () => {
        post('sessrv', 'getlist', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            opt: ['with_ts']
        }))
        .then(function (xhr){
            if(check_is_200(xhr)) {
                // console.log(xhr.data);
                setSession(xhr.data.ses || []);
                setsurveys(xhr.data.lst || []);
            }
        }).catch(function (err){
            
        });
    }

    useEffect(() => {
        getSurveys();
    }, []);

    const confirm_remove_survey = (surv) => {
        setsurvey_to_remove(surv);
        setDisplayConfirmationModal(true);
    }

    const removeSurvey = () => {

        setDisplayConfirmationModal(false);

        const params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            srv: survey_to_remove.key,
        });

        const toastId = toast.loading("Chargement");
        post('sessrv', 'delete', params)
        .then( function ( xhr ) {
            // // console.log(xhr);
            if ( check_is_200(xhr) )
            {
                if ( ( 'state' in xhr.data ) && ( 'msg' in xhr.data ) )
                {
                    if ( xhr.data.state )
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        getSurveys();
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else
            {
                toast.error(<b>Problème de chargement !</b>, { id: toastId, });
            }
        })
        .catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    }

    const createQ = () => {
        history.push({
            pathname: getItemRoute('center_session_survey_form')+session_key,
            state:{back:true},
        });
    }

    const go_to_reply_form = (survey) => {
        if('of_prg' in survey)
            history.push({
                pathname: getItemRoute('center_session_program_survey_reply_form')+session_key+'/'+survey.key,
                state:{back:true},
            });
        else
            history.push({
                pathname: getItemRoute('center_session_survey_reply_form')+session_key+'/'+survey.key,
                state:{back:true},
            });
    }

    const go_to_info = (survey) => {
        if('of_ses' in survey)
            history.push({
                pathname: getItemRoute('center_session_survey_inf')+session_key+'/'+survey.key,
                state:{back:true},
            });
        else if('of_prg' in survey)
            history.push({
                pathname: getItemRoute('center_session_program_survey_inf')+session_key+'/'+survey.key,
                state:{back:true},
            });
        else if('of_sts' in survey)
            history.push({
                pathname: getItemRoute('center_session_satisf_survey_inf')+session_key+'/'+survey.key,
                state:{back:true},
            });
    }
    const go_update_survey = (surv) => {
        if('of_ses' in surv)
            history.push({
                pathname: getItemRoute('center_session_survey_update')+session_key+'/'+surv.key,
                state:{back:true},
            });
    }

    const get_table_surveys = () => {
        return <table className="table_ini">
            <thead>
                <tr>
                    <th>Nom</th>
                    <th style={{width:'200px'}}></th>
                </tr>
            </thead>
            <tbody>
            {surveys.map((surv, key) => {
                return (<tr key={key}>
                    <td>{surv.titre}</td>
                    <td>
                        <Button onClick={(e)=>go_to_info(surv)}>Fiche</Button> 
                        <Button variant='danger' onClick={(e)=>confirm_remove_survey(surv)}>Supprimer</Button>
                    </td>
                </tr>);
            })}
            </tbody>
        </table>;
    }

    const goback = () => {
        history.goBack();
    }

    
    const columns = [
        { name: 'Titre', selector: row => row.titre, sortable: true,},
        { name: 'Type', selector: row => row.type, cell: row => <div className='table_tag' style={{background:('color' in row ? row.color : '#eee')}}>{row.type}</div>, sortable: true,},
        { name: 'Taux de paticipation', selector: row => ('tx_pr' in row ? row.tx_pr+' %' : ''), sortable: true,},
        { name: 'Taux de réponse', selector: row => ('tx_rp' in row ? row.tx_rp+' %' : ''), sortable: true,},
        { name: 'Moy. score', selector: row => ('score' in row ? row.score+' / 10' : ''), sortable: true, minWidth:1,},
        { name: 'Action', selector: row => row.action, sortable: true, minWidth:1, cell:row => <div>
                
                <div className='button_stand' onClick={(e)=>go_to_info(row)}>
                    <FontAwesomeIcon icon={faInfo}/>
                </div>
                {'of_ses' in row && <div className='button_stand' onClick={(e)=>go_update_survey(row)}>
                    <FontAwesomeIcon icon={faCog}/>
                </div>}
                {'of_ses' in row && <div className='button_stand' onClick={(e)=>confirm_remove_survey(row)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </div>}

            </div>},
    ];
    const filteredData = surveys.filter(item => {
                            item.is_qcm = true;
                            let filter_key = filter_by.toLocaleLowerCase();
                            return filter_search_check_item(filter_key, item, ['titre'])
                                
                        }).concat(
                            surveysProg.filter(item => {
                                item.is_qcm = false;
                                let filter_key = filter_by.toLocaleLowerCase();
                                return filter_search_check_item(filter_key, item, ['titre'])
                                    
                            })  
                        );

    const go_print_synthese = () => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));

        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key
        };
        
        post('sessrvr', 'syng', processParams(params))
        .then((xhr) => {
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé</b>, { id: toastId, });
                }
                else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        }).catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    return (
        <div>
            <div className='headerContentPart flex_between' >
                <div className='title'>QCM</div>
                {surveys.length > 0 && <div style={{width:'50%'}}><InputGroup className="search">
                                            <InputGroup.Text className="icon">
                                                <FontAwesomeIcon
                                                    icon={faSearch}
                                                /> 
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Filtrer"
                                                aria-label="Filtrer"
                                                aria-describedby="filtrate"
                                                type='text' 
                                                value={filter_by} 
                                                className="text_zone"
                                                onChange={e => handle_change_input(e, setFilter_by)}
                                            />
                                        </InputGroup></div>}
                <div>
                    <div className='button_stand' 
                            onClick={() => createQ()}>
                            <FontAwesomeIcon icon={faPlus}    />
                    </div>
                    {surveys.length >0 && <div className='button_stand' 
                            onClick={() => go_print_synthese()}>
                                Synthèse
                    </div>}
                </div>
            </div>
            
            {surveys && <DataTable
                    pagination
                    fixedHeader
                    columns={columns}
                    data={filteredData}
                    noDataComponent="Aucun QCM n'est enregistré !"
                    paginationComponentOptions={{
                                                    rowsPerPageText: 'QCM par page',
                                                    rangeSeparatorText: 'sur',
                                                    selectAllRowsItem: true,
                                                    selectAllRowsItemText: 'Tous',
                                                }}
                />}

        {/* {surveys && get_table_surveys()} */}

            <DeleteConfirmation 
                    showModal={displayConfirmationModal} 
                    confirmModal={removeSurvey}
                    hideModal={hideConfirmationModal}
                    message={<div>Êtes-vous sûr de vouloir supprimer le questionnaire <b>{survey_to_remove.titre}</b> y compris ces réponses de cette session ?</div>}
                />
            {/* <Toaster
                position="bottom-right"
                reverseOrder={false}        /> */}
        </div>
     );
}
 
export default Session_Survey_Upd;