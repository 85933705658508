import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

const NavBarConnected = ({ routes, subroutes, rightroutes, account }) => {
    return ( 
        <div>
            {routes.length>0 && <div className='nabarstyle' >
                {/* <div className='logo'> 
                    <img src='\images\logo_gc.png'/>
                </div> */}
                {
                    routes.map((prop, key)=>{
                        if(!(typeof prop == 'object')){
                            return null;
                        }else if(('component' in prop) && ('name' in prop)){

                            if(prop.id == 'profil_my') prop.name = account.info.name.n+' '+account.info.name.p;

                            return <div key={key} className='nav_bar_button'>
                                <NavLink
                                    to={prop.layout + prop.path}
                                    ><span>{prop.name}</span>
                                    {('badge' in prop && prop.badge>0) && <div className='badge'>{prop.badge}</div>}</NavLink></div>
                        }else if('action' in prop){
                            return <div key={key} className='nav_bar_button'>
                                        <a onClick={prop.action}>
                                            <span>{prop.name}</span>
                                        </a>
                                    </div>
                        }else{
                            return null;
                        }
                    })
                }
                
            </div>}
            <div className='nabar_sub_style flex_between'>
                <div className='logo'>
                    <img src='\images\gestioncentres_middle.png'/>
                </div>
                <div className='nabar_buttons'>
                {
                    subroutes.map((prop, key)=>{
                        if(!(typeof prop == 'object')){
                            return null;
                        }else if(('component' in prop) && ('name' in prop)){

                            if(prop.id == 'profil_my') prop.name = account.info.name.n+' '+account.info.name.p;

                            return <div key={key} className='nav_bar_button'>
                                <NavLink
                                    to={prop.layout + prop.path}
                                    >
                                    {prop.icon ? <FontAwesomeIcon icon={prop.icon} style={{color:prop.color}}/> : null}
                                    <span>{prop.name}</span>
                                    {('badge' in prop && prop.badge>0) && <div className='badge'>{prop.badge}</div>}</NavLink></div>
                        }else if('action' in prop){
                            return <div key={key} className='nav_bar_button'><a onClick={prop.action}>
                                {prop.icon ? <FontAwesomeIcon icon={prop.icon}/> : null}
                                <span>{prop.name}</span>
                                </a></div>
                        }else{
                            return null;
                        }
                    })
                }
                </div>
                {rightroutes && <div className='nabar_buttons'>
                {
                    rightroutes.map((prop, key)=>{
                        if(!(typeof prop == 'object')){
                            return null;
                        }else if(('component' in prop) && ('name' in prop)){

                            if(prop.id == 'profil_my') prop.name = account.info.name.n+' '+account.info.name.p;

                            return <div key={key} className='nav_bar_button'>
                                <NavLink
                                    to={prop.layout + prop.path}
                                    >
                                    {prop.icon ? <FontAwesomeIcon icon={prop.icon} style={{color:prop.color}}/> : null}
                                    <span>{prop.name}</span>
                                    {('badge' in prop && prop.badge>0) && <div className='badge'>{prop.badge}</div>}</NavLink></div>
                        }else if('action' in prop){
                            return <div key={key} className='nav_bar_button'><a onClick={prop.action}>
                                {prop.icon ? <FontAwesomeIcon icon={prop.icon}  style={{color:prop.color}}/> : null}
                                <span>{prop.name}</span>
                                </a></div>
                        }else{
                            return null;
                        }
                    })
                }
                </div>}
            </div>
        </div>
     );
}
 
export default NavBarConnected;