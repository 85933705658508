import { useEffect, useState } from "react";
import { Row, FormGroup, Button, Col, FormControl, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import { check_is_200, downloadBlob, post, processParams, progressMessage } from "../../../adapters/xhr";
import { handle_change_file, handle_change_input } from "../../../tools/HInput";
import toast, { Toaster } from "react-hot-toast";
import parse from 'html-react-parser';
import DeleteConfirmation from "../../../tools/components/popups/DeleteConfirmation";
import { getItemRoute } from "../../../Routes";
import { faCog, faCogs, faFile, faPlus, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import { filter_search_check_item } from "../../../tools/DataTableOpt";

const Survey_Upd = () => {

    const history = useHistory();
    const { session_key } = useParams();
    const account = useSelector((state)=>state.account);
    const [filter_by, setFilter_by] = useState('');

    

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [survey_to_remove, setsurvey_to_remove] = useState({});
    const hideConfirmationModal = () => { setDisplayConfirmationModal(false); }

    const [validated, setValidated] = useState(false);
    const [importFile, setImportFile] = useState('');
    const [session, setSession] = useState({});
    const [surveys, setsurveys] = useState([]);


    const getSurveys = () => {
        post('survey', 'getlist', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            opt: ['gss']
        }))
        .then(function (xhr){
            if(check_is_200(xhr)) {
                setSession(xhr.data.ses || []);
                setsurveys(xhr.data.lst || []);
            }
        }).catch(function (err){
            
        });
    }

    useEffect(() => {
        getSurveys();
    }, []);

    const confirm_remove_survey = (surv) => {
        setsurvey_to_remove(surv);
        setDisplayConfirmationModal(true);
    }

    const removeSurvey = () => {

        setDisplayConfirmationModal(false);

        const params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            srv: survey_to_remove.key,
        });

        const toastId = toast.loading("Chargement");
        post('survey', 'delete', params)
        .then( function ( xhr ) {
            // // console.log(xhr);
            if ( check_is_200(xhr) )
            {
                if ( ( 'state' in xhr.data ) && ( 'msg' in xhr.data ) )
                {
                    if ( xhr.data.state )
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        getSurveys();
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else
            {
                toast.error(<b>Problème de chargement !</b>, { id: toastId, });
            }
        })
        .catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    }

    const createQ = () => {
        history.push({
            pathname: getItemRoute('center_session_survey_form')+session_key,
            state:{back:true},
        });
    }

    const go_info_page = (surv) => {
        history.push({
            pathname: getItemRoute('center_session_survey_inf')+session_key+'/'+surv.key,
            state:{back:true},
        });
    }
    const go_update_survey = (surv) => {
        history.push({
            pathname: getItemRoute('center_session_survey_update')+session_key+'/'+surv.key,
            state:{back:true},
        });
    }

    const get_table_surveys = () => {
        return <table className="table_ini">
            <thead>
                <tr>
                    <th>Nom</th>
                    <th style={{width:'200px'}}></th>
                </tr>
            </thead>
            <tbody>
            {surveys.map((surv, key) => {
                return (<tr key={key}>
                    <td>{surv.titre}</td>
                    <td>
                        <Button onClick={(e)=>go_info_page(surv)}>Fiche</Button> 
                        <Button variant='danger' onClick={(e)=>confirm_remove_survey(surv)}>Supprimer</Button>
                    </td>
                </tr>);
            })}
            </tbody>
        </table>;
    }

    const goback = () => {
        history.goBack();
    }

    const columns = [
        { name: 'Titre', selector: row => row.titre, sortable: true,},
        { name: 'Action', selector: row => row.action, sortable: true, minWidth:1, cell:row => <div>
                <div className='button_stand' onClick={(e)=>go_info_page(row)}>
                    <FontAwesomeIcon icon={faFile}/>
                </div>
                <div className='button_stand' onClick={(e)=>go_update_survey(row)}>
                    <FontAwesomeIcon icon={faCog}/>
                </div>
                <div className='button_stand' onClick={(e)=>confirm_remove_survey(row)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </div>
            </div>},
    ];
    const filteredData = surveys.filter(item => {
        let filter_key = filter_by.toLocaleLowerCase();
        return filter_search_check_item(filter_key, item, 
            ['titre']);
    });

    return (
        <div>
            <Row className='headerContentPart' >
                <FormGroup as={Col} xl={3} className='title'>QCM</FormGroup>
                {surveys.length > 0 && <FormGroup as={Col}><InputGroup className="search">
                                            <InputGroup.Text className="icon">
                                                <FontAwesomeIcon
                                                    icon={faSearch}
                                                /> 
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Filtrer"
                                                aria-label="Filtrer"
                                                aria-describedby="filtrate"
                                                type='text' 
                                                value={filter_by} 
                                                className="text_zone"
                                                onChange={e => handle_change_input(e, setFilter_by)}
                                            />
                                        </InputGroup></FormGroup>}
                <FormGroup as={Col}>
                    <div className='button_stand' 
                            onClick={() => createQ()}>
                            <FontAwesomeIcon icon={faPlus}    />
                    </div></FormGroup>
            </Row>
            
            {surveys && <DataTable
                    pagination
                    fixedHeader
                    columns={columns}
                    data={filteredData}
                    noDataComponent="Aucun QCM n'est enregistré !"
                    paginationComponentOptions={{
                                                    rowsPerPageText: 'QCM par page',
                                                    rangeSeparatorText: 'sur',
                                                    selectAllRowsItem: true,
                                                    selectAllRowsItemText: 'Tous',
                                                }}
                />}

        {/* {surveys && get_table_surveys()} */}
            
            

            <DeleteConfirmation 
                    showModal={displayConfirmationModal} 
                    confirmModal={removeSurvey}
                    hideModal={hideConfirmationModal}
                    message={<div>Êtes-vous sûr de vouloir supprimer le questionnaire <b>{survey_to_remove.titre}</b> y compris ces réponses de cette session ?</div>}
                />
            {/* <Toaster
                position="bottom-right"
                reverseOrder={false}        /> */}
        </div>
     );
}
 
export default Survey_Upd;