import { useSelector, useDispatch } from "react-redux";
import { Route, Switch, useParams } from "react-router-dom"
import { getRoutesListByRef, getAuthorizedRoutes, getItem } from "../../Routes";
import Center_Home from "../../view/center/Center_Home";
import SideBarConnected from "../../view/member/SideBar"

const HomeCenter = () => {

    const contr_prof = "/profil";
    const dispatch = useDispatch();
    
    const account = useSelector((state) => state.account);
    
    const { center_key } = useParams();
    
    // let linksNavBar = getRoutesListByRef(['profil_my']);
    const linksSideBar_ref = [  
                                'center_home',
                                'center_apropos', 
                                'center_documents',
                                'center_strucutre', 
                                // 'center_formers',
                                'center_clients',
                                'center_devises',
                                'center_programs',
                                'center_surveys',
                                'center_sessions',
                                'sessions_mine',
                                // 'trainee_sessions',
                                // 'former_sessions',
                                // 'secretary_sessions',
                                'center_calendar',
                                'center_satisfs',
                             ];

    const linksSideBar_content_ref = [];

    // const linksSideBar_content_uniq = [...new Set(linksSideBar_ref.concat(linksSideBar_content_ref))];

    const linksSideBar = getRoutesListByRef(linksSideBar_ref, account.ctr.rol);
    
    if('center_home' in linksSideBar)
    {
        linksSideBar['center_home'].name = account.ctr.name;
        linksSideBar['center_home'].layout = 'infos';
        linksSideBar['center_home'].path = '/bref';
    }
    
    // const linksSideBar_content = getRoutesListByRef(linksSideBar_content_uniq, account.ctr.rol);
    // console.log(account.ctr.rol);
    const linksSideBar_content = getAuthorizedRoutes(account.ctr.rol, ['center_home']);
    
    
    const routesSidebar = Object.values(linksSideBar);
    const routesSidebar_content = Object.values(linksSideBar_content);
    
    // console.log(routesSidebar);
    // console.log(routesSidebar_content);
    
    const getRoutes = (routesSidebar, routesSidebar_content) => {
        // console.log(routesSidebar, routesSidebar_content, routesNavbar);
        return <Switch>
            {routesSidebar.map((prop, key) =>{
                if((typeof prop =='object') && ('component' in prop)){
                    // if(('perms' in prop) && (prop.perms.includes(account.info.profil))){
                        return <Route 
                                    key={key}
                                    path={prop.layout+prop.path+(prop.params || '')}
                                    render={(props) => <prop.component {...props} />}
                                />
                    // }else{
                    //     return null;
                    // }
                }else{
                    return null;
                }
            })}
            {routesSidebar_content.map((prop, key) =>{
                if((typeof prop =='object') && ('component' in prop))
                {
                    return <Route 
                                key={key}
                                path={prop.layout+prop.path+(prop.params || '')}
                                render={(props) => <prop.component {...props} />}
                            />
                }
                else
                {
                    return null;
                }
            })}
            <Route>
                <Center_Home/>
            </Route>
            </Switch>
    }

    return (
        <div>
            <div className="HomeCenterPageDiv">
                <SideBarConnected routes={routesSidebar} account={account}/>
                <div className="ContentPageDiv">
                    {getRoutes(routesSidebar, routesSidebar_content)}
                </div>
            </div>
        </div> 
     );
}
 
export default HomeCenter;