import { useEffect, useState } from "react";
import { check_is_200, downloadBlob, post, processParams, progressMessage } from "../../../../adapters/xhr";
import { useHistory } from "react-router";
import { getItemRoute } from "../../../../Routes";
import { faCog, faInfo, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import DataTable from "react-data-table-component";

const Session_Survey_List = ({session_key, account, with_title, class_title, class_content }) => {

    let progressShow = 0;
    const history = useHistory();
    const [surveys, setsurveys] = useState([]);

    const [can_reply, setcan_reply] = useState(false);
    const [can_update, setcan_update] = useState(false);
    

    const getSurvey = () => {
        post('sessrv', 'getlist', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            opt: ['with_ts']
        }))
        .then(function (xhr){
            if(check_is_200(xhr)) {
                // console.log(xhr.data);
                if('lst' in xhr.data)
                {
                    setsurveys(xhr.data.lst);
                }
                if('rpl' in xhr.data)
                {
                    setcan_reply(xhr.data.rpl);
                }
                if('upd' in xhr.data)
                {
                    setcan_update(xhr.data.upd);
                }
            }
        }).catch(function (err){
            
        });
    }

    useEffect(()=>{
        getSurvey();
    }, []);

    const go_to_reply_form = (survey) => {
        if(can_reply == true)
        {
            if('of_ses' in survey)
                history.push({
                    pathname: getItemRoute('center_session_survey_reply_form')+session_key+'/'+survey.key,
                    state:{back:true},
                });
            else if('of_prg' in survey)
                history.push({
                    pathname: getItemRoute('center_session_program_survey_reply_form')+session_key+'/'+survey.key,
                    state:{back:true},
                });
            else if('of_sts' in survey)
                history.push({
                    pathname: getItemRoute('center_satisf_reply_form')+session_key,
                    state: {back:true, upd:true},
                });
        }
    }
    
    const go_to_info = (survey) => {
        if(can_update == true)
        {
            if('of_ses' in survey)
                history.push({
                    pathname: getItemRoute('center_session_survey_inf')+session_key+'/'+survey.key,
                    state:{back:true},
                });
            else if('of_prg' in survey)
                history.push({
                    pathname: getItemRoute('center_session_program_survey_inf')+session_key+'/'+survey.key,
                    state:{back:true},
                });
            else if('of_sts' in survey)
                history.push({
                    pathname: getItemRoute('center_session_satisf_survey_inf')+session_key+'/'+survey.key,
                    state:{back:true},
                });
        }
    }


    const columns_global = [
        { name: 'Titre', selector: row => row.titre, sortable: true,},
        { name: 'Type', selector: row => row.type, cell: row => <div className='table_tag' style={{background:('color' in row ? row.color : '#eee')}}>{row.type}</div>, sortable: true,},
        { name: 'Taux de réponse', selector: row => ('txr' in row ? row.txr+' %' : ''), sortable: true,},
        { name: 'Moy. score', selector: row => ('score' in row ? row.score+' / 10' : ''), sortable: true, minWidth:1,},
        { name: 'Action', selector: row => row.action, sortable: true, minWidth:1, cell:row => <div>
                
                {can_reply && <div className='button_stand' onClick={(e)=>go_to_reply_form(row)}>
                    <FontAwesomeIcon icon={faPencilAlt}/> Remplir
                </div>}
                {can_update && <div className='button_stand' onClick={(e)=>go_to_info(row)}>
                    <FontAwesomeIcon icon={faInfo}/> Consulter
                </div>}
                
            </div>},
    ];

    const columns = [
        { name: 'Titre', selector: row => row.titre, sortable: true,},
        { name: 'Type', selector: row => row.type, sortable: true,},
        { name: 'Action', selector: row => row.action, sortable: true, minWidth:1, cell:row => <div>
                {can_reply && <div className='button_stand' onClick={(e)=>go_to_reply_form(row)}>
                        <FontAwesomeIcon icon={faPencilAlt}/> Remplir
                    </div>}
            </div>},
    ];

    const show_standart = () => {
        return  <div>

            {surveys.length>0 && <DataTable
                    pagination
                    fixedHeader
                    columns={can_update ? columns_global : columns}
                    data={surveys}
                    noDataComponent="Aucune Enquête !"
                    paginationComponentOptions={{
                                                    rowsPerPageText: 'Enquête par page',
                                                    rangeSeparatorText: 'sur',
                                                    selectAllRowsItem: true,
                                                    selectAllRowsItemText: 'Tous',
                                                }}
                    />}
        </div>;
    }

    const goUpdatePage = () => {
        history.push({
            pathname: getItemRoute('center_session_survey_upd')+session_key,
            state:{back:true},
        });
    }

    const get_update_button = () => {
        return <div className='button_stand'>
                <FontAwesomeIcon 
                    icon={faCog}
                    onClick={() => goUpdatePage()}
                    />
                </div>
    }

    return (<div>
        {(surveys.length>0 || can_update) && 
            <div className='profil_show_item is_block'>
                <div className={class_title}>{with_title ? <div className='section_title_two'>
                    <div className='title_section'>{with_title}</div>
                    {can_update && get_update_button()}
                </div> : ''}
            </div>
        {show_standart()}
        </div>} </div>);
}
 
export default Session_Survey_List;