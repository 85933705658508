import Structure from "./view/center/Structure";
import Former_Add from "./view/Formateurs/Former_add";
import Former_Info from "./view/Formateurs/Former_Info";
import Former_List from "./view/Formateurs/Former_List";
import Session_List from "./view/session/Session_List";
import Session_Fiche from "./view/session/Session_Fiche";
import Session_Form from "./view/session/Session_Form";
import Documents_Upd from "./view/session/Items/Documents/Documents_Upd";
import Stagiaires_Upd from "./view/session/Items/Stagiaires/Stagiaires_Upd";
import Stagiaire_Fiche from "./view/session/Items/Stagiaires/Stagiaire_Fiche";
import Stagiaire_Form from "./view/session/Items/Stagiaires/Stagiaire_Form";
import Sinscrire from "./view/home/Sinscrire";
import Connect from "./view/home/Connect";
import HomeSelectCenter from "./layout/Connected/HomeSelectCenter";
import { Route } from "react-router-dom";
import HomeCenter from "./layout/Connected/HomeCenter";
import Trainee_Sessions from "./view/trainee/Trainee_Sessions";
import Trainee_Session from "./view/trainee/Trainee_Session";
import Messagerie_page from "./view/Messagerie/Messagerie_page";
import Info_ctr from "./view/center/Info_ctr";
import Profil_Form_update from "./view/profil/Profil_Form_update";
import Profil_Form_Pass from "./view/profil/Profil_Form_Pass";
import Profil_My from "./view/profil/Profil_My";
import Former_Sessions from "./view/Formateurs/Former_Sessions";
import Former_Session from "./view/Formateurs/Former_Session";
import Session_Presences from "./view/session/Items/Presences/Session_Presences";
import Session_Survey_Upd from "./view/session/Items/Survey/Survey_Upd";
import Session_Survey_Reply_Frm from "./view/session/Items/Survey/Survey_Reply_Frm";
import Satisfaction_Form from "./view/satisfaction/Satisfaction_Form";
import Satisfaction_Home from "./view/satisfaction/Satisfaction_Home";
import Center_Update from "./view/center/Center_Update";
import Center_Create from "./view/center/Center_Create";
import Center_Docs from "./view/center/Documents/Center_Docs";
import Former_Docs_Form from "./view/Formateurs/Former_Docs/Former_Docs_Form";
import Client_List from "./view/center/Clients/Client_List";
import Client_Form from "./view/center/Clients/Client_Form";
import Devis_List from "./view/center/Devis/Devis_List";
import Devis_Form from "./view/center/Devis/Devis_Form";
import Devis_Fiche from "./view/center/Devis/Devis_Fiche";
import Program_List from "./view/center/Programs/Program_List";
import Program_Fiche from "./view/center/Programs/Program_Fiche";
import Program_Form from "./view/center/Programs/Program_Form";
import Client_Fiche from "./view/center/Clients/Client_Fiche";
import Session_Gestion from "./view/session/Items/Sessions/Session_Gestion";
import Profil_Former_Form from "./view/profil/Former/Profil_Former_Form";
import Former_Search from "./view/Formateurs/Former_Search";
import Centers_Invitations from "./layout/Connected/Centers_Invitations";
import Member_Add from "./view/Structure/Members/Member_Add";
import Member_Search from "./view/Structure/Members/Member_Search";
import Stagiaire_Search from "./view/session/Items/Stagiaires/Stagiaire_Search";
import Structure_Sessions from "./view/Structure/Pages/Structure_Sessions";
import ValidateEmail from "./view/home/ValidateEmail";
import E404 from "./view/errors/E404";
import { faInbox, faUserCircle, faUsers } from "@fortawesome/free-solid-svg-icons";
import Avoirs_List from "./view/center/Devis/Avoirs_List";
import Devis_Convention_Form from "./view/center/Devis/Devis_Convention_Form";
import Session_Calender from "./view/session/Session_Calender";
import Session_Mine from "./view/session/Session_Mine";
import Signer from "./view/signature/Signer";
import Session_Survey_Fiche from "./view/session/Items/Survey/Survey_Fiche";
import Session_Survey_Frm from "./view/session/Items/Survey/Survey_Frm";
import Survey_List from "./view/center/Survey/Survey_List";
import Survey_Upd from "./view/center/Survey/Survey_Upd";
import Survey_Frm from "./view/center/Survey/Survey_Frm";
import Survey_Fiche from "./view/center/Survey/Survey_Fiche";
import Survey_Program_Reply_Form from "./view/session/Items/Survey/Survey_Program_Reply_Form";
import Survey_Program_Fiche from "./view/session/Items/Survey/Survey_Program_Fiche";
import Survey_Satisf_Reply_Form from "./view/session/Items/Survey/Survey_Satisf_Reply_Form";
import Survey_Satisf_Fiche from "./view/session/Items/Survey/Survey_Satisf_Fiche";
import Survey_Satisf_Reply_Form_Non_Connect from "./view/session/Items/Survey/Survey_Satisf_Reply_Form_Non_Connect";
import Survey_Synthese from "./view/center/Survey/Survey_Synthese";

function getList_Home(controllers, profiles){
    return [
        //// ACCUEIL : BEGIN
        // {
        //     id:'home_index',
        //     layout: controllers.contr_home,
        //     path: "/index",
        //     name: "Accueil",
        //     show: true,
        //     component: Accueil,
        // },
        {
            id:'home_index', //home_connect
            layout: controllers.contr_home,
            path: "/index", // /connect
            name: "Connexion",
            show: true,
            component: Connect,
        },
        {
            id:'seance_signature', //home_connect
            layout: controllers.contr_home,
            path: "/signature/",
            params: ':signkey',
            name: "Signature",
            show: true,
            component: Signer,
        },
        // {
        //     id:'home_apropos',
        //     layout: controllers.contr_home,
        //     path: "/apropos",
        //     name: "A propos",
        //     show: true,
        //     component: Apropos,
        // },
        {
            id:'home_validate_email',
            layout: controllers.contr_home,
            path: "/activate/",
            params: ':compte',
            name: "Valider l'email",
            show: false,
            component: ValidateEmail,
        },
        // {
        //     id:'home_part',
        //     layout: controllers.contr_home,
        //     path: "/partenaires",
        //     name: "Partenaires",
        //     show: true,
        //     component: Partenaires,
        // },
        // {
        //     id:'home_videos',
        //     layout: controllers.contr_home,
        //     path: "/videos",
        //     name: "Vidéo",
        //     show: true,
        //     component: Video,
        // },
        {
            id:'home_inscr',
            layout: controllers.contr_home,
            path: "/sinscrire",
            name: "S'inscrire",
            show: true,
            component: Sinscrire,
        },
        {
            id:'home_resetpass',
            layout: controllers.contr_home,
            path: "/reset/",
            params: ':link_key',
            name: "Connexion",
            show: false,
            component: Connect,
        },{
            id:'home_connectkey',
            layout: controllers.contr_home,
            path: "/connectkey/",
            params: ':user_key',
            name: "Connexion",
            show: false,
            component: Connect,
        },
        {
            id:'home_satisfaction_rmp',
            layout: controllers.contr_home,
            path: "/satisfaction/",
            params: ':srvu_key',
            name: "Remplir",
            show: true,
            component: Survey_Satisf_Reply_Form_Non_Connect,
        },
        //// ACCUEIL : END
    ]
}

function getList_connected(controllers, profiles){
    return [
        //// CONNECTED : BEGIN
        {
            id:'my_centres',
            layout: controllers.contr_dash,
            path: "/mescentres",
            name: "mes centres",
            color: 'green',
            icon: faUsers,
            show: true,
            component: HomeSelectCenter,
        }, 
        {
            id:'centers_invitations',
            layout: controllers.contr_dash,
            path: "/invitations",
            name: "Invitations",
            color: '#B76C17',
            icon: faInbox,
            show: true,
            component: Centers_Invitations,
        }, 
        {
            id:'error_404',
            layout: controllers.contr_error,
            path: "/404",
            name: "Error 404",
            show: true,
            component: E404,
        }, 
        //// CONNECTED : END
        //// PROFIL : BEGIN
        {
            id:'profil_my',
            layout: controllers.contr_prof,
            path: "/mon",
            icon: faUserCircle,
            name: "Mon profil",
            color: '#41AFD5',
            component: Profil_My,
        }, 
        {
            id:'profil_my_update',
            layout: controllers.contr_prof,
            path: "/update",
            name: "Modifier Mon profil",
            component: Profil_Form_update,
        }, 
        {
            id:'profil_my_password',
            layout: controllers.contr_prof,
            path: "/password",
            name: "Modifier Mon mot de passe",
            component: Profil_Form_Pass,
        },
        {
            id:'profil_my_former_space',
            layout: controllers.contr_prof,
            path: "/documents",
            name: "Modifier former space",
            component: Profil_Former_Form,
        },
        {
            id:'messenger',
            layout: controllers.contr_prof,
            path: "/messagerie/",
            params: ":session_key/:profile_key",
            name: "Messagerie",
            component: Messagerie_page,
        },
        //// PROFIL : END
    ];
}

function getList_Center(controllers, profiles){
    return [
        //// CENTER : BEGIN
        {
            id:'center_home',
            layout: controllers.contr_dash,
            path: "/center",
            name: "A propos",
            perms:[profiles.president],
            component: HomeCenter,
        }, {
            id:'center_apropos',
            layout: controllers.contr_center,
            path: "/apropos",
            name: "A propos",
            perms:[profiles.president, profiles.trainee, profiles.former, profiles.secretary, profiles.accountant],
            component: Info_ctr,
        }, 
        // {
        //     id:'center_documents_update',
        //     layout: controllers.contr_center,
        //     path: "/manager/documents",
        //     name: "Document(s)",
        //     perms:[profiles.president, profiles.secretary, profiles.accountant],
        //     component: Center_Docs_Upd,
        // }, 
        {
            id:'center_documents',
            layout: controllers.contr_center,
            path: "/documents",
            name: "Document(s)",
            perms:[profiles.president, profiles.trainee, profiles.former, profiles.secretary, profiles.accountant],
            component: Center_Docs,
        }, 
        {
            id:'center_update',
            layout: controllers.contr_center,
            path: "/update",
            name: "Formulaire",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Center_Update,
        }, {
            id:'center_create',
            layout: controllers.contr_center,
            path: "/create",
            name: "Formulaire",
            component: Center_Create,
        }, 
        {
            id:'center_strucutre',
            layout: controllers.contr_center,
            path: "/structure",
            name: "Structure",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Structure,
        },
        //// CENTER : END
        //// FORMERS : BEGIN
        {
            id:'center_formers',
            layout: controllers.contr_center,
            path: "/formers",
            name: "Formateur(s)",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Former_List,
        },
        {
            id:'center_member_doc_form',
            layout: controllers.contr_center,
            path: "/member/documents/",
            params: ":center_key/:profil_key",
            name: "Nouveau",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Former_Docs_Form,
        },
        {
            id:'center_member',
            layout: controllers.contr_center,
            path: "/membre/fiche/",
            params: ":profile_key",
            name: "fiche de présentation",
            perms:[profiles.president, profiles.former, profiles.secretary, profiles.accountant],
            component: Former_Info,
        },
        {
            id:'center_member_sessions',
            layout: controllers.contr_center,
            path: "/membre/sessions/",
            params: ":center_key/:profile_key",
            name: "fiche de présentation",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Structure_Sessions,
        },
        {
            id:'center_former_form',
            layout: controllers.contr_center,
            path: "/form/former",
            name: "Nouveau",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Former_Add,
        },
        {
            id:'center_former_search',
            layout: controllers.contr_center,
            path: "/search/former",
            name: "Nouveau",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Former_Search,
        },
        {
            id:'center_member_form',
            layout: controllers.contr_center,
            path: "/form/member",
            name: "Nouveau",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Member_Add,
        },
        {
            id:'center_member_search',
            layout: controllers.contr_center,
            path: "/search/member",
            name: "Nouveau",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Member_Search,
        },
        //// FORMERS : END
        //// SESSION : BEGIN
        {
            id:'center_sessions',
            layout: controllers.contr_center,
            path: "/sessions",
            name: "Session(s)",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Session_List,
        },
        {
            id:'sessions_mine',
            layout: controllers.contr_center,
            path: "/mes_sessions",
            name: "mes sessions",
            perms:[profiles.president, profiles.trainee, profiles.former, profiles.secretary, profiles.accountant],
            component: Session_Mine,
        },
        //// SESSION : END
        //// TRAINNER : END
        {
            id:'center_trainee_fiche',
            layout: controllers.contr_center,
            path: "/stagiaire/fiche/",
            params: ":session_key/:trainee_key",
            name: "Fiche du stagiaire",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Stagiaire_Fiche,
        },
        {
            id:'center_trainee_form',
            layout: controllers.contr_center,
            path: "/stagiaire/form/",
            params: ":session_key",
            name: "Formulaire du stagiaire",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Stagiaire_Form,
        },
        //// TRAINNER : END
    ];
}

function getList_Session(controllers, profiles){
    return [
        {
            id:'center_session_fiche',
            layout: controllers.contr_session,
            path: "/fiche/",
            params: ":session_key",
            name: "Session info",
            perms:[profiles.president, profiles.former, profiles.secretary, profiles.accountant, profiles.trainee],
            component: Session_Fiche,
        },
        {
            id:'center_session_desc',
            layout: controllers.contr_session,
            path: "/information/",
            params: ":center_key/:session_key",
            name: "Session info",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Session_Fiche,
        },
        {
            id:'center_session_form',
            layout: controllers.contr_session,
            path: "/nouvelle",
            name: "Session formulaire",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Session_Form,
        },
        {
            id:'center_session_update',
            layout: controllers.contr_session,
            path: "/modifier/",
            params: ":session_key",
            name: "Session Modification",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Session_Form,
        },
        {
            id:'center_session_gestion',
            layout: controllers.contr_session,
            path: "/gestion/",
            params: ":center_key/:session_key",
            name: "Session Gestion",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Session_Gestion,
        },
        {
            id:'center_session_trainees',
            layout: controllers.contr_session,
            path: "/stagiaires/",
            params: ":session_key",
            name: "Session stagiaires",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Stagiaires_Upd,
        },
        {
            id:'center_session_trainees_search',
            layout: controllers.contr_session,
            path: "/recherchestagiaires/",
            params: ":session_key",
            name: "Session stagiaires",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Stagiaire_Search,
        },
        {
            id:'center_session_docs',
            layout: controllers.contr_session,
            path: "/documents/",
            params: ":session_key",
            name: "Session documents",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Documents_Upd,
        },
        {
            id:'center_calendar',
            layout: controllers.contr_session,
            path: "/calendrier",
            name: "Calendrier",
            perms:[profiles.president],
            component: Session_Calender,
        },
        {
            id:'center_session_presences',
            layout: controllers.contr_session,
            path: "/presences/",
            params: ":session_key",
            name: "Liste de présence",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Session_Presences,
        },
        // SURVEY
        {
            id:'center_session_survey_upd',
            layout: controllers.contr_session,
            path: "/qcms/",
            params: ":session_key",
            name: "QCMs",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Session_Survey_Upd,
        },
        {
            id:'center_session_survey_form',
            layout: controllers.contr_session,
            path: "/qcmfrm/",
            params: ":session_key",
            name: "QCM",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Session_Survey_Frm,
        },
        {
            id:'center_session_survey_update',
            layout: controllers.contr_session,
            path: "/qcmupd/",
            params: ":session_key/:survey_key",
            name: "QCM",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Session_Survey_Frm,
        },
        {
            id:'center_session_survey_inf',
            layout: controllers.contr_session,
            path: "/qcm/fiche/",
            params: ":session_key/:survey_key",
            name: "QCM, fiche d'info",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Session_Survey_Fiche,
        },
        {
            id:'center_session_survey_reply_form',
            layout: controllers.contr_session,
            path: "/qcm/form/",
            params: ":session_key/:survey_key",
            name: "répondre un qcm",
            perms:[profiles.president, profiles.secretary, profiles.accountant, profiles.trainee],
            component: Session_Survey_Reply_Frm,
        },
        {
            id:'center_session_program_survey_inf',
            layout: controllers.contr_session,
            path: "/enquete/fiche/",
            params: ":session_key/:survey_key",
            name: "QCM, fiche d'info",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Survey_Program_Fiche,
        },
        {
            id:'center_session_program_survey_reply_form',
            layout: controllers.contr_session,
            path: "/enquete/form/",
            params: ":session_key/:survey_key",
            name: "répondre un qcm",
            perms:[profiles.president, profiles.secretary, profiles.accountant, profiles.trainee],
            component: Survey_Program_Reply_Form,
        },
        // SATISFACTION
        {
            id:'center_satisfs',
            layout: controllers.contr_session,
            path: "/satisfactions",
            name: "Satisfaction(s)",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Satisfaction_Home,
        },
        {
            id:'center_satisf_form',
            layout: controllers.contr_session,
            path: "/satisfaction/update/",
            name: "Satisfaction Formulaire",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Satisfaction_Form,
        },
        {
            id:'center_satisf_reply_form',
            layout: controllers.contr_session,
            path: "/satisfaction/form/",
            params: ":session_key",
            name: "SATISFACTION",
            perms:[profiles.trainee],
            component: Survey_Satisf_Reply_Form,
        },
        {
            id:'center_session_satisf_survey_inf',
            layout: controllers.contr_session,
            path: "/satisfaction/fiche/",
            params: ":session_key/:survey_key",
            name: "SATISFACTION",
            perms:[profiles.trainee],
            component: Survey_Satisf_Fiche,
        },
        // CLIENTS
        {
            id:'center_clients',
            layout: controllers.contr_center,
            path: "/clients",
            name: "Client(s)",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Client_List,
        },
        {
            id:'center_client_info',
            layout: controllers.contr_center,
            path: "/client/information/",
            params: ":center_key/:client_key",
            name: "Client information",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Client_Fiche,
        },
        {
            id:'center_client_update',
            layout: controllers.contr_center,
            path: "/client/update/",
            params: ":center_key/:client_key",
            name: "Client Formulaire",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Client_Form,
        },
        {
            id:'center_client_novel',
            layout: controllers.contr_center,
            path: "/client/nouveau/",
            params: ":center_key",
            name: "Client Formulaire",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Client_Form,
        },
        // Devis
        {
            id:'center_devises',
            layout: controllers.contr_center,
            path: "/devises",
            name: "Devis / Facture(s)",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Devis_List,
        },
        {
            id:'center_devis_convention',
            layout: controllers.contr_center,
            path: "/convention/",
            params: ":center_key",
            name: "Devis / Facture(s)",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Devis_Convention_Form,
        },
        {
            id:'center_devis_info',
            layout: controllers.contr_center,
            path: "/devis/fiche/",
            params: ":center_key/:devis_key",
            name: "Devis informations",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Devis_Fiche,
        },
        {
            id:'center_devis_update',
            layout: controllers.contr_center,
            path: "/devis/update/",
            params: ":center_key/:devis_key",
            name: "Devis Formulaire",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Devis_Form,
        },
        {
            id:'center_devis_novel',
            layout: controllers.contr_center,
            path: "/devis/nouveau/",
            params: ":center_key",
            name: "Devis Formulaire",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Devis_Form,
        },
        {
            id:'center_avoir_list',
            layout: controllers.contr_center,
            path: "/avoir/list/",
            params: ":center_key",
            name: "Liste des avoirs",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Avoirs_List,
        },
        // Programmes
        {
            id:'center_programs',
            layout: controllers.contr_center,
            path: "/programmes",
            name: "Programme(s)",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Program_List,
        },
        {
            id:'center_program_info',
            layout: controllers.contr_center,
            path: "/programme/fiche/",
            params: ":center_key/:program_key",
            name: "Programme informations",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Program_Fiche,
        },
        {
            id:'center_program_update',
            layout: controllers.contr_center,
            path: "/programme/update/",
            params: ":center_key/:program_key",
            name: "Programme Formulaire",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Program_Form,
        },
        {
            id:'center_program_novel',
            layout: controllers.contr_center,
            path: "/programme/nouveau/",
            params: ":center_key",
            name: "Programme Formulaire",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Program_Form,
        },
        // Programmes
        {
            id:'center_surveys',
            layout: controllers.contr_center,
            path: "/enquetes",
            name: "Enquête(s)",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Survey_List,
        },
        {
            id:'center_survey_upd',
            layout: controllers.contr_center,
            path: "/enquete/modifier/",
            params: ":center_key",
            name: "Enquête",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Survey_Upd,
        },
        {
            id:'center_survey_form',
            layout: controllers.contr_center,
            path: "/enquete/formulaire/",
            params: ":center_key",
            name: "Enquête",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Survey_Frm,
        },
        {
            id:'center_survey_update',
            layout: controllers.contr_center,
            path: "/enquete/update/",
            params: ":center_key/:survey_key",
            name: "Enquête",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Survey_Frm,
        },
        {
            id:'center_survey_inf',
            layout: controllers.contr_center,
            path: "/enquete/fiche/",
            params: ":center_key/:survey_key",
            name: "Fiche d'info",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Survey_Fiche,
        },
        {
            id:'center_survey_syn',
            layout: controllers.contr_center,
            path: "/enquete/synthese/",
            params: ":center_key/:survey_key",
            name: "Synthèse",
            perms:[profiles.president, profiles.secretary, profiles.accountant],
            component: Survey_Synthese,
        },
    ];
}

function getList_Trainee(controllers, profiles){
    return [
        {
            id:'trainee_sessions',
            layout: controllers.contr_trainee,
            path: "/sessions",
            name: "Mes sessions",
            perms:[profiles.trainee],
            component: Trainee_Sessions,
        },{
            id:'trainee_session',
            layout: controllers.contr_trainee,
            path: "/session/",
            params: ":session_key",
            name: "Session info",
            perms:[profiles.trainee],
            component: Trainee_Session,
        },
    ];
}

function getList_Former(controllers, profiles){
    return [
        {
            id:'former_sessions',
            layout: controllers.contr_former,
            path: "/sessions",
            name: "Formateur(trice)",
            perms:[profiles.former],
            component: Former_Sessions,
        },{
            id:'former_session',
            layout: controllers.contr_former,
            path: "/session/",
            params: ":session_key",
            name: "Session info",
            perms:[profiles.former],
            component: Former_Session,
        },
    ];
}

function getList_Secretary(controllers, profiles){
    return [
        // {
        //     id:'secretary_sessions',
        //     layout: controllers.contr_secretary,
        //     path: "/sessions",
        //     name: "Session(s) administrée(s)",
        //     perms:[profiles.secretary],
        //     component: Secretaty_Sessions,
        // },
    ];
}

function getList(){
    
    const controllers = controllers_list();
    const profiles = profiles_list();

    return   [
        ...getList_Home(controllers, profiles),
        ...getList_connected(controllers, profiles),
        ...getList_Center(controllers, profiles),
        ...getList_Session(controllers, profiles),
        ...getList_Trainee(controllers, profiles),
        ...getList_Former(controllers, profiles),
        ...getList_Secretary(controllers, profiles),
    ];
}
export function getItem(indice) {
    let list = getList().filter((e) => e.id===indice);
    if(list.length > 0) return list[0];
    else return false;
}

export function getItemsByLayout(layout) {
    return getList().filter((e) => e.layout===layout);
}

export function getItemsByLayouts(layouts) {
    return getList().filter((e) => layouts.includes(e.layout));
}

export function getItemRoute(indice) {
    let item = getItem(indice);
    // // console.log('ITEM', item);
    if(item) return item.layout + item.path;
    else return '/';
}

export function getRoutesListByRef(routes=[], roles=[]){
    let result = {};
    for(var ind of routes){
        let route = getItem(ind);
        if(route && 'perms' in route){
            
            if(check_perm(roles, route.perms)){
                // // console.log(route.id);
                result[ind] = route;
            } 
        }else result[ind] = route;
        
    }
    return result;
}

export function getRoutesList(routes=[], roles=[]){
    let result = [];
    for(var route of routes){
        if(route && 'perms' in route){
            if(check_perm(roles, route.perms)){
                // // console.log(route.id);
                result.push(route);
            } 
        }else result.push(route);
        
    }
    return result;
}

function check_perm(roles = [], perms = []){
    let check = roles.filter(function(n) {
        return perms.indexOf(n) !== -1;
    }).length > 0;
    // if(check) // console.log(roles, perms);
    return check;
}

export function getALLRoutes(){
    return getList();
}

export function getAuthorizedRoutes(roles = [], without=[]){
    return getRoutesList(getList(), roles).filter((r) => !without.includes(r.id));
}

export function controllers_list(){
    return {
        contr_home: "/home",
        contr_error:"/erreur",
        contr_dash: "/dashboard",
        contr_prof: "/profil",
        contr_center: "/center",
        contr_former: "/formateur",
        contr_secretary: "/secretaire",
        contr_session: "/session",
        contr_trainee: "/stagiaire",
    }
}

export function get_principals(){
    return Object.values(controllers_list());
}

export function profiles_list(){
    return {
        president: "prs",
        trainee: "stg",
        secretary: "sct",
        accountant: "cmp",
        former: "frm"
    }
}

export function getRoutes(routes = []) {
    return routes.map((prop, key) => {
        // // console.log('path:', prop.layout + prop.path + (prop.params || ''));
        return <Route
                    path={prop.layout + prop.path + (prop.params || '')}
                    render={(props) => <prop.component {...props} />}
                    key={key}
                />
    });
};