import { useEffect, useState } from "react";
import { Row, FormGroup, Col, FormControl, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { post, processParams, progressMessage, downloadBlob, popup_show_file, check_is_200 } from "../../../adapters/xhr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { filter_search_check_item } from "../../../tools/DataTableOpt";
import { handle_change_input } from "../../../tools/HInput";

const Avoirs_List = () => {

    const history = useHistory();
    const account = useSelector((state) => state.account);
    
    let progressShow = 0;



    const [center_key, setCenter_key] = useState(account.ctr.key);
    const [avoir_list, setAvoir_list] = useState([]);
    const [filter_by, setFilter_by] = useState('');
    
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [document_to_remove, setDocument_to_remove] = useState({});
    
    const [validated, setValidated] = useState(false);
    const [frm_titre, setFrm_titre] = useState('');
    const [frm_file, setFrm_file] = useState('');


    const hideConfirmationModal = () => { setDisplayConfirmationModal(false); }

    const getData = () => {
        const params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key
        });

        post("ctrdev", "avrlst", params)
        .then((xhr) => {
            if(check_is_200(xhr)) 
            {
                setAvoir_list(xhr.data.list || []);
            }
        }).catch(function (err){
            
        });
    }

    useEffect(() => {
        getData();
    }, []);



    // const getActions = (doc) => {
    //     return (<div className='buttons_table'>
    //                 <div></div>
    //                 <div className='button_stand' data-tooltip="Ouvrir" onClick={(e)=>showFile(doc)}><FontAwesomeIcon icon={faFile} /></div>
    //                 <div className='button_stand' data-tooltip="Télécharger" onClick={(e)=>getFile(doc)}><FontAwesomeIcon icon={faFileDownload} /></div>
    //                 <div className='button_stand' data-tooltip="Supprimer" onClick={(e)=>confirmRemoveFile(doc)}><FontAwesomeIcon icon={faTimes} /></div>
    //             </div>);
    // }
    const columns = [
        { name: 'N°Avoir', selector: row => row.num, sortable: true,},
        { name: 'N°Devis', selector: row => row.devis, sortable: true,},
        { name: 'N°Facture', selector: row => row.facture, sortable: true,},
        { name: 'Année', selector: row => row.year, sortable: true,},
        { name: 'Par', selector: row => row.par, sortable: true,},
        { name: 'le', selector: row => row.date, sortable: true,},
        { name: 'Message', selector: row => row.msg, sortable: true,},
        // { name: 'Action', selector: row => row.action, sortable: true, minWidth:1, cell:row => <div style={{width:180}}>{getActions(row)}</div>},
    ];

    const filteredData = avoir_list.filter(item => {
        let filter_key = filter_by.toLocaleLowerCase();
        return filter_search_check_item(filter_key, item, 
            ['devis', 'facture']);
    });

    return ( 
        <div>
            <Row className='headerContentPart' >
                <FormGroup as={Col} xl={3} className='title'>Avoir(s) du centre</FormGroup>
                {avoir_list.length > 0 && <FormGroup as={Col}><InputGroup className="search">
                                                <InputGroup.Text className="icon">
                                                    <FontAwesomeIcon
                                                        icon={faSearch}
                                                    /> 
                                                </InputGroup.Text>
                                                <FormControl
                                                    placeholder="Filtrer"
                                                    aria-label="Filtrer"
                                                    aria-describedby="filtrate"
                                                    type='text' 
                                                    value={filter_by} 
                                                    className="text_zone"
                                                    onChange={e => handle_change_input(e, setFilter_by)}
                                                />
                                            </InputGroup></FormGroup>}
            </Row>

            <DataTable
                    pagination
                    fixedHeader
                    columns={columns}
                    data={filteredData}
                    noDataComponent="Aucun avoir n'est enregistré !"
                    paginationComponentOptions={{
                                                    rowsPerPageText: 'Avoir par page',
                                                    rangeSeparatorText: 'sur',
                                                    selectAllRowsItem: true,
                                                    selectAllRowsItemText: 'Tous',
                                                }}
                />
            
        </div>
     );
}
 
export default Avoirs_List;