import { Switch, Route } from 'react-router-dom';
import NavbarHome from '../../view/home/NavbarHome'
import { controllers_list, getItemRoute, getItemsByLayout } from '../../Routes';
import Connect from '../../view/home/Connect';

const HomeGuest = () => {
    const routesHome = getItemsByLayout(controllers_list().contr_home);
    
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            return <Route
                        path={prop.layout + prop.path + (prop.params || '')}
                        render={(props) => <prop.component {...props} />}
                        key={key}
                    />
        });
    };

    return ( 
        <div>
            {/* <NavbarHome routesHome={routesHome} /> */}
            <div className="content">
                <Switch>
                    {getRoutes(routesHome)}
                    <Route
                        path='/'
                        render={(props) => <Connect />}
                    />
                </Switch>
            </div>
        </div>
     );
}
 
export default HomeGuest;