import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { check_is_200, post, processParams } from "../../adapters/xhr";

const Centers_Invitations = () => {
    
    const account = useSelector(state => state.account);
    const [listInvitations, setListInvitations] = useState([]);

    const getData = () => {
        post('ctrntw', 'requests', processParams({
            tkn:account.info.tkn,
        }))
        .then((xhr) => {
            if(check_is_200(xhr))
            {
                let list = xhr.data.list || [];
                let list_endo = [];
                for(let item of list)
                {
                    
                    for(let role of item.roles)
                    {
                        role.name = item.name;

                        list_endo.push(role);
                    }
                }
                setListInvitations(list_endo);
                console.log(list_endo);
            }
        })
        .catch((err) => {

        });
    }

    useEffect(() => {
        getData();
    }, []);

    const reponse = (request_key, decision) => {
        const toastId = toast.loading('Envoi');
        post('ctrntw', 'response', processParams({
            tkn: account.info.tkn,
            req: request_key,
            acp: decision
        }))
        .then(function(xhr){
            if(check_is_200(xhr)) {
                if(('state' in xhr.data) && ('msg' in xhr.data)){
                    if(xhr.data.state){
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        getData();
                    }else{
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }else{
                    toast.error(<b>Problème d'envoi!</b>, { id: toastId, });
                }
            }else{
                toast.error(<b>Problème d'envoi!</b>, { id: toastId, });
            }
        }).catch(function(error){
            toast.error(<b>Problème d'envoi!</b>, { id: toastId, });
        }).then(function() {
        });
    }

    const getActions = (role) => {
        return (<div className='buttons_table'>
                    <div></div>
                    <div className='button_stand' onClick={()=>reponse(role.key, true)}><FontAwesomeIcon icon={faCheck}/> Accepter</div>
                    <div className='button_stand' onClick={()=>reponse(role.key, false)}><FontAwesomeIcon icon={faTimes}/> Refuser</div>
                </div>);
    }

    const columns = [
        { name: 'Centre', selector: row => row.name, sortable: true,},
        { name: 'Rôle', selector: row => row.nom, sortable: true,},
        { name: 'Action', selector: row => row.action, sortable: true, minWidth:1, cell:row => <div>{getActions(row)}</div>},
    ];
    return ( <div>
        <h1 className='pageTitle'>Invitation{listInvitations.length>0 && 's'}</h1>
        <br/>
        <DataTable
                    pagination
                    fixedHeader
                    columns={columns}
                    data={listInvitations}
                    noDataComponent="Aucune invitation !"
                    paginationComponentOptions={{
                                                    rowsPerPageText: 'Invitations par page',
                                                    rangeSeparatorText: 'sur',
                                                    selectAllRowsItem: true,
                                                    selectAllRowsItemText: 'Tous',
                                                }}
                />
                
        
    </div> );
}
 
export default Centers_Invitations;