import { faEraser, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { check_is_200, downloadBlob, popup_show_file, post, processParams, progressMessage } from "../../../../adapters/xhr";
import { array_average } from "../../../../tools/Arrays";
import DeleteConfirmation from "../../../../tools/components/popups/DeleteConfirmation";
import InfoPopUp from "../../../../tools/components/popups/InfoPopUp";
import { show_paragraph } from "../../../../tools/HInput";
import { percentage } from "../../../../tools/math";

const Survey_Satisf_Fiche = () => {

    const account = useSelector((state) => state.account);
    const { session_key, survey_key } = useParams();

    const [session, setSession] = useState({});
    const [survey, setSurvey] = useState({});
    const [nbr_noted_questions, setNbr_noted_questions] = useState(0);
    const [nbr_all_questions, setNbr_all_questions] = useState(0);
    const [reponses, setReponses] = useState([]);
    const [groupes, setGroupes] = useState([]);
    
    const [score_glb, setScore_glb] = useState('');
    const [score_v, setScore_v] = useState('');
    const [score_nv, setScore_nv] = useState('');

    const [nbr_rep_e, setNbr_rep_e] = useState('');
    const [nbr_rep_valid, setNbr_rep_valid] = useState('');
    const [nbr_rep_n_valid, setNbr_rep_n_valid] = useState('');
    
    const [nbr_q_rep_valid, setNbr_q_rep_valid] = useState('');
    const [nbr_q_rep_n_valid, setNbr_q_rep_n_valid] = useState('');


    const [selectedTrainee, setSelectedTrainee] = useState({});
    const [displayReponseModal, setDisplayReponseModal] = useState(false);

    const [displayConfirmationModal, setdisplayConfirmationModal] = useState(false);
    const [reponse_to_reini, setReponse_to_reini] = useState('');
    const [reponse_to_reini_name, setreponse_to_reini_name] = useState('');

    const getData = () => {
        let params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            srv: survey_key
        };
        // console.log(params);

        post('stsf', 'report', processParams(params))
        .then(function (xhr){
            if(check_is_200(xhr))
            {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        // console.log(xhr.data.rps);

                        setSession(xhr.data.session || {});
                        setSurvey(xhr.data.survey || {});
                        setNbr_noted_questions(xhr.data.nbr_qst.note || []);
                        setNbr_all_questions(xhr.data.nbr_qst.all || []);
                        setReponses(xhr.data.rps || []);
                        setGroupes(xhr.data.grps || []);
                    }
                    else toast.error(<b>{xhr.data.msg}</b>);
                }
                else toast.error(<b>Problème d'envoi !</b>);
            }
            else toast.error(<b>Problème d'envoi !</b>);
        });
    }

    useEffect(() => getData(), []);
    

    useEffect(() => {
        update_reponses();
    }, [reponses]);

    const update_reponses = () => {
        let repni_e = 0;
        
        let repni_v = 0;
        let repnq_v = 0;
        let score_v = [];

        let repni_nv = 0;
        let repnq_nv = 0;
        let score_nv = [];

        reponses.map((rep, key) => 
        {
            if(rep.iss)
            {
                if(rep.isv)
                {
                    repni_v++;
                    repnq_v += rep.rps.length;
                    score_v.push(rep.scr);
                }
                else
                {
                    repni_nv++;
                    repnq_nv += rep.rps.length;
                    score_nv.push(rep.scr);
                }
            }
            else
            {
                repni_e++;
            }
        });

        if(reponses.length)
        {
            setNbr_rep_e(repni_e);

            setNbr_rep_valid(repni_v);
            setScore_v ( array_average(score_v, 1) );
            setNbr_q_rep_valid(repnq_v);
            
            setNbr_rep_n_valid(repni_nv);
            setScore_nv ( array_average(score_nv, 1) );
            setNbr_q_rep_n_valid(repnq_nv);

            setScore_glb( percentage(score_v + score_nv, (repni_v + repni_nv) * nbr_noted_questions) );
        }
    }

    const show_numbers_old = () => {
        return <div className='display_flex'>
            
            <div>
                <div className={'survey_global_score '+( score_glb > 7 ? 'good_point' : ( score_glb > 4.5 ? 'medium_point' : 'bad_point' ))}>
                    {score_glb}
                    <div className='sur_10'> /10</div>
                </div>
            </div>
            
            <div>
                {nbr_rep_valid > 0 && <div className='survey_global_element display_flex margin_10'>
                    
                    <div><div className={'survey_item_score '+( score_v > 7 ? 'good_point' : ( score_v > 4.5 ? 'medium_point' : 'bad_point' ))}>
                        {score_v}
                        <div className='sur_10'> /10</div>
                    </div></div>
                    <div className="margin_left_10px">
                        <h2>Réponse{nbr_rep_valid > 1 ? 's' : ''} validée{nbr_rep_valid > 1 ? 's' : ''}</h2>
                        <ul>
                            <li>{nbr_rep_valid} réponse{nbr_rep_valid > 1 ? 's' : ''}</li>
                            <li>Questions répondu {percentage(nbr_q_rep_valid, nbr_rep_valid * nbr_all_questions)+' %'}</li>
                        </ul>
                    </div>
                </div>}

                {nbr_rep_n_valid > 0 && <div className='survey_global_element display_flex margin_10'>
                    
                    <div><div className={'survey_item_score '+( score_nv > 7 ? 'good_point' : ( score_nv > 4.5 ? 'medium_point' : 'bad_point' ))}>
                        {score_nv}
                        <div className='sur_10'> /10</div>
                    </div></div>
                    <div className="margin_left_10px">
                        <h2>Réponse{nbr_rep_n_valid > 1 ? 's' : ''} non validée{nbr_rep_n_valid > 1 ? 's' : ''}</h2>
                        <ul>
                            <li>{nbr_rep_n_valid} réponse{nbr_rep_n_valid > 1 ? 's' : ''}</li>
                            <li>Questions répondu {percentage(nbr_q_rep_n_valid, nbr_rep_n_valid * nbr_all_questions)+' %'}</li>
                        </ul>
                    </div>
                </div>}
                
                {nbr_rep_e > 0 && <div className='survey_global_element margin_10'>
                    <h5>En attente de {nbr_rep_e} stagiaire{nbr_rep_e > 1 ? 's' : '' }</h5>
                </div>}
            </div>

        </div>
    }

    const show_numbers = () => {
        return <div className='' style={{textAlign:'center'}}>
            
            <div>
                <h2>Moyenne score</h2>
                <div style={{fontSize:'700%'}} className={'survey_global_score '+( score_v > 7 ? 'good_point' : ( score_v > 4.5 ? 'medium_point' : 'bad_point' ))}>
                    {score_v}
                    <div className='sur_10'> /10</div>
                </div>
            </div>
            
            <div>
                {reponses.length>0 && <div className='survey_global_element margin_10 cell_item box_shadow_principal'>
                    Taux de réponse {percentage(nbr_rep_valid, reponses.length)+' %'}
                </div>}
                
                {nbr_rep_e > 0 && <div className='survey_global_element margin_10 cell_item box_shadow_principal'>
                    En attente de {nbr_rep_e} stagiaire{nbr_rep_e > 1 ? 's' : '' }
                </div>}
            </div>

        </div>
    }


    const handleInitReponse = (trainee) => {
        setReponse_to_reini(trainee);
        setreponse_to_reini_name(trainee.trn);
        setdisplayConfirmationModal(true);
    }

    const reinitialReponse = () => {
        const toastId = toast.loading("Réinitialisation");
        
        post('stsfr', 'reini', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            srv: survey_key,
            trn: reponse_to_reini.key
        }))
        .then(function (xhr)
        {
            if(check_is_200(xhr))
            {
                if(('state' in xhr.data) && ('msg' in xhr.data))
                {
                    if(xhr.data.state){
                        setReponses(reponses.map(item => {
                            if(reponse_to_reini.key == item.key || reponse_to_reini.key=='all')
                            {
                                item.iss = false;
                                item.isv = false;
                            }
                            return item;
                        }));
                        // update_reponses();
                        
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
                }
            }
            else
            {
                toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
            }
        }).catch(err => {
            toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
        });
        
        hideConfirmationModal();
    }

    const hideConfirmationModal = () => {
        setdisplayConfirmationModal(false);
    }

    const columns = [
        { name: 'Stagiaire', selector: row => row.trn, sortable: true,},
        { name: 'Statut',  selector: row => !row.iss ? 'En attente' : ( row.isv ? 'Validé' : 'Enregistré'), 
                            cell: row => <div className='table_tag' style={{background:(!row.iss ? '#FFDE9D' : ( row.isv ? '#AAF97D' : '#7DF9EE'))}}>
                                            {!row.iss ? 'En attente' : ( row.isv ? 'Validé' : 'Enregistré')}
                                         </div>,
                            sortable: true,
                        },
        { name: 'Progression', selector: row => (row.iss && 'rps' in row ? row.rps.length+' / '+nbr_all_questions : ''), sortable: true,},
        { name: 'Score', selector: row => get_reponse_score(row), sortable: true,},
        { name: 'Action', selector: row => row.action, sortable: true, minWidth:1, cell:row => <div>
                {row.iss && <div>
                        <div className='button_stand' data-tooltip='Consulter' onClick={e => handleTraineeReponse(row)}><FontAwesomeIcon icon={faEye}/></div>
                        <div className='button_stand' data-tooltip='Réinitialiser' onClick={e => handleInitReponse(row)} variant='danger'><FontAwesomeIcon icon={faEraser}/></div>
                    </div>}
            </div>},
    ];


    const filteredReponses = reponses.filter(item => true);

    const afficherList = () =>
    {

        return (
            <div>
                {show_numbers()}
              
                <br/>
              
                <DataTable
                    pagination={false}
                    fixedHeader
                    columns={columns}
                    data={filteredReponses}
                    defaultSortFieldId={1}
                    noDataComponent="Aucune réponse !"
                    paginationComponentOptions={{
                                                    rowsPerPageText: 'réponse par page',
                                                    rangeSeparatorText: 'sur',
                                                    selectAllRowsItem: true,
                                                    selectAllRowsItemText: 'Tous',
                                                }}
                    />

              
                <br/><br/>
                <DeleteConfirmation
                        showModal={displayConfirmationModal} 
                        confirmModal={reinitialReponse}
                        hideModal={hideConfirmationModal} 
                        message={"Êtes-vous sûr de vouloir réinitialiser "+(reponse_to_reini_name=='' ? 'tous les réponses':'la réponse de '+reponse_to_reini_name)+' ?'}
                    />
                {/* <Toaster
                    position="bottom-right"
                    reverseOrder={false}        /> */}
            </div>
        );
    }


    const handleTraineeReponse = (trainee) => {
        setSelectedTrainee(trainee);
        setDisplayReponseModal(true);
    }
    const hideDisplayModal = () => {
        setDisplayReponseModal(false);
    }

    useEffect(() => {
        if('key' in selectedTrainee){
            // getTraineeReponseData();
        }
    }, [selectedTrainee]);

    const getSelectedReponseFiche = () => {

        if(displayReponseModal){
            let is_valid = 'isv' in selectedTrainee && selectedTrainee.isv;
            let the_score_and_div = get_score_and_div(is_valid);
            // console.log(the_score_and_div);
            return (
                <div className='popup_reponse'>
                    {is_valid && <div className={'survey_item_score '+( the_score_and_div.score > 7 ? 'good_point' : ( the_score_and_div.score > 4.5 ? 'medium_point' : 'bad_point' ))}>
                        {the_score_and_div.score}
                        <div className='sur_10'> /10</div>
                    </div>}
                        
                    <table className='table_enq_reponse'>
                        {the_score_and_div.html}
                    </table>
                </div>
            );
        }
    }

    const get_score_and_div = (show_note) => {
        
        let adapt_key = [];
        for(let rep of selectedTrainee.rps)
        {
            adapt_key [ rep.key ] = rep;
        }

        let grps_html = [];

        
        let grps_scr = [];
        let grp_scr = false;
        let cmp_key = 0;

        for(let grp of groupes)
        {
            grp_scr = false;

            let grp_inf = get_score_and_div_by_group(grp, adapt_key, show_note);
            grp_scr = false;

            if(show_note && grp_inf.score.length > 0)
            {
                grp_scr = array_average(grp_inf.score, 1);
                grps_scr.push(grp_scr);
            }

            if('html' in grp_inf && grp_inf.html.length > 0)
            {
                grps_html.push(  <tbody key={cmp_key++}>
                                    <tr>
                                        <td colSpan={(show_note && grp_scr!==false) ? 2 : 3} style={{textAlign:'left'}}>
                                            {grp.titre}
                                        </td>
                                        {(show_note && grp_scr!==false) && <td style={{width:'90px'}} className={( grp_scr > 7 ? 'good_point' : ( grp_scr > 4.5 ? 'medium_point' : 'bad_point' ))}>
                                                            <b>{grp_scr}</b>
                                                            <div className='div_according' style={{fontSize:'60%'}}> /10</div>
                                        </td>}
                                    </tr>
                                    {grp_inf.html}
                                </tbody>  );
            }
        }
        
        // console.log(grps_scr);
        return {score:array_average(grps_scr, 1), html:grps_html};
    }

    const get_score_and_div_by_group = (grp, adapt_key, show_note) => {

        if(!('qsts' in grp)) return {score:0, html:''};

        let grp_html = [];
        let grp_score = [];

        let cmp_key = 0;
        for(let qst of grp.qsts)
        {
            let is_noted = 'isn' in qst && qst.isn;

            let val = '';
            let scr = 0;

            if(qst.key in adapt_key)
            {
                val = adapt_key[qst.key].val;

                if('scr' in adapt_key[qst.key])
                {
                    scr = adapt_key[qst.key].scr;
                }

                for(let chx of qst.choix)
                {
                    if ( val == chx.key ) val = chx.titre;
                }
            }
            
            if(is_noted) grp_score.push(scr);

            // if(val!='')
            grp_html.push(  <>
                                <tr className='question_line' key={cmp_key++}>
                                    <td>Question</td>
                                    <td style={{textAlign:'left'}} colSpan={(is_noted && show_note) ? 1 : 2}>{qst.titre}</td>
                                    {(is_noted && show_note) && <td rowSpan='2' style={{width:'90px'}} className={( scr > 7 ? 'good_point' : ( scr > 4.5 ? 'medium_point' : 'bad_point' ))}>
                                            {scr}
                                            <div className='div_according' style={{fontSize:'60%'}}> /10</div>
                                        </td>}
                                </tr>
                                <tr className='reponse_line' key={cmp_key++}>
                                    <td style={{verticalAlign:'top'}}>Réponse</td>
                                    <td style={{textAlign:'left'}} colSpan={(scr!='' && show_note) ? 1 : 2}>{show_paragraph(val, false)}</td>
                                </tr>
                            </>  );
        }

        return {score:grp_score, html:grp_html};
    }

    const go_print_reponse = () => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            srv: survey_key,
            trn: selectedTrainee.key
        };

        post('stsfr', 'evalfile', processParams(params))
        .then((xhr) => {
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé</b>, { id: toastId, });
                }
                else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });            
        }).catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const go_print_synthese = () => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            srv: survey_key
        };
        
        post('stsfr', 'synfile', processParams(params))
        .then((xhr) => {
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé</b>, { id: toastId, });
                }
                else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        }).catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const get_reponse_score = (reponse) => {
        return reponse.iss && 'isv' in reponse && reponse.isv && 'scr' in reponse ? reponse.scr +' / 10' : ''
    }

    return ( <div>
        <div className='headerContentPart flex_between' >
            <div className='title div_according'>{session.titre}</div>
            <div>
                
                {(nbr_rep_valid > 0 ) && <div className='div_according'>
                    <div className='button_stand' onClick={()=>go_print_synthese()}>
                        Synthèse
                    </div>
                </div>}

                {((nbr_rep_valid + nbr_rep_n_valid)>0) && <div className='div_according'>
                        <div className='button_stand button_danger' onClick={(e)=>handleInitReponse({key:'all', trn:''})}>
                            Réinitialiser
                        </div>
                    </div>}

            </div>
        </div>

        <div className="cell_item" style={{backgroundColor:survey.color}}>
            {'type' in survey && survey.type} 
            &nbsp; : &nbsp;
            {survey.titre}
        </div>

        {reponses.length>0 && afficherList()}
        
        <InfoPopUp 
                    showModal={displayReponseModal} 
                    hideModal={hideDisplayModal}
                    titleModal={<div className='flex_between'>
                                    <div>{('trn' in selectedTrainee) ? selectedTrainee.trn : ''}</div>
                                </div>}
                    size='60'
                    okText="Imprimer"
                    cancelText="Fermer"
                    messageFunction={getSelectedReponseFiche}
                    okAction={go_print_reponse}
                    params={selectedTrainee}
                />
    </div> );
}
 
export default Survey_Satisf_Fiche;