import { useEffect, useState } from "react";
import { Button, Col, Form, Row, FormLabel, FormControl, FormGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import { post, processParams, progressMessage, downloadFile, downloadBlob } from "../../../../adapters/xhr";
import toast, { Toaster } from "react-hot-toast";
import { handle_change_file, handle_change_input } from "../../../../tools/HInput";
import DeleteConfirmation from "../../../../tools/components/popups/DeleteConfirmation";
import { faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Documents_Upd = () => {

    const history = useHistory();
    const account = useSelector((state) => state.account);
    let { session_key } = useParams();
    let progressShow = 0;

    const [session, setSession] = useState({});
    const [documents, setDocuments] = useState([]);
    
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [document_to_remove, setDocument_to_remove] = useState({});
    
    const [validated, setValidated] = useState(false);
    const [frm_titre, setFrm_titre] = useState('');
    const [frm_file, setFrm_file] = useState('');


    const hideConfirmationModal = () => { setDisplayConfirmationModal(false); }

    const getDocuments = () => {
        const params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            opt: ['infs']
        });

        post('sdocument', 'get', params)
        .then(function (xhr){
            if(('status' in xhr) && (xhr.status===200)) {
                if(('ses' in xhr.data) && ('lst' in xhr.data)){
                    setSession(xhr.data.ses);
                    setDocuments(xhr.data.lst);
                }
            }
        }).catch(function (err){
            
        });
    }

    useEffect(() => {
        getDocuments();
    }, []);

    const getFile = (documentSession) => {
        progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        post('sdocument', 'download', processParams({
            tkn: account.info.tkn,
            ses: session_key,
            doc: documentSession.key,
        }), {
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                progressShow = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                toast.loading(progressMessage(progressShow), { id: toastId, });
            },
        })
        .then(function (response){
            toast.success(<b>Fichier téléchargé</b>, { id: toastId, });
            downloadBlob(response.data, documentSession.name+'.'+documentSession.ext);
        }).catch(function (err){
            // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const confirmRemoveFile = (document) => {
        setDocument_to_remove(document);
        setDisplayConfirmationModal(true);
    }

    const removeFile = () => {

        setDisplayConfirmationModal(false);

        const toastId = toast.loading("Suppression de "+document_to_remove.name);
        const params = processParams({
            tkn: account.info.tkn,
            ses: session_key,
            doc: document_to_remove.key
        });

        post('sdocument', 'delete', params)
        .then(function (xhr){
            if(('status' in xhr) && (xhr.status===200)) {
                if(('save' in xhr.data) && ('msg' in xhr.data)) {
                    if(xhr.data.save) {
                        toast.success(<b>Fichier supprimé !</b>, { id: toastId, });
                        getDocuments();
                    }else{
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }else{
                    toast.error(<b>Problème de suppression !</b>, { id: toastId, });
                }
            }
        }).catch(function (err){
            toast.error(<b>Problème de suppression !</b>, { id: toastId, });
        });
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if(form.checkValidity() == true){
            let progressShow = 0;
            const toastId = toast.loading(progressMessage(progressShow));
            
            let params = processParams({
                tkn: account.info.tkn,
                ses: session_key,
                titre: frm_titre,
            });
            params.append('file', frm_file, frm_file.name);

            post('sdocument', 'add', params, {
                onUploadProgress: function (progressEvent) {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    if (totalLength !== null) {
                        progressShow = Math.round( (progressEvent.loaded * 100) / totalLength );
                        toast.loading(progressMessage(progressShow), { id: toastId, });
                    }
                }
            })
            .then(function (xhr){
                if(('status' in xhr) && (xhr.status===200)) {
                    // console.log(xhr);
                    if(('save' in xhr.data) && ('msg' in xhr.data)) {
                        if(xhr.data.save) {
                            toast.success(<b>Données enregistrées !!</b>, { id: toastId, });
                            setTimeout(() => {
                                form.reset();
                                setValidated(false);
                                setFrm_titre('');
                                setFrm_file('');

                                getDocuments();
                            }, 1000);
                        }else{
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                    }else{
                        toast.error(<b>Problème d'enregistrement !</b>, { id: toastId, });
                    }
                }
            }).catch(function (err){
                toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
            });
        }
        setValidated(true);
    }

    const goback = () => {
        history.goBack();
    }

    return ( 
        <div>
            <Row className='headerContentPart' >
                <FormGroup as={Col}  className='title'>Documents de la session</FormGroup>
            </Row>
            <br/>
            
            <table className='table_ini'>
                <tbody>
                {documents.map((doc, key) => {
                    return (<tr key={key}>
                        <td>{doc.name}</td>
                        <td className="text_align_right">
                            <div className='button_stand' onClick={(e)=>getFile(doc)}>
                                <FontAwesomeIcon icon={faDownload}/> 
                            </div>
                            <div className='button_stand' onClick={(e)=>confirmRemoveFile(doc)}>
                                <FontAwesomeIcon icon={faTimes}/> 
                            </div>
                        </td>
                    </tr>);

                })}
                </tbody>
            </table>
            
            <DeleteConfirmation 
                        showModal={displayConfirmationModal} 
                        confirmModal={removeFile}
                        hideModal={hideConfirmationModal} 
                        message={"Êtes-vous sûr de vouloir supprimer le document \""+document_to_remove.name+"\" de cette session ?"}
                    />
            <br/>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className='is_block'>
                <Row>
                    <FormGroup as={Col} >
                        <FormLabel>Nom</FormLabel>
                        <FormControl type="text" value={frm_titre} onChange={(e)=>handle_change_input(e, setFrm_titre)} required/>
                        <FormControl.Feedback type="invalid">Veuillez remplir le nom</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col} xl={3}>
                        <FormControl type='file' onChange={(e)=>handle_change_file(e, setFrm_file)} feedback="Veuillez séléctionner le document" required />
                    </FormGroup>
                    <FormGroup as={Col} xl={2}>
                        <Button type="submit">Enregistrer</Button>        
                    </FormGroup>
                </Row>
                
            </Form>
            {/* <Toaster
                    position="bottom-right"
                    reverseOrder={false}        /> */}
        </div>
     );
}
 
export default Documents_Upd;