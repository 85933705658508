import { faEraser, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { check_is_200, downloadBlob, popup_show_file, post, processParams, progressMessage } from "../../../../adapters/xhr";
import LoadingComponent from "../../../../tools/components/loading/LoadingComponent";
import DeleteConfirmation from "../../../../tools/components/popups/DeleteConfirmation";
import InfoPopUp from "../../../../tools/components/popups/InfoPopUp";
import { percentage } from "../../../../tools/math";

const Session_Survey_Fiche = () => {

    const account = useSelector((state) => state.account);
    const { session_key, survey_key } = useParams();

    const [session, setSession] = useState({});
    const [survey, setSurvey] = useState({});
    const [nbr_questions, setNbr_questions] = useState(0);
    const [reponses, setReponses] = useState([]);
    
    const [score_glb, setScore_glb] = useState('');
    const [score_v, setScore_v] = useState('');
    const [score_nv, setScore_nv] = useState('');

    const [nbr_rep_e, setNbr_rep_e] = useState('');
    const [nbr_rep_valid, setNbr_rep_valid] = useState('');
    const [nbr_rep_n_valid, setNbr_rep_n_valid] = useState('');
    
    const [score_rep_valid, setScore_rep_valid] = useState('');
    const [score_rep_n_valid, setScore_rep_n_valid] = useState('');

    const [nbr_q_rep_valid, setNbr_q_rep_valid] = useState('');
    const [nbr_q_rep_n_valid, setNbr_q_rep_n_valid] = useState('');


    const [selectedTrainee, setSelectedTrainee] = useState({});
    const [selectedTraineeReport, setSelectedTraineeReport] = useState({});
    const [displayReponseModal, setDisplayReponseModal] = useState(false);

    const [displayConfirmationModal, setdisplayConfirmationModal] = useState(false);
    const [reponse_to_reini, setReponse_to_reini] = useState('');
    const [reponse_to_reini_name, setreponse_to_reini_name] = useState('');

    const getData = () => {
        post('sessrv', 'report', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            srv: survey_key
        }))
        .then(function (xhr){
            if(check_is_200(xhr))
            {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        setSession(xhr.data.session || {});
                        setSurvey(xhr.data.survey || {});
                        setNbr_questions(xhr.data.nbr_qst || []);
                        setReponses(xhr.data.rps || []);
                    }
                    else toast.error(<b>{xhr.data.msg}</b>);
                }
                else toast.error(<b>Problème d'envoi !</b>);
            }
            else toast.error(<b>Problème d'envoi !</b>);
        });
    }

    useEffect(() => getData(), []);

    useEffect(() => {
        update_reponses();
    }, [reponses]);

    const update_reponses = () => {
        let repni_e = 0;
        
        let repni_v = 0;
        let repnq_v = 0;
        let score_v = 0;

        let repni_nv = 0;
        let repnq_nv = 0;
        let score_nv = 0;

        reponses.map((rep, key) => 
        {
            if(rep.iss)
            {
                if(rep.isv)
                {
                    repni_v++;
                    repnq_v += rep.nbr;
                    score_v += rep.scr;
                }
                else
                {
                    repni_nv++;
                    repnq_nv += rep.nbr;
                    score_nv += rep.scr;
                }
            }
            else
            {
                repni_e++;
            }
        });
        
        if(reponses.length)
        {
            setNbr_rep_e(repni_e);

            setNbr_rep_valid(repni_v);
            setScore_rep_valid(score_v);
            setNbr_q_rep_valid(repnq_v);
            
            setNbr_rep_n_valid(repni_nv);
            setScore_rep_n_valid(score_nv);
            setNbr_q_rep_n_valid(repnq_nv);

            setScore_v(percentage(score_v, repni_v * nbr_questions) / 10);
            setScore_nv(percentage(score_nv, repni_nv * nbr_questions) / 10);
            setScore_glb(percentage(score_v + score_nv, (repni_v + repni_nv) * nbr_questions) / 10);
        }
    }

    const show_numbers_old = () => {
        return <div className='display_flex'>
            
            <div>
                <div className={'survey_global_score '+( score_v > 7 ? 'good_point' : ( score_v > 4.5 ? 'medium_point' : 'bad_point' ))}>
                    {score_v}
                    <div className='sur_10'> /10</div>
                </div>
            </div>
            
            <div>
                {nbr_rep_valid > 0 && <div className='survey_global_element display_flex margin_10'>
                    
                    <div><div className={'survey_item_score '+( score_v > 7 ? 'good_point' : ( score_v > 4.5 ? 'medium_point' : 'bad_point' ))}>
                        {score_v}
                        <div className='sur_10'> /10</div>
                    </div></div>
                    <div className="margin_left_10px">
                        <h2>Réponse{nbr_rep_valid > 1 ? 's' : ''} validée{nbr_rep_valid > 1 ? 's' : ''}</h2>
                        <ul>
                            <li>{nbr_rep_valid} réponse{nbr_rep_valid > 1 ? 's' : ''}</li>
                            <li>Questions répondu {percentage(nbr_q_rep_valid, nbr_rep_valid * nbr_questions)+' %'}</li>
                        </ul>
                    </div>
                </div>}

                {nbr_rep_n_valid > 0 && <div className='survey_global_element display_flex margin_10'>
                    
                    <div><div className={'survey_item_score '+( score_nv > 7 ? 'good_point' : ( score_nv > 4.5 ? 'medium_point' : 'bad_point' ))}>
                        {score_nv}
                        <div className='sur_10'> /10</div>
                    </div></div>
                    <div className="margin_left_10px">
                        <h2>Réponse{nbr_rep_n_valid > 1 ? 's' : ''} non validée{nbr_rep_n_valid > 1 ? 's' : ''}</h2>
                        <ul>
                            <li>{nbr_rep_n_valid} réponse{nbr_rep_n_valid > 1 ? 's' : ''}</li>
                            <li>Questions répondu {percentage(nbr_q_rep_n_valid, nbr_rep_n_valid * nbr_questions)+' %'}</li>
                        </ul>
                    </div>
                </div>}
                
                {nbr_rep_e > 0 && <div className='survey_global_element margin_10'>
                    <h5>En attente de {nbr_rep_e} stagiaire{nbr_rep_e > 1 ? 's' : '' }</h5>
                </div>}
            </div>

        </div>
    }

    const show_numbers = () => {
        return <div className='' style={{textAlign:'center'}}>
            
            <div>
                <h2>Moyenne score</h2>
                <div style={{fontSize:'700%'}} className={'survey_global_score '+( score_v > 7 ? 'good_point' : ( score_v > 4.5 ? 'medium_point' : 'bad_point' ))}>
                    {score_v}
                    <div className='sur_10'> /10</div>
                </div>
            </div>
            
            <div>
                {reponses.length>0 && <div className='survey_global_element margin_10 cell_item box_shadow_principal'>
                    Taux de réponse {percentage(nbr_rep_valid, reponses.length)+' %'}
                </div>}
                
                {nbr_rep_e > 0 && <div className='survey_global_element margin_10 cell_item box_shadow_principal'>
                    En attente de {nbr_rep_e} stagiaire{nbr_rep_e > 1 ? 's' : '' }
                </div>}
            </div>

        </div>
    }


    // const show_reponse_of = (trn) => {
    //     post('sessrvr', 'stagsurvey', processParams({
    //         tkn: account.info.tkn,
    //         ctr: account.ctr.key,
    //         ses: session_key,
    //         srv: survey_key,
    //         trn
    //     }))
    //     .then(function (xhr){
    //         if(check_is_200(xhr))
    //         {
    //             // // console.log(xhr.data);
    //         }
    //     });
    // }

    const handleInitReponse = (trainee) => {
        setReponse_to_reini(trainee);
        setreponse_to_reini_name(trainee.trn);
        setdisplayConfirmationModal(true);
    }

    const reinitialReponse = () => {
        const toastId = toast.loading("Réinitialisation");
        
        post('sessrvr', 'reini', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            srv: survey_key,
            trn: reponse_to_reini.key
        }))
        .then(function (xhr)
        {
            if(check_is_200(xhr))
            {
                if(('state' in xhr.data) && ('msg' in xhr.data))
                {
                    if(xhr.data.state){
                        setReponses(reponses.map(item => {
                            if(reponse_to_reini.key == item.key || reponse_to_reini.key=='all')
                            {
                                item.iss = false;
                                item.isv = false;
                            }
                            return item;
                        }));
                        // update_reponses();
                        
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
                }
            }
            else
            {
                toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
            }
        }).catch(err => {
            toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
        });
        
        hideConfirmationModal();
    }

    const hideConfirmationModal = () => {
        setdisplayConfirmationModal(false);
    }

    const columns = [
        { name: 'Stagiaire', selector: row => row.trn, sortable: true,},
        { name: 'Statut',  selector: row => !row.iss ? 'En attente' : ( row.isv ? 'Validé' : 'Enregistré'), 
                            cell: row => <div className='table_tag' style={{background:(!row.iss ? '#FFDE9D' : ( row.isv ? '#AAF97D' : '#7DF9EE'))}}>
                                            {!row.iss ? 'En attente' : ( row.isv ? 'Validé' : 'Enregistré')}
                                         </div>,
                            sortable: true,
                        },
        { name: 'Progression', selector: row => (row.iss && 'nbr' in row ? row.nbr+' / '+nbr_questions : ''), sortable: true,},
        { name: 'Score', selector: row => (row.iss && 'isv' in row && row.isv && 'scr' in row ? ( percentage(row.scr, nbr_questions) / 10 ) +' / 10' : ''), sortable: true,},
        { name: 'Action', selector: row => row.action, sortable: true, minWidth:1, cell:row => <div>
                {row.iss && <div>
                        <div className='button_stand' data-tooltip='Consulter' onClick={e => handleTraineeReponse(row)}><FontAwesomeIcon icon={faEye}/></div>
                        <div className='button_stand' data-tooltip='Réinitialiser' onClick={e => handleInitReponse(row)} variant='danger'><FontAwesomeIcon icon={faEraser}/></div>
                    </div>}
            </div>},
    ];


    const filteredReponses = reponses.filter(item => true);

    const afficherList = () =>
    {

        return (
            <div>
                {show_numbers()}
              
                <br/>
              
                <DataTable
                    pagination={false}
                    fixedHeader
                    columns={columns}
                    data={filteredReponses}
                    defaultSortFieldId={1}
                    noDataComponent="Aucune réponse !"
                    paginationComponentOptions={{
                                                    rowsPerPageText: 'réponse par page',
                                                    rangeSeparatorText: 'sur',
                                                    selectAllRowsItem: true,
                                                    selectAllRowsItemText: 'Tous',
                                                }}
                    />

              
                <br/><br/>
                <DeleteConfirmation
                        showModal={displayConfirmationModal} 
                        confirmModal={reinitialReponse}
                        hideModal={hideConfirmationModal} 
                        message={"Êtes-vous sûr de vouloir réinitialiser "+(reponse_to_reini_name=='' ? 'tous les réponses':'la réponse de '+reponse_to_reini_name)+' ?'}
                    />
                {/* <Toaster
                    position="bottom-right"
                    reverseOrder={false}        /> */}
            </div>
        );
    }

    const EnAttenteList = ({ list }) => {
        list = list.sort((a, b) => a.trn.localeCompare(b.trn, undefined, { sensitivity: 'base' }))
        return (<div>
            <h4>Stagiaires en attente</h4>
            <table className='table_ini'>
                <tbody>
                    {list.map((prf, key) => (
                        <tr key={key}>
                            <td>{prf.trn}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>);
    }

    const handleTraineeReponse = (trainee) => {
        setSelectedTrainee(trainee);
        setDisplayReponseModal(true);
    }
    const hideDisplayModal = () => {
        setDisplayReponseModal(false);
    }

    useEffect(() => {
        if('key' in selectedTrainee){
            getTraineeReponseData();
        }
    }, [selectedTrainee]);

    const getTraineeReponseData = () => {
        
        setSelectedTraineeReport({});

        post('sessrvr', 'stagsurvey', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            srv: survey_key,
            trn: selectedTrainee.key
        }))
        .then(function (xhr)
        {
            if(check_is_200(xhr)){
                if(('state' in xhr.data) && ('crc' in xhr.data))
                {
                    setSelectedTraineeReport(xhr.data);
                }
            }
        }).catch(function (err){
            // // console.log(err);
        });
    }

    const get_reponse_child = (is_valid, qst) => {

        let html = [];
        let have_answer = false;
        let is_correct = true;
        let is_false = false;

        for(let kec in qst.choix)
        {
            let choix = qst.choix[kec];
            
            have_answer = have_answer || choix.selected;
            is_correct = is_correct && choix.selected==choix.just;

            html.push(<tr key={kec}>
                            <td>{choix.titre}</td>
                            <td width='160px' className={choix.selected && is_valid ? (choix.just ? 'is_correct' : 'is_wrong') : ''}>{choix.selected ? <span className={'dot '+(is_valid ? (choix.selected && choix.just ? 'is_true':'is_false') : '')}></span> : ''}</td>
                            {is_valid && <td width='160px'>{choix.just ? <span className="dot is_true" ></span> : ''}</td>}
                        </tr>);
        }

        is_correct = have_answer && is_correct;
        is_false = have_answer && !is_correct;


        return {html, have_answer, is_correct, is_false }
    }

    const getSelectedReponseFiche = () => {
        if(displayReponseModal){
            if('trn' in selectedTraineeReport){
                
                let is_valid = 'isv' in selectedTrainee && selectedTrainee.isv;
                let score_trn = percentage(selectedTrainee.scr, nbr_questions) / 10;

                return (
                    <div>
                        {is_valid && <div className='table_tag'>
                            <div className={'survey_item_score '+( score_trn > 7 ? 'good_point' : ( score_trn > 4.5 ? 'medium_point' : 'bad_point' ))}>
                                {score_trn}
                                <div className='sur_10'> /10</div>
                            </div>
                            <div className='cell_item'>Bonnes réponses {selectedTrainee.scr} / {nbr_questions}</div>
                        </div>}
                        <table className='table_qcm_reponse'>
                            <thead>
                                <tr>
                                    <th>Question</th>
                                    <th>Réponse sélectionée</th>
                                    {is_valid && <th>Bonne réponse</th>}
                                </tr>
                            </thead>
                                {selectedTraineeReport.crc.map((qst, key) =>
                                {
                                    let rep_child = get_reponse_child(is_valid, qst);

                                    return <tbody key={key}>
                                                <tr className={'tr_question_title '+ ( is_valid ? ( rep_child.have_answer ? (rep_child.is_correct ? 'is_true' : 'is_false') : 'is_false') : '')}>
                                                    <td>
                                                        {qst.titre}
                                                    </td>
                                                    <td></td>
                                                    {is_valid && <td></td>}
                                                </tr>
                                                {rep_child.html}
                                            </tbody>
                                })}
                            
                        </table>
                        
                    </div>
                );
            }else{
                return <LoadingComponent is_loading/>;
            }
        }else{
            return <div></div>;
        }
    }

    const go_print_reponse = () => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            srv: survey_key,
            trn: selectedTrainee.key
        };

        // console.log(params);
        
        post('sessrvr', 'evalfile', processParams(params))
        .then((xhr) => {
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé</b>, { id: toastId, });
                }
                else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });            
        }).catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const go_print_synthese = () => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));

        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            srv: survey_key
        };
        
        post('sessrvr', 'synfile', processParams(params))
        .then((xhr) => {
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé</b>, { id: toastId, });
                }
                else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        }).catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }


    
    return ( <div>
        <div className='headerContentPart flex_between' >
            <div className='title div_according'>{session.titre}</div>
            <div>
                {(nbr_rep_valid > 0 ) && <div className='div_according'>
                    <div className='button_stand' onClick={()=>go_print_synthese()}>
                        Synthèse
                    </div>
                </div>}
                {((nbr_rep_valid + nbr_rep_n_valid)>0) && <div className='div_according'>
                        <div className='button_stand button_danger' onClick={(e)=>handleInitReponse({key:'all', trn:''})}>
                            Réinitialiser
                        </div>
                    </div>}
                
            </div>
            
        </div>

        <div className="cell_item" style={{backgroundColor:survey.color}}>
            {'type' in survey && survey.type} 
            &nbsp; : &nbsp;
            {survey.titre}
        </div>
                

        {/* {(reponses_enregistre.length>0 || reponses_valide.length>0) && <Button onClick={e => handleInitReponse({key:'all', trn:''})} variant='danger'>Réinitialiser</Button>} */}
        
        {reponses.length>0 && afficherList()}
        
        <InfoPopUp 
                    showModal={displayReponseModal} 
                    hideModal={hideDisplayModal}
                    titleModal={ 'trn' in selectedTraineeReport ? selectedTraineeReport.trn.nom+' '+selectedTraineeReport.trn.pnom : ''}
                    size='60'
                    okText="Imprimer"
                    cancelText="Fermer"
                    messageFunction={getSelectedReponseFiche}
                    okAction={go_print_reponse}
                    params={selectedTrainee}
                />
    </div> );
}
 
export default Session_Survey_Fiche;