import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { FormControl, InputGroup, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { handle_change_input } from "../../tools/HInput";
import Former_Sessions from "../Formateurs/Former_Sessions";
import Trainee_Sessions from "../trainee/Trainee_Sessions";

const Session_Mine = () => {

    const account = useSelector((state) => state.account);

    const [filter_by, setFilter_by] = useState('');

    return ( <div>
        <Row className='headerContentPart' >
            <div className='flex_between'>
                <div className='title'>Mes sessions</div>
                <InputGroup className="search width_50">
                    <InputGroup.Text className="icon">
                        <FontAwesomeIcon
                            icon={faSearch}
                        /> 
                    </InputGroup.Text>
                    <FormControl
                        placeholder="Filtrer"
                        aria-label="Filtrer"
                        aria-describedby="filtrate"
                        type='text' 
                        value={filter_by} 
                        className="text_zone"
                        onChange={e => handle_change_input(e, setFilter_by)}
                    />
                </InputGroup>
                
            </div>
        </Row>
        <Trainee_Sessions 
                account={account}
                filter_by={filter_by}
                setFilter_by={setFilter_by}
            />

        <Former_Sessions
                account={account}
                filter_by={filter_by}
                setFilter_by={setFilter_by}
            />
    </div> );
}
 
export default Session_Mine;