import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, FormGroup, FormLabel, FormControl, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { check_is_200, post, processParams } from "../../../adapters/xhr";
import TextQ from "../../../tools/components/Texts/TextQ";

const Devis_Convention_Form = () => {

    const account = useSelector((state) => state.account );
    const history = useHistory();
    const { center_key } = useParams();

    const [rubtiques, setRubtiques] = useState([]);
    
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    const goback = () => history.goBack();

    const 
    handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if(form.checkValidity() === true)
        {
            const params = {
                tkn: account.info.tkn,
                ctr: center_key,
                rubs:rubtiques
            }

            const toastId = toast.loading('Envoi');

            post("ctrdev", "cnvsave", processParams(params))
            .then(function (xhr) {
                if(check_is_200(xhr)) {
                    // console.log(xhr.data);
                    if('state' in xhr.data && 'msg' in xhr.data)
                    {
                        if(xhr.data.state)
                        {
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setTimeout(()=> { goback(); }, 2000);
                        }
                        else
                        {
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                    }
                    else
                    {
                        toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                    }
                }else{
                    toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                }
                
            }).catch(function(error){
                toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
            }).then(function (){
                setLoading(false);
                // toast.dismiss(toastId);
            });
        }
        setValidated(true);
    }

    const getData = () => {
        const params = processParams({
            tkn:account.info.tkn, 
            ctr: center_key,
        });
        post("ctrdev", "cnv", params).then(function (xhr) {
            if(check_is_200(xhr)) {
                setRubtiques(xhr.data.list || []);
                // setTitle(info.titre || '');
                // setObjectif(info.objectif || '');
                // setPrix(info.prix || []);
                // setNbrhrs(info.nbr_heures || []);
                // setDescription(info.description || '');
                // setAttestation(info.attestation || '');
            }
        }).catch(function(error){
            // console.log('Error', error);
        }).then(function (){
            // console.log('END');
        });
    };

    useEffect(() => {
        getData();
        // return () => { setIsMounted(false); }; 
    }, []);


    const onChangeRubtique = (e, indice, attr) => {
        const newRubtiques = rubtiques.map((item, key) => {
            if(key==indice){
                item[attr] = e.target.value;
            }
            return item;
        });
        setRubtiques(newRubtiques);
    };

    const onChangeTXT = (txt, indice, attr) => {
        const item_was_rmv = rubtiques.filter(item => ('rmv' in item));
        if(item_was_rmv.length == 0)
        {
            const newRubtiques = rubtiques.map((item, key) => {
                if(key==indice){
                    item[attr] = txt;
                }
                return item;
            });
            setRubtiques(newRubtiques);
        }
    };


    const add_rubtique = () => {
        setRubtiques(rubtiques.concat({'ttl':'', 'txt':''}));
    }

    const remove_rubtique = (selitem) => {
        rubtiques[selitem].rmv = true;
        let newrubriques = rubtiques.filter((item, key) => key !== selitem);
        setRubtiques(newrubriques);
    }

    return ( <div>
            <Row className='headerContentPart' >
                <FormGroup as={Col} className='title'>Formulaire de Convention</FormGroup>
            </Row>
            
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {rubtiques.map((item, key)=>{
                    return  <div key={key}>
                    
                    <Row>
                        <FormGroup as={Col}>
                            <FormLabel>Titre</FormLabel>
                            <div className='button_stand' onClick={() => remove_rubtique(key)}>
                                <FontAwesomeIcon icon={faTimes}/>
                            </div>
                            <FormControl type="text" value={item.ttl} onChange={(e)=>onChangeRubtique(e, key, 'ttl')} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup  as={Col}>
                            <FormLabel>Description</FormLabel>
                            <TextQ  value={item.txt} setValue={(txt)=>{onChangeTXT(txt, key, 'txt')}} required={true} validated={validated} />
                        </FormGroup>
                    </Row>
                    <hr/>
                </div>
                })}

           <div style={{margin:30}} className='button_stand' onClick={() => add_rubtique()}>
                            <FontAwesomeIcon icon={faPlus}    />
                    </div>

            {!loading && <Button variant="primary" type="submit">
                Enregistrer
            </Button>}

            {loading && <Button variant="primary" disabled>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                <span className="sr-only">Loading...</span>
            </Button>}
            {/* <Toaster
                position="bottom-right"
                reverseOrder={false}        /> */}
        </Form>
    </div> );
}
 
export default Devis_Convention_Form;