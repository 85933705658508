import { useState } from "react";
import { Button, Col, Form, Spinner, Row, FormGroup, FormLabel, FormControl, InputGroup } from "react-bootstrap"
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from 'react-router';
import { check_is_200, post, processParams, progressMessage } from "../../../adapters/xhr";
import { getItemRoute } from "../../../Routes";

const Structure_Form = ( { about } ) => {
    
    const account = useSelector((state) => state.account);
    const history = useHistory();
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    let for_member = about != undefined && about=='m';


    const sexe_list = [ 
        {n:'Mr.', v:'h'},
        {n:'Mme.', v:'f'},
    ];
    let role_list = [ 
        {n:'Secrétaire', v:'s'},
        {n:'Comptable', v:'c'},
        {n:'Formateur', v:'f'},
    ];

    // if(for_member)
    // {
    //     role_list = [ 
    //         {n:'Secrétaire', v:'s'},
    //         {n:'Comptable', v:'c'},
    //     ];
    // }
    // else
    // {
    //     role_list = [ 
    //         {n:'Formateur', v:'f'},
    //     ];
    // }

    const contract_types = [ 
        {n:'CDD', v:'cdd'},
        {n:'CDI', v:'cdi'},
        {n:'Bénévole', v:'bnv'},
        {n:'Indépendant', v:'ind'},
        {n:'Société', v:'soc'},
    ];

    const [nom, setnom] = useState('');
    const [pnom, setpnom] = useState('');
    const [contract, setContract] = useState('');
    const [nais_dt, setnais_dt] = useState('');
    const [nais_li, setnais_li] = useState('');
    const [sexe, setsexe] = useState('');
    const [role, setRole] = useState(!for_member ? 'f' : '');
    const [tel, settel] = useState('');
    const [email, setemail] = useState('');
    const [cp, setcp] = useState('');
    const [adr, setadr] = useState('');
    const [ville, setville] = useState('');

    const handleChange = (e, fSet) => {
        fSet(e.target.value);
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        let progressShow = 0;

        if(form.checkValidity() === true){
            setLoading(true);
            
            let info = {nom, pnom, contract, nais_dt, nais_li, sexe, role, tel, email, cp, adr, ville, 
                tkn: account.info.tkn,
                ctr: account.ctr.key,
            };
            
            let params = processParams(info);
            
            const toastId = toast.loading(progressMessage(progressShow));
            
            post("member", "add", params)
            .then(function(xhr)
            {
                if(check_is_200(xhr)) 
                {
                    if(('save' in xhr.data) && ('msg' in xhr.data)){
                        if(xhr.data.save){
                            toast.success(<b>Données enregistrées !!</b>, { id: toastId, });
                            setTimeout(() => { 
                                history.push({
                                    pathname: getItemRoute('center_formers'),
                                    state:{back:true},
                                });
                            }, 1000);
                        }else{
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });        
                        }
                    }else{
                        toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                    }
                }else{
                    toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                }
            }).catch(function(error){
                toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
            }).then(function() {
                setLoading(false);
            });
        }
        setValidated(true);
    };
    return ( 
        <div>
            <Row className='headerContentPart' >
                <div className='flex_between'>
                    <div className='title'>Nouveau {for_member ? 'Membre' : 'Formateur' }</div>
                </div>
            </Row>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Nom</FormLabel>
                        <InputGroup>
                            <div as={Col} xl={4}>
                                <FormControl as='select' value={sexe} onChange={(e)=>handleChange(e, setsexe)}>
                                    {sexe_list.map((item, key) => <option key={key} value={item.v}>{item.n}</option>)}
                                </FormControl>
                            </div>
                            <FormControl type='text' value={nom} onChange={(e)=>handleChange(e, setnom)} required/>
                            <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                        </InputGroup>

                        
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Prénom</FormLabel>
                        <FormControl type='text' value={pnom} onChange={(e)=>handleChange(e, setpnom)} required/>
                        <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Statut</FormLabel>
                        <FormControl as='select' value={contract} onChange={(e)=>handleChange(e, setContract)} required>
                            {contract=='' && <option value=''>Choisissez</option>}
                            {contract_types.map((item, key) => <option key={key} value={item.v}>{item.n}</option>)}
                        </FormControl>
                        <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Rôle</FormLabel>
                        <FormControl as='select' value={role} onChange={(e)=>handleChange(e, setRole)} required readOnly={!for_member}>
                            {role=='' && <option value=''>Choisissez</option>}
                            {role_list.map((item, key) => <option key={key} value={item.v}>{item.n}</option>)}
                        </FormControl>
                        <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Tel</FormLabel>
                        <FormControl type='text' value={tel} onChange={(e)=>handleChange(e, settel)} required/>
                        <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Email</FormLabel>
                        <FormControl type='email' value={email} onChange={(e)=>handleChange(e, setemail)} required/>
                        <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Date de naissance</FormLabel>
                        <FormControl type='date' value={nais_dt} onChange={(e)=>handleChange(e, setnais_dt)}/>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Lieu de naissance</FormLabel>
                        <FormControl type='text' value={nais_li} onChange={(e)=>handleChange(e, setnais_li)}/>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Code postal</FormLabel>
                        <FormControl type='text' value={cp} onChange={(e)=>handleChange(e, setcp)} required/>
                        <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Adresse</FormLabel>
                        <FormControl type='text' value={adr} onChange={(e)=>handleChange(e, setadr)} required/>
                        <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Ville</FormLabel>
                        <FormControl type='text' value={ville} onChange={(e)=>handleChange(e, setville)} required/>
                        <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                    </FormGroup>
                </Row>
                {!loading && <Button type="submit">Enregistrer</Button>}
                {loading && <Button variant="primary" disabled>
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>}
                {/* <Toaster
                    position="bottom-right"
                    reverseOrder={false}        /> */}
            </Form>
        </div>
    );
}
 
export default Structure_Form;