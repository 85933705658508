import { useEffect, useState } from "react";
import { check_is_200, post, processParams } from "../../../../adapters/xhr";
import { useParams } from 'react-router-dom';
import LoadingComponent from "../../../../tools/components/loading/LoadingComponent";
import Survey_Satisf_Reply_Component from "./Survey_Satisf_Reply_Component";
import { useSelector } from "react-redux";

const Survey_Satisf_Reply_Form_Non_Connect = () => {

    const account = useSelector((state) => state.account);

    const [form_data, setForm_data] = useState('');
    const [error, setError] = useState('');
    const [center, setCenter] = useState({});
    const [is_connected, setIs_connected] = useState(true);
    
    const { srvu_key } = useParams();

    const getData = () => {
        post('stsf', 'cryf', processParams({
            tke: srvu_key,
            icn: typeof account === 'object' && 'isLoggedIn' in account && account.isLoggedIn ? 0 : 1
        }))
        .then(function (xhr)
        {
            if(check_is_200(xhr)) 
            {
                if('state' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        setForm_data(xhr.data.form || {});
                        setCenter(xhr.data.ctr || {});
                    }
                    else
                        setError(xhr.data.msg || "Lien expiré !");
                }
                else
                {
                    setError(xhr.data.msg || "Lien expiré !");
                }
            }
            else setError("Lien expiré !");
        }).catch(()=>{
            setError("Lien expiré !");
        });
    }

    useEffect(()=> {
        setIs_connected(typeof account === 'object' && 'isLoggedIn' in account && account.isLoggedIn);
        getData()
    }, []);

    return ( <div style={is_connected ? {} : {width:'80%', margin:'10px auto', }}>
        {(form_data == '' && error == '') && <LoadingComponent is_loading large_size />}
        {(form_data == '' && error != '') && <div className='text-danger div_according button_stand'>{error}</div>}
        {(typeof form_data === 'object' && ('tkn' in form_data) && ('ctr' in form_data) && ('ses' in form_data) && ('srv' in form_data)) && 
            <div style={{padding:'0px auto'}}>
                {!is_connected && <table style={{marginBottom:'20px'}}>
                    <tr>
                        {('logo' in center) && <td className='apropos_logo'><img src={center.logo}/></td>}
                        {('nom' in center) && <td style={{padding:'40px'}}><h3>{center.nom}</h3></td>}
                    </tr>
                </table>}
                
                <Survey_Satisf_Reply_Component
                    account_tkn={form_data.tkn || ''} 
                    account_ctr={form_data.ctr || ''} 
                    session_key={form_data.ses || ''}
                    survey_key={form_data.srv || ''}
                    show_center_info={!is_connected}
                />
            </div>}
    </div> );
}
 
export default Survey_Satisf_Reply_Form_Non_Connect;