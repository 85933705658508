import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Col, Button, Spinner, Row, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { check_is_200, post, processParams, progressMessage } from "../../../adapters/xhr";
import { useSelector } from "react-redux";
import { handle_change_input, handle_change_input_item_array } from "../../../tools/HInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const Devis_Form = () => {
    
    const account = useSelector((state) => state.account );
    const history = useHistory();
    const { center_key, devis_key } = useParams();

    let is_creation = devis_key == undefined;
    // console.log('center_key', !center_key);
    // console.log('devis_key', !devis_key);
    
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isMounted, setIsMounted] = useState(true);

    const [client, setClient] = useState('');
    const [programme, setProgramme] = useState('');
    const [delaif, setDelaif] = useState('');
    const [prix, setPrix] = useState('');
    const [nbrhrs, setNbrhrs] = useState('');
    const [nbrstg, setNbrstg] = useState('');
    const [remise, setRemise] = useState('');
    const [datestart, setDatestart] = useState('');
    const [validation_days, setValidation_days] = useState(30);
    const [stagiaire_list, setStagiaire_list] = useState([]);
    const [client_list, setClient_list] = useState([]);
    const [prog_list, setProg_list] = useState([]);
    const [delai_list, setDelai_list] = useState([]);
    const [is_effected, setIs_effected] = useState(false);

    let progressShow = 0;
    
    const getData = () => {
        const params = {
            tkn:account.info.tkn, 
            ctr: center_key,
            opt: ['lstcp']
        };
        
        if(!is_creation)
        {
            params.dev = devis_key;
        }
        
        post("ctrdev", "info", processParams(params))
        .then(function (xhr) {
            if(check_is_200(xhr) && isMounted) {
                if('devis' in xhr.data)
                {
                    const devis_info = xhr.data.devis;
                    
                    if(!is_creation)
                    {
                        setClient(devis_info.client || '');
                        setProgramme(devis_info.program || '');
                        setDelaif(devis_info.delai_fact || '');
                        setDatestart(devis_info.date_start || '');
                        setPrix(devis_info.prix || '');
                        setValidation_days(devis_info.days_val || '');
                        setNbrstg(devis_info.nbr_stagiaires || '');
                        setStagiaire_list(devis_info.list_stagiaires || []);
                        setNbrhrs(devis_info.nbr_heures || '');
                        setRemise(devis_info.remise || '');
                        if(xhr.data.devis.signature_file || false)
                        {
                            goback();
                        }
                    }
                }
                if('clients' in xhr.data && Array.isArray(xhr.data.clients))
                {
                    setClient_list(xhr.data.clients);
                }
                if('programs' in xhr.data && Array.isArray(xhr.data.programs))
                {
                    // console.log(xhr.data.programs);
                    setProg_list(xhr.data.programs);
                }
                if('delais' in xhr.data && Array.isArray(xhr.data.delais))
                {
                    setDelai_list(xhr.data.delais);
                }
                // if('nom' in info)   settitle(info.nom);
                // if('juridique' in info)     setjurd(info.juridique);
                // if('nsiret' in info)    setsiret(info.nsiret);
                // if('nsiren' in info)    setsiren(info.nsiren);
                // if('ndecact' in info)   setDecact(info.ndecact);
                // if('tel' in info)   settel(info.tel);
                // if('fix' in info)   setfixe(info.fix);
                // if('email' in info)     setemail(info.email);
                // if('adresse' in info)   setadresse(info.adresse);
                // if('cp' in info)    setcp(info.cp);
                // if('ville' in info)     setville(info.ville);
                // if('representant' in info)  setRepr(info.representant);
                // if('representant_gender' in info)   setRepr_gdr(info.representant_gender);
            }
        }).catch(function(error){
            // console.log('Error', error);
        }).then(function (){
            // console.log('END');
        });
    };

    useEffect(() => {
        let thrprg = prog_list.filter(prg => prg.r == programme);
        if(thrprg.length > 0)
        {
            setPrix(thrprg[0].p || 0);
            setNbrhrs(thrprg[0].n || 0);
        }
    }, [programme]);

    useEffect(() => {
        getData();
        return () => { setIsMounted(false); }; 
    }, []);

    const goback = () => history.goBack();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        progressShow = 0;
        event.preventDefault();
        if(form.checkValidity() === true)
        {
            const form = {
                client, prix, nbrhrs, datestart, nbrstg, remise, 
                prgm: programme,
                vald: validation_days,
                delf: delaif,
                lstn: stagiaire_list,
                tkn: account.info.tkn,
                ctr: account.ctr.key,
                dev: devis_key,
                isc: is_creation
            };
            // console.log(form);
            let params = processParams(form);
            
            const toastId = toast.loading(progressMessage(progressShow));
            post("ctrdev", "update", params)
            .then(function (xhr) {
                if(check_is_200(xhr)) {
                    if('state' in xhr.data && 'msg' in xhr.data)
                    {
                        if(xhr.data.state)
                        {
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setTimeout(()=> { goback(); }, 2000);
                        }
                        else
                        {
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                    }
                    else
                    {
                        toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                    }
                }else{
                    toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                }
                
            }).catch(function(error){
                toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
            }).then(function (){
                setLoading(false);
                // toast.dismiss(toastId);
            });
            
        }
        setValidated(true);
    };

    const addStagiaire_nom = () => setStagiaire_list(stagiaire_list.concat(['']));
    

    useEffect(() => {
        setNbrstg(stagiaire_list.filter(e => e.trim()!='').length);
    }, [stagiaire_list]);


    useEffect(() => {
        client_list.map(item => { if(item.r == client) setDelaif(item.f); } );
    }, [client])

    return ( 
        <div>
            <Row className='headerContentPart' >
                <div className='flex_between'>
                    <div className='title'>{is_creation ? 'Nouveau' : 'Modification' } devis</div>
                </div>
            </Row>
            
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <FormGroup  as={Col}>
                        <FormLabel>Client</FormLabel>
                        <FormControl as='select' value={client} onChange={(e)=>handle_change_input(e, setClient)} required>
                            {client == '' && <option value=''>Choisissiez</option>}
                            {client_list.map((item, key) => <option key={key} value={item.r}>{item.n}</option>)}
                        </FormControl>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup  as={Col}>
                        <FormLabel>Programme</FormLabel>
                        <FormControl as='select' value={programme} onChange={(e)=>handle_change_input(e, setProgramme)} required>
                            {programme == '' && <option value=''>Choisissiez</option>}
                            {prog_list.map((item, key) => <option key={key} value={item.r}>{item.t}</option>)}
                        </FormControl>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Date de création</FormLabel>
                        <FormControl type="date" value={datestart} onChange={(e)=>handle_change_input(e, setDatestart)} required></FormControl>
                        <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Durée de validité du devis / Jour</FormLabel>
                        <FormControl type="text"  value={validation_days} onChange={(e)=>{handle_change_input(e, setValidation_days)}} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Délai de facturation</FormLabel>
                        <FormControl as='select' value={delaif} onChange={(e)=>handle_change_input(e, setDelaif)}>
                            {delai_list.map((item, key) => <option key={key} value={item.r}>{item.t}</option>)}
                        </FormControl>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Nombre d'heure{nbrhrs > 1 && 's'} / stagiaire</FormLabel>
                        <FormControl type="text"  value={nbrhrs} onChange={(e)=>{handle_change_input(e, setNbrhrs)}} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Prix H.T. / heure</FormLabel>
                        <FormControl type="text"  value={prix} onChange={(e)=>{handle_change_input(e, setPrix)}} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Le prix H.T du devis</FormLabel>
                        <FormControl value={parseFloat(prix * nbrhrs * nbrstg).toFixed(2)} disabled />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        {nbrstg > 0 && <FormLabel>Nom {nbrstg > 1 ? 'des '+nbrstg : 'du'} stagiaire{nbrstg > 1 && 's'}</FormLabel>}
                        {stagiaire_list.map((nom, key) => <FormControl type="text" key={key} value={nom} onChange={(e)=>{handle_change_input_item_array(e, stagiaire_list, key, setStagiaire_list, (e)=> e.trim()!='')}} required/>)}
                        <div className='button_stand' onClick={() => addStagiaire_nom()}>
                            <FontAwesomeIcon icon={faPlus} /> Ajouter un stagiaire
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>

                    </FormGroup>
                </Row>
                {!loading && <Button variant="primary" type="submit">
                    Enregistrer
                </Button>}

                {loading && <Button variant="primary" disabled>
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>}
                {/* <Toaster
                    position="bottom-right"
                    reverseOrder={false}        /> */}
            </Form>
        </div>
     );
}

export default Devis_Form;