import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';
import { useState } from "react";
import InfoPopUp from "../../tools/components/popups/InfoPopUp";
import { datetime_sql_to_str } from "../../tools/Dates";

const Session_Horaire = ({ horaires, session_title }) => {

    // console.log(horaires);
    const [displayEventModal, setDisplayEventModal] = useState(false);
    
    const get_form_days = () => {
        return [
                { n:"Lundi",    i:'1'},
                { n:"Mardi",    i:'2'},
                { n:"Mercredi", i:'3'},
                { n:"Jeudi",    i:'4'},
                { n:"Vendredi", i:'5'},
                { n:"Samedi",   i:'6'},
                { n:"Dimanche", i:'0'},
            ];
    };

    const getHoraires_presentation = () => {
        let days = get_form_days();
        
        let days_horaires = [];
        
        let days_by_horaire = [];
        
        horaires.map((dts, key) => dts.times.map((tms, keyt) => {
            if(!(key in days_by_horaire)) days_by_horaire[key] = [];
            tms.days.map((day) => {
                if(!(day in days_by_horaire[key])) days_by_horaire[key][day] = [];
                days_by_horaire[key][day] = days_by_horaire[key][day].concat({s:tms.tm_s, e:tms.tm_e});
            });
            days_horaires = days_horaires.concat(tms.days);
        }));

        // horaires.map((dts, key) => dts.times.map((tms, keyt) => {
        //     if(!(key in days_by_horaire)) days_by_horaire[key] = [];
        //     days_by_horaire[key] = days_by_horaire[key].concat(tms.days);
        //     days_horaires = days_horaires.concat(tms.days);
        // }));

        // console.log(horaires);
        // console.log('-------');
        // console.log(days_horaires);
        // console.log(days_by_horaire);
        // console.log(days);

        let all_days_horaires = [...new Set(days_horaires)];
        
        return (
            <div>
                <table className='table_horaire'>
                    <tbody>
                        <tr>
                            <th rowSpan='2'>Période</th>
                            {horaires.map((dts, key) => <th key={key}>Du {datetime_sql_to_str(dts.dt_s)}</th>)}
                        </tr>
                        <tr>
                            {horaires.map((dts, key) => <th key={key}>Au {datetime_sql_to_str(dts.dt_e)}</th>)}
                        </tr>
                        {days.map((dy, kdy) => {
                            if(all_days_horaires.includes(dy.i))
                            {
                                return <tr key={kdy}>
                                    <td>{dy.n}</td>
                                    {horaires.map((dts, key) => {
                                        if(key in days_by_horaire && dy.i in days_by_horaire[key])
                                        {
                                            return <td key={key} className='horaire_cell'>{days_by_horaire[key][dy.i].map((time, keyt) => {
                                                return (<div key={keyt}>
                                                    {time.s} - {time.e}
                                                </div>)
                                            })}</td>;
                                        }
                                        else
                                        {
                                            return <td key={key}></td>
                                        }
                                    })}
                                    
                                </tr>
                            }
                            else
                            return null;
                        })}
                    </tbody>
                </table>
            </div>
        );
    }

    const getHoraires_calendar = () => {
        let events_data = [];
        let min_date = '';
        if(horaires){
            horaires.map(horr => {
                if(min_date == '' || min_date > horr.dt_s) min_date = horr.dt_s;
                
                horr.times.map(time => {
                    events_data.push({
                        id: 1,
                        title: time.tm_s+' à '+time.tm_e,
                        color: '#fb8500',
                        textColor: '#FFF',
                        daysOfWeek: time.days,
                        startRecur: horr.dt_s,
                        endRecur: horr.dt_e,
                    });
                });
            });
        }

        return (
            <FullCalendar
                plugins={[ dayGridPlugin, listPlugin ]}
                initialView="dayGridMonth"
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,listWeek'
                }}
                events={events_data}
                selectable={true}
                height={500}
                // eventClick={handleHoraireClick}
                showNonCurrentDates={false}
                initialDate={min_date || null}
                locale={frLocale}
            />
        );
        
    }
    
    const handleHoraireClick = (eventClick) => {
        setDisplayEventModal(true);
        console.log(eventClick);
    }
    const hideDisplayModal = () => {
        setDisplayEventModal(false);
    }
    const getSelectedEventFiche = () => {
        return <div>salam</div>;
    }

    return ( 
        <div>
            <div className='session_group_content'>
                {getHoraires_presentation()}
                {/* {getHoraires_calendar()} */}

                <InfoPopUp 
                    showModal={displayEventModal} 
                    hideModal={hideDisplayModal}
                    titleModal="Information de la session"
                    okText="Fermer"
                    messageFunction={getSelectedEventFiche}
                    okAction={hideDisplayModal}
                />
            </div>
        </div>
     );
}
 
export default Session_Horaire;