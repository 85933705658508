import { Form, Col } from "react-bootstrap"
import { check_is_200, post, processParams, progressMessage } from "../../../adapters/xhr";
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Button, Spinner, Row, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router";
import { getItemRoute } from "../../../Routes";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { handle_change_file, handle_change_input } from "../../../tools/HInput";
import TextQ from "../../../tools/components/Texts/TextQ";

const Profil_Former_Form = ({ title_form, form_create }) => {
    
    const account = useSelector((state)=>state.account);
    const history = useHistory();

    const [isMounted, setIsMounted] = useState(true);
    const [info, setInfo] = useState({});
    const [loading, setLoading] = useState(false);
    
    const [validated, setValidated] = useState(false);
    const [ttl, setTtl] = useState('');
    const [keys, setKeys] = useState('');
    const [num, setNum] = useState('');
    const [desc, setDesc] = useState('');

    
    
    let progressShow = 0;    

    
    const getData = () => {
        const params = {
            tkn: account.info.tkn
        };
        post("frm", "get", processParams(params)).then(function (xhr) {
            if(check_is_200(xhr) && isMounted) {
                if('info' in xhr.data)
                {
                    setTtl(xhr.data.info.titre || '');
                    setNum(xhr.data.info.n_activite || '');
                    setKeys(xhr.data.info.mots || '');
                    setDesc(xhr.data.info.presentation || '');
                }
            }
        }).catch(function(error){
            // console.log('Error', error);
        }).then(function (){
            // console.log('END');
        });
    };

    useEffect(()=>{
        setIsMounted(true);
        getData();
        return () => { setIsMounted(false); }; 
    }, []);
    
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        progressShow = 0;
        
        
        if(form.checkValidity() === true){
            setLoading(true);
            let params = {
                ttl, keys, num, desc
            };

            if(account.info.tkn != undefined)
            {
                params.tkn = account.info.tkn;
            }
            
            params = processParams(params);
            
            
            const toastId = toast.loading(progressMessage(progressShow));
            post("frm", "update", params)
            .then(function (xhr) {
                if(check_is_200(xhr)) 
                {
                    console.log(xhr.data);
                    if('state' in xhr.data && 'msg' in xhr.data)
                    {
                        if(xhr.data.state)
                        {
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setTimeout(() => { 
                                history.push(getItemRoute('profil_my'));

                            }, 1000);
                        }
                        else {
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setLoading(false);
                        }
                    }
                    else 
                    {
                        toast.error(<b>Problème d'enregistrement !</b>, { id: toastId, });
                        setLoading(false);
                    }
                }
                else
                {
                    toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
                    setLoading(false);       
                }
                
                
                
            }).catch(function(error){
                // console.log('Error', error);
                toast.error(<b>Problème d'envoi !</b>, { id: toastId, });   
                setLoading(false);
            }).then(function (){
                // console.log('END');
                // setLoading(false);
            });
        }
        setValidated(true);
    }



    return (
        <div className='layout_without_center'>
            <h1 className='pageTitle'>{title_form || 'Informations'}</h1>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className='is_block'>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Titre</FormLabel>
                        <FormControl type="text" value={ttl} onChange={(e)=>{handle_change_input(e, setTtl)}} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col} xl={4}>
                        <FormLabel>N° de déclaration d’activité</FormLabel>
                        <FormControl type="text" value={num} onChange={(e)=>{handle_change_input(e, setNum)}} />
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Mots clés</FormLabel>
                        <FormControl type="text" value={keys} onChange={(e)=>{handle_change_input(e, setKeys)}} />
                    </FormGroup>
                </Row>
                
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Présentation</FormLabel>
                        <TextQ value={desc} setValue={setDesc} required={true} validated={validated} />
                    </FormGroup>
                </Row>
                
                {!loading && <Button variant="primary" type="submit">
                    Enregistrer
                </Button>}

                {loading && <Button variant="primary" disabled>
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>}
                {/* <Toaster
                    position="bottom-right"
                    reverseOrder={false}        /> */}
            </Form>
        </div>
    );
} 
export default Profil_Former_Form;