import { useEffect, useState } from "react";
import { check_is_200, post, processParams } from "../../adapters/xhr";
import { useHistory, useParams } from 'react-router-dom';
import LoadingComponent from "../../tools/components/loading/LoadingComponent";
import { getItemRoute } from "../../Routes";

const ValidateEmail = () => {

    const [is_loading, setIs_loading] = useState(true);
    const [is_error, setIs_error] = useState(false);
    const [is_success, setIs_success] = useState(false);

    const [msg_error, setMsg_error] = useState('');
    const [msg_success, setMsg_success] = useState('');

    const { compte } = useParams();
    const history = useHistory();

    const checkEmail = () => {
        post('profil', 'activa', processParams({ compte }))
        .then(xhr=>{
            if(check_is_200(xhr))
            {
                if(('state' in xhr.data) && ('msg' in xhr.data))
                {
                    setIs_loading(false);
                    if(xhr.data.state)
                    {
                        setIs_success(true);
                        setMsg_success(xhr.data.msg || '');
                    }
                    else
                    {
                        setIs_error(true);
                        setMsg_error(xhr.data.msg || '');
                    }
                }
            }
            else
            {
                setIs_error(true);
                setMsg_error("Problème de chargement !");
            }
        })
        .catch(err=>{
            setIs_error(true);
            setMsg_error("Problème de chargement !");
        });
    }

    useEffect(()=>checkEmail(), []);

    const go_connect = () => {
        history.push(getItemRoute('home_connect'));
    }

    return ( <div className='layout_without_center'>
                <h1 className='pageTitle'>Validation d'email</h1>
                <br/><br/><br/>
                <div>
                    <LoadingComponent
                        large_size
                        is_loading={is_loading}
                        is_error={is_error}
                        msg_error={msg_error}
                        is_success={is_success}
                        msg_success={msg_success}
                    />
                    {is_success && <div style={{textAlign:'center'}}><br/><div className='button_stand' onClick={()=>go_connect()}>Se connecter</div><br/></div>}
                </div>
            </div> );
}
 
export default ValidateEmail;