import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import Questions_list from "../Question_item/Questions_list";

const Question_Group_Form = ({group, removeGroup, renameGroup, setQuestions, is_quiz, typeList}) => {
        return <div>
            <Row>
                <FormGroup style={{width:50}}>
                    <div className='button_stand' 
                        onClick={()=>removeGroup()} >
                        <FontAwesomeIcon 
                            icon={ faTimes }
                            className='text-danger'
                            />
                    </div>
                </FormGroup>
                <FormGroup as={Col} className='question_item'>
                    <Row>
                        <FormGroup as={Col} xl={1}>
                            <FormLabel>Titre</FormLabel>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <FormControl type='text' value={group.titre} onChange={(e)=>renameGroup(e)} required/>
                            <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                        </FormGroup>
                    </Row>
                    <Questions_list
                        items={group.questions}
                        setItems={(elems)=>setQuestions(elems)}
                        is_quiz={is_quiz}
                        typeList={typeList}
                    />
                </FormGroup>
            </Row>
        </div>;
}
 
export default Question_Group_Form;