import { useEffect, useState } from "react";
import { Col, Form, FormControl, InputGroup, Row, FormGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { check_is_200, downloadBlob, popup_show_file, post, processParams, progressMessage } from "../../../adapters/xhr";
import { getItemRoute } from "../../../Routes";
import { handle_change_input } from "../../../tools/HInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartArea, faChartPie, faCog, faFileDownload, faInfo, faInfoCircle, faList, faPlus, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import toast, { Toaster } from "react-hot-toast";
import DataTable from "react-data-table-component";
import { filter_search_check_item } from "../../../tools/DataTableOpt";
import DeleteConfirmation from "../../../tools/components/popups/DeleteConfirmation";
import InfoPopUp from "../../../tools/components/popups/InfoPopUp";

const Survey_List = () => {
    
    const account = useSelector((state)=>state.account);

    const history = useHistory();
    
    const [items_list, setItems_list] = useState([]);
    const [filter_by, setFilter_by] = useState('');

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [item_to_remove, setItem_to_remove] = useState({});

    const [selectedEnq, setSelectedEnq] = useState('');

    const getData = () => {
        let params = processParams({
            tkn:account.info.tkn, 
            ctr:account.ctr.key,
            opt: ['prgs']
        });

        post("survey", 'getlist', params)
        .then(function(xhr){
            if(check_is_200(xhr))
            {
                // console.log(xhr.data);
                if('list' in xhr.data) setItems_list(xhr.data.list);
            }
        });
    }

    useEffect(() => {
        getData();
    }, []);
    
    const add_action = () => {
        history.push({
            pathname:getItemRoute('center_survey_form')+account.ctr.key,
            state:{back:true}
        });
    }

    const go_info = (prog_key) => {
        history.push({
            pathname:getItemRoute('center_survey_inf')+account.ctr.key+'/'+prog_key,
            state:{back:true}
        });
    }

    const go_synthese = (prog_key) => {
        history.push({
            pathname:getItemRoute('center_survey_syn')+account.ctr.key+'/'+prog_key,
            state:{back:true}
        });
    }

    const go_update = (prog_key) => {
        history.push({
            pathname:getItemRoute('center_program_update')+account.ctr.key+'/'+prog_key,
            state:{back:true}
        });
    }

    const go_delete = (prog) => {
        setItem_to_remove(prog);
        setDisplayConfirmationModal(true);
    }

    const hideConfirmationModal = () => setDisplayConfirmationModal(false);

    const act_delete = () => {
        hideConfirmationModal();

        let params = processParams({
            tkn:account.info.tkn, 
            ctr:account.ctr.key,
            prg:item_to_remove.key
        });

        const toastId = toast.loading('Envoi');

        post("prgm", "delete", params)
        .then(function (xhr) {
            if(check_is_200(xhr)) {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        getData();
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else 
                {
                    toast.error(<b>Problème d'envoi des données !</b>, { id: toastId, });
                }
            }else{
                toast.error(<b>Problème de suppression !</b>, { id: toastId, });
            }
            
        }).catch(function(error){
            toast.error(<b>Problème de suppression !</b>, { id: toastId, });
        });
    }

    const go_download = (program_key, program_title) => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        post('prgm', 'getfile', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            prg: program_key
        }))
        .then( xhr => {
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé </b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        }).catch(function (err){
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    

    const getActions = (item) => {
        return (<div className='buttons_table'>
                    <div></div>
                    <div className='button_stand' onClick={() => go_info(item.key)} data-tooltip="Informations">
                        <FontAwesomeIcon icon={faInfoCircle}/>
                    </div>
                    <div className='button_stand' onClick={() => go_synthese(item.key)} data-tooltip="Synthèse">
                        <FontAwesomeIcon icon={faChartPie}/>
                    </div>
                    {/* <div className='button_stand' onClick={() => go_update(item.key)} data-tooltip="Modifier">
                        <FontAwesomeIcon icon={faCog}/>
                    </div>
                    <div className='button_stand' onClick={() => go_delete(item)} data-tooltip="Supprimer">
                        <FontAwesomeIcon icon={faTimes}/>
                    </div> */}
                </div>);
    }

    

    const show_progs = (row) => {
        return <div>{
            row.programs.length == 0 ? '' : 
            <div className='button_stand' 
                 onClick={(()=>setSelectedEnq(row))}>
                    <FontAwesomeIcon icon={faList} />
                    &nbsp; <b>{row.programs.length}</b>
            </div>
        }</div>
    }

    const columns = [
        { name: 'Titre', selector: row => row.titre, sortable: true,},
        { name: 'Type', selector: row => row.type, cell:row => <div style={{backgroundColor:row.color, padding:'3px 10px', borderRadius:'5px'}}>{row.type}</div>, sortable: true,},
        { name: 'Programme(s)', selector: row => show_progs(row), sortable: true,},
        { name: 'Action', selector: row => row.action, sortable: true, cell:row => <div>{getActions(row)}</div>},
    ];

    const filteredData = items_list.filter(item => {
        let filter_key = filter_by.toLocaleLowerCase();
        return filter_search_check_item(filter_key, item, ['titre']);
    });

    return ( 
        <div>
            <Row className='headerContentPart' >
                <div className='flex_between'>
                    <div className='title'>Enquête(s)</div>
                    {items_list.length > 0 && <InputGroup className="search width_50">
                                                <InputGroup.Text className="icon">
                                                    <FontAwesomeIcon
                                                        icon={faSearch}
                                                    /> 
                                                </InputGroup.Text>
                                                <FormControl
                                                    placeholder="Filtrer"
                                                    aria-label="Filtrer"
                                                    aria-describedby="filtrate"
                                                    type='text' 
                                                    value={filter_by} 
                                                    className="text_zone"
                                                    onChange={e => handle_change_input(e, setFilter_by)}
                                                />
                                            </InputGroup>}
                    <div><div className='float_right button_stand' onClick={() => add_action()}>
                        <FontAwesomeIcon icon={faPlus} />
                    </div></div>
                </div>
            </Row>

            
            <DataTable
                    pagination
                    fixedHeader
                    columns={columns}
                    data={filteredData}
                    noDataComponent="Aucune enquête n'est enregistré !"
                    paginationComponentOptions={{
                                                    rowsPerPageText: 'Enquêtes par page',
                                                    rangeSeparatorText: 'sur',
                                                    selectAllRowsItem: true,
                                                    selectAllRowsItemText: 'Tous',
                                                }}
                />
            <InfoPopUp
                    showModal={selectedEnq!=''} 
                    hideModal={()=>setSelectedEnq('')} 
                    cancelText='Fermer'
                    titleModal="Liste des programme(s)"
                    messageText={<div>
                            <b>Enquête : </b>{selectedEnq.titre}<br/>
                            <b>Type : </b><div className='div_according' style={{backgroundColor:selectedEnq.color, padding:'3px 10px', borderRadius:'5px'}}>{selectedEnq.type}</div><br/>
                            <b>Liste des programmes : </b><ul>
                                {(selectedEnq.programs || []).map((prg, key)=><li key={key}>{prg}</li>)}
                                </ul><br/>
                        </div>}

                />
            <DeleteConfirmation 
                        showModal={displayConfirmationModal} 
                        confirmModal={act_delete}
                        hideModal={hideConfirmationModal} 
                        message={"Êtes-vous sûr de vouloir supprimer l'enquête \""+item_to_remove.titre+"\" ?"}
                    />
        </div>
    );
}

export default Survey_List;