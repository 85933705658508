import { useEffect, useState } from "react";
import { Button, Col, Form, Row, FormControl, FormGroup, FormLabel, Spinner } from "react-bootstrap"
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useHistory, useParams } from 'react-router-dom';
import { check_is_200, post, processParams, progressMessage } from "../../../adapters/xhr";
import { handle_change_input } from "../../../tools/HInput";
import Question_Group_Form from "../../../tools/components/Questions/Question_Group/Question_Group_List";

const Survey_Frm = () => {

    const account = useSelector((state) => state.account);
    const history = useHistory();

    const { session_key, survey_key } = useParams();

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    let is_update = survey_key != undefined;
    
    const [survey, setSurvey] = useState({});
    
    const [enq_title, setEnq_title] = useState('');
    const [enq_catgr, setEnq_catgr] = useState('');
    const [enq_descr, setEnq_descr] = useState('');
    
    const [categ_list, setCateg_list] = useState([]);
    const [qst_types, setQst_types] = useState([]);
    
    const [items, setitems] = useState([]);
    
    const handleChange = (e, fSet) => {
        fSet(e.target.value);
    }

    const goBack = () => {
        history.goBack();
    }

    const getData = () => {
        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            srv: survey_key
        };

        post('survey', 'form', processParams(params))
        .then(xhr => {
            if(check_is_200(xhr)) 
            {
                if('survey' in xhr.data)
                {
                    let surv = xhr.data.survey;
                    setSurvey(surv);
                    setEnq_title(surv.titre || '');
                    setEnq_catgr(surv.type || '');
                    setEnq_descr(surv.descr || '');
                    setitems(surv.groups || []);
                }
                setCateg_list(xhr.data.cats || []);
                setQst_types(xhr.data.typs || []);
            }
            else
            {
                
            }
        }).catch(function (error){
            
        });
    }

    useEffect(() => {
        getData();
    }, []);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        let progressShow = 0;

        if(form.checkValidity() === true){
            setLoading(true);
            
            let info = {
                enq_title, enq_catgr, enq_descr, items,
                tkn: account.info.tkn,
                ctr: account.ctr.key,
                elc: survey.clme || '',
            };

            // console.log(info);
            if(is_update && 'key' in survey)
            {
                info['srv'] = survey.key;
            }
            
            
            
            let params = processParams(info);
            
            const toastId = toast.loading(progressMessage(progressShow));
            
            post("survey", "sve", params)
            .then(function(xhr){
                if(check_is_200(xhr)) 
                {   
                    if(('save' in xhr.data) && ('msg' in xhr.data))
                    {
                        if(xhr.data.save){
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setTimeout(() => { 
                                goBack();
                            }, 1000);
                        }else{
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });        
                        }
                    }else{
                        toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                    }
                }else{
                    toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                }
            }).catch(function(error){
                toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
            }).then(function() {
                setLoading(false);
            });
        }
        setValidated(true);
    };


    
    return ( 
        <div>
            <Row className='headerContentPart' >
                <FormGroup as={Col}  className='title'>{is_update?'Modification':'Création'} d'une enquête</FormGroup>
            </Row>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Titre</FormLabel>
                        <FormControl type='text' value={enq_title} onChange={(e)=>handleChange(e, setEnq_title)} required/>
                        <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} md={5}>
                        <FormLabel>Type</FormLabel>
                        <FormControl as='select' value={enq_catgr} onChange={(e)=>handleChange(e, setEnq_catgr)} required>
                            {enq_catgr=='' && <option value=''>Choisissiez</option>}
                            {categ_list.map((item, key) => <option key={key} value={item.v}>{item.n}</option>)}
                        </FormControl>
                        <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Description</FormLabel>
                        <FormControl as='textarea' value={enq_descr} onChange={(e)=>handle_change_input(e, setEnq_descr)}/>
                    </FormGroup>
                </Row>
                
                <Question_Group_Form
                    items={items}
                    setItems={setitems}
                    is_quiz={false}
                    typeList={qst_types}    />
                    
                {!loading && <Button type="submit">Enregistrer</Button>}
                {loading && <Button variant="primary" disabled>
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>}
                {/* <Toaster
                    position="bottom-right"
                    reverseOrder={false}        /> */}
            </Form>
        </div>
    );
}
 
export default Survey_Frm;