import { useEffect, useState } from "react";
import { Col, Row, FormGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { check_is_200, downloadBlob, downloadFile, popup_show_file, post, processParams, progressDownloadMessage, progressMessage } from "../../../../adapters/xhr";
import toast, { Toaster } from "react-hot-toast";
import InfoPopUp from "../../../../tools/components/popups/InfoPopUp";
import LoadingComponent from "../../../../tools/components/loading/LoadingComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate, faCheckCircle, faCheckDouble, faDownload, faEnvelope, faFile, faFileAlt, faInfoCircle, faStamp } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";

const Session_Gestion = (props) => {

    let session_name = '';
    if(('location' in props) && ('state' in props.location) && ('sesname' in props.location.state))
        session_name = props.location.state.sesname;

    const account = useSelector(state => state.account);
    const { center_key, session_key } = useParams();

    const [suivies, setSuivies] = useState([]);
    
    const [modal_info_stag_inscr_is_show, setModal_info_stag_inscr_is_show] = useState(false);
    const [modal_info_stag_inscr_message, setModal_info_stag_inscr_message] = useState('');
    
    const getData = () => {
        let params = {
            tkn: account.info.tkn,
            ctr: center_key,
            ses: session_key
        };

        post('sesadm', 'info', processParams(params))
        .then(function (xhr) {
            if(check_is_200(xhr))
            {
                // console.log(xhr.data);
                setSuivies(xhr.data.suivi || []);
            }
            else
            {
                //error
            }
        })
        .catch(function (err)
        {

        });
    }

    useEffect(() => {
        getData();
    }, []);

    const get_Devis_ini = (devis_key) => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        post('ctrdev', 'getfile', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            dev: devis_key
        }))
        .then(function (xhr)
        {
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Fichier téléchargé</b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème téléchargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème téléchargement !</b>, { id: toastId, });
        
        }).catch(function (err){
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }
    
    const get_Devis = (devis_key) => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        post('ctrdev', 'getfilesgd', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            dev: devis_key
        }))
        .then(function (xhr)
        {
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Fichier téléchargé</b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème téléchargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème téléchargement !</b>, { id: toastId, });
        
        }).catch(function (err){
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const get_Facture = (devis_key, devis_num, devis_client) => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        post('ctrdev', 'getfct', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            dev: devis_key
        }))
        .then(function (xhr){
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Fichier téléchargé</b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème téléchargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème téléchargement !</b>, { id: toastId, });
        }).catch(function (err){
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const get_Convention = (devis_key) => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        post('ctrdev', 'getfilecnv', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            dev: devis_key
        }))
        .then(function (xhr)
        {
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Fichier téléchargé</b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème téléchargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème téléchargement !</b>, { id: toastId, });
        
        }).catch(function (err){
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const get_Emargement = (devis_key, devis_num, devis_client) => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        console.log({ctr: account.ctr.key,
            ses: session_key,
            dev: devis_key});
            
        post('presence', 'ses', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            dev: devis_key
        }))
        .then(function (xhr){
            console.log(xhr);
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Fichier téléchargé</b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème téléchargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème téléchargement !</b>, { id: toastId, });
        }).catch(function (err){
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const get_Attestations = (devis_key) => {
        let progressShow = 0;
        const toastId = toast.loading(progressDownloadMessage(progressShow));
        
        post('sesadm', 'geta', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            dev: devis_key
        }))
        .then(function (xhr){
            console.log(xhr.data);
            downloadFile(
                {file: xhr.data.file},
                xhr.data.name,
                'zip',
                (e) => { toast.success(<b>Fichier téléchargé</b>, { id: toastId, }); },
                (p) => { toast.loading(progressDownloadMessage(p), { id: toastId, }); }
            )
            .catch(function (err){
                toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
            });
            
        }).catch(function (err){
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const get_Certificats = (devis_key) => {
        let progressShow = 0;
        const toastId = toast.loading(progressDownloadMessage(progressShow));
        
        post('sesadm', 'getcr', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            dev: devis_key
        }))
        .then(function (xhr){
            console.log(xhr.data);
            downloadFile(
                {file: xhr.data.file},
                xhr.data.name,
                'zip',
                (e) => { toast.success(<b>Fichier téléchargé</b>, { id: toastId, }); },
                (p) => { toast.loading(progressDownloadMessage(p), { id: toastId, }); }
            )
            .catch(function (err){
                toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
            });
            
        }).catch(function (err){
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const get_Convocations = (devis_key) => {
        let progressShow = 0;
        const toastId = toast.loading(progressDownloadMessage(progressShow));
        
        post('sesadm', 'getc', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            dev: devis_key
        }))
        .then(function (xhr){
            
            downloadFile(
                {file: xhr.data.file},
                xhr.data.name,
                'zip',
                (e) => { toast.success(<b>Fichier téléchargé</b>, { id: toastId, }); },
                (p) => { toast.loading(progressDownloadMessage(p), { id: toastId, }); }
            )
            .catch(function (err){
                toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
            });
            
        }).catch(function (err){
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const get_Folder = (devis_key) => {
        let progressShow = 0;
        const toastId = toast.loading(progressDownloadMessage(progressShow));
        
        post('sesadm', 'getz', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            dev: devis_key
        }))
        .then(function (xhr){
            
            downloadFile(
                {file: xhr.data.file},
                xhr.data.name,
                'zip',
                (e) => { toast.success(<b>Fichier téléchargé</b>, { id: toastId, }); },
                (p) => { toast.loading(progressDownloadMessage(p), { id: toastId, }); }
            )
            .catch(function (err){
                toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
            });
            
        }).catch(function (err){
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const modal_info_stag_inscr_show = () => setModal_info_stag_inscr_is_show(true);
    const modal_info_stag_inscr_hide = () => setModal_info_stag_inscr_is_show(false);
    
    
    const get_inscrit_trainee = (devis_key) => {
        setModal_info_stag_inscr_message(<LoadingComponent medium_size is_loading/>);
        
        modal_info_stag_inscr_show();

        let params = {
            tkn: account.info.tkn,
            ctr: center_key,
            ses: session_key,
            dev: devis_key,
        };

        let list_inscr = [];
        let list_devis = [];

        post('sesadm', 'devstg', processParams(params))
        .then(function (xhr) {
            if(check_is_200(xhr))
            {
                list_devis = xhr.data.devis || [];
                list_inscr = xhr.data.session || [];
                
                setModal_info_stag_inscr_message(<div>
                    <table className='width_100'>
                        <tbody>
                            <tr>
                                <th>Mentionnés dans le devis</th>
                                <th>Inscrits dans la session</th>
                            </tr>
                            <tr>
                                <td className='vertical_align_top'>
                                    {list_devis.length >0 && <ol>
                                        {list_devis.map((name, key)=> <li key={key}>{name}</li>)}
                                    </ol>}
                                    {list_devis.length==0 && <i>Liste vide</i>}
                                </td>
                                <td className='vertical_align_top'>
                                    {list_inscr.length >0 && <ol>
                                        {list_inscr.map((name, key)=> <li key={key}>{name}</li>)}
                                    </ol>}
                                    {list_inscr.length==0 && <i>Liste vide</i>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>);
            }
            else
            {
                //error
            }
        })
        .catch(function (err)
        {

        });
        
    }



    const get_pdf = () => {

        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key
        };

        // console.log(params);
        
        post('sesadm','lstp', processParams(params), {
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                progressShow = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                toast.loading(progressMessage(progressShow), { id: toastId, });
            },
        })
        .then(function (response){
            toast.success(<b>Fichier téléchargé</b>, { id: toastId, });
            downloadBlob(response.data, "Liste devis "+session_name+".pdf");
        }).catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    

    const getActions = (suivi) => {
        return (<div className='buttons_table'>
                    <div></div>
                    <div className='button_stand' onClick={()=>get_Devis_ini(suivi.key, suivi.num, suivi.client_name)} data-tooltip="Devis initial"><FontAwesomeIcon icon={faFileAlt}/></div>
                    <div className='button_stand' onClick={()=>get_Devis(suivi.key, suivi.num, suivi.client_name)} data-tooltip="Devis signé"><FontAwesomeIcon icon={faFileAlt}/></div>
                    <div className='button_stand' onClick={()=>get_Facture(suivi.key, suivi.num, suivi.client_name)} data-tooltip="Facture"><FontAwesomeIcon icon={faFile}/></div>
                    {(suivi.convention_file || false) && <div className='button_stand' onClick={()=>get_Convention(suivi.key)} data-tooltip="Convention"><FontAwesomeIcon className='icon_purple' icon={faFile}/></div>}
                    {suivi.inscr_stagiaires > 0 && <div style={{'display':'inline-block'}}>
                        <div className='button_stand' onClick={()=>get_Emargement(suivi.key, suivi.num, suivi.client_name)} data-tooltip="Feuille d'émargement"><FontAwesomeIcon icon={faCheckDouble}/></div>
                        <div className='button_stand' onClick={()=>get_Attestations(suivi.key)} data-tooltip="Attestations"><FontAwesomeIcon icon={faCertificate}/></div>
                        <div className='button_stand' onClick={()=>get_Certificats(suivi.key)} data-tooltip="Certificats"><FontAwesomeIcon icon={faStamp}/></div>
                        <div className='button_stand' onClick={()=>get_Convocations(suivi.key)} data-tooltip="Convocations"><FontAwesomeIcon icon={faEnvelope}/></div>
                        <div className='button_stand' onClick={()=>get_Folder(suivi.key)} data-tooltip="Tout télècharger"><FontAwesomeIcon icon={faDownload}/></div>
                    </div>}
                </div>);
    }

    const columns = [
        { name: 'N°Devis', selector: row => row.num, width:'120px', sortable: true},
        { name: 'N°Facture', selector: row => row.numfac, width:'120px', sortable: true},
        { name: 'Client', selector: row => row.client_name, sortable: true,},
        { name: 'Montant HT', selector: row => row.devis_montant, sortable: true,},
        { name: 'Statut de paiement', selector: row => row.is_cashed ? 'Payé' : '', sortable: true,},
        { name: 'Pris en charge (opco, ...) HT', selector: row => row.opco_montant > 0 ? row.opco_montant : '', sortable: true},
        { name: 'Statut de paiement', selector: row => row.is_cashed_opco ? 'Payé' : '', sortable: true,},
        { name: 'OPCO', selector: row => row.opco_name, sortable: true,},
        { name: 'Stagiaires inscrits', selector: row => '', cell: row => <div onClick={()=>get_inscrit_trainee(row.key)}>{(row.inscr_stagiaires || 0)+" / "+(row.nbr_stagiaires || 0)} <div className='button_stand'><FontAwesomeIcon icon={faInfoCircle}/></div></div>, sortable: true, maxWidth: '200px'},
        { name: 'Action', selector: row => row.action, sortable: true, width: '350px', cell:row => <div style={{}}>{getActions(row)}</div>},
    ];

    return ( <div>
        <div className='headerContentPart flex_between' >
            <div className='title div_according'>
                Documents administratifs
                {(session_name != '') && <div className='sub_title'>{session_name}</div>}
            </div>
            <div className='div_according'>
                <div className='button_stand' onClick={() => get_pdf()}>
                    Imprimer
                </div>
            </div>
            

        </div>
        {/* <table className='table_ini'>
            <thead>
                <tr>
                    <th>Client</th>
                    <th>Motif</th>
                    <th>Fichier</th>
                    <th></th>
                </tr>
            </thead>
        </table> */}
        
        <DataTable
                    pagination
                    fixedHeader
                    columns={columns}
                    data={suivies}
                    noDataComponent="Aucun document n'est enregistré !"
                    paginationComponentOptions={{
                                                    rowsPerPageText: 'Documents par page',
                                                    rangeSeparatorText: 'sur',
                                                    selectAllRowsItem: true,
                                                    selectAllRowsItemText: 'Tous',
                                                }}
                />
        <InfoPopUp 
                    showModal={modal_info_stag_inscr_is_show} 
                    hideModal={modal_info_stag_inscr_hide}
                    titleModal="Liste des stagiaires inscrits"
                    okText="Fermer"
                    messageText={modal_info_stag_inscr_message}
                    okAction={modal_info_stag_inscr_hide}
                />
    </div> );

    
    
}


 
export default Session_Gestion;