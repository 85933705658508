import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { check_is_200, post, processParams } from "../../adapters/xhr";
import { getItemRoute } from "../../Routes";
import { ButtonGroup, Col, Dropdown, DropdownButton, Row, FormGroup, FormControl, InputGroup } from "react-bootstrap";
import ProfilWidget from "../../tools/components/ProfilWidget/ProfilWidget";
import { handle_change_input } from "../../tools/HInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

const Structure = () => {
    
    const account = useSelector((state)=>state.account);
    const history = useHistory();
    
    const link_add = getItemRoute('center_member_form');
    const link_search = getItemRoute('center_member_search');
    const link_info = getItemRoute('center_member');

    const [members, setMembers] = useState([]);
    
    const [filter_by, setFilter_by] = useState('');

    const getData = () => {
        let params = processParams(
            {
                tkn:account.info.tkn, 
                ctr:account.ctr.key,
                bil:1,
            });
            
        post("member", "members", params)
        .then(function(xhr){
            if(check_is_200(xhr)) {
                // console.log(xhr.data);
                let listMembers = xhr.data;
                setMembers(listMembers);
            }else{

            }
        });
    }

    useEffect(() => {
        getData();
    }, []);

    const show_members = (mms, clas) => {
        return (
            <div>
                {mms.filter(item => {
                    let name = ['nom' in item ? item.nom.toLocaleLowerCase() : '', 
                                'pnom' in item ? item.pnom.toLocaleLowerCase() : ''];
                    
                    let name_asc = name.join(' ');
                    let name_desc = name.reverse().join(' ');
                    
                    return name_asc.includes(filter_by.toLocaleLowerCase()) || name_desc.includes(filter_by.toLocaleLowerCase());
                }).map((item, key) => <div key={key}>
                                            <ProfilWidget
                                                width_percent={100} 
                                                key={key} 
                                                avatarClass={clas}
                                                profil={item} 
                                                action={() => get_info(item.key)} 
                                                sub_title={item.iscnf?'':'En attente de confirmation'}
                                            />
                                        </div>)}
            </div>
        );
    }
    
    const add_action = () => {
        history.push({
            pathname:link_add,
            state:{back:true}
        });
    }
    const get_action = () => {
        history.push({
            pathname:link_search,
            state:{back:true}
        });
    }
    const get_info = (key_s) => {
        history.push({
            pathname:link_info+key_s,
            state:{back:true}
        });
    }

    return ( 
        <div>

            
            <Row className='headerContentPart' >
                <div className='flex_between'>
                    <div className='title'>Structure</div>
                    {members.length > 0 && <InputGroup className="search width_50">
                                                <InputGroup.Text className="icon">
                                                    <FontAwesomeIcon
                                                        icon={faSearch}
                                                    /> 
                                                </InputGroup.Text>
                                                <FormControl
                                                    placeholder="Filtrer"
                                                    aria-label="Filtrer"
                                                    aria-describedby="filtrate"
                                                    type='text' 
                                                    value={filter_by} 
                                                    className="text_zone"
                                                    onChange={e => handle_change_input(e, setFilter_by)}
                                                />
                                            </InputGroup>}
                    {link_add!=='/' && <div><DropdownButton
                                        as={ButtonGroup}
                                        dropdown={null}
                                        title={<FontAwesomeIcon icon={faPlus}/>}
                                        className='float_right button_stand padding_0' >
                                        <Dropdown.Item onClick={() => add_action()}>Créer un membre</Dropdown.Item>
                                        <Dropdown.Item onClick={() => get_action()}>Inviter un membre</Dropdown.Item>
                                    </DropdownButton></div>}
                </div>

            </Row>
            
            <Row>
            {members.map((item, key) => {
                if(item.l.length > 0)
                {
                    return <FormGroup as={Col} key={key}>
                                <div>
                                    <h3 className='textAlignCenter backWhite'>
                                        <div className={'button_stand'}>{item.n}{item.l.length>1 ? 's':''}</div>
                                    </h3>
                                    {show_members(item.l, 'bg_prf_'+item.i)}
                                </div>
                            </FormGroup>
                }
                else
                {
                    return null;
                }
            })}
            </Row>
        </div>
    );
}
 
export default Structure;