import { check_is_200, post, processParams, progressMessage } from "../../adapters/xhr";
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Button, Spinner, Row, Form, Col, FormGroup, FormLabel, FormControl, InputGroup } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router";
import { getItemRoute } from "../../Routes";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { handle_change_file, handle_change_input } from "../../tools/HInput";

const Profil_Form = ({ title_form, form_create }) => {
    
    const account = useSelector((state)=>state.account);
    const history = useHistory();

    const [isMounted, setIsMounted] = useState(true);
    const [info, setInfo] = useState({});
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [prenom, setPrenom] = useState('');
    const [adresse, setAdresse] = useState('');
    const [cp, setCp] = useState('');
    const [ville, setVille] = useState('');
    const [tel, setTel] = useState('');
    const [fix, setFix] = useState('');
    const [nais_dt, setNais_dt] = useState('');
    const [nais_li, setNais_li] = useState('');
    const [sexe, setSexe] = useState('');
    const [avatar, setAvatar] = useState('');
    const [avatarShow, setAvatarShow] = useState();
    const [crop, setCrop] = useState({aspect:1, width:200});
    const [avatarEnd, setAvatarEnd] = useState();

    const [pass_new, setpass_new] = useState('');
    const [pass_new_c, setpass_new_c] = useState('');

    const [is_ident, setis_ident] = useState('');

    let is_create_form = form_create == true;
    
    let progressShow = 0;    
    
    const sexeList = [ 
                        {n:'Mr', v:'h'},
                        {n:'Mme', v:'f'},
                    ];

    const [validated, setValidated] = useState(false);
    
    const getData = () => {
        const params = {
            tkn: account.info.tkn
        };
        post("profil", "mesinfos", processParams(params)).then(function (xhr) {
            if(check_is_200(xhr) && isMounted) {

                setEmail(xhr.data.email);
                setName(xhr.data.nom);
                setPrenom(xhr.data.pnom);
                setAdresse(xhr.data.adresse);
                setCp(xhr.data.cp);
                setVille(xhr.data.ville);
                setTel(xhr.data.tel);
                setFix(xhr.data.fix);
                setNais_dt(xhr.data.nais_dt);
                setNais_li(xhr.data.nais_li);
                setSexe(xhr.data.sexe);
                if(xhr.data.avtr && xhr.data.url){
                    setAvatarShow(xhr.data.url+xhr.data.avtr+'?r='+(new Date()))
                }
                
            }
        }).catch(function(error){
            // console.log('Error', error);
        }).then(function (){
            // console.log('END');
        });
    };

    useEffect(()=>{
        setIsMounted(true);
        if(!is_create_form) getData();
        return () => { setIsMounted(false); }; 
    }, []);
    
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        progressShow = 0;
        let cond_is_ident = !is_create_form || is_ident;
        
        if(form.checkValidity() === true && cond_is_ident){
            setLoading(true);
            let params = {
                nom: name,
                pnom: prenom,
                adresse: adresse,
                cp: cp,
                ville: ville,
                tel: tel,
                fix: fix,
                nais_dt: nais_dt,
                nais_li: nais_li,
                sexe: sexe,
                mail: email,
                pwrd: pass_new,
                iscf: is_create_form ? 1 : 0,
            };

            if(account.info.tkn != undefined)
            {
                params.tkn = account.info.tkn;
            }
            
            params = processParams(params);
            
            if(avatarEnd !== undefined){
                params.append('avt', avatarEnd);
            }

            const toastId = toast.loading(progressMessage(progressShow));
            post("profil", "update", params)
            .then(xhr => {
                if(check_is_200(xhr)) 
                {
                    console.log(xhr.data);
                    if('state' in xhr.data && 'msg' in xhr.data)
                    {
                        if(xhr.data.state)
                        {
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setTimeout(() => { 
                                if(is_create_form)
                                {
                                    history.push(getItemRoute('home_connect'));
                                }
                                else
                                {
                                    history.push(getItemRoute('profil_my'));
                                }

                            }, 1000);
                        }
                        else {
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setLoading(false);
                        }
                    }
                    else 
                    {
                        toast.error(<b>Problème d'enregistrement !</b>, { id: toastId, });
                        setLoading(false);
                    }
                }
                else
                {
                    toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
                    setLoading(false);       
                }
                
                
                
            }).catch(function(error){
                // console.log('Error', error);
                toast.error(<b>Problème d'envoi !</b>, { id: toastId, });   
                setLoading(false);
            }).then(function (){
                // console.log('END');
                // setLoading(false);
            });
        }
        setValidated(true);
    }


    const update_pass = (ind, e, sets) => {
        handle_change_input(e, sets);
        let pass_n = pass_new;
        let pass_c = pass_new_c;
        
        if(ind==1) pass_n = e.target.value;
        if(ind==2) pass_c = e.target.value;

        if(pass_c!='' && pass_n!=''){
            setis_ident(pass_c==pass_n);
        }else setis_ident('');
    }

    const getCroppedImg = async () => {
        try {
            const canvas = document.createElement("canvas");
            const scaleX = avatar.naturalWidth / avatar.width;
            const scaleY = avatar.naturalHeight / avatar.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(
                avatar,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );
    
            const base64Image = canvas.toDataURL("image/jpeg", 1);
    
            setAvatarEnd(base64Image);
    
        } catch (e) {
            
        }
    };

    useEffect(() => getCroppedImg(), [crop]);

    return (
        <div className='layout_without_center'>
            <h1 className='pageTitle'>{title_form}</h1>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className='is_block'>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Nom</FormLabel>
                        <InputGroup>
                            <div as={Col} xl={4}>
                                <FormControl as="select" value={sexe} onChange={(e)=>{handle_change_input(e, setSexe)}} custom>
                                {sexeList.map((opt, key) => (
                                                    <option key={key} value={opt.v}>{opt.n}</option>
                                                ))}
                                </FormControl>
                            </div>
                            <FormControl type="text" value={name} onChange={(e)=>{handle_change_input(e, setName)}} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Prénom</FormLabel>
                        <FormControl type="text" value={prenom} onChange={(e)=>{handle_change_input(e, setPrenom)}} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Email</FormLabel>
                        <FormControl type="text" value={email} onChange={(e)=>{handle_change_input(e, setEmail)}} readOnly={!is_create_form} required />
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                {is_create_form && <div>
                    <Row>
                        <FormGroup as={Col}>
                            <FormLabel>Mot de passe</FormLabel>
                            <FormControl type="password" value={pass_new} onChange={(e)=>{update_pass(1, e, setpass_new)}} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <FormLabel>Re-taper le mot de passe</FormLabel>
                            <FormControl type="password" value={pass_new_c} onChange={(e)=>{update_pass(2, e, setpass_new_c)}} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                    </Row>
                    {is_ident===false && <Row>
                        <FormGroup as={Col}>
                            <div className="mb_variant mb_danger">Les mots de passe saisie ne sont pas identiques</div>
                        </FormGroup>
                    </Row>}
                </div>}
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Date naissance</FormLabel>
                        <FormControl type="date" value={nais_dt} onChange={(e)=>{handle_change_input(e, setNais_dt)}} />
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Lieu naissance</FormLabel>
                        <FormControl type="text" value={nais_li} onChange={(e)=>{handle_change_input(e, setNais_li)}} />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Adresse</FormLabel>
                        <FormControl type="text" maxLength="100" value={adresse} onChange={(e)=>{handle_change_input(e, setAdresse)}} />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Code postale</FormLabel>
                        <FormControl type="text" value={cp} onChange={(e)=>{handle_change_input(e, setCp)}} />
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Ville</FormLabel>
                        <FormControl type="text" value={ville} onChange={(e)=>{handle_change_input(e, setVille)}} />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Tel</FormLabel>
                        <FormControl type="text" value={tel} onChange={(e)=>{handle_change_input(e, setTel)}} />
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Fix</FormLabel>
                        <FormControl type="text" value={fix} onChange={(e)=>{handle_change_input(e, setFix)}} />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Avatar</FormLabel>
                        <FormControl type='file' onChange={(e)=>{handle_change_file(e, setAvatar, setAvatarShow)}} />
                        {/* {avatarShow && <ReactCrop src={avatarShow} crop={crop} onChange={newCrop => setCrop(newCrop)} />} */}
                        {avatarShow && (
                                    <div className='is_table'>
                                        <div className='is_table_col' style={{width: "50%"}}>
                                            <ReactCrop
                                                src={avatarShow}
                                                onImageLoaded={setAvatar}
                                                crop={crop}
                                                onChange={setCrop}
                                            />
                                            
                                        </div>
                                        <div style={{width: "50%", padding:'1px 10px'}}>
                                        {avatarEnd && <div className='is_table_col'><img src={avatarEnd} className='image_show_circle_size margin_5'/></div> }
                                        {avatarEnd && <div className='is_table_col'><img src={avatarEnd} className='image_show_small_size margin_5'/></div> }
                                        {avatarEnd && <div className='is_table_col'><img src={avatarEnd} className='image_show_full_size margin_5'/></div> }
                                        </div>
                                    </div>
                                )}
                        
                    </FormGroup>

                    

                </Row>
                
                {!loading && <Button variant="primary" type="submit">
                    Enregistrer
                </Button>}

                {loading && <Button variant="primary" disabled>
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>}
            </Form>
        </div>
    );
} 

export default Profil_Form;