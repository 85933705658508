import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import Question_valeurs from "../Questions_types/Question_valeurs";
import Question_valeurs_quiz from "../Questions_types/Question_valeurs_quiz";

const Question_Form = ({ question, indice, removeItem, setTitle, setChoix, is_quiz, type, setType, typeList} ) => {
    return (
        <Row key={indice}>
            <FormGroup style={{width:50}}>
                <div className='button_stand' 
                     onClick={()=>removeItem()} >
                    <FontAwesomeIcon 
                        icon={ faTimes }
                        className='text-danger'
                        />
                </div>
            </FormGroup>
            
            <FormGroup as={Col} className='question_item'>
                <Row>
                    <FormGroup as={Col} xl={1}>
                        <FormLabel>Question</FormLabel>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormControl as='textarea' value={question.titre} onChange={(e)=>setTitle(e)} required/>
                        <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                    </FormGroup>
                </Row>

                {is_quiz ? <Question_valeurs_quiz 
                        list={question.choix} 
                        setValues={setChoix} 
                        indice={indice} 
                    />
                    :
                    <Question_valeurs 
                        list={question.choix} 
                        setChoix={setChoix} 
                        type={question.type}
                        setType={setType}
                        typeList={typeList}
                        indice={indice} 
                    />}
            </FormGroup>
        </Row>
    )
};
 
export default Question_Form;