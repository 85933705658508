import { useEffect, useState } from "react";
import { Form, Button, FormGroup, FormLabel, FormControl } from "react-bootstrap"
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { check_is_200, post, processParams } from "../../../adapters/xhr/index.js";
import useFormInput from "../../../tools/components/useFormInput.js";

const Form_ini_pass = ({ return_con_form }) => {

    const email = useFormInput('');
    
    const { user_key } = useParams();
    
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const history = useHistory();
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();

        let its_ok = true;
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            its_ok = false;
        }
        setValidated(true);

        if(its_ok){
            checkConnexion({'eml': email.value});
        }
    };


    const checkConnexion = (params) => {
        setLoading(true);
        const toastId = toast.loading('Rénitialisation');
        
        post("profil", "reset", processParams(params)).then(function (xhr) {
            if(check_is_200(xhr)){
                // console.log(xhr.data);
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        setTimeout(function () {
                            return_con_form();
                        }, 2000);
                    }
                    else 
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
                }
            }
        }).catch(function(error){
            toast.error(<b>Problème de vérification</b>, { id: toastId, });
        }).then(function (){
            setLoading(false);
        });
    }

    const checkkey = () => {
        if (typeof user_key !== 'undefined'){
            checkConnexion({'rky': user_key});
        }
    }

    useEffect(() => checkkey(),[])
    
    return ( 
        <div>
            <h1 className='pageTitle'>Réinitialisation du mot de passe</h1>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className='centerForm'>
                <FormGroup>
                    <FormLabel>Email</FormLabel>
                    <FormControl type="email" {...email} required />
                    <FormControl.Feedback type="invalid">
                        Veuillez saisir votre email
                    </FormControl.Feedback>
                </FormGroup>
                {!loading && <Button variant="primary" type="submit">
                    Réinitialiser
                </Button>}
                &nbsp;
                <Button variant="primary" onClick={() => return_con_form()}>
                    Annuler
                </Button>
            </Form>
        </div>
    );
}
 
export default Form_ini_pass;