import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import toast from "react-hot-toast";
import { check_is_200, post, processParams, progressMessage } from "../../../adapters/xhr";
import ConfirmationModel from "../../../tools/components/popups/ConfirmationModel";
import DeleteConfirmation from "../../../tools/components/popups/DeleteConfirmation";
import { handle_change_input } from "../../../tools/HInput";

const Structure_Btn_Opt = ({ in_menu, user_tkn, center_key, profile_key, profile_pos, profil_name }) => {
    
    const [modal_center_link, setModal_center_link] = useState(false);

    const get_modal_info = () => { setModal_center_link(true); }

    const modal_center_link_hide = () => setModal_center_link(false);

    const modal_center_link_component = () => <Structure_Btn_Opt_info
                                                    user_tkn={user_tkn} 
                                                    center_key={center_key} 
                                                    profile_key={profile_key} 
                                                    profile_pos={profile_pos} 
                                                    profil_name={profil_name}    />

    return (<div className={ in_menu ? '' : 'div_according'}>
                <div className={in_menu ? '' : 'button_stand button_bg'} onClick={() => get_modal_info()} >
                    Relation avec le centre
                </div>
                <ConfirmationModel
                            title={(profil_name || '') + ' et le centre'}
                            showModal={modal_center_link}
                            hideModal={modal_center_link_hide}
                            show_footer={false}
                            complement={modal_center_link_component}
                        />
            </div>);
}
const Structure_Btn_Opt_info = ({ user_tkn, center_key, profile_key, profile_pos, profil_name }) => {
    const [is_member, setIs_member] = useState(false);
    const [contrat, setContrat] = useState('');
    
    const [roles_act, setRoles_act] = useState([]);
    const [roles_ena, setRoles_ena] = useState([]);
    const [roles_inv, setRoles_inv] = useState([]);
    const [roles_to_inv, setRoles_to_inv] = useState([]);

    const [role_to_cancel, setRole_to_cancel] = useState({});

    const [role_to_revoke, setRole_to_revoke] = useState({});
    
    const [invite_form_validated, setInvite_form_validated] = useState(false);
    const [invite_form_loading, setInvite_form_loading] = useState(false);
    const [invite_form_msg, setInvite_form_msg] = useState('');
    const [invite_form_contrat, setInvite_form_contrat] = useState('');
    
    const [cancel_modal_show, setCancel_modal_show] = useState(false);
    const [revoke_modal_show, setRevoke_modal_show] = useState(false);



    const contract_types = [
        {n:'CDD', v:'cdd'},
        {n:'CDI', v:'cdi'},
        {n:'Bénévole', v:'bnv'},
        {n:'Indépendant', v:'ind'},
        {n:'Société', v:'soc'},
    ];

    const get_contract_name = (cont) => {
        let ctype = contract_types.filter(c=>c.v == cont);
        if(ctype.length == 1) return ctype[0]['n'];
        else return '';
    }

    const invite_modal_form = () => {
    
        const select_inv_role = (e, role) => {
            if(e.target.checked)
            {
                // ADD
                if(!roles_to_inv.includes(role.ind))
                {
                    setRoles_to_inv(roles_to_inv.concat([role.ind]));
                }
            }
            else 
            {
                // REMOVE
                setRoles_to_inv(roles_to_inv.filter(e => e != role.ind));
            }
        }

        return (
            <div>
                {contrat!=='' && <div><h5>Type de contrat</h5>
                {contrat}</div>}
                
                {roles_act.length > 0 && <div>
                    <hr/>
                    <h5>Rôles dans le centre</h5>
                    <table className='table_ini'>
                        <thead>
                            <tr>
                                <th>Rôle</th>
                                <th>Depuis</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {roles_act.map((role, key)=><tr key={key}>
                                <td>{role.titre}</td>
                                <td>{role.act_d}</td>
                                <td><div className='button_stand' onClick={()=>revoke_role(role)}>
                                    <FontAwesomeIcon icon={faTimes}/>
                                    </div></td>
                            </tr>)}
                        </tbody>
                    </table>
                    </div>}
                
                {roles_ena.length > 0 && <div>
                    <hr/>
                    <h5>Invitation{roles_ena.length > 1 ? 's':''} en cours</h5>
                    <table className='table_ini'>
                        <thead>
                            <tr>
                                <th>Rôle</th>
                                <th>Depuis</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {roles_ena.map((role, key)=><tr key={key}>
                                <td>{role.titre}</td>
                                <td>{role.req_d}</td>
                                <td><div className='button_stand' onClick={()=>cancel_request(role)}>
                                    <FontAwesomeIcon icon={faTimes}/>
                                    </div></td>
                            </tr>)}
                        </tbody>
                    </table>
                    </div>}

                {roles_inv.length > 0 && <div>
                    {contrat!=='' && <hr/>}
                    <h5>Inviter</h5>
                    <Form id='form_id' noValidate validated={invite_form_validated} onSubmit={invite_form_send}>
                        {contrat==='' && <Row><FormGroup as={Col}>
                            <FormLabel>Statut</FormLabel>
                            <FormControl as='select' value={invite_form_contrat} onChange={(e)=>handle_change_input(e, setInvite_form_contrat)} required>
                                <option value=''>Choisissez</option>
                                {contract_types.map((item, key) => <option key={key} value={item.v}>{item.n}</option>)}
                            </FormControl>
                            <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                        </FormGroup></Row>}
                        <Row>
                            <FormGroup as={Col}>
                                <FormLabel>Rôles</FormLabel>                                
                                {roles_inv.map((item, key)=><FormGroup key={key} className="mb-3">
                                    <Form.Check 
                                        type="checkbox" 
                                        label={item.titre}
                                        checked={roles_to_inv.includes(item.ind)}
                                        onChange={(e) => select_inv_role(e, item)}
                                    />
                                </FormGroup>)}

                                <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                            </FormGroup>
                        </Row>
                        {roles_to_inv.length > 0 && <Row>
                            <FormGroup as={Col}>
                                <FormLabel>Message (facultatif) </FormLabel>
                                <FormControl as="textarea"  value={invite_form_msg} onChange={(e)=>{handle_change_input(e, setInvite_form_msg)}}/>    
                            </FormGroup>
                        </Row>}
                        {roles_to_inv.length > 0 && <Button type='submit'>Inviter</Button>}
                    </Form>
                    </div>}
                    <br/>
            </div>
        );
    }
    const invite_form_send = (event) => {
        
        setInvite_form_validated(true);

        const form = event.currentTarget;
        let progressShow = 0;
        event.preventDefault();

        if(form.checkValidity() === true){
        
            setInvite_form_loading(true);
        
            const form = {
                tkn: user_tkn,
                ctr: center_key,
                prf: profile_key,
                pos: roles_to_inv,
                msg: invite_form_msg,
                cnt: invite_form_contrat,
            };
            
            let params = processParams(form);
            
            const toastId = toast.loading(progressMessage(progressShow));
            post("ctrntw", "invite", params)
            .then(function (xhr) {
                if(check_is_200(xhr)) {
                    if('state' in xhr.data && 'msg' in xhr.data)
                    {
                        if(xhr.data.state)
                        {
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            let rolesreq = roles_inv.filter(r => roles_to_inv.includes(r.ind));
                            setRoles_inv(roles_inv.filter(r => !roles_to_inv.includes(r.ind)));
                            setRoles_ena(roles_ena.concat(rolesreq));
                            setContrat(get_contract_name(invite_form_contrat));
                            setRoles_to_inv([]);
                        }
                        else
                        {
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                    }
                    else
                    {
                        toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
                    }
                }else{
                    toast.error(<b>Problème d'envoi!</b>, { id: toastId, });
                }
                
            }).catch(function(error){
                toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
            }).then(function (){
                setInvite_form_loading(false);
                // toast.dismiss(toastId);
            });
        }
    };


    /**
     * 
     */

    const cancel_modal_hide = () => setCancel_modal_show(false);
    const cancel_request_function = () => {
        let params = {
            tkn: user_tkn,
            ctr: center_key,
            prf: profile_key,
            pos: role_to_cancel.ind
        };
        
        params = processParams(params);
        
        const toastId = toast.loading('Annulation');
        post("ctrntw", "cancel", params)
        .then(function (xhr) {
            if(check_is_200(xhr)) {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        console.log(xhr.data);
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        let new_list = roles_ena.filter(r => r != role_to_cancel);
                        setRoles_ena(new_list);
                        setRoles_inv(roles_inv.concat([role_to_cancel]));
                        setRole_to_cancel({});

                        if(roles_act.length == 0 && new_list.length == 0)
                        {
                            setContrat('');
                        }
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème d'annulation !</b>, { id: toastId, });
                }
            }else{
                toast.error(<b>Problème d'annulation!</b>, { id: toastId, });
            }
            
        }).catch(function(error){
            toast.error(<b>Problème d'annulation !</b>, { id: toastId, });
        }).then(function (){
            cancel_modal_hide();
        });
    }
     
    /**
     * 
     */

    const revoke_modal_hide = () => setRevoke_modal_show(false);
    const revoke_request_function = () => {
        let params = {
            tkn: user_tkn,
            ctr: center_key,
            prf: profile_key,
            pos: role_to_revoke.ind
        };
        
        params = processParams(params);
        
        const toastId = toast.loading('Annulation');
        
        post("ctrntw", "revoke", params)
        .then(function (xhr) {
            if(check_is_200(xhr)) {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        let new_list = roles_act.filter(r => r != role_to_revoke);
                        setRoles_act(new_list);
                        setRoles_inv(roles_inv.concat([role_to_revoke]));
                        setRole_to_revoke({});

                        if(new_list.length == 0 && roles_ena.length == 0)
                        {
                            setContrat('');
                        }
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème de libération du rôle !</b>, { id: toastId, });
                }
            }else{
                toast.error(<b>Problème de libération du rôle!</b>, { id: toastId, });
            }
            
        }).catch(function(error){
            toast.error(<b>Problème de libération du rôle !</b>, { id: toastId, });
        }).then(function (){
            revoke_modal_hide();
        });
    }
     
    /**
     * 
     */

    const cancel_request = (roleinfo) => { setRole_to_cancel(roleinfo); setCancel_modal_show(true); };

    const revoke_role = (roleinfo) => { setRole_to_revoke(roleinfo); setRevoke_modal_show(true); };
    
    const check_is_profile_member = () => {
        const form = {
            tkn: user_tkn,
            ctr: center_key,
            prf: profile_key,
            pos: profile_pos,    // position
        };
        
        let params = processParams(form);
        
        post("ctrntw", "ismr", params)
        .then(function (xhr){
            if(check_is_200(xhr))
            {
                setIs_member('ism' in xhr.data && xhr.data.ism);
                setContrat(xhr.data.cnt || '');
                let roles = xhr.data.roles || [];

                setRoles_act(roles.filter(e=>e.act == true));
                setRoles_ena(roles.filter(e=>e.req == true));
                setRoles_inv(roles.filter(e=>e.act != true && e.req != true ));
                // console.log(roles);
                // setHas_role('get' in xhr.data  && xhr.data.get);
                // setHas_active_role('act' in xhr.data  && xhr.data.act);
                // setcan_cancel_request('req' in xhr.data  && xhr.data.req);
            }
        })
        .catch(function (err){

        });
    }

    useEffect(()=>{
        check_is_profile_member();
    }, []);


    return ( <div>

        {invite_modal_form()}

        <DeleteConfirmation 
                    showModal={cancel_modal_show} 
                    confirmModal={cancel_request_function}
                    hideModal={cancel_modal_hide} 
                    message={<div>Êtes-vous sûr de vouloir supprimer la demande de <b>{profil_name}</b> pour le rôle <b>{role_to_cancel.titre}</b> dans le centre ?</div>}
                />
                
        <DeleteConfirmation 
                    showModal={revoke_modal_show} 
                    confirmModal={revoke_request_function}
                    hideModal={revoke_modal_hide} 
                    message={<div>Êtes-vous sûr de vouloir libérer <b>{profil_name}</b> du rôle <b>{role_to_revoke.titre}</b> dans le centre ?</div>}
                />
    </div> );
}
export default Structure_Btn_Opt;