import { faArrowAltCircleLeft, faArrowAltCircleRight, faArrowLeft, faArrowRight, faEnvelope, faSave, faSeedling } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, FormControl, ProgressBar, Row } from "react-bootstrap";
import { percentage } from "../../../../../tools/math";

const SesEnq_Form = ({ questions, reponses, setReponses, sendForm }) => {

    const [current_question, setCurrent_question] = useState(0);

    const change_text = (e, key_suggest) => {
        if(e.target.value)
            setReponses({...reponses, [key_suggest]:[e.target.value]});
        else setReponses({...reponses, [key_suggest]:[]});
    }

    const select_suggestion = (event, key_suggest, key_choix, is_multiple) => {
        let newValue = [];
        let existing = reponses[key_suggest];
        
        if(is_multiple)
        {
            let thevalue = event.currentTarget.value;
            // // console.log(thevalue);
            if(!existing.includes(key_choix)){
                existing.push(key_choix);
                newValue = existing;
            }
            else 
            {
                newValue = existing.filter(function(e) { return e !== key_choix });
            }
            // console.log(newValue);
        }
        else
        {
            newValue = [key_choix];
        }

        setReponses({...reponses, [key_suggest]:newValue});
    }
    
    const show_input_text = (key_qst, val_qst) => {
            return <FormControl as='textarea' 
                    rows={15}
                    value={key_qst in reponses ? (reponses[key_qst][0] || '') : ''}
                    onChange={e => change_text(e, key_qst)}
                ></FormControl>;
    }

    const show_choices = (suggestions, key_qst, is_multiple) => {
        return (
            <div>
            {suggestions.map((sugst, key) => {
                return (
                    <div key={key}>
                        <input
                            type={is_multiple ? "checkbox" : "radio"}
                            label={sugst.titre}
                            checked={key_qst in reponses ? reponses[key_qst].includes(sugst.key) : false}
                            onChange={e => select_suggestion(e, key_qst, sugst.key, is_multiple)}
                        /> {sugst.titre}
                    </div>
                )
            })}
            </div>
        )
    }
    
    let lng = questions.length;

    const move_cursor = (position, condition) => {
        if(condition)
            setCurrent_question(position);
    }

    let taux_q = (((current_question+1) / questions.length) * 100).toFixed(0);
    let taux_r = ((Object.keys(reponses).filter(rps => reponses[rps].filter(item => item.trim()) .length > 0).length / questions.length) * 100).toFixed(0);

    return ( <div>
        <br/><br/>
        {questions.length > 0 && <Row>
            <Col md={3}>
                <div>
                    <h4>Question {current_question+1} / {questions.length}</h4>
                    <ProgressBar now={taux_q} label={`${percentage(current_question+1, questions.length) }%`}/>
                </div>
            </Col>
            <Col md={3}>
                <div>
                    <h4>Taux de réponse</h4>
                    <ProgressBar now={taux_r} label={`${taux_r}%`}/>
                </div>
            </Col>
        </Row>}
        
        <br/><br/>
        
        {questions.map((qst, key) => {
            return (
                <div key={key}>
                    {(key == current_question) && <div key={key}>
                        {('grp' in qst && qst.grp!='') && <div className='question_item_grp_name'>{qst.grp}</div>}
                        <div className='display_flex'>
                            <div className='textAlignCenter'>
                                
                                <div style={{position:'relative'}}><div className={'button_stand data-tooltip-qcm '+(key == lng-1 ? 'button_disabled':'')} style={{width:'130px', textAlign:'left'}} onClick={e => move_cursor(key+1, key != lng-1)}>
                                    <FontAwesomeIcon style={{margin:'0px 10px'}} icon={faArrowRight}/> Suivant
                                </div></div>

                                <div style={{position:'relative'}}><div className={'button_stand data-tooltip-qcm '+(key == 0 ? 'button_disabled':'')} style={{width:'130px', textAlign:'left'}} onClick={e => move_cursor(key-1, key > 0)}>
                                    <FontAwesomeIcon style={{margin:'0px 10px'}} icon={faArrowLeft}/> Précèdent
                                </div></div>
                                
                                {taux_r > 0 && <div style={{position:'relative'}}><div className='button_stand data-tooltip-qcm' style={{width:'130px', textAlign:'left'}} onClick = {e => sendForm(false)}>
                                        <FontAwesomeIcon style={{margin:'0px 10px'}} icon={faSave}/> Enregistrer
                                    </div></div>}
                                {taux_r > 0 && <div style={{position:'relative'}}><div className='button_stand data-tooltip-qcm' style={{width:'130px', textAlign:'left'}} onClick = {e => sendForm(true)}>
                                        <FontAwesomeIcon style={{margin:'0px 10px'}} icon={faEnvelope}/> Valider
                                    </div></div>}
                            </div>
                            <div style={{marginLeft:'20px', width:'100%'}}>
                                <h1>{qst.titre}</h1>
                                <div style={{margin:'30px'}}>
                                    { qst.choix.length == 0
                                        ?
                                        show_input_text(qst.key)
                                        :
                                        show_choices(qst.choix, qst.key, qst.multi)}
                                </div>
                            </div>    
                        </div>
                    </div>}
                </div>
            );
        })}
        <div className='textAlignCenter'>
        
        </div>
    </div> );
}
 
export default SesEnq_Form;