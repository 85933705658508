import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from 'react-router-dom';
import { getItemRoute } from "../../Routes.js";
import Form_connect from "./Connect/Form_connect.js";
import Form_ini_pass from "./Connect/Form_ini_pass.js";
import Form_reset_pass from "./Connect/Form_reset_pass.js";

const Connect = () => {

    const account = useSelector((state)=>state.account);

    const { link_key } = useParams();
    const history = useHistory();
    const link_to_select = getItemRoute('my_centres');

    const [reini_pass, setReini_pass] = useState(false);
    const [isreset, setIsreset] = useState(link_key != undefined);

    const go_to_reini = () => setReini_pass(true);
    const return_con_form = () => {
        setReini_pass(false);
        setIsreset(false);
    }

    if(account.isLoggedIn){
        history.push({
            pathname: link_to_select,
            state:{back:false}
        })
    }
    return (<div className='layout_without_center'>
        {!account.isLoggedIn && <div>
            {!reini_pass && !isreset && <Form_connect go_to_reini={go_to_reini}/>}
            {reini_pass && <Form_ini_pass return_con_form={return_con_form}/>}
            {isreset && <Form_reset_pass return_con_form={return_con_form} link_key={link_key}/>}
            </div>}
            </div>
        );
}
 
export default Connect;