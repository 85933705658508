import { FormControl } from "react-bootstrap";

const RequiredItem = ({ condition, message = 'Veuillez remplir le champ' }) => {
    return ( 
        <div>
            <FormControl type='text' className='hidden_ele' value={condition ? '.' : ''} onChange={()=>{}} required></FormControl>
            <FormControl.Feedback type="invalid">{message}</FormControl.Feedback>
        </div>
     );
}
 
export default RequiredItem;