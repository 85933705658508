import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export function getDate()
{
    return new Date().toISOString().replace(/T.*$/, "");
}
export function getDays(days)
{
    let result = [];
    if(Array.isArray(days))
    {
        for(let d of days)
        {
            result.push(getDay(d));
        }
    }
    return result;
}

export function getDay(day)
{
    let days = {'0':'Dimanche', '1':'Lundi', '2':'Mardi', '3':'Mercredi', '4':'Jeudi', '5':'Vendredi', '6': 'Samedi'};
    let day_format = day+'';
    if(day_format in days){
        return days[day_format];
    }
    return '';
}

export function getMonth(month)
{
    let months = {  '0':"janvier",
                    '1':"février",
                    '2':"mars",
                    '3':"avril",
                    '4':"mai",
                    '5':"juin",
                    '6':"juillet",
                    '7':"août",
                    '8':"septembre", 
                    '9':"octobre",
                    '10':"novenbre",
                    '11':"Décembre"
                };
    let mnt_format = month+'';
    if(mnt_format in months){
        return months[mnt_format];
    }
    return '';
}

export function date_sql_to_str(datesql)
{
    if((datesql+'').split("-").length != 3) return '';
    return datesql.trim().split(' ')[0].split("-").reverse().join("/");
}

export function date_sql_to_date(datesql)
{
    var parts = datesql.split(' ')[0].split('-');
    return new Date(parts[0], parts[1] - 1, parts[2]); 
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export function datetime_sql_to_str(datesql)
{
    if(!datesql) return null;

    

    datesql = datesql.trim().split(' ');
    if(datesql.length == 0) return '';
    
    
    // let date = [datesql[0].split('-').reverse().join('/')];

    let date = new Date(datesql);
    date = js_date_to_str_date(date);

    if(datesql.length > 1)
    {
        let time = datesql[1].split(':').slice(0, 2).join(':');
        date.push(time);
    }
    return date.join(' à ');
}

export function js_date_to_str_date(date) {
    return [[
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/')];
}

export function js_date_to_sql_date(date) {
    return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
    ].join('-');
}

export const CustomDatePicker = ({ selected, onChange, selectsStart, selectsEnd, startDate, endDate, minDate, maxDate }) => {
    return <DatePicker
                        selected={selected}
                        onChange={onChange}
                        className='form-control'
                        popperClassName="some-custom-class"
                        dateFormat="dd/MM/yyyy"
                        
                        selectsStart={selectsStart}
                        selectsEnd={selectsEnd}

                        startDate={startDate}
                        endDate={endDate}
                        minDate={minDate}
                        maxDate={maxDate}


                        popperModifiers={[
                            {
                            name: "offset",
                            options: {
                                offset: [5, 10],
                            },
                            },
                            {
                            name: "preventOverflow",
                            options: {
                                rootBoundary: "viewport",
                                tether: false,
                                altAxis: true,
                            },
                            },
                        ]}
                    />
}