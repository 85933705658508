import { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { Row, FormGroup, Col, DropdownButton, ButtonGroup, Dropdown, FormText, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { check_is_200, post, processParams } from "../../adapters/xhr";
import { getItemRoute } from "../../Routes";
import { handle_change_input } from "../../tools/HInput";
import ProfilWidget from "../../tools/components/ProfilWidget/ProfilWidget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

const Former_List = () => {
    const link_add = getItemRoute('center_former_form');
    const link_search = getItemRoute('center_former_search');
    const history = useHistory();
    const link_info = getItemRoute('center_member');
    const [listFormer_active, setListFormer_active] = useState([]);
    const [listFormer_enattentes, setListFormer_enattentes] = useState([]);

    const [filter_by, setFilter_by] = useState('');

    const account = useSelector((state)=>state.account);

    const getData = () => {
        let params = processParams({tkn:account.info.tkn, ctr:account.ctr.key});
            
        post("member", "formers", params)
        .then(function(xhr){
            if(check_is_200(xhr)) {
                let listFormer = xhr.data;
                setListFormer_active(listFormer.filter(item=>item.is_cnf));
                setListFormer_enattentes(listFormer.filter(item=>!item.is_cnf));

            }else{

            }
        });
    }

    useEffect(() => {
        getData();
    }, []);
    
    const add_former = () => {
        history.push({
            pathname:link_add,
            state:{back:true}
        });
    }
    const get_former = () => {
        history.push({
            pathname:link_search,
            state:{back:true}
        });
    }
    const get_info = (key_s) => {
        history.push({
            pathname:getItemRoute('center_member')+key_s,
            state:{back:true}
        });
    }

    return ( 
        <div>
            <Row className='headerContentPart' >
                <FormGroup as={Col} xl={3} className='title'>Formateur(s)</FormGroup>
                {listFormer_active.length > 0 && <FormGroup as={Col}><InputGroup className="search">
                                        <InputGroup.Text className="icon">
                                                <FontAwesomeIcon
                                                    icon={faSearch}
                                                /> 
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Filtrer"
                                                aria-label="Filtrer"
                                                aria-describedby="filtrate"
                                                type='text' 
                                                value={filter_by} 
                                                className="text_zone"
                                                onChange={e => handle_change_input(e, setFilter_by)}
                                            />
                                        </InputGroup></FormGroup>}
                {false && <FormGroup as={Col}>
                    <DropdownButton
                        as={ButtonGroup}
                        dropdown={null}
                        title={<FontAwesomeIcon icon={faPlus}/>}
                        className='button_stand padding_0'
                    >
                        <Dropdown.Item onClick={() => add_former()}>Créer un formateur</Dropdown.Item>
                        <Dropdown.Item onClick={() => get_former()}>Inviter un formateur</Dropdown.Item>
                    </DropdownButton>
                </FormGroup>}
            </Row>


            {listFormer_active.filter(item => {
                    let name = ['nom' in item ? item.nom.toLocaleLowerCase() : '', 
                                'pnom' in item ? item.pnom.toLocaleLowerCase() : ''];
                    
                    let name_asc = name.join(' ');
                    let name_desc = name.reverse().join(' ');
                    
                    return name_asc.includes(filter_by.toLocaleLowerCase()) || name_desc.includes(filter_by.toLocaleLowerCase());
                })
                .map((item, key) => <div key={key}><ProfilWidget width_percent={100} key={key} profil={item} action={() => get_info(item.key)}/></div>)}

            {listFormer_enattentes.length > 0 && <div>EN ATTENTES</div>}
            {listFormer_enattentes.filter(item => {
                    let name = ['nom' in item ? item.nom.toLocaleLowerCase() : '', 
                                'pnom' in item ? item.pnom.toLocaleLowerCase() : ''];
                    
                    let name_asc = name.join(' ');
                    let name_desc = name.reverse().join(' ');
                    
                    return name_asc.includes(filter_by.toLocaleLowerCase()) || name_desc.includes(filter_by.toLocaleLowerCase());
                })
                .map((item, key) => <div key={key}><ProfilWidget width_percent={100} key={key} profil={item} action={() => get_info(item.key)}/></div>)}
        </div>
    );
}
 
export default Former_List;