import { useSelector } from "react-redux";
import HomeGuest from "./Guest/HomeGuest";
import HomeMember from "./Connected/HomeMember";


const Home = (props) => {
    const account = useSelector((state)=>state.account);
    
    // // console.log(account, props);
    
    return ( 
        <div className='HomePageDiv'>
            {!account.isLoggedIn && <HomeGuest/>}
            {account.isLoggedIn && <HomeMember/>}
        </div>
     );
}
 
export default Home;