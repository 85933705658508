import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { check_is_200, post, processParams } from "../../adapters/xhr";
import { getItemRoute } from "../../Routes";
import { changeCenter } from "../../store/accountSlice";

const HomeSelectCenter = () => {
    const account = useSelector(state => state.account);
    const history = useHistory();
    const dispatch = useDispatch();

    const [affectation, setAffectation] = useState([]);
    const [invite, setInvite] = useState(false);

    const current_hour = (new Date()).getHours();
    const is_morning = (current_hour>3 && current_hour<19);

    const link_to_center = getItemRoute('center_create');
    
    const getData = () => {
        post('profil', 'uses', processParams({tkn:account.info.tkn, gses:account.info.gses}))
        .then(function (xhr) {
            if(check_is_200(xhr)) {
                if('lst' in xhr.data)
                {
                    for(let ctr of xhr.data.lst)
                    {
                        if('goto' in ctr)
                            redirect(ctr);
                    }
                    setAffectation(xhr.data.lst);
                }
                if('has' in xhr.data)
                {
                    setInvite(xhr.data.has == 0);
                }
            }
        });
    }

    const redirect = (center_key) => {
        console.log(center_key);
        dispatch(changeCenter(center_key));
        history.push({
            pathname: getItemRoute('center_home'),
            state:{back:true},
        });
    }

    useEffect(() => getData(), []);

    const create_center = () => {
        if(true)
        {
            history.push({
                pathname: link_to_center,
                state:{back:true}
            });
        }
    }

    const get_invitations = () => {
        history.push({
            pathname: getItemRoute('centers_invitations'),
            state:{back:true}
        });
    }

    return ( <div className='layout_without_center getall'>

        <h1 className='pageTitle' style={{margin:"100px 0px 70px 0px"}}>
            {is_morning ? 'Bonjour' : 'Bonsoir'} {(account.info.name.p || '')} !
        </h1>
        
            <div className='select_center_contener'>
        {affectation.map((aff, key)=>{
            return <div key={key} className='select_center_item is_block' onClick={(e) => redirect(aff)}>
                        <div className='center_name'>{aff.name}</div>
                        {aff.logo!='' ? <div className='center_image'><img src={aff.logo}/></div> : ''}
                    </div>;
        })}
        {affectation.length == 0 && <div>Pas de centre !</div>}
            </div>
        
        <center>
        {/* <div className='button_stand' onClick={()=>get_invitations()}>Invitations du centres</div> */}
        {/* {invite && <Button onClick={()=>create_center()}>Créer votre centre</Button>} */}

        </center>
        </div> );
}
 
export default HomeSelectCenter;