import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { getItemRoute } from "../../../Routes";
import DeleteConfirmation from "../../../tools/components/popups/DeleteConfirmation";
import { Col, Row, FormGroup, Form } from "react-bootstrap";
import Profil_Docs_List from "../../profil/Documents/Profil_Docs_List";
import Former_Docs_List from "../../Formateurs/Former_Docs/Former_Docs_List";
import Structure_Btn_Opt from "./Structure_Btn_Opt";
import { check_is_200, post, processParams } from "../../../adapters/xhr";
import Structure_Btn_Add_Session from "./Structure_Btn_Add_Session";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Structure_Roles from "./Structure_Roles";
import { date_sql_to_str } from "../../../tools/Dates";

const Structure_Info = ({ about }) => {
    
    let { profile_key } = useParams();

    const account = useSelector((state) => state.account);
    const history = useHistory();
    const location = useLocation();
    
    const [is_looking_for_trainee, setIs_looking_for_trainee] = useState(location.state.lookfor == 't');
    const [session_info, setSession_info] = useState(location.state.session || {});

    const [is_member, setIs_member] = useState('');
    const [is_active, setIs_active] = useState('');
    const [was_requested, setWas_requested] = useState('');

    
    const [profil, setProfil] = useState({nom:'', pnom:'', nais_dt:'', nais_li:'', sexe:'', tel:'', email:'', cp:'', adresse:'', ville:''});
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState();


    const getData = () =>{
        const params = processParams({
            tkn : account.info.tkn,
            ctr : account.ctr.key,
            frm : profile_key
        });

        // console.log({
        //     tkn : account.info.tkn,
        //     ctr : account.ctr.key,
        //     frm : profile_key
        // });

        post("member", "fiche", params)
        .then(function (xhr){
            if(check_is_200(xhr)) {
                if('profil' in xhr.data)
                {
                    if('profil' in xhr.data)
                    {
                        let pprof = xhr.data.profil;
                        setProfil(pprof);
                        
                        if('rolev' in pprof)
                        {
                            setIs_member('ism' in pprof.rolev && pprof.rolev.ism);
                            setIs_active('act' in pprof.rolev && pprof.rolev.act);
                            setWas_requested('req' in pprof.rolev && pprof.rolev.req);
                        }
                    }
                }
                
            }else{

            }
        })
    };

    const goback = () => history.goBack();

    const removeFormer = () => {
        const params = processParams({
            tkn : account.info.tkn,
            ctr : account.ctr.key,
            frm : profile_key
        });

        hideConfirmationModal();
        
        const toastId = toast.loading("Suppression");

        post("member", "detache", params)
        .then(function (xhr) {
            if(check_is_200(xhr)) 
            {
                console.log(xhr.data);
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        goback();
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else 
                {
                    toast.error(<b>Problème d'envoi des données !</b>, { id: toastId, });
                }
            }
            else
            {
                toast.error(<b>Problème de détachement !</b>, { id: toastId, });
            }
            
        }).catch(function(error){
            toast.error(<b>Problème de détachement !</b>, { id: toastId, });
        });
    };

    const showConfirmationModal = () => {
        setDisplayConfirmationModal(true);
    };
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    useEffect(()=>{
        getData();
    }, []);

    const go_sessions = () => {
        history.push({
            pathname:getItemRoute('center_member_sessions')+account.ctr.key+'/'+profile_key,
            state:{back:true}
        });
    }

    const getShowInfo = () => {
        return <Form>
            <div className='is_table headerContentPart'>
                {profil.avatar_img && <div className='is_table_col'><img className='image_show_full_size' style={{marginTop:10}} src={profil.avatar_img}/></div>}
                <div className='' style={profil.avatar_img ? {paddingLeft:10,width:'100%'} : {}}>
                    
                    <div className='flex_between'>
                        <div className='title'>{profil.nom+' '+profil.pnom}</div>
                        <div>
                            <div className=''>        
                                <Structure_Btn_Opt 
                                                        user_tkn={account.info.tkn}  
                                                        profil_name={profil.nom+' '+profil.pnom} 
                                                        profile_key={profile_key} 
                                                        center_key={account.ctr.key}
                                                    />
                                <Structure_Btn_Add_Session
                                                        session={session_info}
                                                        user_tkn={account.info.tkn}  
                                                        profil_name={profil.nom+' '+profil.pnom} 
                                                        profile_key={profile_key} 
                                                        center_key={account.ctr.key}
                                                    /> 
                                
                                {is_member==true && <div className='button_stand button_bg' onClick={()=>go_sessions()}>Sessions</div>}
                            </div>
                        </div>
                    </div>
                    
                    {/* {is_intern==true && <DeleteConfirmation 
                            showModal={displayConfirmationModal} 
                            confirmModal={removeFormer}
                            hideModal={hideConfirmationModal} 
                            message={"Êtes-vous sûr de vouloir retirer "+profil.nom+" "+profil.pnom+" du centre ?"}
                        />} */}
                        
                    
                    
                    <div className='show_info_as_form' >
                        <Row>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Nom</div>
                                    <div className='profil_show_item_value'><b>{profil.sexe == 'h' ? 'Mr.' : 'Mme.'}</b> {profil.nom}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Prénom</div>
                                    <div className='profil_show_item_value'>{profil.pnom}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Email</div>
                                    <div className='profil_show_item_value'>{profil.email}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Tel</div>
                                    <div className='profil_show_item_value'>{profil.tel}</div>
                                </div>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Date de naissance</div>
                                    <div className='profil_show_item_value'>{date_sql_to_str(profil.nais_dt)}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Lieu de naissance</div>
                                    <div className='profil_show_item_value'>{profil.nais_li}</div>
                                </div>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Code postal</div>
                                    <div className='profil_show_item_value'>{profil.cp}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Adresse</div>
                                    <div className='profil_show_item_value'>{profil.adresse}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Ville</div>
                                    <div className='profil_show_item_value'>{profil.ville}</div>
                                </div>
                            </FormGroup>
                        </Row>
                        <Profil_Docs_List account={account} profil_key={profile_key}/>
                    </div>
                    {is_member==true && <Former_Docs_List 
                                                    account={account} 
                                                    center_key={account.ctr.key} 
                                                    profil_key={profile_key} 
                                                    can_update={true} 
                                                    with_title={true}
                                        />}

                    {is_member==true && <Structure_Roles 
                                                    account={account} 
                                                    center_key={account.ctr.key} 
                                                    profil_key={profile_key} 
                                                    can_update={true} 
                                                    with_title={true}
                                        />}
                                        
                    {/* <Satisfaction_Stats former_key={profile_key}/> */}
                </div>
            </div>
        </Form>
    }

    // console.log('center', account.ctr.key);
    return (
        <div>
            <div>
                {getShowInfo()}
            </div>
        </div>
    );
}
 
export default Structure_Info;