import { useEffect, useState } from "react";
import { Form, Button, FormGroup, FormLabel, FormControl } from "react-bootstrap"
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { check_is_200, post, processParams } from "../../../adapters/xhr/index.js";
import { getItemRoute } from "../../../Routes.js";
import { save } from "../../../store/accountSlice.js";
import useFormInput from "../../../tools/components/useFormInput.js";

const Form_connect = ({ go_to_reini }) => {
    // let cnx = {e:'bouzidi.dev@gmail.com', p:'salam'};         // PRE
    // let cnx = {e:'em@gggg.com', p:'salam'};                  // TRN
    // let cnx = {e:'rachid.ziat@outlook.com', p:'salam'};      // TRN
    // let cnx = {e:'rachid.ziat@icloud.com', p:'salam'};       // FRM
    // let cnx = {e:'mmm@mmm.fr', p:'salam'};                   // SEC
    // let cnx = {e:'marine.caliari@gestiondata.fr', p:'Turquoise'};                   // PRE
    // let cnx = {e:'marine.caliari@monclub.online', p:'IAMFOR'};                   // FOR
    // let cnx = {e:'lm@gmail.com', p:'IAMSEC'};                   // SEC

    let cnx = {e:'', p:''};
    const email = useFormInput(cnx.e);
    const passw = useFormInput(cnx.p);

    const { user_key } = useParams();
    
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [show_get_pass, setShow_get_pass] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();

        let its_ok = true;
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            its_ok = false;
        }
        setValidated(true);

        if(its_ok){
            checkConnexion({'eml': email.value, 'psw': passw.value});
        }
    };


    const go_to_inscri = () => {
        history.push({
            pathname: getItemRoute('home_inscr'),
            state:{back:false}
        });
    }

    const checkConnexion = (params) => {
        setLoading(true);
        const toastId = toast.loading('Vérification');
        
        post("profil", "connexion", processParams(params)).then(function (xhr) {
            if(check_is_200(xhr))
            {
                console.log(xhr.data);
                if('tkn' in xhr.data)
                {
                    toast.success(<b>Bonjour {xhr.data.name.p}</b>, { id: toastId, });
                    setTimeout(function () {
                        dispatch(save(xhr.data));
                        // history.push('/');
                    }, 1000);
                    
                }
                else
                {
                    toast.error(<b>Email ou mot de passe erroné !</b>, { id: toastId, });
                    setShow_get_pass(true);
                }
            }
        }).catch(function(error){
            toast.error(<b>Problème de vérification</b>, { id: toastId, });
            setShow_get_pass(true);
        }).then(function (){
            setLoading(false);
        });
    }

    const checkkey = () => {
        if (typeof user_key !== 'undefined'){
            checkConnexion({'rky': user_key});
        }
    }

    useEffect(() => checkkey(),[])
    
    return ( 
        <div className='login_div'>
            <img src='\images\gestioncentres_middle.png'/>
            <div className='login_div_form'>
                <Form noValidate validated={validated} onSubmit={handleSubmit} className='centerForm'>
                    <FormGroup>
                        <FormLabel>Nom d'utilisateur</FormLabel>
                        <FormControl type="email" {...email} required />
                        <FormControl.Feedback type="invalid">
                            Veuillez saisir un email
                        </FormControl.Feedback>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Mot de passe</FormLabel>
                        <FormControl type="password"  {...passw} required />
                        <FormControl.Feedback type="invalid">
                            Veuillez saisir un Mot de passe
                        </FormControl.Feedback>
                    </FormGroup>
                    
                    {!loading && <Button variant="primary" type="submit" className='width_100'>
                        Connexion
                    </Button>}
                    &nbsp;
                    {show_get_pass && <Button variant="primary" className='width_100' onClick={() => go_to_reini()}>
                        Récupérer mot de passe
                    </Button>}
                </Form>
                {/* <div className='loginOR'><span>Ou</span></div>
                <center>
                    <Button variant="primary" onClick={() => go_to_inscri()}>
                        Créer votre compte
                    </Button>
                </center> */}
            </div>
        </div>
     );
}
 
export default Form_connect;