import { faClone, faCog, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Col, Row, FormGroup, FormLabel } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { post, processParams } from "../../../../adapters/xhr";
import { getItemRoute } from "../../../../Routes";
import DeleteConfirmation from "../../../../tools/components/popups/DeleteConfirmation";
import Messagerie from "../../../Messagerie/Messagerie";

const Stagiaire_Fiche = () => {

    let history = useHistory();
    let { trainee_key, session_key } = useParams();
    const account = useSelector((state) => state.account);
    
    const [trainee, setTrainee] = useState({});
    const [link_trainee, setLink_trainee] = useState('');
    
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const hideConfirmationModal = () => { setDisplayConfirmationModal(false); }
    
    const getData = () => {
        post('trainee', 'get', processParams({
            tkn: account.info.tkn,
            trn: trainee_key,
            ses: session_key,
            adv: 1
        }))
        .then(function (xhr){
            if(('status' in xhr) && (xhr.status===200)) {
                // // console.log(xhr.data);
                setTrainee(xhr.data);
                setLink_trainee(getItemRoute('home_connectkey')+xhr.data.link);
            }else{
                
            }
        }).catch(function (error){
            
        });
    }

    useEffect(() => getData(), []);

    const goback = () => {
        history.goBack();
    }
    
    
    const updateTrainee = () => {
        if(trainee.can_update){
            history.push({
                pathname: getItemRoute('center_trainee_form')+session_key+'/'+trainee_key,
                state:{back:false}
            })
            // // console.log('updateTrainee');
        }
    }
    
    const deleteTrainee = () => {
        if(trainee.can_delete){
            setDisplayConfirmationModal(true);
        }
    }

    const removeStagiaire = () => {
        if(trainee.can_delete){
            setDisplayConfirmationModal(false);

            const params = processParams({
                tkn: account.info.tkn,
                ses: session_key,
                stg: trainee_key
            });

            const toastId = toast.loading("Chargement");
            post('trainee', 'delete', params)
            .then( function ( xhr ) {
                // // console.log(xhr);
                if ( ( 'status' in xhr ) && ( xhr.status===200 ) )
                {
                    if ( ( 'state' in xhr.data ) && ( 'msg' in xhr.data ) )
                    {
                        if ( xhr.data.state )
                        {
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            history.goBack();
                        }
                        else
                        {
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                    }
                    else
                    {
                        toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            })
            .catch(function (err){
                // // console.log(err);
                toast.error(<b>Problème de chargement !</b>, { id: toastId, });
            });
        }
    }
    
    const copyLink = () => {
        navigator.clipboard.writeText(window.location.origin+link_trainee);
        toast.success('Lien copié !');
    }

    return (
        <div>
            <h1>
                Stagiaire
                <div className='buttons_right_side'>
                    <Button className='button_stand' onClick={() => goback()}>Retour à la session</Button>
                </div>
            </h1>
            
            <div className='is_table'>
                {trainee.avatar_img && <div className='is_table_col'><br/><img className='image_show_full_size' src={trainee.avatar_img}/></div>}
                <div className='is_table_col' style={trainee.avatar_img ? {padding:20,width:'100%'} : {}}>
                    <h1 className='aligned'>
                        <b>{trainee.sexe == 'h' ? 'Mr.' : 'Mme.'}</b> {trainee.nom+' '+trainee.pnom}
                        <div className='buttons_right_side'>
                            {('can_update' in trainee) && (trainee.can_update) && <div className='button_stand button_green' onClick={() => updateTrainee()}><FontAwesomeIcon icon={faCog} /></div>}
                            {('can_delete' in trainee) && (trainee.can_delete) && <div className='button_stand button_danger' onClick={() => deleteTrainee()}><FontAwesomeIcon icon={faTimes} /></div>}
                        </div>
                    </h1>
                    
                    <div className='is_block'>
                        <Row>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Lien</div>
                                    <div className='profil_show_item_value'>
                                        {link_trainee && <FontAwesomeIcon icon={faClone}  onClick={() => copyLink()}  />}
                                    </div>
                                </div>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Email</div>
                                    <div className='profil_show_item_value'>{trainee.email}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Tel</div>
                                    <div className='profil_show_item_value'>{trainee.tel}</div>
                                </div>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Date de naissance</div>
                                    <div className='profil_show_item_value'>{trainee.nais_dt}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Lieu de naissance</div>
                                    <div className='profil_show_item_value'>{trainee.nais_li}</div>
                                </div>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Code postal</div>
                                    <div className='profil_show_item_value'>{trainee.cp}</div>
                                </div>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Adresse</div>
                                    <div className='profil_show_item_value'>{trainee.adresse}</div>
                                </div>

                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup as={Col}>
                                
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Ville</div>
                                    <div className='profil_show_item_value'>{trainee.ville}</div>
                                </div>
                            </FormGroup>
                        </Row>
                    </div>
                    {/* <Satisfaction_Stats former_key={profile_key}/> */}
                </div>
            </div>
            
            <DeleteConfirmation
                        showModal={displayConfirmationModal} 
                        confirmModal={removeStagiaire}
                        hideModal={hideConfirmationModal} 
                        message={"Êtes-vous sûr de vouloir retirer \""+trainee.nom+" "+trainee.pnom+"\" de cette session ?"}
                    />
            
            {/* <Toaster
                position="bottom-right"
                reverseOrder={false}    /> */}
            {('can_msg' in trainee) && (trainee.can_msg) && <div className='is_block'><Messagerie session_key={session_key} account={account} to={trainee_key} can_add_note={true}/></div>}
            
    </div> );
}
 
export default Stagiaire_Fiche;