import { faInfoCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { check_is_200, post, processParams } from "../../../adapters/xhr";
import { getItemRoute } from "../../../Routes";
import { filter_search_check_item } from "../../../tools/DataTableOpt";
import { handle_change_input } from "../../../tools/HInput";

const Structure_Sessions = () => {

    const account = useSelector((state) => state.account);
    const { center_key, profile_key } = useParams();

    const history = useHistory();

    const [filter_by, setFilter_by] = useState('');
    const [sessions_mng, setSessions_mng] = useState([]);
    const [sessions_trm_flt, setSessions_trm_flt] = useState([]);


    const getData = () => {
        post('sesprf', 'all', processParams({
            tkn : account.info.tkn,
            ctr : center_key,
            prf : profile_key
        }))
        .then(xhr => {
            if(check_is_200(xhr))
            {
                let sessions_obj_fr = xhr.data.fr || {};
                let sessions_obj_mn = xhr.data.mn || {};
                let sessions_obj_st = xhr.data.st || {};
                

                let managerList = [];

                managerList = managerList.concat(adapt_listes(sessions_obj_fr, 'Formateur'));
                managerList = managerList.concat(adapt_listes(sessions_obj_mn, 'Gérant'));
                managerList = managerList.concat(adapt_listes(sessions_obj_st, 'Stagiaire'));
                
                setSessions_mng(managerList);
    
            }
        })
        .catch(err => {
    
        });
    }

    const sessions_mng_flt = sessions_mng.filter(item => {
        let filter_key = filter_by.toLocaleLowerCase();
        return filter_search_check_item(filter_key, item, ['titre', 'semaine', 'role', 'state']);
    });

    const adapt_listes = (sessions_obj, role) => {
        let list_item = [];
        let sessions_lst = [];

        if('prv' in sessions_obj)
        { 
            sessions_lst = sessions_obj.prv || [];
            for(let item of sessions_lst)
                list_item.push({titre: item.titre, semaine: item.semaine, role, state:'prochaine',
                action:(<div className='buttons_table'>
                            <div></div>
                            <div className='button_stand' onClick={() => go_session(item.key)}>
                                <FontAwesomeIcon icon={faInfoCircle}/>
                            </div>
                        </div>)
            });
        }
        if('cur' in sessions_obj)
        { 
            sessions_lst = sessions_obj.cur || [];
            for(let item of sessions_lst)
                list_item.push({titre: item.titre, semaine: item.semaine, role, state:'en cours',
                action:(<div className='buttons_table'>
                            <div></div>
                            <div className='button_stand' onClick={() => go_session(item.key)}>
                                <FontAwesomeIcon icon={faInfoCircle}/>
                            </div>
                        </div>)
            });
        }
        if('nxt' in sessions_obj)
        { 
            sessions_lst = sessions_obj.nxt || [];
            for(let item of sessions_lst)
                list_item.push({titre: item.titre, semaine: item.semaine, role, state:'précédente',
                action:(<div className='buttons_table'>
                            <div></div>
                            <div className='button_stand' onClick={() => go_session(item.key)}>
                                <FontAwesomeIcon icon={faInfoCircle}/>
                            </div>
                        </div>)
            });
        }
        return list_item;
    }

    useEffect(()=>{
        getData();
    }, []);

    const go_session = (session_key) => {
        history.push({
            pathname: getItemRoute('center_session_fiche')+session_key,
            state: {back:true},
        })
    }

    const List_Sessions_Manager = ({ title, data}) => {
        const columns = [
            { name: 'Titre', selector: row => row.titre, sortable: true,},
            { name: 'Semaine', selector: row => row.semaine, sortable: true,},
            { name: 'Rôle', selector: row => row.role, sortable: true,},
            { name: 'Etat', selector: row => row.state, sortable: true,},
            { name: 'Action', selector: row => row.action, sortable: true, minWidth:1, cell:row => <div style={{width:180}}>{row.action}</div>},
        ];
        
        return  <div>
                    <h3>{title}</h3>
                    <DataTable
                        pagination
                        fixedHeader
                        columns={columns}
                        data={data}
                        noDataComponent=""
                        paginationComponentOptions={{
                                                        rowsPerPageText: 'Dossiers par page',
                                                        rangeSeparatorText: 'sur',
                                                        selectAllRowsItem: true,
                                                        selectAllRowsItemText: 'Tous',
                                                    }}
                    />
                </div>;
    }


    const List_Sessions_Stagiaire = ({ title, data}) => {
        const columns = [
            { name: 'Titre', selector: row => row.titre, sortable: true,},
            { name: 'Semaine', selector: row => row.semaine, sortable: true,},
            { name: 'Etat', selector: row => row.state, sortable: true,},
            { name: 'Action', selector: row => row.action, sortable: true, minWidth:1, cell:row => <div style={{width:180}}>{row.action}</div>},
        ];
        
        return  <div>
                    <h3>{title}</h3>
                    <DataTable
                        pagination
                        fixedHeader
                        columns={columns}
                        data={data}
                        noDataComponent="Aucun stagiaire n'est enregistré !"
                        paginationComponentOptions={{
                                                        rowsPerPageText: 'Stagiaires par page',
                                                        rangeSeparatorText: 'sur',
                                                        selectAllRowsItem: true,
                                                        selectAllRowsItemText: 'Tous',
                                                    }}
                    />
                </div>;
    }

    const show_sessions = (title, sessions) => {
        return <div>
            <h5>{title}</h5>
            {sessions.map((ses, key) => {
                return <div key={key} onClick={(e) => go_session(ses.key)}>{ses.titre}</div>
            })}
        </div>
    }

    return ( <div>
        <h1 className='pageTitle'>Liste des sessions</h1>
        <InputGroup className="search">
                                            <InputGroup.Text className="icon">
                                                <FontAwesomeIcon
                                                    icon={faSearch}
                                                /> 
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Filtrer"
                                                aria-label="Filtrer"
                                                aria-describedby="filtrate"
                                                type='text' 
                                                value={filter_by} 
                                                className="text_zone"
                                                onChange={e => handle_change_input(e, setFilter_by)}
                                            />
                                        </InputGroup>
        
        <List_Sessions_Manager  data={sessions_mng_flt}/>

    </div> );
}
 
export default Structure_Sessions;