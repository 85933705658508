import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, Form, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";

const Question_valeurs_quiz = ({ list, setValues }) => {

    const [list_sggst, setList_sggst] = useState(list);
    
    const add_suggest = () => {
        setList_sggst(list_sggst.concat({ text:'', correct:false }));
    }

    const onChange_suggestion_reponse = (indice, checked) => {
        const new_suggst = list_sggst[indice];
        const newlist_sggst = list_sggst.map((suggst, ind) => ind!=indice ? suggst : {...new_suggst, correct:!checked});
        setList_sggst(newlist_sggst);
    }


    const delete_suggest = (key) => {
        setList_sggst(list_sggst.filter((itm, keyi) => keyi != key));
    }

    const onChange_suggestion_text = (e, key) => {
        const newSugst = list_sggst.map((itm, itmk) => itmk != key ? itm : {...itm, text:e.target.value });
        setList_sggst(newSugst);
    }

    useEffect(() => {
        setValues(list_sggst)
    }, [list_sggst]);

    return (
        <div>
        <Row>
            <FormGroup as={Col}  style={{margin:0}}>

                <FormLabel>Suggestion{list_sggst.length>1?'s':''}</FormLabel>

                {list_sggst.map((item, key) => (
                    <Row key={key}>
                        <FormGroup as={Col} sm={1} style={{margin:0, 'textAlign':'right'}}>
                            <div className='button_stand' onClick={e => delete_suggest(key)} style={{marginTop:2}}><FontAwesomeIcon icon={faTimes}/></div>
                        </FormGroup>
                        <FormGroup as={Col} sm={8} style={{margin:0}}>
                            <FormControl type='text' value={item.text} onChange={(e)=>onChange_suggestion_text(e, key)} required/>
                        </FormGroup>
                        <FormGroup as={Col} sm={3} style={{margin:0}}>
                        <Form.Check 
                            type="checkbox"
                            label={ item.correct ? 'La bonne réponse' :'Sélectionner' }
                            variant={ item.correct ? 'success' : 'light'}
                            checked={ item.correct }
                            onChange={(e) => onChange_suggestion_reponse(key, (e.currentTarget.value == 'on')==item.correct)}
                        />
                        </FormGroup>
                        
                    </Row>
                ))}
                <Row>
                    <FormGroup as={Col} xl={1} style={{margin:0, 'textAlign':'right'}}>
                        <div className='button_stand' onClick={(e) => add_suggest()}>
                            <FontAwesomeIcon icon={faPlus}/>
                        </div>
                    </FormGroup>
                </Row>
                
            </FormGroup>
        </Row>
        </div>
    )
};
 
export default Question_valeurs_quiz;