import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { check_is_200, downloadBlob, popup_show_file, post, processParams, progressMessage } from '../../../adapters/xhr';
import { getItemRoute } from '../../../Routes';

const Center_Docs_Smallinfo = ({ account }) => {

    const history = useHistory();

    const [documents, setDocuments] = useState([]);
    const [nbr, setNbr] = useState(0);

    const getDocuments = () => {
        const params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            opt: ['infs']
        });

        post('ctrdoc', 'geti', params)
        .then(xhr => {
            if(check_is_200(xhr)) {
                setDocuments(xhr.data.lst || []);
                setNbr(xhr.data.nbr || 0);
            }
        }).catch(function (err){
            
        });
    }

    const getFile = (docm) => {
        popup_show_file(docm.file);
    }

    useEffect(() => {
        getDocuments();
    }, []);

    const get_more = () => {
        history.push({
            pathname:getItemRoute('center_documents'),
            state:{back:false}
        });
    }

    if(!nbr) return null;
    return ( <div className='infob_item'>
                <div className='flex_between'>
                    <div className='infob_title'>Documents</div>
                    <div className='infob_nbr'>{nbr}</div>
                </div>
                <div className='infob_content'>
                    {documents.map((doc, key) => {
                        return ( key>2 ? null : <div key={key} className='infob_link clickable' onClick={(e)=>getFile(doc)}>{doc.name}</div>);
                    })}
                </div>
                <div className='infob_footer clickable' onClick={()=>get_more()}>Voir tout</div>
            </div> );
}
 
export default Center_Docs_Smallinfo;