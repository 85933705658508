const Pagination_normal = ({ current_page, last_page, go_to=(e)=>{} }) => {
    const list = Array.from({length: last_page}, (_, i) => i + 1)
    return ( <div className='pagination_content'>
        {list.map((ind,key) => {
            return <div key={key} className={'button_stand '+(current_page==ind ? 'current' : '')} onClick={()=>go_to(ind)}>
                    {ind}
                </div>
        })}
    </div> );
}
 
export default Pagination_normal;