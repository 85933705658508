import { useEffect, useState } from "react";
import { check_is_200, downloadBlob, popup_show_file, post, processParams, progressMessage } from "../../../../adapters/xhr";
import { useHistory } from "react-router";
import { getItemRoute } from "../../../../Routes";
import { faCertificate, faCog, faFileAlt, faFileSignature, faHandHoldingHeart, faHandLizard, faHandPaper, faHandPeace, faHands, faStamp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import ProfilWidget from "../../../../tools/components/ProfilWidget/ProfilWidget";

const Stagiaires_List = ({session_key, session, account, can_update, trainee_more, trainee_msg, trainee_cnv, with_title, class_title, class_content }) => {

    let progressShow = 0;
    const history = useHistory();
    const [stagiaires, setStagiaires] = useState([]);


    const getStagiaires = () => {
        post('trainee', 'getlist', processParams({
            tkn: account.info.tkn,
            ses: session_key,
        }))
        .then(function (xhr){
            if(('status' in xhr) && (xhr.status===200)) {
                setStagiaires(xhr.data);
            }else{
                
            }
        }).catch(function (error){
            
        });
    }

    useEffect(()=>{
        getStagiaires();
    }, []);

    const go_to_stagiaire_fiche = (stg_key) => {
        if(trainee_more==true){
            history.push({
                pathname: getItemRoute('center_trainee_fiche')+session_key+'/'+stg_key,
                state:{back:true},
            });
        }
    }

    const go_to_stagiaire_msg = (stg_key) => {
        if(trainee_msg==true){
            history.push({
                pathname: getItemRoute('messenger')+session_key+'/'+stg_key,
                state:{back:true},
            });
        }
    }

    const get_stagiaire_convocation = (stg_key, name) => {
        if(trainee_cnv==true){
            progressShow = 0;
            const toastId = toast.loading(progressMessage(progressShow));
            post('trainee', 'cnvfile', processParams({
                tkn: account.info.tkn,
                ctr: account.ctr.key,
                ses: session_key,
                trn: stg_key,
            }))
            .then(function (xhr){
                if(check_is_200(xhr))
                {
                    if('file' in xhr.data)
                    {
                        popup_show_file(xhr.data.file);
                        toast.success(<b>Terminé</b>, { id: toastId, });
                    }
                    else 
                    {
                        toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                    }
                }
                else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
            }).catch(function (err){
                // // console.log(err);
                toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
            });
        }
    }

    const get_stagiaire_attestation = (stg_key, name) => {
        if(trainee_cnv==true){
            progressShow = 0;
            const toastId = toast.loading(progressMessage(progressShow));
            
            post('trainee', 'attfile', processParams({
                tkn: account.info.tkn,
                ctr: account.ctr.key,
                ses: session_key,
                trn: stg_key,
            }))
            .then(function (xhr){
                
                if(check_is_200(xhr))
                {
                    if('file' in xhr.data)
                    {
                        popup_show_file(xhr.data.file);
                        toast.success(<b>Terminé</b>, { id: toastId, });
                    }
                    else 
                    {
                        toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                    }
                }
                else toast.error(<b>Problème de chargement !</b>, { id: toastId, });

            }).catch(function (err){
                // // console.log(err);
                toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
            });
        }
    }

    const get_stagiaire_certificat = (stg_key, name) => {
        if(trainee_cnv==true){
            progressShow = 0;
            const toastId = toast.loading(progressMessage(progressShow));
            
            post('trainee', 'crtrfile', processParams({
                tkn: account.info.tkn,
                ctr: account.ctr.key,
                ses: session_key,
                trn: stg_key,
            }))
            .then(function (xhr){
                
                if(check_is_200(xhr))
                {
                    if('file' in xhr.data)
                    {
                        popup_show_file(xhr.data.file);
                        toast.success(<b>Terminé</b>, { id: toastId, });
                    }
                    else 
                    {
                        toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                    }
                }
                else toast.error(<b>Problème de chargement !</b>, { id: toastId, });

            }).catch(function (err){
                // // console.log(err);
                toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
            });
        }
    }

    const show_standart = () => {
        return  <div className={class_content}>
            {stagiaires.map((item, key) => <div key={key} className='train_info_ses_page'>
                                                <ProfilWidget 
                                                    width_percent={100} 
                                                    key={key} 
                                                    profil={item} 
                                                    session_key={session_key}
                                                    go_center_profil={trainee_more} 
                                                    go_messenger_session={trainee_msg}
                                                    padding='5px'
                                                    buttons = { trainee_cnv ? 
                                                        [
                                                            {
                                                                title:<FontAwesomeIcon icon={faFileSignature}/>,
                                                                action:() => get_stagiaire_convocation(item.key, item.nom+' '+item.pnom),
                                                                tooltip:'Convocation'
                                                            },
                                                            {
                                                                title:<FontAwesomeIcon icon={faCertificate}/>,
                                                                action:() => get_stagiaire_attestation(item.key, item.nom+' '+item.pnom),
                                                                tooltip:'Attestation'
                                                            },
                                                            {
                                                                title:<FontAwesomeIcon icon={faStamp}/>,
                                                                action:() => get_stagiaire_certificat(item.key, item.nom+' '+item.pnom),
                                                                tooltip:'Certificat de réalisation'
                                                            },
                                                            {
                                                                title:<FontAwesomeIcon icon={faHandPaper}/>,
                                                                tooltip: item.conf ? 'Présence confirmée' : 'En attente de confirmation',
                                                                color: item.conf ? '#00887A':'#dddddd'
                                                            }
                                                        ] : []
                                                    }
                                                />
                                            </div>)}

            {/* {stagiaires.length>0 && <table className='table_ini'>
                    <thead>
                        <tr>
                            <th>Nom</th><th>Prénom</th>
                            {(trainee_msg==true || trainee_more==true) ? <th></th> : ''}
                        </tr>
                    </thead>
                    <tbody>
                    {stagiaires.map((stg, key) => {
                    return (<tr key={key}>
                        <td>{stg.nom}</td>
                        <td>{stg.pnom}</td>
                        {(trainee_msg==true || trainee_more==true) ? (<td>
                            {trainee_msg==true ? <Button onClick={(e) => go_to_stagiaire_msg(stg.key)}>Messagerie</Button> : ''}
                            {trainee_more==true ? <Button onClick={(e) => go_to_stagiaire_fiche(stg.key)}>Fiche</Button> : ''}
                            {trainee_cnv==true ? <Button onClick={(e) => go_to_stagiaire_convocation(stg.key, stg.nom+' '+stg.pnom)}>Convocation</Button> : ''}
                        </td>) : ''}
                    </tr>);

                })}        
                    </tbody>
                </table>} */}
                {/* {trainee_cnv && <Toaster
                    position="bottom-right"
                    reverseOrder={false}        />} */}
        </div>;
    }

    const goUpdatePage = () => {
        history.push({
            pathname: getItemRoute('center_session_trainees')+session_key,
            state:{
                back:true,
                sesname:session.titre,
                session_key, session
            },
        });
    }

    const get_update_button = () => {
        return <div className='button_stand'>
                <FontAwesomeIcon 
                    icon={faCog}
                    onClick={() => goUpdatePage()} />
                </div>
    }

    return (<div>
        {(stagiaires.length>0 || can_update) && <div className='profil_show_item is_block'>
        
        <div className={class_title}>{with_title == true ? <div className='section_title_two'><div className='title_section'>Stagiaires</div> {can_update && get_update_button()}</div> : '' } </div>
            {show_standart()}
        </div>} </div>);
}
 
export default Stagiaires_List;