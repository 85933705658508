import Center_Form from "./Center_Form";

const Center_Create = () => {
    return ( <div className='layout_without_center'>
        <center><h1 className='pageTitle'>Créer votre centre</h1></center>
        <br/><br/>
        {/* <div className='mb_variant mb_warning'>Inactive pour le moment veuillez contacter nous pour plus d'information</div> */}
        <Center_Form/>
        </div> );
}
 
export default Center_Create;