import 'react-calendar/dist/Calendar.css';
import '../../style/calendar.scss';

import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { check_is_200, post, processParams } from '../../adapters/xhr';
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { filter_search_check_item, sort_function_by } from '../../tools/DataTableOpt';
import { getItemRoute } from '../../Routes';
import { date_sql_to_str, getDay, getDays, getMonth } from '../../tools/Dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

const Session_Calender = () => {

    const history = useHistory();
    const account = useSelector((state) => state.account);
    const currentDate = (new Date()).toISOString().split('T')[0];
    let days_sessions = [];

    
    const [sessions, setSessions] = useState([]);
    const [currDate, setCurrDate] = useState('');
    const [currDateSql, setCurrDateSql] = useState(currentDate);
    const [days_by_sessions, setDays_by_sessions] = useState([]);
    
    const changeDate = (date) => {
        setCurrDate(getDay(date.getDay()) + ' ' + date.getDate() + ' ' + getMonth(date.getMonth()) + ', '+ date.getFullYear());
        setCurrDateSql(
                    date.getFullYear() 
                        + '-' + ((date.getMonth()+1) < 10 ? '0' : '' ) + (date.getMonth()+1) 
                        + '-' + (date.getDate() < 10 ? '0' : '' ) + date.getDate()
                    );
    }

    

    const getEvents = () => {
        const params = processParams({
            tkn : account.info.tkn,
            ctr : account.ctr.key,
        });

        post('session', 'cldr', params)
        .then(function (xhr){
            if(check_is_200(xhr)) {
                let sesss = xhr.data.list || [];
                for(let i in sesss){
                    let inf = getState(sesss[i]);
                    sesss[i]['etata'] = inf.text;
                    sesss[i]['etata_cls'] = inf.clss;
                }
                setSessions(sesss);
            }else{
                
            }
        }).catch(function (err){

        }).finally(()=>{
            changeDate(new Date());
        });
    };
    
    let cellClassName = (date, view, frmDate)=> {
            let the_class = '';
            if(view === 'month')
            {
                sessions.map((sess, key) => {
                    sess.times.map( horaire => {
                        {   
                            if(!(horaire.dt_s > frmDate || frmDate > horaire.dt_e))
                            {
                                horaire.times.map( time => {
                                    if( time.days.includes(date.getDay().toString()))
                                    {
                                        the_class = 'there_even';
                                        if(!(frmDate in days_sessions)) days_sessions[frmDate] = [];
                                        if(! days_sessions[frmDate].includes(sess.key)) days_sessions[frmDate].push(sess.key);
                                    }
                                });
                            }
                            
                        }
                    }); 
                });
            }
            return the_class;
        };

    const getCellClassName = ({date, view}) => {
        if(sessions.length>0)
        {
            let frmDate = date.getFullYear() 
                            + '-' + ((date.getMonth()+1) < 10 ? '0' : '' ) + (date.getMonth()+1) 
                            + '-' + (date.getDate() < 10 ? '0' : '' ) + date.getDate();

            let mm = cellClassName(date, view, frmDate);
            // console.log(mm, frmDate);
            return mm; 
        }else return null;
    }

    useEffect(() => getEvents(), []);
    useEffect(() => setDays_by_sessions(days_sessions), [currDateSql]);
    
    const getState = (session) => {
        
        if(!Array.isArray(session.times)) return {text:'', clss:''};

        let min_date = '';
        let max_date = '';

        for(let hrr of session.times)
        {
            if(min_date == '' || hrr.dt_s < min_date ) min_date = hrr.dt_s;
            if(max_date == '' || hrr.dt_e > max_date ) max_date = hrr.dt_e;
        }
        
        let text = '';
        let clss = '';

        if(min_date == '')
        {
            
        }
        else if(currentDate < min_date)
        {
            text = 'à venir';
            clss = 'session_item_a_venir';
        }
        else if(currentDate > max_date)
        {
            text = 'passée';
            clss = 'session_item_passer';
        }
        else
        {
            text = 'en cours';
            clss = 'session_item_en_cour';
        }
        
        // text += ':: '+currentDate+' - '+min_date+' - '+max_date;

        return {text, clss};
    }

    const go_to_session = (session_key) => {
        history.push({
            pathname:getItemRoute('center_session_fiche')+session_key,
            state:{back:true}
        });
    }
    
    const columns = [
        { name: 'Programme', selector: row => row.prog_name, sortable: true,},
        { name: 'Semaine', maxWidth:'110px', selector: row => row.week, sortable: true,},
        { name: 'Du', maxWidth:'10px', selector: row => date_sql_to_str(row.date_s), sortable: true, sortFunction:sort_function_by('date_s'),},
        { name: 'Au', maxWidth:'10px', selector: row => date_sql_to_str(row.date_e), sortable: true, sortFunction:sort_function_by('date_e'),},
        { name: 'Devis', maxWidth:'15%', selector: row => row.devis_lst.join(), sortable: true,},
        { name: 'Clients', maxWidth:'15%', selector: row => row.clients_lst.join(), sortable: true,},
        { name: 'Etat', selector: row => row.action, sortable: true, maxWidth:'120px', cell:row => <div style={{backgroundColor:session_state_color(row), width:'100%', padding:10}}>{row.etata.toUpperCase()}</div>},
        { name: 'Action', maxWidth:'10px', selector: row => <div className='button_stand' onClick={()=>go_to_session(row.key)}><FontAwesomeIcon icon={faInfo}/></div>, sortable: true},
        // { name: 'Action', selector: row => row.action, sortable: true, minWidth:1, cell:row => <div style={{width:180}}>{getActions(row)}</div>},
    ];

    const filteredData = sessions.filter(item => {
        return currDateSql in days_by_sessions && days_by_sessions[currDateSql].includes(item.key);
    });

    const session_state_color = (sess) => {
        switch(sess.etata)
        {
            case 'à venir': return 'rgba(63, 195, 128, 0.3)';
            case 'passée': return 'rgba(248, 148, 6, 0.3)';
            case 'en cours': return 'rgba(242, 38, 19, 0.3)';
        }
        return '';
    }

    return ( 
        <div>
            <div className='headerContentPart' style={{marginBottom:'0px'}}>
                <div className='title'>Calendrier</div>
            </div>
            <div style={{margin:'30px 0px'}}>
                <Calendar onChange={changeDate} tileClassName={getCellClassName} className='is_block_calendar'/>
            </div>
            
            <h2 style={{margin:'30px 0px'}}>{currDate}</h2>
            <DataTable
                pagination
                fixedHeader
                columns={columns}
                data={filteredData}
                // conditionalRowStyles={conditionalRowStyles}
                paginationPerPage={40}
                paginationRowsPerPageOptions={[40, 60, 100]}
                noDataComponent={"Aucune session n'est programmée pour le "+ currDate +" !"}
                paginationComponentOptions={{
                                                rowsPerPageText: 'Sessions par page',
                                                rangeSeparatorText: 'sur',
                                                selectAllRowsItem: true,
                                                selectAllRowsItemText: 'Tous',
                                            }}

            />
        </div>
     );
}
 
export default Session_Calender;