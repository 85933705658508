import { faMinus, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Col, Form, FormControl, FormGroup, FormLabel, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { check_is_200, post, processParams, progressMessage } from "../../../../adapters/xhr";
import DeleteConfirmation from "../../../../tools/components/popups/DeleteConfirmation";
import { handle_change_input } from "../../../../tools/HInput";

const Survey_Programs_List = ({account, survey_key}) => {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newProg, setNewProg] = useState('');
    const [show_add_form, setShow_add_form] = useState(false);

    const [prog_to_delete, setProg_to_delete] = useState({});

    const [items_list, setItems_list] = useState([]);
    
    const getData = () => {
        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            srv: survey_key
        };

        post('surveyp', 'getprgs', processParams(params))
        .then(xhr => {
            if(check_is_200(xhr)) {
                if('list' in xhr.data)
                {
                    setItems_list(xhr.data.list);
                }
            }else{
                
            }
        }).catch(function (error){
            
        });
    }

    useEffect(() => {
        getData();
    }, []);


    const go_delete = (prog) => setProg_to_delete(prog);
    const hideConfirmationModal = () => setProg_to_delete({});

    const act_delete = () => {
        let keyprog = prog_to_delete.key;
        hideConfirmationModal();

        let params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            srv: survey_key,
            prg: prog_to_delete.key
        });

        const toastId = toast.loading('Envoi');

        post("surveyp", "prgdl", params)
        .then(function (xhr) {
            if(check_is_200(xhr)) {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        setItems_list(items_list.map(item=>{
                            if(item.key == keyprog) item.isc = 0;
                            return item;
                        }));
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else 
                {
                    toast.error(<b>Problème d'envoi des données !</b>, { id: toastId, });
                }
            }else{
                toast.error(<b>Problème de suppression !</b>, { id: toastId, });
            }
            
        }).catch(function(error){
            toast.error(<b>Problème de suppression !</b>, { id: toastId, });
        });
    }


    const getActions = (item) => {
        return (<div className='buttons_table'>
                    <div></div>
                    {/* <div className='button_stand' onClick={() => go_info(item.key)} data-tooltip="Informations">
                        <FontAwesomeIcon icon={faInfoCircle}/>
                    </div>
                    <div className='button_stand' onClick={() => go_download(item.key, item.titre)} data-tooltip="Télecharger">
                        <FontAwesomeIcon icon={faFileDownload}/>
                    </div>
                    <div className='button_stand' onClick={() => go_update(item.key)} data-tooltip="Modifier">
                        <FontAwesomeIcon icon={faCog}/>
                    </div> */}
                    <div className='button_stand' onClick={() => go_delete(item)} data-tooltip="Détacher">
                        <FontAwesomeIcon icon={faTimes}/>
                    </div>
                </div>);
    }
    
    const columns = [
        { name: 'Titre', selector: row => row.title, sortable: true,},
        { name: 'Nombre de réponse(s) à cet enquête', selector: row => row.nbr ? row.nbr : '', sortable: true,},
        { name: 'Action', selector: row => row.action, sortable: true, width:'100px', cell:row => <div style={{width:40}}>{getActions(row)}</div>},
    ];

    const handleAddProgram = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        let progressShow = 0;
        setValidated(true);

        if(form.checkValidity() === true){
            setLoading(true);
            
            let info = {
                tkn: account.info.tkn,
                ctr: account.ctr.key,
                srv: survey_key,
                prg: newProg
            };
            let params = processParams(info);
            
            const toastId = toast.loading("Ajout du programme ...");
            
            post("surveyp", "prgad", params)
            .then(function(xhr){
                if(check_is_200(xhr)) 
                {
                    if(('state' in xhr.data) && ('msg' in xhr.data))
                    {
                        if(xhr.data.state){
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            toggle_add_form();
                            setItems_list(items_list.map(item=>{
                                if(item.key == newProg) item.isc = 1;
                                return item;
                            }));

                        }else{
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });        
                        }
                    }else{
                        toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                    }
                }else{
                    toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                }
            }).catch(function(error){
                toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
            }).then(function() {
                setLoading(false);
            });
        }
        
    };

    const toggle_add_form = () => {
        setNewProg('');
        setValidated(false);
        setShow_add_form(!show_add_form);
    }

    return ( <div>
        <h3 className='div_according'>Programme(s)</h3>
        
        <div className='button_stand' onClick={()=>toggle_add_form()}>
            <FontAwesomeIcon icon={show_add_form ? faMinus : faPlus}/>
        </div>
        
        {show_add_form && <Form noValidate validated={validated} onSubmit={handleAddProgram}>
            <Row>
                <FormGroup as={Col}>
                    <FormLabel>Programme</FormLabel>
                </FormGroup>
                <FormGroup as={Col} md={5}>
                    <FormControl as='select' value={newProg} onChange={(e)=>handle_change_input(e, setNewProg)} required>
                        {newProg=='' && <option value=''>Choisissiez</option>}
                        {items_list.filter( item => !item.isc ).map((item, key) => <option key={key} value={item.key}>{item.title}</option>)}
                    </FormControl>
                    <FormControl.Feedback>Veuillez remplir ce champs</FormControl.Feedback>
                </FormGroup>
                <FormGroup as={Col} md={5}>
                    {!loading && <Button type="submit">Ajouter</Button>}
                    {loading && <Button variant="primary" disabled>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                    </Button>}
                </FormGroup>
            </Row>
        </Form>}

        <DataTable
                    pagination
                    fixedHeader
                    columns={columns}
                    data={items_list.filter( item => item.isc )}
                    noDataComponent="Aucun programme n'est enregistré !"
                    paginationComponentOptions={{
                                                    rowsPerPageText: 'Programme par page',
                                                    rangeSeparatorText: 'sur',
                                                    selectAllRowsItem: true,
                                                    selectAllRowsItemText: 'Tous',
                                                }}
                />

        <DeleteConfirmation 
                        showModal={'key' in prog_to_delete} 
                        confirmModal={act_delete}
                        hideModal={hideConfirmationModal} 
                        message={"Êtes-vous sûr de vouloir détacher cette enquête du programme \""+prog_to_delete.title+"\" ?"}
                        confirmMessage="Détacher"
                    />
    </div> );
}
 
export default Survey_Programs_List;