import { useEffect, useState } from "react";
import { Form, Row, FormLabel, FormGroup, FormControl, Button, Col, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { check_is_200, downloadBlob, post, processParams, progressMessage } from "../../../../adapters/xhr";
import { handle_change_file, handle_change_input } from "../../../../tools/HInput";
import toast, { Toaster } from "react-hot-toast";
import parse from 'html-react-parser';
import ConfirmationModel from "../../../../tools/components/popups/ConfirmationModel";
import { getItemRoute } from "../../../../Routes";
import DataTable from "react-data-table-component";
import { filter_search_check_item } from "../../../../tools/DataTableOpt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faBackspace, faBackward, faCog, faDownload, faEnvelope, faInfo, faPencilAlt, faPhone, faPlus, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

const Stagiaires_Upd = (props) => {

    let session_name = '';
    if(('location' in props) && ('state' in props.location) && ('sesname' in props.location.state))
        session_name = props.location.state.sesname;

    const history = useHistory();
    const { session_key } = useParams();
    const account = useSelector((state)=>state.account);

    const location = useLocation();
        
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [stagiaire_to_remove, setStagiaire_to_remove] = useState({});
    const [stagiaire_to_change_devis, setStagiaire_to_change_devis] = useState({});
    const [change_devis_frm, setChange_devis_frm] = useState('');
    const [change_devis_frm_ini, setChange_devis_frm_ini] = useState('');
    const [is_remove, setIs_remove] = useState('');
    const [is_confirm, setIs_confirm] = useState('');
    const [stagiaire_to_confirm, setStagiaire_to_confirm] = useState({});
    const hideConfirmationModal = () => { setDisplayConfirmationModal(false); }
    
    const [actionClass, setActionClass] = useState('');
    const [actionMessage, setActionMessage] = useState('');

    const [validated, setValidated] = useState(false);
    const [importFile, setImportFile] = useState('');
    const [stagiaires, setStagiaires] = useState([]);
    const [devis_list, setDevis_list] = useState([]);
    const [devis_frm, setDevis_frm] = useState('');
    const [filter_by, setFilter_by] = useState('');
    const [show_imp, setShow_imp] = useState(false);

    const getDevis = () => {
        post('ctrdev', 'listafs', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
        }))
        .then(function (xhr){
            if(check_is_200(xhr)) {
                if('list' in xhr.data)
                {
                    setDevis_list(xhr.data.list);
                }
            }
        }).catch(function (err){
            
        })
    }

    const getStagiaires = () => {
        const params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            opt: ['infs']
        });

        post('trainee', 'getlist', params)
        .then( xhr => {
            if(check_is_200(xhr)) {
                if(('ses' in xhr.data) && ('lst' in xhr.data)){
                    setStagiaires(xhr.data.lst);
                }
            }
        }).catch(function (err){
            
        });
    }

    useEffect(() => {
        getDevis();
        getStagiaires();
        
    }, []);

    
    const confirm_stagiaire_presence = (stag) => {
        setIs_remove(false);
        setIs_confirm(true);
        
        setStagiaire_to_confirm(stag);
        setDisplayConfirmationModal(true);
        setActionClass('warning');
        setActionMessage('Confirmer');
    }

    const confirm_remove_stagiaire = (stag) => {
        setIs_remove(true);
        setIs_confirm(false);

        setStagiaire_to_remove(stag);
        setDisplayConfirmationModal(true);
        setActionClass('danger');
        setActionMessage('Supprimer');
    }

    const removeStagiaire = () => {

        let stag = stagiaire_to_remove;
        setDisplayConfirmationModal(false);

        const params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            stg: stag.key,
        });

        const toastId = toast.loading("Chargement");
        post('trainee', 'delete', params)
        .then( function ( xhr ) {
            // // console.log(xhr);
            if ( ( 'status' in xhr ) && ( xhr.status===200 ) )
            {
                if ( ( 'state' in xhr.data ) && ( 'msg' in xhr.data ) )
                {
                    if ( xhr.data.state )
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        getStagiaires();
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else
            {
                toast.error(<b>Problème de chargement !</b>, { id: toastId, });
            }
        })
        .catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    }

    const getModel = () => {
        let progressShow = 0;

        const params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key
        });

        const toastId = toast.loading(progressMessage(progressShow));
        post('trainee', 'gmodel', params, {
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                progressShow = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                toast.loading(progressMessage(progressShow), { id: toastId, });
            },
        }).then(function (response) {
            // // console.log(response);
            toast.success(<b>Fichier téléchargé</b>, { id: toastId, });
            downloadBlob(response.data, "model_stagiaires.xlsx");
        }).catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    
    const sendFile = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        let params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            dev: devis_frm,
        });

        if(importFile !== ''){
            params.append('file', importFile, importFile.name);
        }

        let progressShow = 0;
        if(form.checkValidity() === true){

            const toastId = toast.loading(progressMessage(progressShow));
            post('trainee', 'umodel', params, {
                onUploadProgress: function (progressEvent) {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    if (totalLength !== null) {
                        progressShow = Math.round( (progressEvent.loaded * 100) / totalLength );
                        toast.loading(progressMessage(progressShow), { id: toastId, });
                    }
                }
            })
            .then( function ( xhr ) {
                console.log(xhr.data);
                if ( check_is_200(xhr) )
                {
                    if ( ( 'state' in xhr.data ) && ( 'msg' in xhr.data ) )
                    {
                        // console.log(xhr.data);
                        if ( xhr.data.state )
                        {
                            toast.success(<b>Données enregistrées avec succées !</b>, { id: toastId, });
                            setImportFile('');
                            getStagiaires();
                        }
                        else
                        {
                            toast.error(<b>{parse(xhr.data.msg)}</b>, { id: toastId, });
                        }
                    }
                    else
                    {
                        toast.error(<b>Problème de chargement 3!</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème de chargement  2!</b>, { id: toastId, });
                }
            })
            .catch(function (err){
                // // console.log(err);
                toast.error(<b>Problème de chargement 1 !</b>, { id: toastId, });
            });
        }
        setValidated(true);
    }

    const columns = [
        { name: 'Nom', selector: row => row.nom, sortable: true,},
        { name: 'Prénom', selector: row => row.pnom, sortable: true,},
        { name: 'Devis', selector: row => row.devis, sortable: true,},
        { name: 'Client', selector: row => row.client, sortable: true,},
        { name: 'Etat', selector: row => row.conf_str, sortable: true, cell:row => <div style={{width:200, color:row.conf?'green':'red'}}>{row.conf_str}</div>,},
        { name: 'Action', selector: row => row.action, sortable: true, minWidth:1, cell:row => <div>
                <div className='button_stand' data-tooltip='Contact' onClick={() =>show_info_stagiaire(row)}><FontAwesomeIcon icon={faInfo} /></div>
                {row.conf ? '' : <div className='button_stand' data-tooltip='Confirmer' onClick={() =>confirm_stagiaire_presence(row)}>
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                </div>}
                <div className='button_stand' data-tooltip='Modifier' onClick={() =>change_stagiaire_devis(row)}><FontAwesomeIcon icon={faCog} /></div>
                <div className='button_stand' data-tooltip='Retirer' onClick={(e)=>confirm_remove_stagiaire(row)}><FontAwesomeIcon icon={faTimes} /></div>
            </div>},
    ];

    const filteredData = stagiaires.filter(item => {
        let filter_key = filter_by.toLocaleLowerCase();
        return filter_search_check_item(filter_key, item, 
            ['pnom', 'devis', 'client', 'conf_str']);
    });


    const show_info_stagiaire = (stag) => {
        toast((t) => (
            <span>
                <b>{stag.nom+" "+stag.pnom}</b><br/>
                <FontAwesomeIcon icon={faPhone}/> {stag.tel}<br/>
                <FontAwesomeIcon icon={faEnvelope}/> {stag.email}
            </span>
          ), { duration: 10000 });
    }

    const change_stagiaire_devis = (stag) => {
        setIs_remove(false);
        setIs_confirm(false);

        setStagiaire_to_change_devis(stag);
        setDisplayConfirmationModal(true);
        setActionClass('warning');
        setActionMessage('Réaffecter');
        
        let sele = '';
        for(let cl of devis_list) 
        {
            if(cl.n == stag.devis) sele = cl.r;
        }
        setChange_devis_frm_ini(sele);
    }

    const form_to_change_devis = () => {
        return <div>
            <FormGroup as={Col}>
                <FormLabel>Liste des devis affectés à la session</FormLabel>
                <FormControl as='select' value={change_devis_frm} onChange={(e)=>handle_change_input(e, setChange_devis_frm)} required>
                    <option value=''>Choisir</option>
                    {devis_list.map((item, key) => <option key={key} value={item.r} disabled={item.r == change_devis_frm_ini}>{item.n}, {item.c}</option>)}
                </FormControl>
            </FormGroup>
        </div>;
    }
    
    const confirmStagiaire = () => {
        let stag = stagiaire_to_confirm;
        setDisplayConfirmationModal(false);

        const params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            stg: stag.key
        });

        const toastId = toast.loading("Chargement");
        post('trainee', 'markprs', params)
        .then( function ( xhr ) {
            if (check_is_200(xhr))
            {
                console.log(xhr.data);
                if ( ( 'state' in xhr.data ) && ( 'msg' in xhr.data ) )
                {
                    if ( xhr.data.state )
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        getStagiaires();
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else
            {
                toast.error(<b>Problème de chargement !</b>, { id: toastId, });
            }
        })
        .catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    }

    const changeDevis = () => {
        let stag = stagiaire_to_change_devis;
        setDisplayConfirmationModal(false);

        const params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            stg: stag.key,
            dev: change_devis_frm
        });

        const toastId = toast.loading("Chargement");
        post('trainee', 'upddev', params)
        .then( function ( xhr ) {
            if (check_is_200(xhr))
            {
                if ( ( 'state' in xhr.data ) && ( 'msg' in xhr.data ) )
                {
                    if ( xhr.data.state )
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        getStagiaires();
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else
            {
                toast.error(<b>Problème de chargement !</b>, { id: toastId, });
            }
        })
        .catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    }

    const goback = () => {
        history.goBack();
    }

    const go_search = () => {
        history.push({
            pathname:getItemRoute('center_session_trainees_search')+session_key,
            state:location.state
        });
    }

    const go_trainee_form = () => {
        history.push({
            pathname:getItemRoute('center_trainee_form')+session_key,
            state:location.state
        });
    }

    const change_show_imp = () => {
        setShow_imp(!show_imp);
    }

    const get_pdf = () => {

        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key
        };

        // console.log(params);
        
        post('trnses','lstp', processParams(params), {
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                progressShow = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                toast.loading(progressMessage(progressShow), { id: toastId, });
            },
        })
        .then(function (response){
            toast.success(<b>Fichier téléchargé</b>, { id: toastId, });
            downloadBlob(response.data, "Liste stagiaire "+session_name+".pdf");
        }).catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    return (
        <div>
            <div className='headerContentPart flex_between' >
                <div className='title div_according'>Modifier la liste les stagiaires
                    {(session_name != '') && <div className='sub_title'>{session_name}</div>}
                </div>
                <div className='div_according'>
                    <div className='button_stand' onClick={() => get_pdf()}>
                        Imprimer
                    </div>
                </div>
            </div>

            {stagiaires.length>0 && <div>
                        <InputGroup className="search">
                            <InputGroup.Text className="icon">
                                <FontAwesomeIcon
                                    icon={faSearch}
                                /> 
                            </InputGroup.Text>
                            <FormControl
                                placeholder="Filtrer"
                                aria-label="Filtrer"
                                aria-describedby="filtrate"
                                type='text' 
                                value={filter_by} 
                                className="text_zone"
                                onChange={e => handle_change_input(e, setFilter_by)}
                            />
                        </InputGroup>
                    </div>}
            {stagiaires.length>0 && <DataTable
                    pagination
                    fixedHeader
                    columns={columns}
                    data={filteredData}
                    noDataComponent="Aucun stagiaire n'est enregistré !"
                    paginationComponentOptions={{
                                                    rowsPerPageText: 'Stagiaires par page',
                                                    rangeSeparatorText: 'sur',
                                                    selectAllRowsItem: true,
                                                    selectAllRowsItemText: 'Tous',
                                                }}
                    
                />}
            
            <Form noValidate validated={validated} onSubmit={sendFile}>
                <div className='button_stand' onClick={()=>go_trainee_form()}>
                    <FontAwesomeIcon icon={faPlus}/> Créer un stagiaire
                </div>
                
                <div className='button_stand' onClick={()=>go_search()}>
                    <FontAwesomeIcon icon={faPlus}/> Ajouter un stagiaire existant
                </div>

                <div className='button_stand' onClick={()=>change_show_imp()}>
                    <FontAwesomeIcon icon={faDownload}/> Importation via un fichier
                </div>

                
                {show_imp && <div  className='is_block'>
                        <div className='button_stand' onClick={()=>getModel()}>
                            <FontAwesomeIcon icon={faDownload}/> Télécharger le modèle
                        </div>
                        <Row>
                            <FormGroup as={Col}>
                                <FormLabel>Fichier</FormLabel>
                                <FormControl type='file' onChange={(e) => handle_change_file(e, setImportFile)} required />
                            </FormGroup>
                            <FormGroup as={Col}>
                                <FormLabel>Devis</FormLabel>
                                <FormControl as='select' value={devis_frm} onChange={(e)=>handle_change_input(e, setDevis_frm)} required>
                                    <option value=''>Liste des devis</option>
                                    {devis_list.map((item, key) => <option key={key} value={item.r}>{item.n}, {item.c}</option>)}
                                </FormControl>
                            </FormGroup>
                            <FormGroup as={Col} style={{paddingTop:32, paddingLeft:30}}>
                                <Button type="submit">Envoyer</Button>
                            </FormGroup>
                        </Row>    
                    </div>}
                
                <br/>
                
            </Form>
            <ConfirmationModel
                        title={is_remove ? 
                                        "Confirmation de suppression retirer"
                                        :
                                        ( is_confirm ? "Confirmation de présence" : "Modification du devis")
                                    }
                        showModal={displayConfirmationModal} 
                        confirmModal={is_remove ? removeStagiaire : ( is_confirm ? confirmStagiaire : changeDevis )}
                        hideModal={hideConfirmationModal} 
                        actionClass={actionClass}
                        actionMessage={actionMessage}
                        message={is_remove ? 
                                    "Êtes-vous sûr de vouloir retirer \""+stagiaire_to_remove.nom+" "+stagiaire_to_remove.pnom+"\" de cette session ?"
                                    :
                                    ( is_confirm ?
                                            "Êtes-vous sûr de vouloir confirmer la présence de \""+stagiaire_to_confirm.nom+" "+stagiaire_to_confirm.pnom+"\" ?"
                                            : 
                                            "Êtes-vous sûr de vouloir changer le devis "+stagiaire_to_change_devis.devis+" de \""+stagiaire_to_change_devis.nom+" "+stagiaire_to_change_devis.pnom+"\" ?")
                                }
                        complement={is_remove ? () => {} : ( is_confirm ? () => {} : form_to_change_devis )}
                    />
            {/* <Toaster
                position="bottom-right"
                reverseOrder={false}        /> */}
        </div>
     );
}
 
export default Stagiaires_Upd;