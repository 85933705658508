import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Messagerie from './Messagerie';

const Messagerie_page = () => {
    const account = useSelector((state) => state.account);
    const { profile_key, session_key } = useParams();

    return ( <Messagerie
        session_key = {session_key}
        account={account}
        can_add_note={true}
        to={profile_key}
     /> );
}
 
export default Messagerie_page;