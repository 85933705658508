import { css } from "@emotion/react";
import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HashLoader } from "react-spinners";

const LoadingComponent = ({ medium_size, large_size, is_loading, msg_loading, is_error, msg_error, is_success, msg_success, classbtn }) => {

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        `;
    
    const color_loading = '#ffb703';
    const color_success = '#49D95B';
    const color_error = '#DF3535';

    const size_spinner  = large_size ? 150 : (medium_size ? 100 : 20);
    const size_icon     = large_size ? 10 : (medium_size ? 6 : 2);
    const size_title    = large_size ? 40 : (medium_size ? 30 : '');

    return ( 
            <div style={{textAlign:'center'}} className={classbtn}>
                {is_loading && 
                    <HashLoader color={color_loading} loading={true} css={override} size={size_spinner} />}

                {(!is_loading && is_success && !is_error) && 
                    <FontAwesomeIcon size={size_icon+'x'} icon={faCheckCircle} color={color_success} />}

                {(!is_loading && is_error && !is_success) && 
                    <FontAwesomeIcon size={size_icon+'x'} icon={faExclamationTriangle} color={color_error} />}

                <div style={{fontSize:size_title, fontWeight:'bold'}}>
                    {is_loading && msg_loading}
                    {(!is_loading && is_success && !is_error) && msg_success}
                    {(!is_loading && is_error && !is_success) && msg_error}
                </div>
            </div> 
        );
}
 
export default LoadingComponent;