import { faCog, faDownload, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { check_is_200, downloadBlob, popup_show_file, post, processParams, progressMessage } from "../../../../adapters/xhr";
import { getItemRoute } from "../../../../Routes";

const Documents_List = ({session_key, account, can_update, with_title, class_title, class_content }) => {
    
    let progressShow = 0;
    const history = useHistory();
    const [documents, setDocuments] = useState([]);

    const getDocuments = () => {
        post('sdocument', 'get', processParams({
            tkn: account.info.tkn,
            ses: session_key,
        }))
        .then(function (xhr){
            if(check_is_200(xhr))
            {
                setDocuments(xhr.data);
            }
            else
            {
                
            }
        }).catch(function (error){
            
        });
    }


    useEffect(()=>{
        getDocuments();
    }, []);
    
    const getFile = (document) => {
        // console.log(document);
        popup_show_file(document.path);
        
    }

    const show_standart = () => {
        return <div className={class_content}>{documents.map((doc, key) => {
            return (
                <div key={key} className='block_link clickable' onClick={() => getFile(doc)}>
                    {doc.name}
                </div>
                );

        })}</div>;
    }

    const goUpdatePage = () => {
        history.push({
            pathname: getItemRoute('center_session_docs')+session_key,
            state:{back:true},
        });
    }

    const get_update_button = () => {
        return <div className='button_stand'>
                <FontAwesomeIcon 
                    icon={faCog}
                    onClick={() => goUpdatePage()}
                    />
                </div>
    }

    return (<div>
        <div className='profil_show_item is_block'>
        <div className={class_title}>{with_title == true ? <div className='section_title_two'><div className='title_section'>Documents de la session</div>{can_update && get_update_button()}</div> : ''}</div>
        {show_standart()}
        </div> </div>);
}
 
export default Documents_List;