import { getItemRoute } from "../../Routes";
import { useHistory } from 'react-router-dom';

const Session_Item = ({ session, can_acces, from_s }) => {
    const history = useHistory();
    can_acces = (can_acces !== false);

    const currentDate = (new Date()).toISOString().split('T')[0]
    
    const getState = () => {
        if(!Array.isArray(session.times)) return {text:'', clss:''};

        let min_date = '';
        let max_date = '';

        for(let hrr of session.times)
        {
            if(min_date == '' || hrr.dt_s < min_date ) min_date = hrr.dt_s;
            if(max_date == '' || hrr.dt_e > max_date ) max_date = hrr.dt_e;
        }
        
        let text = '';
        let clss = '';

        if(min_date == '')
        {
            
        }
        else if(currentDate < min_date)
        {
            text = 'à venir';
            clss = 'session_item_a_venir';
        }
        else if(currentDate > max_date)
        {
            text = 'passée';
            clss = 'session_item_passer';
        }
        else
        {
            text = 'en cours';
            clss = 'session_item_en_cour';
        }
        
        // text += ':: '+currentDate+' - '+min_date+' - '+max_date;

        return {text, clss};
    }

    const getState_format = () => {
        let the_state = getState();
        if(the_state)
        {
            return <div className='state'>{the_state.text}</div>
        }
        return null;
    }

    const go_to_session = () => {
        if(can_acces)
        {
            history.push({
                pathname:getItemRoute('center_session_fiche')+session.key,
                state:{back:true, from:from_s}
            });
        }
    }

    let the_state_item = getState();
    return ( <div className={'session_item_list is_table '+ (can_acces ? 'clickable_cursor' : '')+' '+(the_state_item.clss)}
                    onClick={() => go_to_session()}
                >
                    <div className='session_item_list_title'>{session.titre}</div>
                    <div className='is_table_col' style={{width:200, textAlign:'right'}}>{getState_format()}</div>
            </div> );
}
 
export default Session_Item;