import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getItemRoute } from "../../Routes";
import { rmvGses, save } from "../../store/accountSlice";
import Formers_Smallinfo from "../Formateurs/Formers_Smallinfo";
import Sessions_Smallinfo from "../session/Sessions_Smallinfo";
import Clients_Smallinfo from "./Clients/Clients_Smallinfo";
import Devis_Smallinfo from "./Devis/Devis_Smallinfo";
import Center_Docs_Smallinfo from "./Documents/Center_Docs_Smallinfo";
import Program_Smallinfo from "./Programs/Program_Smallinfo";

const Center_Home = () => {

    const account = useSelector((state) => state.account);
    const history = useHistory();

    let dispatch = useDispatch();

    let gotoses = account.info.gses || '';
    if(gotoses)
    {
        // account.info.gses = '';
        console.log(account);
        dispatch(rmvGses());
    }
    
    const go_center_info = () => {
        history.push({
            pathname:getItemRoute('center_apropos'),
            state:{back:false}
        });
    }

    useEffect(() => {
        if(Object.keys(account.ctr).length == 0)
            history.push({
                pathname:getItemRoute('my_centres'),
                state:{back:false}
            });
    }, [account]);

    return ( <div>
        <div className='flex_between'>
            <div className='title'>
                {account.ctr.logo && <div className='apropos_logo'>
                    <img src={account.ctr.logo}/>
                </div>}
            </div>
        </div>
        <div className='info_bloc_items'>
            <Center_Docs_Smallinfo account={account}/>
            <Program_Smallinfo account={account}/>
            <Formers_Smallinfo account={account}/>
            <Clients_Smallinfo account={account}/>
            <Devis_Smallinfo account={account}/>
            
            <Sessions_Smallinfo account={account} goSession={gotoses} allSession/>
            <Sessions_Smallinfo account={account} goSession={gotoses} forFormer/>
            <Sessions_Smallinfo account={account} goSession={gotoses} forSecretaire/>
            <Sessions_Smallinfo account={account} goSession={gotoses} forTrainner/>
        </div>
    </div> );
}
 
export default Center_Home;