import { useEffect, useState } from "react";
import { Button, Col, Form, Row, FormGroup, FormControl, Spinner, InputGroup } from "react-bootstrap"
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { useHistory, useParams } from 'react-router-dom';
import { check_is_200, post, processParams, progressMessage } from "../../adapters/xhr";
import { faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Question_Group_Form from "../../tools/components/Questions/Question_Group/Question_Group_List";

const Satisfaction_Form = () => {

    const account = useSelector((state) => state.account);
    const history = useHistory();

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const [items, setitems] = useState([]);
    const [qst_types, setQst_types] = useState([]);
    
    const [survey, setSurvey] = useState({});
    const [clme, setClme] = useState('');

    
    const getData = () => {
        
        post('stsf', 'form', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key
        }))
        .then(function (xhr){
            if(check_is_200(xhr))
            {
                setQst_types(xhr.data.typs || []);
                if('survey' in xhr.data){
                    setClme(xhr.data.survey.clme || '');
                    setitems(xhr.data.survey.groups || []);
                }
            }
            else
            {

            }
        });
    }

    const goBack = () => {
        history.goBack();
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        let progressShow = 0;

        if(form.checkValidity() === true){
            setLoading(true);
            
            let info = {
                tkn: account.info.tkn,
                ctr: account.ctr.key,
                elc: clme,
                items
            };
            
            let params = processParams(info);
            
            const toastId = toast.loading(progressMessage(progressShow));
            
            post('stsf', 'sve', params)
            .then(function(xhr){
                if(check_is_200(xhr)) 
                {   
                    if(('save' in xhr.data) && ('msg' in xhr.data))
                    {
                        if(xhr.data.save)
                        {
                            toast.success(<b>Données enregistrées !!</b>, { id: toastId, });
                            setTimeout(() => { 
                                goBack();
                            }, 1000);
                        }else{
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });        
                        }
                    }else{
                        toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                    }
                }else{
                    toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                }
            }).catch(function(error){
                toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
            }).then(function() {
                setLoading(false);
            });
        }
        setValidated(true);
    };



    useEffect(()=>{
        getData();
    }, []);
    
    return (
        <div>
            <Row className='headerContentPart' >
                <div className='flex_between'>
                    <div className='title'>Modifier le formulaire de satisfaction</div>
                </div>
            </Row>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {/* {survey_items_show()} */}
                <Question_Group_Form
                    items={items}
                    setItems={setitems}
                    is_quiz={false}
                    typeList={qst_types}    />
                

                {!loading && <Button type="submit">Enregistrer</Button>}
                {loading && <Button variant="primary" disabled>
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>}
                {/* <Toaster
                    position="bottom-right"
                    reverseOrder={false}        /> */}
            </Form>
        </div>
    );
}

export default Satisfaction_Form;