import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch, useParams, useHistory } from "react-router-dom"
import { check_is_200, post, processParams } from "../../adapters/xhr";
import { getRoutesListByRef, getAuthorizedRoutes, getItemRoute, getItem } from "../../Routes";
import { clear } from "../../store/accountSlice";
import Center_Home from "../../view/center/Center_Home";
import NavBarConnected from "../../view/member/NavBar"
import HomeCenter from "./HomeCenter";
import HomeSelectCenter from "./HomeSelectCenter";

const HomeMember = () => {


    const contr_prof = "/profil";
    const dispatch = useDispatch();
    
    const account = useSelector((state) => state.account);
    const history = useHistory();

    const { center_key } = useParams();
    
    const [invitation_nbr, setInvitation_nbr] = useState(0);

    const getData = () => {
        post('ctrntw', 'requestsnbrs', processParams({
            tkn:account.info.tkn,
            nbr:1
        }))
        .then((xhr) => {
            if(check_is_200(xhr))
            {
                setInvitation_nbr(xhr.data.nbr || 0);
            }
        })
        .catch((err) => {

        });
    }

    useEffect(() => {
        getData();
    }, []);

    let linksNavBar = getRoutesListByRef(['my_centres', 'profil_my']);
    const subroutesNavbar = []; //Object.values(linksNavBar);

    let mescentres = getItem('my_centres');
    subroutesNavbar.push(mescentres);

    let invitations = getItem('centers_invitations');
    invitations.badge = invitation_nbr;
    subroutesNavbar.push(invitations);

    let monprofil = getItem('profil_my');
    subroutesNavbar.push(monprofil);
    

    const rightroutesNavbar = [{
        layout: contr_prof,
        path: "/deconnect",
        name: "Se déconnecter",
        color: 'red',
        icon: faSignOutAlt,
        action: () => {
            dispatch(clear());
            history.push({
                pathname:getItemRoute('home_index'),
                state:{back:false}
            });
        },
    }];
    
    const routesNavbar = [];
    // routesNavbar.push({
    //         layout: contr_prof,
    //         path: "/deconnect",
    //         name: "Se déconnecter",
    //         action: () => {
    //             dispatch(clear());
    //             history.push({
    //                 pathname:getItemRoute('home_index'),
    //                 state:{back:false}
    //             });
    //         },
    //     });
    
    let linksAuthorized = Object.values(getRoutesListByRef(['profil_my', 'my_centres', 'profil_my_update', 'profil_my_password', 'profil_my_docs', 'center_create']));

    // console.log(linksAuthorized);
    // console.log(subroutesNavbar);

    let linksAuthorized1 = linksAuthorized.concat(subroutesNavbar);

    const getRoutes = (subroutesNavbar) => {
        // // console.log(routesSidebar, routesSidebar_content, subroutesNavbar);
        return <Switch>
            {subroutesNavbar.map((prop, key) =>{
                if((typeof prop =='object') && ('component' in prop)){
                    return <Route 
                                key={key}
                                path={prop.layout+prop.path+(prop.params || '')}
                                render={(props) => <prop.component {...props} />}
                            />
                }else{
                    return null;
                }
            })}
            <Route>
                <HomeCenter/>
            </Route>
            <Route path='/'>
                <HomeSelectCenter/>
            </Route>
        </Switch>
    }

    return ( 
        <div className="HomeMemberPageDiv">
            <NavBarConnected routes={routesNavbar} subroutes={subroutesNavbar} rightroutes={rightroutesNavbar} account={account}/>
            <div className="HomeMemberContentPageDiv">
                {getRoutes(linksAuthorized)}
            </div>
            <Toaster
                    position="bottom-right"
                    reverseOrder={false}        />
        </div>
     );
}
 
export default HomeMember;