import { Button, Modal } from "react-bootstrap";

const ConfirmationModel = ({ title, showModal, hideModal, submitForm, confirmModal = ()=>{}, actionMessage, actionClass, hide_actionButton, cancelMessage, params, message, complement = () => {}, show_footer=true }) => {
    return ( 
        <Modal show={showModal} onHide={hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>{title ? title : 'Demande de confirmation'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message && <div className={"alert alert-"+(actionClass ? actionClass : 'danger')}>{message}</div>}
                {complement()}
            </Modal.Body>
            {show_footer && <Modal.Footer>
                {!hide_actionButton && <Button variant={actionClass ? actionClass : 'danger'} type={submitForm && 'submit'} form={submitForm} onClick={() => confirmModal(params)}>
                    {actionMessage ? actionMessage : 'Supprimer'}
                </Button>}
                <Button variant="default" onClick={hideModal}>
                    {cancelMessage ? cancelMessage : 'Annuler'}
                </Button>
            </Modal.Footer>}
        </Modal>
     );
}
 
export default ConfirmationModel;