import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Dropdown, Row, FormGroup, FormLabel, FormControl, Form } from "react-bootstrap";
import { check_is_200, downloadBlob, popup_show_file, post, processParams, progressMessage } from "../../../adapters/xhr";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen, faCheck, faCog, faEllipsisH, faEnvelope, faExternalLinkAlt, faExternalLinkSquareAlt, faFile, faFileAlt, faFileDownload, faLink, faPlus, faTimes, faUnlink } from "@fortawesome/free-solid-svg-icons";
import { getItemRoute } from "../../../Routes";
import ConfirmationModel from "../../../tools/components/popups/ConfirmationModel";
import { handle_change_checkbox, handle_change_file, handle_change_input } from "../../../tools/HInput";
import toast, { Toaster } from "react-hot-toast";
import RequiredItem from "../../../tools/RequiredItem";
import LoadingComponent from "../../../tools/components/loading/LoadingComponent";
import { page_refresh } from "../../../tools/PageTool";
import DeleteConfirmation from "../../../tools/components/popups/DeleteConfirmation";
import { datetime_sql_to_str } from "../../../tools/Dates";
import InfoPopUp from "../../../tools/components/popups/InfoPopUp";

const Devis_Fiche = () => {

    const account = useSelector((state) => state.account );
    const history = useHistory();
    const { center_key, devis_key } = useParams();

    let progressShow = 0;

    const [devis, setDevis] = useState({});
    const [is_remise, setIs_remise] = useState('');

    const [msg_avoir, setMsg_avoir] = useState('');

    const [infoCancel, setInfoCancel] = useState({});
    const [client_email, setClient_email] = useState('');
    const [client_sms, setClient_sms] = useState('');

    const [renvoi_devis_email, setRenvoi_devis_email] = useState(false);
    const [renvoi_devis_sms, setRenvoi_devis_sms] = useState(false);
    const [renvoi_devis_email_plus, setRenvoi_devis_email_plus] = useState('');
    
    const [showModal_renvoi_devis, setShowModal_renvoi_devis] = useState(false);
    const [showModal_validate_devis, setShowModal_validate_devis] = useState(false);
    const [showModal_validate_convention, setShowModal_validate_convention] = useState(false);
    const [showModal_validate_OPCO, setShowModal_validate_OPCO] = useState(false);
    // const [showModal_reini_devis, setShowModal_reini_devis] = useState(false);
    const [showModal_attach_devis, setShowModal_attach_devis] = useState(false);
    const [showModal_detach_devis, setShowModal_detach_devis] = useState(false);
    const [showModal_cancel_devis, setShowModal_cancel_devis] = useState(false);
    const [conv_cancel_confirm_modal, setConv_cancel_confirm_modal] = useState(false);
    const [showModal_remove_devis, setShowModal_remove_devis] = useState(false);

    const [showModal_encaissement, setShowModal_encaissement] = useState(false);
    const [encaissement_form_validated, setEncaissement_form_validated] = useState(false);
    const [encaissement_date_form, setEncaissement_date_form] = useState('');
    const [is_encaissement_of_opco, setIs_encaissement_of_opco] = useState(false);

    const [opco_form_validated, setOpco_form_validated] = useState(false);
    const [opco_form_nds, setOpco_form_nds] = useState('');
    const [opco_form_cad, setOpco_form_cad] = useState('');
    const [opco_form_mnt, setOpco_form_mnt] = useState('');
    const [opco_form_nom, setOpco_form_nom] = useState('');
    const [opco_form_fil, setOpco_form_fil] = useState('');

    const [opco_cancel_confirm_modal, setOpco_cancel_confirm_modal] = useState(false);


    const [validated_form_sign, setValidated_form_sign] = useState(false);
    const [validate_devis_file, setValidate_devis_file] = useState('');

    const [detach_msg, setDetach_msg] = useState('');
    const [ismounted, setIsmounted] = useState(false);
    
    const [opcos_list, setOpcos_list] = useState([]);
    const [validated_form_attach, setValidated_form_attach] = useState(false);
    const [form_attach_loading, setForm_attach_loading] = useState(false);
    const [form_attach_success, setForm_attach_success] = useState(false);
    const [form_attach_success_msg, setForm_attach_success_msg] = useState('');
    const [form_attach_error, setForm_attach_error] = useState(false);
    const [form_attach_error_msg, setForm_attach_error_msg] = useState('');
    
    const [selected_attach_session, setSelected_attach_session] = useState('');
    const [list_sessions_a_venir, setList_sessions_a_venir] = useState([]);

    const [is_signed_devis, setIs_signed_devis] = useState(false);
    const [is_signed_convention, setIs_signed_convention] = useState(false);
    const [is_signed_opco, setIs_signed_opco] = useState(false);
    const [is_cashed, setIs_cashed] = useState(false);
    const [is_cashed_opco, setIs_cashed_opco] = useState(false);

    const [is_attached_devis, setIs_attached_devis] = useState(false);
    const [can_dettacher, setCan_dettacher] = useState(false);
    const [is_canceled, setIs_canceled] = useState('');

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const getData = () => {
        const params = {
            tkn: account.info.tkn, 
            ctr: center_key,
            dev: devis_key,
            opt: ['lstcp']
        };

        setIsmounted(false);
        
        post("ctrdev", "info", processParams(params))
        .then(function (xhr) {
            if(check_is_200(xhr)) 
            {
                if('devis' in xhr.data)
                {
                    setDevis(xhr.data.devis);
                    getClient(xhr.data.devis.client || '');

                    let is_sign = xhr.data.devis.signature_file || false;
                    let is_conv = xhr.data.devis.convention_file || false;
                    let is_opco = xhr.data.devis.opco_file || false;
                    
                    setIs_signed_devis(is_sign);
                    setIs_signed_convention(is_conv);
                    setIs_signed_opco(is_opco);
                    setIs_cashed(xhr.data.devis.is_cashed || false);
                    setIs_cashed_opco(xhr.data.devis.is_cashed_opco || false);
                    setIs_canceled((xhr.data.devis.was_canceled || '') != '');
                    
                    setIsmounted(true);

                    if(is_sign)
                    {
                       let is_att = xhr.data.devis.session || false; 
                       setIs_attached_devis(is_att);
                    }
                }
                setOpcos_list(xhr.data.opcos || []);
            }
        }).catch(function(error){
        }).then(function (){
        });
    };

    useEffect(() => {
        getData();
    }, []);

    const getClient = (client_id) => {
        const params = {
            tkn: account.info.tkn, 
            ctr: center_key,
            cli: client_id
        };
        
        post("ctrcli", "info", processParams(params))
        .then(function (xhr) {
            if(check_is_200(xhr)) {
                if('client' in xhr.data)
                {
                    let client_inf = xhr.data.client;
                    setClient_email( client_inf.email || '' );
                    setClient_sms( client_inf.tel || '' );
                }
            }
        }).catch(function(error){
        }).then(function (){
        });
    };

    const getSessions = () => {
        const params = {
            tkn: account.info.tkn, 
            ctr: center_key,
            dev: devis_key,
        };
        
        post("ctrdev", "preattch", processParams(params))
        .then(function (xhr) {
            if(check_is_200(xhr)) {
                // console.log(xhr.data);
                setList_sessions_a_venir(xhr.data.sessions || []);
            }
        }).catch(function(error){
        }).then(function (){
        });
    };

    const go_update = () => {
        history.push({
            pathname:getItemRoute('center_devis_update')+center_key+'/'+devis_key,
            state:{back:true}
        });
    }

    const cancel_renvoi_devis = () => setShowModal_renvoi_devis(false);
    const renvoi_devis = () => setShowModal_renvoi_devis(true);
    const renvoi_devis_action = () => {
        const params = {
            tkn: account.info.tkn, 
            ctr: center_key,
            dev: devis_key,
            mil: renvoi_devis_email ? '1' : '0',
            sms: renvoi_devis_sms ? '1' : '0',
            mils: renvoi_devis_email_plus,
        };
        
        const toastId = toast.loading('Envoie ...');
            
        post('ctrdev', 'rnvcli', processParams(params))
        .then(function (xhr)
        {
            if('state' in xhr.data && 'msg' in xhr.data)
            {
                if(xhr.data.state)
                {
                    toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
                else
                {
                    toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
            }
            else
            {
                toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
            }
            cancel_renvoi_devis();
        });
    }

    const get_renvoi_devis_form = () => {
        return (
            <div>
                <Form>
                    <Row>
                        <FormGroup as={Col}>
                            <Form.Check disabled={!client_email.trim()} label="Via son email" value={renvoi_devis_email} onChange={e => handle_change_checkbox(e, setRenvoi_devis_email)} />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup as={Col}>
                            <Form.Check disabled={!client_sms.trim()} label="Via SMS" value={renvoi_devis_sms} onChange={e => handle_change_checkbox(e, setRenvoi_devis_sms)} />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup as={Col}>
                            <FormLabel>Emails (facultatif)</FormLabel>
                            <FormControl type='text' value={renvoi_devis_email_plus} onChange={e => handle_change_input(e, setRenvoi_devis_email_plus)} />
                        </FormGroup>
                    </Row>
                    {/* <Row>
                        <FormGroup as={Col}>
                            <FormLabel>Autre adresse email</FormLabel>
                            <FormControl type='text'/>
                        </FormGroup>
                    </Row> */}
                </Form>
            </div>
        );
    }


    
    // const cancel_reini_devis = () => setShowModal_reini_devis(false);
    // const reini_devis = () => setShowModal_reini_devis(true);
    // const reini_devis_action = () => {
    //     cancel_reini_devis();
    //     const toastId = toast.loading('Traitement');
    //     const params = processParams({
    //         tkn: account.info.tkn, 
    //         ctr: center_key,
    //         dev: devis_key,
    //         ses: selected_attach_session,
    //         msga:msg_avoir,
    //     });

    //     post('ctrdev', 'rein', params)
    //     .then(function (xhr)
    //     {
    //         if(check_is_200(xhr))
    //         {
    //             if('state' in xhr.data && 'msg' in xhr.data)
    //             {
    //                 if(xhr.data.state)
    //                 {
    //                     toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
    //                     getData();
    //                 }
    //                 else
    //                 {
    //                     toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
    //                 }
    //             }
    //             else
    //             {
    //                 toast.error(<b>Problème de traitement !</b>, { id: toastId, });
    //             }
    //         }
    //         else
    //         {
    //             toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
    //         }            
    //     }).catch(function (exp) {
    //         toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
    //     });
    // }

    const detach_devis = () => setShowModal_detach_devis(true);
    const hide_modal_detach_devis = () => {
        setShowModal_detach_devis(false);
        setDetach_msg('');
    }
    const detach_devis_form = () => {
        return (
            <div>
                <div className="alert alert-warning">
                    Êtes-vous sur de vouloir détacher le devis <b>n°{devis.num}</b> de la session <b>{devis.session_name}</b>
                </div>
                {detach_msg != '' && <div className="alert alert-danger">
                    {detach_msg}
                </div>}
            </div>
        );
    }
    const detach_devis_action = (event) => {

        setDetach_msg('');

        const params = processParams({
            tkn: account.info.tkn, 
            ctr: center_key,
            dev: devis_key,
        });
            
        const toastId = toast.loading();

        post('ctrdev', 'dttch', params)
        .then(function (xhr)
        {
            if('state' in xhr.data && 'msg' in xhr.data)
            {
                if(xhr.data.state)
                {
                    toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                    setIs_attached_devis(false);
                }
                else
                {
                    toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    setDetach_msg(xhr.data.msgp || '');
                }
            }
            else
            {
                toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
            }
            cancel_validate_devis();
        }).catch(function (exp) {
            toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
        });        
    }

    const cancel_validate_devis = () => setShowModal_validate_devis(false);
    const validate_devis = () => setShowModal_validate_devis(true);
    const validate_devis_action = (event) => {
        const form = event.currentTarget;
        progressShow = 0;
        event.preventDefault();

        if(form.checkValidity() === true){

            const params = processParams({
                tkn: account.info.tkn, 
                ctr: center_key,
                dev: devis_key,
            });
            
            params.append('sidf', validate_devis_file, validate_devis_file.name);
            
            const toastId = toast.loading(progressMessage(progressShow));
                
            post('ctrdev', 'vldfl', params, 
            {
                onUploadProgress: function (progressEvent) {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    // // console.log("onUploadProgress", totalLength);
                    if (totalLength !== null) {
                        let prg = Math.round( (progressEvent.loaded * 100) / totalLength );
                        progressShow = prg;
                        toast.loading(progressMessage(progressShow), { id: toastId, });
                    }
                }
            })
            .then(function (xhr)
            {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state && 'num' in xhr.data)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        setIs_signed_devis(true);
                        devis.numfac = xhr.data.num;
                        setDevis(devis);
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
                }
                cancel_validate_devis();
            });
        }
        setValidated_form_sign(true);
    }

    const get_validate_devis_form = () => {
        return (
            <div>
                <Form id='validate_form' noValidate validated={validated_form_sign} onSubmit={validate_devis_action}>
                    <Row>
                        <FormGroup as={Col}>
                            <FormLabel>Devis signé</FormLabel>
                            <FormControl type='file' onChange={e => handle_change_file(e, setValidate_devis_file)} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                    </Row>
                </Form>
            </div>
        );
    }

    const change_encaisser_opco = () => {
        setIs_encaissement_of_opco(true);
        setEncaissement_form_validated(false);
        setShowModal_encaissement(true);
    }

    const hideModal_encaissement = () => setShowModal_encaissement(false);
    const change_encaisser = () => {
        setIs_encaissement_of_opco(false);
        setEncaissement_form_validated(false);
        setShowModal_encaissement(true);
    }
    const encaissement_action = (event) => {
        const form = event.currentTarget;
        progressShow = 0;
        event.preventDefault();
        setEncaissement_form_validated(true);

        if(form.checkValidity() === true){
            setEncaissement_form_validated(false);
            hideModal_encaissement();

            let params = {
                            tkn: account.info.tkn, 
                            ctr: center_key,
                            dev: devis_key,
                            act: is_encaissement_of_opco ? (is_cashed_opco ? 'd' : 'e') : (is_cashed ? 'd' : 'e')
                        };
            
            if(!is_encaissement_of_opco && !is_cashed)
                params.dte = encaissement_date_form;

            else if(is_encaissement_of_opco && !is_cashed_opco)
                params.dte = encaissement_date_form;
            
            const toastId = toast.loading(progressMessage(progressShow));
               
            post('ctrdev', is_encaissement_of_opco ? 'chencop' : 'chenc', processParams(params))
            .then(function (xhr)
            {
                if(check_is_200(xhr))
                {
                    if('state' in xhr.data && 'msg' in xhr.data)
                    {
                        if(xhr.data.state)
                        {
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            if(is_encaissement_of_opco)
                                setIs_cashed_opco(!is_cashed_opco);
                            else setIs_cashed(!is_cashed);
                        }
                        else
                        {
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                    }
                    else
                    {
                        toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
                    }
                } else toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
            });
        }
        
    }
    const get_encaissement_form = () => {
        return (
            <Form id='encaissement_form' noValidate validated={encaissement_form_validated} onSubmit={encaissement_action}>
                {((is_encaissement_of_opco && is_cashed_opco) || (!is_encaissement_of_opco && is_cashed)) ?
                <div className='alert alert-danger'>
                    Êtes-vous sûr de vouloir retirer l'encaissement {is_encaissement_of_opco && <span>d'OPCO</span>} de la facture <b>N°{devis.numfac}</b>
                </div>
                :
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Date d'encaissement</FormLabel>
                        <FormControl type='date' onChange={e => handle_change_input(e, setEncaissement_date_form)} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>}
            </Form>
        );
    }


    const cancel_validate_OPCO = () => setShowModal_validate_OPCO(false);
    const validate_OPCO = () => {
        setOpco_form_validated(false);
        setShowModal_validate_OPCO(true);
    }
    const validate_OPCO_action = (event) => {
        const form = event.currentTarget;
        progressShow = 0;
        event.preventDefault();

        if(form.checkValidity() === true){

            const params = processParams({
                tkn: account.info.tkn, 
                ctr: center_key,
                dev: devis_key,
                opc_nd: opco_form_nds,
                opc_ca: opco_form_cad,
                opc_mn:opco_form_mnt,
                opc_ky:opco_form_nom
            });
            
            params.append('opc_fl', opco_form_fil, opco_form_fil.name);
            
            const toastId = toast.loading(progressMessage(progressShow));
                
            post('ctrdev', 'adopco', params, 
            {
                onUploadProgress: function (progressEvent) {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    // // console.log("onUploadProgress", totalLength);
                    if (totalLength !== null) {
                        let prg = Math.round( (progressEvent.loaded * 100) / totalLength );
                        progressShow = prg;
                        toast.loading(progressMessage(progressShow), { id: toastId, });
                    }
                }
            })
            .then(function (xhr)
            {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        cancel_validate_OPCO();
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        setIs_signed_opco(true);
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
                }
            });
        }
        setOpco_form_validated(true);
    }

    const get_validate_OPCO_form = () => {
        return (
            <div>
                <Form id='validate_opco_form' noValidate validated={opco_form_validated} onSubmit={validate_OPCO_action}>
                    <Row>
                        <FormGroup as={Col}>
                            <FormLabel>N°Dossier</FormLabel>
                            <FormControl type='text' onChange={e => handle_change_input(e, setOpco_form_nds)} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <FormLabel>Code Adhérent</FormLabel>
                            <FormControl type='text' onChange={e => handle_change_input(e, setOpco_form_cad)} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup as={Col}>
                            <FormLabel>OPCO</FormLabel>
                            <FormControl as='select' value={opco_form_nom} onChange={(e)=>handle_change_input(e, setOpco_form_nom)} required>
                                {opco_form_nom == '' && <option value=''>Choisissiez</option>}
                                {opcos_list.map((item, key) => <option key={key} value={item.key}>{item.nom}</option>)}
                            </FormControl>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <FormLabel>Montant HT</FormLabel>
                            <FormControl type='number' step={0.01} onChange={e => handle_change_input(e, setOpco_form_mnt)} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ (max deux chiffres dans la parie decimal)</FormControl.Feedback>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup as={Col}>
                            <FormLabel>Courrier d'accord d'OPCO</FormLabel>
                            <FormControl type='file' onChange={e => handle_change_file(e, setOpco_form_fil)} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                    </Row>
                </Form>
            </div>
        );
    }

    const cancel_validate_convention = () => setShowModal_validate_convention(false);
    const validate_convention = () => setShowModal_validate_convention(true);
    const validate_convention_action = (event) => {
        const form = event.currentTarget;
        progressShow = 0;
        event.preventDefault();

        if(form.checkValidity() === true){

            const params = processParams({
                tkn: account.info.tkn, 
                ctr: center_key,
                dev: devis_key,
            });
            
            params.append('sidf', validate_devis_file, validate_devis_file.name);
            
            const toastId = toast.loading(progressMessage(progressShow));
                
            post('ctrdev', 'vldcnv', params, 
            {
                onUploadProgress: function (progressEvent) {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    // // console.log("onUploadProgress", totalLength);
                    if (totalLength !== null) {
                        let prg = Math.round( (progressEvent.loaded * 100) / totalLength );
                        progressShow = prg;
                        toast.loading(progressMessage(progressShow), { id: toastId, });
                    }
                }
            })
            .then(function (xhr)
            {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        cancel_validate_convention();
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        setIs_signed_convention(true);
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
                }
                cancel_validate_devis();
            });
        }
        setValidated_form_sign(true);
    }

    const get_validate_convention_form = () => {
        return (
            <div>
                <Form id='validate_form' noValidate validated={validated_form_sign} onSubmit={validate_convention_action}>
                    <Row>
                        <FormGroup as={Col}>
                            <FormLabel>Convention signée</FormLabel>
                            <FormControl type='file' onChange={e => handle_change_file(e, setValidate_devis_file)} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                    </Row>
                </Form>
            </div>
        );
    }

    const cancel_attach_devis = () => {
        setShowModal_attach_devis(false);
        if(form_attach_loading || form_attach_success || form_attach_error)
        {
            page_refresh();
        }
    }
    const attach_devis = () => {
        if(!is_attached_devis)
        {
            getSessions();
        }
        setShowModal_attach_devis(true);
    }
     
    const attach_devis_action = (event) => {
        setForm_attach_loading(true);
        setForm_attach_success(false);
        setForm_attach_error(false);

        const form = event.currentTarget;
        progressShow = 0;
        event.preventDefault();

        if(form.checkValidity() === true){

            const params = processParams({
                tkn: account.info.tkn, 
                ctr: center_key,
                dev: devis_key,
                ses: selected_attach_session,
            });
            
            
            post('ctrdev', 'attch', params)
            .then(function (xhr)
            {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        setForm_attach_success(true);
                        setForm_attach_success_msg(xhr.data.msg);
                    }
                    else
                    {
                        setForm_attach_error(true);
                        setForm_attach_error_msg(xhr.data.msg);
                    }
                }
                else
                {
                    setForm_attach_error(true);
                    setForm_attach_error_msg("Problème d'envoi !");
                }
                // cancel_attach_devis();
            }).catch(function (exp) {
                setForm_attach_error(true);
                setForm_attach_error_msg("Problème d'envoi !");
            });
        }
        setForm_attach_loading(false);
        setValidated_form_attach(true)
    }

    const get_attach_devis_form = () => {
        return (
            <div>
                <LoadingComponent medium_size 
                        is_loading={form_attach_loading} 
                        is_success={form_attach_success}
                        msg_success={form_attach_success_msg}
                        is_error={form_attach_error}
                        msg_error={form_attach_error_msg}
                    />
                {!(form_attach_loading || form_attach_success || form_attach_error) &&
                    <Form id='attach_form' noValidate validated={validated_form_attach} onSubmit={attach_devis_action}>
                    <Row>
                        <FormGroup as={Col}>
                            <div>
                                <Form.Check
                                    type='radio'
                                    label='Nouvelle session'
                                    value='n'
                                    checked={selected_attach_session == 'n'}
                                    onChange={() => setSelected_attach_session('n')}
                                />
                            </div>
                            <div>
                                {list_sessions_a_venir.length > 0 && <div>
                                    <h4>Les sessions a venir</h4>
                                    {list_sessions_a_venir
                                    .map((session, key) => 
                                        <div key={key}>
                                            <Form.Check
                                                type='radio'
                                                label={session.titre}
                                                value={session.key}
                                                checked={selected_attach_session == session.key}
                                                onChange={() => setSelected_attach_session(session.key)}
                                            />
                                            <ul>
                                                <li>Date début : {session.date_d}</li>
                                                <li>Date fin : {session.date_f}</li>
                                                <li>Nombre de stagiaires : {session.nbr_stg} / {session.nbr_max}</li>
                                                {(session.clients.length > 0) && <li>Clients : {session.clients.join(', ')}</li>}
                                            </ul>
                                        </div>)
                                    }
                                </div>}
                            </div>
                            <RequiredItem condition={selected_attach_session} message='Veuillez saisir la session' />
                        </FormGroup>
                    </Row>
                </Form>}
            </div>
        );
    }

    const go_program = () => history.push({
        pathname: getItemRoute('center_program_info')+center_key+'/'+(devis.program || ''),
        state:{back:true}
    });
    
    const go_session = () => history.push({
        pathname: getItemRoute('center_session_desc')+center_key+'/'+(devis.session || ''),
        state:{back:true}
    });

    const go_client = () => history.push({
        pathname: getItemRoute('center_client_info')+center_key+'/'+(devis.client || ''),
        state:{back:true}
    });

    const get_Facture = (xversion = false) => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        let params = {
                        tkn: account.info.tkn,
                        ctr: account.ctr.key,
                        dev: devis_key
                    };
                    
        if(xversion) params['fxe'] = '';

        post('ctrdev', 'getfct', processParams(params))
        .then(function (xhr){
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé</b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });

        }).catch(function (err){
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const get_Facture_Opco = (xversion = false) => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        let params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            dev: devis_key
        };
                    
        if(xversion) params['fxe'] = '';

        post('ctrdev', 'getfctopc', processParams(params))
        .then(function (xhr){
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé</b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });

        }).catch(function (err){
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const get_Convention = () => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));

        post('ctrdev', 'getcnv', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            dev: devis_key
        }))
        .then(function (xhr){
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé</b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });

        }).catch(function (err){
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const get_data_cancel_info = () => {
        const params = {
            tkn:account.info.tkn, 
            ctr: center_key,
            dev: devis_key
        };
        
        post("ctrdev", "cclinf", processParams(params))
        .then(function (xhr) {
            if(check_is_200(xhr)) {
                setInfoCancel(xhr.data);
            }
        }).catch(function(error){
        }).then(function (){
        });
    };

    const show_cancel_info = () => {
        get_data_cancel_info();
        setShowModal_cancel_devis(true);
    }
    const hide_cancel_info = () => setShowModal_cancel_devis(false);

    const get_cancel_infos = () => {
        return <div>
            <div>Le {datetime_sql_to_str(infoCancel.date) || ''}</div>
            <div>Par {infoCancel.par || ''}</div>
            <div>Motif : {infoCancel.msg || ''}</div>
        </div>
    }
    
    const get_cancel_file = () => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        // console.log({
        //     tkn: account.info.tkn,
        //     ctr: account.ctr.key,
        //     dev: devis_key
        // });

        post('ctrdev', 'getavr', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            dev: devis_key
        }))
        .then(function (xhr){
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé</b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });

        }).catch(function (err){
            toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    }

    const goback = () => history.goBack();
    const go_delete = () => setDisplayConfirmationModal(true);

    const hideConfirmationModal = () => setDisplayConfirmationModal(false);

    const act_delete = () => {
        hideConfirmationModal();

        let params = processParams({
            tkn:account.info.tkn, 
            ctr:account.ctr.key,
            dev:devis.key,
            msga:msg_avoir,
        });

        const toastId = toast.loading('Envoi');

        post("ctrdev", "delete", params)
        .then(function (xhr) {
            if(check_is_200(xhr)) {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        goback();
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else 
                {
                    toast.error(<b>Problème d'envoi des données !</b>, { id: toastId, });
                }
            }else{
                toast.error(<b>Problème de suppression !</b>, { id: toastId, });
            }
            
        }).catch(function(error){
            toast.error(<b>Problème de suppression !</b>, { id: toastId, });
        });
    }


    const confirmAvoir = (action) => {
        if(devis.numfac=='') return <span>{devis.numfac}</span>;

        return <div><div className="alert alert-warning">
                {action} générera un avoir sur la facture <b>N°{devis.numfac}</b>
            </div>
            <Row>
                <FormGroup as={Col}>
                    <FormLabel>Veuillez laisser un message (facultafif)</FormLabel>
                    <FormControl as="textarea" value={msg_avoir} onChange={(e)=>{handle_change_input(e, setMsg_avoir)}} placeHolder='votre message ...'/>
                </FormGroup>
            </Row>
        </div>;
    }

    const go_download = () => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        post('ctrdev', 'getfile', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            dev: devis_key
        }))
        .then(function (xhr){
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé</b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });

        }).catch(function (err){
            toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    }

    const go_download_devis = () => {

        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        post('ctrdev', 'getfilesgd', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            dev: devis_key
        }))
        .then(function (xhr)
        {
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé </b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        
        }).catch(function (err){
            toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    }

    const hide_opco_cancel_confirm_modal = () => setOpco_cancel_confirm_modal(false);
    const show_opco_cancel_confirm_modal = () => setOpco_cancel_confirm_modal(true);

    const request_cancel_OPCO = () => {

        const toastId = toast.loading('Annulation');
        
        post('ctrdev', 'rmopco', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            dev: devis_key
        }))
        .then(function (xhr)
        {
            if(check_is_200(xhr))
            {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        hide_opco_cancel_confirm_modal();
                        setIs_signed_opco(false);
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                    else 
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else toast.error(<b>Problème d'annulation !</b>, { id: toastId, });
            }
            else toast.error(<b>Problème d'annulation !</b>, { id: toastId, });
        
        }).catch(function (err){
            toast.error(<b>Problème d'annulation !</b>, { id: toastId, });
        });
    }

    const hide_conv_cancel_confirm_modal = () => setConv_cancel_confirm_modal(false);
    const show_conv_cancel_confirm_modal = () => setConv_cancel_confirm_modal(true);

    const request_cancel_convention = () => {

        const toastId = toast.loading('Annulation');
        
        post('ctrdev', 'rmvcnv', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            dev: devis_key
        }))
        .then(function (xhr)
        {
            if(check_is_200(xhr))
            {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        hide_conv_cancel_confirm_modal();
                        setIs_signed_convention(false);
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                    else 
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else toast.error(<b>Problème d'annulation !</b>, { id: toastId, });
            }
            else toast.error(<b>Problème d'annulation !</b>, { id: toastId, });
        
        }).catch(function (err){
            toast.error(<b>Problème d'annulation !</b>, { id: toastId, });
        });
    }

    const go_download_OPCO = () => {

        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        post('ctrdev', 'getfileopc', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            dev: devis_key
        }))
        .then(function (xhr)
        {
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé </b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        
        }).catch(function (err){
            toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    }

    const go_download_convention = () => {

        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        post('ctrdev', 'getfilecnv', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            dev: devis_key
        }))
        .then(function (xhr)
        {
            if(check_is_200(xhr))
            {
                // console.log(xhr.data);
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé </b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        
        }).catch(function (err){
            toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    }

    return ( 
        <div>
            <Row className='headerContentPart' >
                <div className='flex_between'>
                    <div className='title'>Devis N°{devis.num}</div>
                
                    <div>
                        {ismounted && <div>
                            {is_canceled===true && <div className='width_as_content'>
                                {is_signed_devis && <div className='button_stand' onClick={() => go_download_devis()} data-tooltip='Devis signé'>
                                    <FontAwesomeIcon icon={faFileAlt}/>
                                </div>}
                                {is_signed_convention && <div className='button_stand' onClick={() => go_download_convention()} data-tooltip='Convention signée'>
                                    <FontAwesomeIcon  className='icon_purple' icon={faFile}/>
                                </div>}
                                <div className='button_stand' onClick={() => show_cancel_info()} data-tooltip='info'>
                                    Devis annulé
                                </div>
                            </div>}
                            {is_canceled===false && <div className='width_as_content'>
                                <Dropdown className='button_stand' data-tooltip="Devis">
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" style={{border:0, margin:0, padding:0}}>
                                        <FontAwesomeIcon icon={faFileAlt}/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => go_download()}>Devis</Dropdown.Item>
                                        {is_signed_devis && <Dropdown.Item onClick={() => go_download_devis()}>Devis signé</Dropdown.Item>}
                                        <Dropdown.Item onClick={() => validate_devis()}>Enregistrer un devis signé</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                
                                {!is_signed_devis && <div className='width_as_content'>
                                    <div className='button_stand button_stand_relative' onClick={() => renvoi_devis()}  data-tooltip="Renvoyez le devis">
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                    </div>
                                </div>}
                                {(is_signed_devis && !is_attached_devis && is_canceled==false) && <div className='width_as_content'>
                                    <div className='button_stand  button_stand_relative' data-tooltip="Attacher le devis à une session" onClick={() => attach_devis()}>
                                        <FontAwesomeIcon icon={faLink}/>
                                    </div>
                                    {/* <div className='button_stand button_bg' onClick={() => reini_devis()} style={{background:'#F78888'}}>Réinitialiser le devis</div> */}                                
                                </div>}
                                {(is_signed_devis) && <div className='width_as_content'>
                                    {/* <div className='button_stand  button_stand_relative'  data-tooltip='Facture' onClick={() => get_Facture()}>
                                        <FontAwesomeIcon icon={faFile}/>
                                    </div> */}

                                    <Dropdown className='button_stand  button_stand_relative' data-tooltip='Factures'>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{border:0, margin:0, padding:0}}>
                                            <FontAwesomeIcon className='icon_green' icon={faFile}/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => get_Facture()}>Facture</Dropdown.Item>
                                            <Dropdown.Item onClick={() => get_Facture(true)}>Facture X</Dropdown.Item>
                                            <Dropdown.Item onClick={() => change_encaisser()}>{is_cashed ? "Annuler l'encaissement de la facture" : 'Encaisser la facture' }</Dropdown.Item>
                                            <Dropdown.Divider />
                                            {is_signed_opco &&  <Dropdown.Item onClick={() => get_Facture_Opco()}>Facture OPCO</Dropdown.Item>}
                                            {is_signed_opco &&  <Dropdown.Item onClick={() => get_Facture_Opco(true)}>Facture OPCO X</Dropdown.Item>}
                                            {is_signed_opco &&  <Dropdown.Item onClick={() => go_download_OPCO()}>La prise en charge OPCO</Dropdown.Item>}
                                            <Dropdown.Item onClick={() => validate_OPCO()}>{is_signed_opco ? 'Modifier' : 'Enregistrer'} une prise en charge OPCO</Dropdown.Item>
                                            {is_signed_opco &&  <Dropdown.Item onClick={() => show_opco_cancel_confirm_modal()}>Retirer la prise en charge OPCO</Dropdown.Item>}
                                            {is_signed_opco &&  <Dropdown.Item onClick={() => change_encaisser_opco()}>{is_cashed_opco ? "Annuler l'encaissement de la facture OPCO" : 'Encaisser la facture OPCO' }</Dropdown.Item>}
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown className='button_stand  button_stand_relative' data-tooltip='Convention'>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{border:0, margin:0, padding:0}}>
                                                <FontAwesomeIcon className='icon_purple' icon={faFile}/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => get_Convention()}>Convention</Dropdown.Item>
                                            {is_signed_convention &&  <Dropdown.Item onClick={() => go_download_convention()}>Convention signée</Dropdown.Item>}
                                            <Dropdown.Item onClick={() => validate_convention()}>Enregistrer une convention signée</Dropdown.Item>
                                            {is_signed_convention &&  <Dropdown.Item onClick={() => show_conv_cancel_confirm_modal()}>Retirer la convention signée</Dropdown.Item>}
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    {is_attached_devis && <div className='button_stand  button_stand_relative' data-tooltip="Détacher le devis de la session" onClick={() => detach_devis()}>
                                        <FontAwesomeIcon icon={faUnlink}/>
                                    </div>}
                                </div>}
                                {(!is_signed_devis && is_canceled==false) && <div className='button_stand  button_stand_relative' onClick={() => go_update()} data-tooltip='Modifier'>
                                    <FontAwesomeIcon icon={faCog}/>
                                </div>}
                                {is_canceled==false && <div className='button_stand  button_stand_relative' onClick={() => go_delete()} data-tooltip='Annuler'>
                                    <FontAwesomeIcon icon={faTimes}/>
                                </div>}
                            </div>}
                        </div>}
                    </div>
                </div>
            </Row>

            <div>

        
        {devis.numfac!='' && <Row  className='show_info_as_form'>
            <FormGroup as={Col}>
                <div className='profil_show_item'>
                    <div className='profil_show_item_title'>N° Devis</div>
                    <div className='profil_show_item_value clickable'>{devis.num}</div>
                </div>
            </FormGroup>
            <FormGroup as={Col}>
                <div className='profil_show_item'>
                    <div className='profil_show_item_title'>N° Facture</div>
                    <div className='profil_show_item_value clickable flex_between'>
                        <div>{devis.numfac}</div>
                        {is_cashed && <div style={{background:'#C6F7CC', padding:'0px 7px', borderRadius:'5px'}}>Encaissée</div>}
                    </div>
                </div>
            </FormGroup>
            {devis.numavr!='' && <FormGroup as={Col}>
                <div className='profil_show_item'>
                    <div className='profil_show_item_title'>N° Avoir</div>
                    <div className='profil_show_item_value clickable'>{devis.numavr}</div>
                </div>
            </FormGroup>}
        </Row>}
        {is_signed_opco && <Row  className='show_info_as_form'>
            <FormGroup as={Col}>
                <div className='profil_show_item'>
                    <div className='profil_show_item_title'>Opérateur</div>
                    <div className='profil_show_item_value clickable'>{
                        opcos_list.map((item) => item.key == devis.opco_key ? item.nom : '')
                    }</div>
                </div>
            </FormGroup>
            <FormGroup as={Col}>
                <div className='profil_show_item'>
                    <div className='profil_show_item_title'>N° Dossier</div>
                    <div className='profil_show_item_value clickable flex_between'>
                        <div>{devis.opco_dossier}</div>
                        {is_cashed_opco && <div style={{background:'#C6F7CC', padding:'0px 7px', borderRadius:'5px'}}>Encaissé</div>}
                    </div>
                </div>
            </FormGroup>
            <FormGroup as={Col}>
                <div className='profil_show_item'>
                    <div className='profil_show_item_title'>Montant Pris en Charge HT</div>
                    <div className='profil_show_item_value clickable'>{devis.opco_montant}</div>
                </div>
            </FormGroup>
        </Row>}
        <Row className='show_info_as_form'>
            <FormGroup as={Col}>
                <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Programme</div>
                            <div className='profil_show_item_value'>
                                <FontAwesomeIcon className="clickable" icon={faExternalLinkAlt} style={{marginRight:10}}  onClick={()=>go_program()}/>
                                {devis.program_name}
                            </div>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Client</div>
                            {devis.client && <div className='profil_show_item_value'>
                                <FontAwesomeIcon className="clickable" icon={faExternalLinkAlt} style={{marginRight:10}}  onClick={()=>go_client()}/>
                                {devis.client_name}
                            </div>}
                        </div>
                    </FormGroup>
                </Row>
                {devis.session && <Row><FormGroup as={Col}>
                    <div className='profil_show_item'>
                        <div className='profil_show_item_title'>Session</div>
                        <div className='profil_show_item_value'>
                            <FontAwesomeIcon className="clickable" icon={faExternalLinkAlt} style={{marginRight:10}}  onClick={()=>go_session()}/> 
                            {devis.session_name}
                        </div>
                    </div>
                </FormGroup></Row>}

                <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Date de création</div>
                            <div className='profil_show_item_value'>{datetime_sql_to_str(devis.date_start)}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Prix H.T. / heure</div>
                            <div className='profil_show_item_value'>{devis.prix}</div>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Nombre d'heure / stagiaire</div>
                            <div className='profil_show_item_value'>{devis.nbr_heures}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Durée de validité du devis</div>
                            <div className='profil_show_item_value'>{devis.days_val}</div>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Total HT</div>
                            <div className='profil_show_item_value'>{(devis.nbr_heures * devis.prix * devis.nbr_stagiaires)}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>TVA 20.0 % </div>
                            <div className='profil_show_item_value'>{(devis.nbr_heures * devis.prix * devis.nbr_stagiaires) * 0.2}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Total TTC</div>
                            <div className='profil_show_item_value'>{(devis.nbr_heures * devis.prix * devis.nbr_stagiaires) * 1.2}</div>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col} xl={3}>
                        {is_remise && <div>
                                <div className='profil_show_item'>
                                    <div className='profil_show_item_title'>Remise</div>
                                    <div className='profil_show_item_value'>{devis.remise}</div>
                                </div>
                            </div>}
                    </FormGroup>
                </Row>
            </FormGroup>
            
            <FormGroup as={Col}>
                
            <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item_title'>
                            {devis.nbr_stagiaires > 1 && 'Noms des '+devis.nbr_stagiaires+' stagiaires'}
                            {devis.nbr_stagiaires == 1 && 'Nom du stagiaire'}
                        </div>
                        <div className='profil_show_item_value'>
                            <ol>
                                {devis.list_stagiaires && devis.list_stagiaires.map((nom, key) => <li key={key}>{nom}</li>)}
                            </ol>
                        </div>
                    </FormGroup>
                </Row>
            </FormGroup>
        </Row>
        </div>
        {is_canceled == false && <div>
            <ConfirmationModel
                    title='Envoi du devis'
                    showModal={showModal_renvoi_devis}
                    hideModal={cancel_renvoi_devis}
                    confirmModal={renvoi_devis_action}
                    actionMessage='Renvoi'
                    complement={get_renvoi_devis_form}
                />
            
            <ConfirmationModel
                    title='Valider le devis'
                    showModal={showModal_validate_devis}
                    hideModal={cancel_validate_devis}
                    submitForm='validate_form'
                    actionMessage='Valider'
                    complement={get_validate_devis_form}
                />

            
            <ConfirmationModel
                    title='Valider la convention'
                    showModal={showModal_validate_convention}
                    hideModal={cancel_validate_convention}
                    submitForm='validate_form'
                    actionMessage='Valider'
                    complement={get_validate_convention_form}
                />

            <ConfirmationModel
                    title='Enregistrer une prise en charge OPCO'
                    showModal={showModal_validate_OPCO}
                    hideModal={cancel_validate_OPCO}
                    submitForm='validate_opco_form'
                    actionMessage='Valider'
                    complement={get_validate_OPCO_form}
                />

            <ConfirmationModel
                    title={'Encaissement de la facture '+(is_encaissement_of_opco ? "d'OPCO" : '')}
                    showModal={showModal_encaissement}
                    hideModal={hideModal_encaissement}
                    submitForm='encaissement_form'
                    actionMessage={((is_encaissement_of_opco && is_cashed_opco) || (!is_encaissement_of_opco && is_cashed)) ? 'Je confirme' : 'Enregistrer'}
                    complement={get_encaissement_form}
                />

            <DeleteConfirmation 
                    showModal={opco_cancel_confirm_modal} 
                    titleModal="Confirmation d'annulation de la prise en charge d'OPCO"
                    confirmModal={request_cancel_OPCO}
                    hideModal={hide_opco_cancel_confirm_modal} 
                    message={<div>Êtes-vous sûr de vouloir annuler la prise en charge d'OPCO du devis <b>N°{devis.num}</b> ?</div>}
                    confirmMessage='Oui, je confirme'
                    cancelMessage='Non'
                />

            <DeleteConfirmation 
                    showModal={conv_cancel_confirm_modal} 
                    titleModal="Confirmation de la suppression de la convention signée"
                    confirmModal={request_cancel_convention}
                    hideModal={hide_conv_cancel_confirm_modal} 
                    message={<div>Êtes-vous sûr de vouloir retirer la convention signée du devis <b>N°{devis.num}</b> ?</div>}
                    confirmMessage='Oui, je confirme'
                    cancelMessage='Non'
                />

            <ConfirmationModel
                    title='Attacher le devis'
                    showModal={showModal_attach_devis}
                    hideModal={cancel_attach_devis}
                    submitForm='attach_form'
                    actionMessage='Attacher'
                    hide_actionButton={form_attach_loading || form_attach_error || form_attach_success}
                    cancelMessage={(form_attach_loading || form_attach_error || form_attach_success) && 'OK'}
                    complement={get_attach_devis_form}
                />

            {/* <ConfirmationModel
                    title='Réinitialiser le devis'
                    showModal={showModal_reini_devis}
                    hideModal={cancel_reini_devis}
                    confirmModal={reini_devis_action}
                    actionMessage='Oui, je confirme'
                    actionClass='danger'
                    message={<div>Êtes-vous sur de vouloir réinitialiser le devis <b>N°{devis.num}</b> ?</div>}
                    complement={() => { return confirmAvoir('La réinitialisation')}}
                /> */}

            <DeleteConfirmation 
                    showModal={displayConfirmationModal} 
                    titleModal="Confirmation d'annulation"
                    confirmModal={act_delete}
                    hideModal={hideConfirmationModal} 
                    message={<div>Êtes-vous sûr de vouloir annuler le devis <b>N°{devis.num}</b> ?</div>}
                    messageComplement={confirmAvoir("Non")}
                    confirmMessage='Oui, je confirme'
                />
        </div>}
        {is_canceled == true && <InfoPopUp 
                    showModal={showModal_cancel_devis} 
                    hideModal={hide_cancel_info}
                    titleModal="Informations d'annulation"
                    okText="Imprimer"
                    cancelText="Fermer"
                    messageFunction={get_cancel_infos}
                    okAction={get_cancel_file}
                />}
        {is_attached_devis &&  <ConfirmationModel
                                    title='Détacher le devis'
                                    showModal={showModal_detach_devis}
                                    hideModal={hide_modal_detach_devis}
                                    confirmModal = {detach_devis_action}
                                    actionMessage = 'Oui, je confirme'
                                    complement={detach_devis_form}
                                />}
        </div>
     );
}

export default Devis_Fiche;