import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { check_is_200, post, processParams } from '../../adapters/xhr';
import { getItemRoute } from '../../Routes';

const Sessions_Smallinfo = ({ account, allSession, goSession, forFormer, forSecretaire, forTrainner }) => {

    const history = useHistory();

    const [all_list, setAll_list] = useState([]);
    const [all_nbr, setAll_nbr] = useState(0);

    const [frm_list, setFrm_list] = useState([]);
    const [frm_nbr, setFrm_nbr] = useState(0);

    const [sec_list, setSec_list] = useState([]);
    const [sec_nbr, setSec_nbr] = useState([]);

    const [trn_list, setTrn_list] = useState([]);
    const [trn_nbr, setTrn_nbr] = useState(0);

    const getData = () => {
        const params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            'gs' : goSession,
            'fa' : allSession, 
            'af' : forFormer, 
            'as' : forSecretaire, 
            'at' : forTrainner
        });

        post("session", "listi", params)
        .then(function(xhr)
        {
            if(check_is_200(xhr))
            {
                if(allSession && 'all' in xhr.data)
                {
                    check_redirect(xhr.data.all.list || []);
                    setAll_list(xhr.data.all.list || []);
                    setAll_nbr(xhr.data.all.nbr || 0);
                }
                
                if(forFormer && 'frm' in xhr.data)
                {
                    check_redirect(xhr.data.frm.list || []);
                    setFrm_list(xhr.data.frm.list || []);
                    setFrm_nbr(xhr.data.frm.nbr || 0);
                }

                if(forSecretaire && 'sec' in xhr.data)
                {
                    check_redirect(xhr.data.sec.list || []);
                    setSec_list(xhr.data.sec.list || []);
                    setSec_nbr(xhr.data.sec.nbr || 0);
                }

                if(forTrainner && 'trn' in xhr.data)
                {
                    check_redirect(xhr.data.trn.list || []);
                    setTrn_list(xhr.data.trn.list || []);
                    setTrn_nbr(xhr.data.trn.nbr || 0);
                }
            }
        }).catch(function (err){
            
        });
    }

    const check_redirect = (lst_ses) => {
        for( let ssn of lst_ses )
            if('goto' in ssn) get_info(ssn.key); 
    }

    useEffect(() => {
        getData();
    }, []);

    const get_more = () => {
        history.push({
            pathname:getItemRoute('center_sessions'),
            state:{back:false}
        });
    }

    const get_more_as_former = () => {
        history.push({
            pathname:getItemRoute('sessions_mine'),
            state:{back:false}
        });
    }

    const get_more_as_secretary = () => {
        history.push({
            pathname:getItemRoute('secretary_sessions'),
            state:{back:false}
        });
    }

    const get_more_as_trainee = () => {
        history.push({
            pathname:getItemRoute('trainee_sessions'),
            state:{back:false}
        });
    }

    

    const get_info = (item_key) => {
        history.push({
            pathname:getItemRoute('center_session_fiche')+item_key,
            state:{back:true}
        });
    }

    

    if(allSession)
        return (!(all_nbr>0) ? '' : <div className='infob_item'>
                    <div className='flex_between'>
                        <div className='infob_title'>Sessions</div>
                        <div className='infob_nbr'>{all_nbr}</div>
                    </div>
                    <div className='infob_content'>
                        {all_list.map((item, key) => {
                            return ( key>2 ? null : <div key={key} className='infob_link clickable' onClick={(e)=>get_info(item.key)}>{item.titre}</div>);
                        })}
                    </div>
                    <div className='infob_footer clickable' onClick={()=>get_more()}>Voir tout</div>
                </div>);

    else if(forFormer)
        return (!(frm_nbr>0) ? '' : <div className='infob_item'>
                        <div className='flex_between'>
                            <div className='infob_title'>Sessions Former</div>
                            <div className='infob_nbr'>{frm_nbr}</div>
                        </div>
                        <div className='infob_content'>
                            {frm_list.map((item, key) => {
                                return ( key>2 ? null : <div key={key} className='infob_link clickable' onClick={(e)=>get_info(item.key)}>{item.titre}</div>);
                            })}
                        </div>
                        <div className='infob_footer clickable' onClick={()=>get_more_as_former()}>Voir tout</div>
                    </div>);
                
    else if(forSecretaire)
        return (!(sec_nbr>0) ? '' : <div className='infob_item'>
                        <div className='flex_between'>
                            <div className='infob_title'>Sessions Secretaire</div>
                            <div className='infob_nbr'>{sec_nbr}</div>
                        </div>
                        <div className='infob_content'>
                            {sec_list.map((item, key) => {
                                return ( key>2 ? null : <div key={key} className='infob_link clickable' onClick={(e)=>get_info(item.key)}>{item.titre}</div>);
                            })}
                        </div>
                        <div className='infob_footer clickable' onClick={()=>get_more_as_secretary()}>Voir tout</div>
                    </div>);
                
    else if(forTrainner)
        return (!(trn_nbr>0) ? '' : <div className='infob_item'>
                        <div className='flex_between'>
                            <div className='infob_title'>Mes sessions, stagiaire</div>
                            <div className='infob_nbr'>{trn_nbr}</div>
                        </div>
                        <div className='infob_content'>
                            {trn_list.map((item, key) => {
                                return ( key>2 ? null : <div key={key} className='infob_link clickable' onClick={(e)=>get_info(item.key)}>{item.titre}</div>);
                            })}
                        </div>
                        <div className='infob_footer clickable' onClick={()=>get_more_as_trainee()}>Voir tout</div>
                    </div>);
    
    else return '';
}

export default Sessions_Smallinfo;