import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { check_is_200, downloadBlob, popup_show_file, post, processParams, progressMessage } from "../../../../adapters/xhr";
import InfoPopUp from "../../../../tools/components/popups/InfoPopUp";
import toast, { Toaster } from "react-hot-toast";
import { Col, Container, Row, FormGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faComment, faCommentAlt, faEnvelope, faTimes } from "@fortawesome/free-solid-svg-icons";
import { datetime_sql_to_str, date_sql_to_str } from "../../../../tools/Dates";
import DeleteConfirmation from "../../../../tools/components/popups/DeleteConfirmation";

const Session_Presences = (props) => {

    let session_name = '';
    if(('location' in props) && ('state' in props.location) && ('sesname' in props.location.state))
        session_name = props.location.state.sesname;

    let progressShow = 0;
    const account = useSelector((state) => state.account);
    const history = useHistory();
    const { session_key } = useParams();
    
    const [session, setSession] = useState({titre:''});
    const [events, setEvents] = useState([]);
    const [selectedList, setSelectedList] = useState({});
    const [displayEventModal, setDisplayEventModal] = useState(false);
    
    const [comments_modal, setComments_modal] = useState(false);
    const [comments, setComments] = useState([]);

    const [delete_modal, setDelete_modal] = useState(false);
    const [comment_to_remove, setComment_to_remove] = useState({});

    const [lst_info_convc, setLst_info_convc] = useState([]);
    const [lst_info_hours, setLst_info_hours] = useState([]);

    const getData = () => {
        post('presence', 'seslst', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            opt: ['infs']
        })).then(function (xhr){
            if(check_is_200(xhr)){
                // console.log(xhr.data);
                
                setSession(xhr.data.session || []);

                let presences = xhr.data.presences || [];
                // console.log(presences);

                let events_data = [];
                if('lst' in presences){
                    presences.lst.map((item, key) => {
                        let prcv = item.hrs.reduce((a, b) => { return {prc:a.prc + b.prc}; }, {prc:0}).prc / (item.hrs.length || 1);
                        let hvcv = item.hrs.reduce((a, b) => { return {havec:a.havec + b.havec}; }, {havec:0}).havec;
                        events_data.push({
                            list: item,
                            date: item.dt,
                            hrs: item.hrs,
                            prc: prcv,
                            hvc: hvcv,
                            color:(prcv == 0 ? '#ECECEC' : (prcv < 99 ? "#90CCF4" : "#D5F587"))
                          });
                    });
                    setEvents(events_data);
                }
            }
        }).catch(function (err){
            // console.log(err);
        });
    }

    useEffect(() => {
        getData();
    }, []);


    const handleEventClick = (presence) => 
    {
        setSelectedList(presence);
        setDisplayEventModal(true);
    }

    useEffect(() => 
    {
        if('date' in selectedList)
        {
            getListData();
        }
    }, [selectedList]);

    const hideDisplayModal = () => 
    {
        setDisplayEventModal(false);
    }

    const getListData = () => {
        post('presence', 'seslstitm', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            dat: selectedList.date
        })).then(function (xhr){
            if(check_is_200(xhr)){
                // console.log(xhr.data);
                
                setLst_info_convc(xhr.data.c || []);
                setLst_info_hours(xhr.data.h || []);
                // if('p' in xhr.data) setpresenceListInfo_p({l:xhr.data.p, n:xhr.data.p.length});
                // if('a' in xhr.data) setpresenceListInfo_a({l:xhr.data.a, n:xhr.data.a.length});                
            }
        }).catch(function (err){
            // console.log(err);
        });
    }

    const getSelectedEventFiche = () => {
        if(displayEventModal){
            // console.log(lst_info_hours);
            if('list' in selectedList){
                return (
                    <div>
                        <b>Date: </b>{date_sql_to_str(selectedList.list.dt)}<br/>
                        <table className='table_ini'>
                            <thead>
                                <tr>
                                    <th>Convoqué{lst_info_convc.length>0 && 's'}</th>
                                    {lst_info_hours.map((itm, key)=>
                                        <th key={key} className='text_align_center'>{itm.d} à {itm.f}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {lst_info_convc.map((itm, key)=><tr key={key}>
                                        <td>{itm.nom}</td>
                                        {lst_info_hours.map((itmh, keyc)=>{
                                            return <td key={keyc} className='text_align_center'>
                                                {(itmh.l.length > 0 && itmh.l.includes(itm.key)) &&
                                                <FontAwesomeIcon icon={faCheckCircle}/>}
                                            </td>}
                                        )}
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>
                );
            }else{
                return <div></div>;
            }
        }
    }

    const getSelectedEventFiche_grp = (title, nbr, list) => {
        if(nbr == 0) return <span/>
        return (<div>
                {title} {nbr}
                {list.map((item, key) => (
                    <div key={key}>
                         - {item.nom}
                    </div>
                ))}
            </div>);
    }
    const DownloadListFile = () => {
        progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            dat: selectedList.list.dt
        };

        post('presence', 'download', processParams(params))
        .then(xhr => {
            if('file' in xhr.data)
            {
                popup_show_file(xhr.data.file);
                toast.success(<b>Fichier téléchargé</b>, { id: toastId, });
            }
            else
            {
                toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
            }
        }).catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const sendNotificationOfDate = (date, seances) => {
        const toastId = toast.loading("Notifier les stagiaires du "+date_sql_to_str(date));
        let params = { 
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            dat: date,
            sea: seances
        };

        post('trnsgn', 'ntfbdt', processParams(params))
        .then(xhr => {
            if(check_is_200(xhr))
            {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                    else 
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
                else toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
            }
            else toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
        })
        .catch(function (error){
            toast.error(<b>Problème d'envoi!</b>, { id: toastId, });
        });
    }

    const donwload_all = () => {
        progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
        };

        console.log(params);

        post('presence', 'ses', processParams(params), {
            // responseType: 'blob',
            // onDownloadProgress(progressEvent) {
            //     progressShow = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            //     toast.loading(progressMessage(progressShow), { id: toastId, });
            // },
        })
        .then(xhr => {
            if('file' in xhr.data)
            {
                popup_show_file(xhr.data.file);
                toast.success(<b>Fichier téléchargé</b>, { id: toastId, });
            }
            else
            {
                toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
            }
            // downloadBlob(response.data, "Émargement numérique générale.pdf");
        }).catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }


    const show_list = () => {
        return (<Container>
            {/* <Row>
                <Col md={4} className='div_presence_item' onClick={(e)=>donwload_all()}>
                    <div>
                        Feuille d'émargement global
                    </div>
                </Col>
            </Row>
            <h3>Par date</h3> */}
            <Row>
            {events.map((item, key) => 
                <Col md={4} className='div_presence_item' key={key}>
                    
                    <div style={{background:item.color}} className='flex_between'>
                        <div onClick={(e)=>handleEventClick(item)}>{date_sql_to_str(item.date)}</div>
                        <div onClick={(e)=>handleEventClick(item)}>{item.hrs.length > 1 ? item.hrs.length+' séances' : '1 séance'}</div>
                        <div onClick={(e)=>handleEventClick(item)}>{<b>{item.prc} %</b>}</div>
                        {(item.hvc > 0) && <div onClick={(e)=>handleEventClick(item)}><FontAwesomeIcon style={{float:'right', margin:5}} icon={faCommentAlt} /></div>}
                        {item.prc != 100 && <div    className="width_as_content " 
                                                    style={{backgroundColor:'#FFF', padding:'0px 10px'}}
                                                    data-tooltip='Envoyer un email aux stagiaires'
                                                    onClick={(e)=>sendNotificationOfDate(item.date, item.hrs.map((e) => e.key))}
                                                    >
                                                        <FontAwesomeIcon icon={faEnvelope}/>
                                                    </div>}
                    </div>
                </Col>
            )}
            </Row>
        </Container>)
    }
    
    const goback = () => {
        history.goBack();
    }

    const show_comments_modal = () => {
        getComments();
        setDisplayEventModal(false);
        setComments_modal(true);
        
    }
    const hide_comments_modal = () => setComments_modal(false);

    const getComments = () => {
        
        post('presence', 'cmlst', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            dat: selectedList.date,
        })).then(xhr => {
            if(check_is_200(xhr))
            {
                setComments(xhr.data.list || []);
            }
        }).catch(function (err){
            // console.log(err);
        });
    }
    
    const getCommentsFiche = () => {
        
        return (
            <div>
                {comments.map((com, key)=> <div key={key} className='presence_com'>
                    <div className='presence_com_title'>
                        Le {datetime_sql_to_str(com.date)}
                        <FontAwesomeIcon icon={faTimes} onClick={()=>go_removeComment(com)}/>
                    </div>
                    <div className='presence_com_content'>{com.msg}</div>
                </div>)}
            </div>
        );
    }

    const downloadListComments = () => {
        progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            dat: selectedList.list.dt
        };

        // console.log(params);
        
        post('presence', 'downloadcom', processParams(params))
        .then(xhr => {
            if('file' in xhr.data)
            {
                popup_show_file(xhr.data.file);
                toast.success(<b>Fichier téléchargé</b>, { id: toastId, });
            }
            else
            {
                toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
            }
        }).catch(function (err){
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }


    const go_removeComment = (com) => {
        setComment_to_remove(com);
        show_delete_modal();
    }
    const show_delete_modal = () =>  setDelete_modal(true);
    const hide_delete_modal = () =>  setDelete_modal(false);

    const removeComment = () => {
        setDelete_modal(false);
        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            dat: selectedList.list.dt,
            com: comment_to_remove.key,
        };
        
        post('presence', 'rmcm', processParams(params))
        .then(xhr => {
            if(check_is_200(xhr))
            {
                console.log(xhr.data);
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>);
                        setComments(comments.filter(item=>item.key!=comment_to_remove.key));
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>);
                    }
                }
                else
                    toast.error(<b>Problème de suppression !</b>);
            }
            else
                toast.error(<b>Problème de suppression !</b>);
            
        }).catch(function (err){
            toast.error(<b>Problème de suppression !</b>);
        });
    }
    
    return ( <div>
        <Row className='headerContentPart' >
            <FormGroup as={Col} xl={9} className='title'>
                Émargement numérique
                {(session_name != '') && <div className='sub_title'>{session_name}</div>}
            </FormGroup>
            <FormGroup as={Col}>
                <div className=''>
                    <div className='button_stand' onClick={() => donwload_all()} style={{backgroundColor:'#F3D250'}}>
                        Feuille d'émargement global
                    </div>
                </div>
            </FormGroup>
        </Row>
        <br/>
        {show_list()}
        <InfoPopUp 
                    showModal={displayEventModal} 
                    hideModal={hideDisplayModal}
                    titleModal='Émargement numérique'
                    okText="Imprimer"
                    cancelText="Fermer"
                    secondText={selectedList.hvc>0 && "Commentaires"}
                    messageFunction={getSelectedEventFiche}
                    okAction={DownloadListFile}
                    secondAction={show_comments_modal}
                    params={selectedList}
                />

        <InfoPopUp 
                    showModal={comments_modal} 
                    hideModal={hide_comments_modal}
                    titleModal='Commentaire(s)'
                    okText="Imprimer"
                    cancelText="Fermer"
                    messageFunction={getCommentsFiche}
                    okAction={downloadListComments}
                    secondAction={show_comments_modal}
                    params={selectedList}
                />

        <DeleteConfirmation 
                    showModal={delete_modal} 
                    confirmModal={removeComment}
                    hideModal={hide_delete_modal} 
                    message={"Êtes-vous sûr de vouloir supprimer le commentaire daté du \""+datetime_sql_to_str(comment_to_remove.date)+"\" ?"}
                />
        {/* <Toaster
                    position="bottom-right"
                    reverseOrder={false}        /> */}
    </div> );
}
 
export default Session_Presences;