import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { check_is_200, post, processParams } from "../../../../adapters/xhr";
import { arraysEqual } from "../../../../tools/Arrays";
import { show_paragraph } from "../../../../tools/HInput";
import SesEnq_Form from "./Form/SesEnq_Form";

const Survey_Program_Reply_Form = () => {

    const account = useSelector((state) => state.account);
    const history = useHistory();

    const { session_key, survey_key } = useParams();

    const [titre, settitre] = useState('');
    const [clme, setclme] = useState('');
    const [survey, setSurvey] = useState({});
    const [session, setSession] = useState({});
    const [questions, setquestions] = useState([]);
    const [reponses, setReponses] = useState({});
    const [is_ended, setIs_ended] = useState(false);
    
    const getSurveyForm = () => {
        post('survey', 'form', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            srv: survey_key,
            opt: ['fetchq', 'upd']
        }))
        .then(function (xhr)
        {
            if(check_is_200(xhr)) 
            {

                if('state' in xhr.data && xhr.data.state && ('survey' in xhr.data))
                {
                    const srv = xhr.data.survey;
                    
                    setSession(xhr.data.ses || {});
                    setSurvey(srv);
                    settitre(srv.titre);
                    setclme(srv.clme);
                    setquestions(srv.questions);
                    // console.log(srv.questions);
                    setIs_ended(srv.is_ended);
                    
                    let reps = {};
                    for(let qst of srv.questions)
                    {
                        let default_val = [];
                        
                        if(qst.choix.length ==0)
                        {
                            if(qst.val)
                                default_val.push(qst.val);
                        } else
                        for(let ch of qst.choix)
                        {
                            if(ch.selected)
                            {
                                default_val.push(ch.key);
                            }
                        }
                        reps[qst.key] = default_val;
                    }
                    setReponses(reps);
                }
                else
                {

                }
            }
        });
    }

    useEffect(() => {
        getSurveyForm();
    }, []);

    const goback = () => {
        history.goBack();
    }

    const sendForm = (vldt) => {
        let resp_key = Object.keys(reponses);

        let rps = [];

        for(let qst_k of resp_key){
            let repsi = [];


            rps.push({q: qst_k, r: reponses[qst_k]});
        }

        const toastId = toast.loading();

        post('surveyr', 'save', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            srv: survey_key,
            elc: clme,
            rps, vldt
        }))
        .then(function (xhr){
            if(check_is_200(xhr)) 
            {
                if(('state' in xhr.data) && ('msg' in xhr.data)){
                    if(xhr.data.state){
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        goback();
                        
                    }else{
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });        
                    }
                }else{
                    toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                }
            }else{
                toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
            }
        });
    }

    const show_result = () => {
        
        let question_show = [];
        let score_all = 0;
        let with_validation = false;
        
        questions.map((qst, key) => {
            let selected_reps = [];
            let correct_reps = [];
            let exist_just = false;
            let mention = '';

            if(qst.choix.length == 0)
            {
                selected_reps.push(show_paragraph(qst.val));
            }
            else
            for(let chx of qst.choix){
                if(chx.selected) selected_reps.push(chx.titre);
                if('just' in chx){
                    exist_just = exist_just || true;
                    if(chx.just) correct_reps.push(chx.titre);
                }
            }

            with_validation = with_validation || exist_just;

            if(exist_just)
            {
                let is_correct = arraysEqual(selected_reps, correct_reps);
                if(is_correct)
                {
                    score_all++;
                    mention = 'Correct';
                    correct_reps = [];
                }
                else
                {
                    mention = 'Fausse';
                }
            }
            
            question_show.push(
                <div key={key}>
                {show_question_reponse(qst.grp, qst.titre, mention, selected_reps, correct_reps)}
                </div>
            );
        });

        let result = (
            <div>
                <h3>{with_validation ? 'Score'+score_all+', ' : ''}taux de réponse {questions.length > 0 ? ((Object.keys(reponses).filter(rps => reponses[rps].length > 0).length / questions.length) * 100).toFixed(0) +' %' : ''}</h3>
                {question_show}
            </div>
        );
        return result;
    }

    const show_question_reponse = (group, question, mention, reponses, corrects) => {
        return (
            <div>
                {group && <h6>{group}</h6>}
                <h5>{question}</h5>
                {mention}
                <div>
                    <b>Réponse:</b>  
                    { reponses.length>1 ? <ul>{reponses.map((rp, key) => <li key={key}>{rp}</li>)}</ul> : reponses[0] }
                </div>
                {corrects.length > 0 && <div>
                    <b>Correction:</b>  
                    { corrects.length>1 ? <ul>{corrects.map((rp, key) => <li key={key}>{rp}</li>)}</ul> : corrects[0] }
                </div>}
                <br/><br/>
            </div>
        );
    }

    return ( <div>
        <div className='headerContentPart flex_between' >
            <div className='title div_according'>{session.titre}</div>
        </div>
        <div className="div_qcm_session" style={{backgroundColor:survey.color}}>
            {'type_name' in survey && survey.type_name} 
            &nbsp; : &nbsp;
            {survey.titre}
        </div>

        {!is_ended && <SesEnq_Form
                            questions={questions}
                            reponses={reponses}
                            setReponses={(r)=>setReponses(r)}
                            sendForm={(f)=>sendForm(f)}
                        />}
        {is_ended && show_result()}
        
    </div> );
}

export default Survey_Program_Reply_Form;