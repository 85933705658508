import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { check_is_200, post, processParams } from "../../adapters/xhr";
import { handle_change_input } from "../../tools/HInput";
import Session_Item from "../session/Session_Item";

const Trainee_Sessions = ({account, filter_by, setFilter_by}) => {
    
    const [sessions, setSessions] = useState([]);
    
    const getData = () => {
        post('trainee', 'sessions', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            stt: 'cur'
        }))
        .then(function (xhr){
            if(check_is_200(xhr)) {
                setSessions(xhr.data.list || []);
            }else{
                
            }
        }).catch(function (error){
            
        });
    }

    useEffect(() => getData(), []);

    return ( sessions.length > 0 ? <div>
        <h4>Mes sessions en tant que stagiaire</h4>
        {sessions
                .filter(item => {
                    return item.titre.toLocaleLowerCase().includes(filter_by.toLocaleLowerCase());
                })
                .map((session, key) => {
            return (
                <Session_Item key={key} session={session}/>
            );
        })}
        </div> : '' );
}
 
export default Trainee_Sessions;