import { faCircle, faDownload, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, Form, FormControl, FormGroup, FormLabel, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { check_is_200, popup_show_file, post, postUrl, processParams, progressMessage } from "../../../../adapters/xhr";
import ConfirmationModel from "../../../../tools/components/popups/ConfirmationModel";
import { handle_change_input } from "../../../../tools/HInput";
import InfoPopUp from "../../../../tools/components/popups/InfoPopUp";

const Session_Room = ({ account, session_key }) => {

    const [roomUrl, setRoomUrl] = useState('');
    const [isManager, setIsManager] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const [objet, setObjet] = useState('');
    const [descr, setDescr] = useState('');

    const [form_objet, setForm_objet] = useState('');
    const [form_descr, setForm_descr] = useState('');

    const [show_descr, setShow_descr] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [form_validated, setForm_validated] = useState(false);

    const [show_historique, setShow_historique] = useState(false);

    const [rooms_list, setRooms_list] = useState([]);
    const [rooms_list_loading, setRooms_list_loading] = useState(false);

    const [selected_room_info, setSelected_room_info] = useState('');


    const getData = () => {
        let params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key
        };

        post('sesrom', 'get', processParams(params))
        .then(xhr => {
            
            if(check_is_200(xhr)) 
            {
                if('room' in xhr.data)
                {
                    setRoomUrl(xhr.data.room);
                    setObjet(xhr.data.objet || '');
                    setDescr(xhr.data.descr || '');
                    setIsManager('mng' in xhr.data && xhr.data.mng);
                }
                else
                {
                    setRoomUrl('');
                    setIsManager(false);
                }
            }
        }).catch(function (error){
            
        });
    }

    const create_session = () => {
        
        setIsLoading(true);
        const toastId = toast.loading('Création de la réunion');
        let params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            objet:form_objet, 
            descr:form_descr
        };

        post('sesrom', 'create', processParams(params))
        .then(xhr => {
            if(check_is_200(xhr)) {
                if('save' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.save)
                    {
                        if('room' in xhr.data)
                            setRoomUrl(xhr.data.room.roomUrl);
                        
                        setObjet(form_objet);
                        setDescr(form_descr);

                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else
            {
                toast.error(<b>Problème de chargement !</b>, { id: toastId, });
            }
        }).catch(function (error){
            console.log('err', error);
        }).finally(function(){
            setIsLoading(false);
        });
    }

    const close_session = () => {
        
        const toastId = toast.loading('Fermeture de la réunion');

        let params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key
        };

        post('sesrom', 'close', processParams(params))
        .then(xhr => {
            if(check_is_200(xhr)) {
                if('save' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.save)
                    {
                        setRoomUrl('');
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
            }else{
                toast.error(<b>Problème de fermeture !</b>, { id: toastId, });
            }
        }).catch(function (error){
            console.log('err', error);
        });
    }

    useEffect(()=>{
        getData();
    }, []);


    const open_session = () => setShowModal(true);
    const hideModal = () => setShowModal(false);

    const create_action = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setForm_validated(true);

        if(form.checkValidity() === true)
        {
            create_session();
            hideModal();
            setForm_validated(false);
        }
    }

    const get_create_form = () => {
        return (
            <Form id='room_form' noValidate validated={form_validated} onSubmit={create_action}>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Objet</FormLabel>
                        <FormControl type='text' value={form_objet} onChange={e => handle_change_input(e, setForm_objet)} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Description</FormLabel>
                        <FormControl as='textarea' rows={5} value={form_descr} onChange={e => handle_change_input(e, setForm_descr)}/>
                    </FormGroup>
                </Row>
            </Form>
        );
    }


    const open_close_historique = () => {
        if(!show_historique) getList();
        if(!(show_historique && rooms_list_loading))
            setShow_historique(!show_historique);
    }

    const getList = () => {
        setRooms_list_loading(true);
        setRooms_list([]);

        let params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key
        };

        post('sesrom', 'lst', processParams(params))
        .then(xhr => {
            
            if(check_is_200(xhr)) 
            {
                setRooms_list(xhr.data.list || []);
            }
            setRooms_list_loading(false);  
        }).catch(function (error){
          setRooms_list_loading(false);  
        });
    }
    const columns = [
        { name: 'Créer par',    sortable: true, selector: row => row.crby,},
        { name: 'Date début',   sortable: true, selector: row => row.start,},
        { name: 'Date fin',     sortable: true, selector: row => row.ended ? row.end : 'En cours',},
        { name: 'Durée (min)',  sortable: true, selector: row => row.ended ? row.min : 'En cours',},
        { name: 'Objet',        sortable: true, selector: row => row.obj},
        { name: 'Description',  sortable: true, selector: row => row.dsc === '' ? '' : <FontAwesomeIcon onClick={()=>show_room_info(row)} icon={faPlus} /> , sortable: true,},
    ];

    const show_room_info = (room) => setSelected_room_info(room);
    const selected_room_info_content = () => {
        return  (<div className='nl2br'>
            <b>Créer par : </b> {selected_room_info.crby}<br/>
            <b>Date début : </b> {selected_room_info.start}<br/>
            <b>Date fin : </b> {selected_room_info.ended ? selected_room_info.end : 'En cours'}<br/>
            <b>Durée (min) : </b> {selected_room_info.ended ? selected_room_info.min : 'En cours'}<br/>
            <b>Objet : </b> {selected_room_info.obj}<br/>
            <b>Description : </b> {selected_room_info.dsc}<br/>
        </div>);
    }

    const download_rooms = () => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        post('sesrom', 'sermspdf', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key
        }))
        .then(function (xhr){
            if(check_is_200(xhr))
            {
                console.log(xhr.data);
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé</b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });

        }).catch(function (err){
            toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    }

    return ( <div>

        {(isManager && roomUrl == '' && isLoading) && <Spinner  animation="border" variant="primary" />}

        {(isManager && roomUrl == '' && !isLoading) && 
                <div className='button_stand' onClick={e => open_session()}>Créer une réunion audiovisuel</div>
            }
        {roomUrl != '' && <div>
            <div style={{margin:'10px 0px'}} className='flex_between'>
                <div>
                    <h4 className="div_according">Objet : {objet}</h4>
                    {descr != '' && <div className="button_stand" onClick={()=>{ setShow_descr(!show_descr); }}>
                        <FontAwesomeIcon icon={show_descr ? faMinus : faPlus} />
                    </div>}
                </div>

                {(isManager) && <div className='button_stand' onClick={e => close_session()}>Terminer la réunion</div>}

                <div className="div_according" 
                    style={{backgroundColor:'red', padding:'5px 10px', color:'white', margin:'5px', fontWeight:'bold', fontSize:'130%', borderRadius:'5px'}}>
                    <FontAwesomeIcon icon={faCircle} /> &nbsp;
                    En direct
                </div>
            </div>
            <div style={{margin:'10px 0px'}}>
                {(descr != '' && show_descr) && <p className="nl2br"><b><u>Description : </u></b> {descr}</p>}
            </div>
            <div className="wherebydiv">
                <iframe 
                    className="wherebyframe"
                    style={{width:'100%', height:'100vh'}}
                    src={roomUrl}
                    allow="camera; microphone; fullscreen; speaker; display-capture; autoplay"
                    ></iframe>
            </div>
        </div>}
        
        
            

        
        {isManager && <div className='button_stand' onClick={e => open_close_historique()}>
            {show_historique ? 'Masquer' : 'Afficher'} l'historique des réunions
        </div>}
        {(isManager && show_historique && rooms_list.length>0) && <div className='button_stand' onClick={e => download_rooms()}>
                <FontAwesomeIcon icon={faDownload} />
            </div>}
        {(isManager && show_historique) && <div>
            {rooms_list_loading && <Spinner animation="border" />}
            <DataTable
                pagination
                fixedHeader
                columns={columns}
                data={rooms_list}
                noDataComponent="Aucune réunion !"
                paginationComponentOptions={{
                                                rowsPerPageText: 'Réunion par page',
                                                rangeSeparatorText: 'sur',
                                                selectAllRowsItem: true,
                                                selectAllRowsItemText: 'Tous',
                                            }}
                />
            </div>}
        <ConfirmationModel
                    title="Création d'une réunion Audio-Visuel"
                    showModal={showModal}
                    hideModal={hideModal}
                    submitForm='room_form'
                    actionClass='primary'
                    actionMessage='Créer'
                    complement={get_create_form}
                />
        <InfoPopUp
                titleModal="Réunion info"
                showModal={selected_room_info != ''}
                hideModal={()=>{ setSelected_room_info(''); }}
                messageFunction={selected_room_info_content}
                cancelText='Fermer'
                />
    </div> );
}
 
export default Session_Room;