import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Col, Button, Spinner, Image, Row, FormControl, FormGroup, FormLabel, FormContro, Form } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { check_is_200, post, processParams, progressMessage } from "../../adapters/xhr";
import { useSelector } from "react-redux";
// import { getItemRoute } from "../../Routes";
import { handle_change_checkbox, handle_change_file, handle_change_input } from "../../tools/HInput";

const Center_Form = ( { width_title }) => {
    
    const account = useSelector((state) => state.account );
    const history = useHistory();
    
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isMounted, setIsMounted] = useState(true);

    const [categ_list, setCateg_list] = useState([]);

    const [title, settitle] = useState('');
    const [appCmr, setAppCmr] = useState('');
    const [categ, setCateg] = useState('');
    const [desc, setdesc] = useState('');
    const [jurd, setjurd] = useState('');
    const [decact, setDecact] = useState('');
    const [siret, setsiret] = useState('');
    const [siren, setsiren] = useState('');
    const [tvain, setTvain] = useState('');
    const [tel, settel] = useState('');
    const [fixe, setfixe] = useState('');
    const [email, setemail] = useState('');
    const [adresse, setadresse] = useState('');
    const [cp, setcp] = useState('');
    const [ville, setville] = useState('');
    const [logo, setlogo] = useState('');
    const [signature, setSignature] = useState('');
    const [loi, setloi] = useState('');
    const [rpfs_is, setRpfs_is] = useState(false);
    const [rpfs_nm, setRpfs_nm] = useState('');
    const [rpfs_tva, setRpfs_tva] = useState('');
    const [rpfs_adr, setRpfs_adr] = useState('');
    const [rpfs_cp, setRpfs_cp] = useState('');
    const [rpfs_city, setRpfs_city] = useState('');

    const [rip_cb, setRip_cb] = useState('');
    const [rip_cg, setRip_cg] = useState('');
    const [rip_ncp, setRip_ncp] = useState('');
    const [rip_cler, setRip_cler] = useState('');
    const [rip_dm, setRip_dm] = useState('');
    const [rip_int, setRip_int] = useState('');
    const [rip_idt, setRip_idt] = useState('');
    const [rip_tit, setRip_tit] = useState('');

    const [imgtoshow_logo, setimgtoshow_logo] = useState();
    const [imgtoshow_signature, setImgtoshow_signature] = useState();
    let progressShow = 0;
    
    const getData = () => {
        const params = processParams({'tkn':account.info.tkn});
        post("center", "mine", params).then(function (xhr) {
            if(check_is_200(xhr) && isMounted) {
                
                setCateg_list(xhr.data.catgs || []);

                const info = xhr.data.centre;
                
                settitle(info.nom || '');
                setAppCmr(info.appelation || '');
                setCateg(info.categ || '');
                setdesc(info.desc_n || '');
                setjurd(info.juridique || '');
                setsiret(info.nsiret || '');
                setsiren(info.nsiren || '');
                setTvain(info.ntvain || '');
                setDecact(info.ndecact || '');
                settel(info.tel || '');
                setfixe(info.fix || '');
                setemail(info.email || '');
                setadresse(info.adresse || '');
                setcp(info.cp || '');
                setville(info.ville || '');
                setloi(info.loi_n || '');

                setRpfs_is(info.rep_fiscal || false);
                setRpfs_nm(info.rep_fiscal_nom || '');
                setRpfs_tva(info.rep_fiscal_tva || '');
                setRpfs_adr(info.rep_fiscal_adress || '');
                setRpfs_cp(info.rep_fiscal_cp || '');
                setRpfs_city(info.rep_fiscal_ville || '');

                if(info.logo && info.url){
                    setimgtoshow_logo(info.url+info.logo+'?r='+(new Date()))
                }
                if(info.sign && info.url){
                    setImgtoshow_signature(info.url+info.sign+'?r='+(new Date()))
                }
                
                setRip_cb(info.rip_codebanque || '');
                setRip_cg(info.rip_codeguichet || '');
                setRip_ncp(info.rip_numero || '');
                setRip_cler(info.rip_cle || '');
                setRip_dm(info.rip_domiciliation || '');
                setRip_int(info.rip_iban || '');
                setRip_idt(info.rip_bic || '');
                setRip_tit(info.rip_titulaire || '');

                // setlogo('');
            }
        }).catch(function(error){
            // console.log('Error', error);
        }).then(function (){
            // console.log('END');
        });
    };

    useEffect(() => {
        getData();
        return () => { setIsMounted(false); }; 
    }, []);

    const goback = () => history.goBack();
    
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        progressShow = 0;
        event.preventDefault();
        if(form.checkValidity() === true){

            const form = {
                            tkn:account.info.tkn,
                            title, appCmr, categ, desc, jurd, siret, siren, tvain, decact, tel, fixe, email, adresse, cp, ville, loi,
                            rip_cb, rip_cg, rip_ncp, rip_cler, rip_dm, rip_int, rip_idt, rip_tit,
                            rpfs_is, rpfs_nm, rpfs_tva, rpfs_adr, rpfs_cp, rpfs_city
                        };

            // console.log(form);
            let params = processParams(form);
            
            if(logo !== '')
            {
                params.append('logo', logo, logo.name);
            }

            if(signature !== '')
            {
                params.append('sign', signature, signature.name);
            }
            
            const toastId = toast.loading(progressMessage(progressShow));
            post("center", "update", params, 
            {
                onUploadProgress: function (progressEvent) {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    
                    if (totalLength !== null) {
                        let prg = Math.round( (progressEvent.loaded * 100) / totalLength );
                        progressShow = prg;
                        toast.loading(progressMessage(progressShow), { id: toastId, });
                    }
                }
            })
            .then(function (xhr) {
                if(check_is_200(xhr)) {
                    // console.log(xhr.data);
                    if('state' in xhr.data && 'msg' in xhr.data)
                    {
                        if(xhr.data.state)
                        {
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setTimeout(() => { 
                                goback();
                                // console.log('go back');
                            }, 2000);
                        }
                        else
                        {
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                    }
                    else 
                    {
                        toast.error(<b>Problème d'envoi des données !</b>, { id: toastId, });
                    }
                }else{
                    toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                }
                
            }).catch(function(error){
                toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
            }).then(function (){
                setLoading(false);
                // toast.dismiss(toastId);
            });
            
        }
        setValidated(true);
    };

    const handleChange = (e, iSet) => {
        iSet(e.target.value);
    }

    return ( 
        <div>
            {width_title==true && <Row className='headerContentPart' >
                <FormGroup as={Col} xl={3} className='title'>Formulaire</FormGroup>
            </Row>}
            
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                
                <Row>
                    <FormGroup  as={Col}>
                        <FormLabel>Nom du centre</FormLabel>
                        <FormControl type="text" value={title} onChange={(e)=>{handleChange(e, settitle)}} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup  as={Col}>
                        <FormLabel>Appelation Commerciale</FormLabel>
                        <FormControl type="text" value={appCmr} onChange={(e)=>{handleChange(e, setAppCmr)}}/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                    
                <fieldset>
                    <caption>Informations générales</caption>
                    <Row>
                        <FormGroup as={Col}>
                            <FormLabel>N° de déclaration d’activité</FormLabel>
                            <FormControl type="text"  value={decact} onChange={(e)=>{handleChange(e, setDecact)}}required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <FormLabel>Catégorie</FormLabel>
                            <FormControl as='select' value={categ} onChange={(e)=>handleChange(e, setCateg)} required>
                                <option value=''>Choisissiez</option>
                                {categ_list.map((item, key) => <option key={key} value={item.v}>{item.n}</option>)}
                            </FormControl>
                            <FormControl.Feedback type="invalid">Veuillez choisir</FormControl.Feedback>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <FormLabel>Catégorie juridique</FormLabel>
                            <FormControl type="text"  value={jurd} onChange={(e)=>{handleChange(e, setjurd)}}required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup as={Col}>
                            <FormLabel>N° SIREN</FormLabel>
                            <FormControl type="text" value={siren} onChange={(e)=>{handleChange(e, setsiren)}} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <FormLabel>N° TVA intracommunautaire</FormLabel>
                            <FormControl type="text" value={tvain} onChange={(e)=>{handleChange(e, setTvain)}} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <FormLabel>N° SIRET</FormLabel>
                            <FormControl type="text" value={siret} onChange={(e)=>{handleChange(e, setsiret)}} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                    </Row>
                </fieldset>
                <fieldset>
                    <caption>Contact</caption>
                    <Row>
                        <FormGroup as={Col}>
                            <FormLabel>Téléphone</FormLabel>
                            <FormControl type="text" value={tel} onChange={(e)=>{handleChange(e, settel)}} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <FormLabel>Fixe</FormLabel>
                            <FormControl type="text" value={fixe} onChange={(e)=>{handleChange(e, setfixe)}}/>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <FormLabel>Email</FormLabel>
                            <FormControl type="text" value={email} onChange={(e)=>{handleChange(e, setemail)}} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                    </Row>
                </fieldset>
                <fieldset>
                    <caption>Adresse postale</caption>
                    <Row>
                        <FormGroup as={Col}>
                            <FormLabel>Adresse</FormLabel>
                            <FormControl type="text" value={adresse} onChange={(e)=>{handleChange(e, setadresse)}} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup as={Col}>
                            <FormLabel>Code postal</FormLabel>
                            <FormControl type="text" value={cp} onChange={(e)=>{handleChange(e, setcp)}} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <FormLabel>Ville</FormLabel>
                            <FormControl type="text" value={ville} onChange={(e)=>{handleChange(e, setville)}} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                    </Row>
                </fieldset>
                
                <fieldset>
                    <caption>Représentant fiscal</caption>
                    <Form.Check 
                        type="switch"
                        label="Ajouter le représentant fiscal"
                        checked={rpfs_is}
                        onChange={(e)=>{ handle_change_checkbox(e, setRpfs_is) }}
                    />
                    {rpfs_is && <div>
                        <Row>
                            <FormGroup as={Col}>
                                <FormLabel>Nom</FormLabel>
                                <FormControl type="text" value={rpfs_nm} onChange={(e)=>{handleChange(e, setRpfs_nm)}}/>
                                <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <FormLabel>N° TVA</FormLabel>
                                <FormControl type="text" value={rpfs_tva} onChange={(e)=>{handleChange(e, setRpfs_tva)}} />
                                <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                            </FormGroup>
                        </Row>
                        <Row>
                        <FormGroup as={Col}>
                            <FormLabel>Adresse</FormLabel>
                            <FormControl type="text" value={rpfs_adr} onChange={(e)=>{handleChange(e, setRpfs_adr)}} />
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                        <FormGroup as={Col} md={3}>
                            <FormLabel>Code postal</FormLabel>
                            <FormControl type="text" value={rpfs_cp} onChange={(e)=>{handleChange(e, setRpfs_cp)}} />
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                        <FormGroup as={Col} md={3}>
                            <FormLabel>Ville</FormLabel>
                            <FormControl type="text" value={rpfs_city} onChange={(e)=>{handleChange(e, setRpfs_city)}} />
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </FormGroup>
                    </Row>
                    </div>}
                </fieldset>
                
                <Row>
                    <FormGroup as={Col}>
                        <fieldset>
                            <caption>Logo</caption>
                            <FormControl type='file' onChange={(e)=>{handle_change_file(e, setlogo, setimgtoshow_logo)}} />
                            {imgtoshow_logo && <div style={{display:'inline-block',boxShadow:'0 0 3px #eee',marginTop:'10px', marginLeft:'10px'}}><Image src={imgtoshow_logo} height="200"/></div>}
                        </fieldset>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <fieldset>
                            <caption>Signature</caption>
                            <FormControl type='file' onChange={(e)=>{handle_change_file(e, setSignature, setImgtoshow_signature)}} />
                            {imgtoshow_signature && <div style={{display:'inline-block',boxShadow:'0 0 3px #eee',marginTop:'10px', marginLeft:'10px'}}><Image src={imgtoshow_signature} height="200"/></div>}
                        </fieldset>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Description</FormLabel>
                        <FormControl as="textarea" value={desc} onChange={(e)=>{handleChange(e, setdesc)}} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Loi interne</FormLabel>
                        <FormControl as="textarea" value={loi} onChange={(e)=>{handleChange(e, setloi)}} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <fieldset>
                    <caption>Relevé d'identité bancaire (RIB)</caption>
                    <Row>
                        <FormGroup as={Col}>
                            <FormLabel>Code Banque</FormLabel>
                            <FormControl type="text" value={rip_cb} onChange={(e) => handleChange(e, setRip_cb)}></FormControl>
                            {/* <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback> */}
                        </FormGroup>
                        <FormGroup as={Col}>
                            <FormLabel>Code Guichet</FormLabel>
                            <FormControl type="text" value={rip_cg} onChange={(e) => handleChange(e, setRip_cg)}></FormControl>
                            {/* <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback> */}
                        </FormGroup>
                        <FormGroup as={Col}>
                            <FormLabel>Numéro de Compte</FormLabel>
                            <FormControl type="text" value={rip_ncp} onChange={(e) => handleChange(e, setRip_ncp)}></FormControl>
                            {/* <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback> */}
                        </FormGroup>
                        <FormGroup as={Col}>
                            <FormLabel>Clé RIB</FormLabel>
                            <FormControl type="text" value={rip_cler} onChange={(e) => handleChange(e, setRip_cler)}></FormControl>
                            {/* <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback> */}
                        </FormGroup>
                        <FormGroup as={Col}>
                            <FormLabel>Domiciliation</FormLabel>
                            <FormControl type="text" value={rip_dm} onChange={(e) => handleChange(e, setRip_dm)}></FormControl>
                            {/* <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback> */}
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup as={Col}>
                            <FormLabel>International Bank Account Number : IBAN</FormLabel>
                            <FormControl type="text" value={rip_int} onChange={(e) => handleChange(e, setRip_int)}></FormControl>
                            {/* <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback> */}
                        </FormGroup>
                        <FormGroup as={Col}>
                            <FormLabel>Bank Identification Code : BIC</FormLabel>
                            <FormControl type="text" value={rip_idt} onChange={(e) => handleChange(e, setRip_idt)}></FormControl>
                            {/* <FormControl.Feedback type='invalid'>Veuillez remplir le champ</FormControl.Feedback> */}
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup as={Col}>
                            <FormLabel>Titulaire du compte</FormLabel>
                            <FormControl as="textarea" value={rip_tit} onChange={(e)=>{handleChange(e, setRip_tit)}} />
                            {/* <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback> */}
                        </FormGroup>
                    </Row>
                </fieldset>
                {!loading && <Button variant="primary" type="submit">
                    Enregistrer
                </Button>}

                {loading && <Button variant="primary" disabled>
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>}
            </Form>
        </div>
     );
}
 
export default Center_Form;