import { createSlice } from "@reduxjs/toolkit";

export const accountSlice = createSlice({
    name: 'account',
    initialState:
    {
        isLoggedIn : false,
        ctr: {},
        info : {},
        // isLoggedIn : true,
        // ctr: { 
        //      key: "",
        //      name: "",
        //      rol: ""
        // },
        // info : {
        //     eml: "bouzidi.dev@gmail.com",
        //     tel: "0987654321",
        //     name: {n: "Morad", p: "test"},
        //     tkn:"N3ZKaEs4RWtZWkN2V1ZkNCtrQS83b2hDSVVZZ1pHeE1XNnhlZHhVTFczYU1QZEpXVlUzaDU4cEtCOThBdXZWcDNpTGpKWG8rcU9DQlNKc3k5QnVCSDdaUVZneWNKeGV6ZjdqRm5Vak1hQlpMSU1Cb05obVpKQnhyWFNxYWlsTjQ2MjJ0Nk5YVnNvV1RHTE4ycCtsN2Y0YkpreHMyVUt1cTdEOW9KY0lXKzFyeGZMZU12RHVOSDc5RjRiWm1QOFl0RGFRQi9TYS9ZQ1lTMmUwOEREbFh0WlEwcEpKQlo1dnhJbC94ZVBPSVYwc3RDL3VBREFQRmUrK3lJb2hSMVRGcFF5eXJqTnA5NFArOERBdXJBYlRqQ0REZzhtdFBkbyt0U3BmSXBQNEpBZUFWWVBxTDBNRWg1bWxUSis3ekVHSVA",
        //     ctr:"aVRxSUNFdlBtUHlFbjZyU1B4Sk82eFVIT2xEbjErYWlpUENvcmRkZFJnR1JEVkR4NlcwU1pMTVYvQm9zczVwcmtjVlZnTGU2eUNFQmF5ZFY4VlhCR29DbTNnakl5TkRtTzZsMDg4ZDFScUh1bmM2M3lTSC93M3AzS1BxK0JyVWNldWFrVUFRRDY3cVJvcGFUTEJ4c1hKR0sxa0pLWnBUQWF5WGk0OUFSVGRCenhLU1hmWk9qWmF1OU1qUW9xUkRRVUZCOHVYQmFvMGt4VE5VQzFhRjdBbUZSSUlQZ2VKM0wxNEFHQTdnZ3BZdVFmeWZMaGZvSERQYjRkcEpBak1PQk5mOVJiaUNwYTBFVHQyekNkQ2FuZEE9PQ",
        //     profil:"PRE"
        //     gses : // session to go when we connect for the first time
        // }
    },
    reducers:{
        save:(state, { payload }) => {
            state.isLoggedIn = true;
            state.info = payload;    
        },
        rmvGses:(state, {}) => {
            if('info' in state && 'gses' in state.info)
                state.info.gses = '';
        },
        changeToken:(state, { payload }) => {
            state.info.tkn = payload;    
        },
        changeCenter:(state, { payload }) => {
            state.ctr = payload;
        },
        clear:(state) => {
            state.isLoggedIn = false;
            state.ctr = {}
            state.info = {}
        },
    }
});

export const { save, rmvGses, changeToken, changeCenter, clear } = accountSlice.actions

export default accountSlice.reducer