export function handle_change_input(event, setter = () => {}) {
    setter(event.target.value);
}

export function handle_change_checkbox(event, setter = () => {}) {
    setter(event.target.checked);
}

export function handle_change_input_item(event, element, item, setter = () => {}) {
    element[item] = event.target.value;
    setter({...element});
}

export function handle_change_input_item_array(event, element, item, setter = () => {}, filter_by = (e) => true) {
    element[item] = event.target.value;
    let elements = [...element].filter(item => filter_by(item));
    setter(elements);
}

export function handle_change_file (event, setter = () => {}, shower = () => {}) {
    if(event.target.files.length > 0){
        shower(URL.createObjectURL(event.target.files[0]));
        setter(event.target.files[0]);
    }
}

export function show_paragraph (text, with_nl2br = true) {
    return <div className={'justify nl2br'} dangerouslySetInnerHTML={{__html: text}}/>;
}

export function handle_textarea_chat(e, buttonSubmit) {
    if ( e.charCode === 13 ) 
    {
        if( !e.shiftKey )
            buttonSubmit.current.click();
    }
}