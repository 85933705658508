export function percentage(percent, total, decimal = 0) 
{
    if(total == 0) return 0;
    return round ( 100 * percent / total, decimal);
}

export function round(num, precision)
{
    if(precision == 0)  return Math.round(num);
    return Math.round((num + Number.EPSILON) * (10 * precision)) / (10*precision);
}