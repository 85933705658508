import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Row, Col, FormGroup, Button, Spinner, InputGroup } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { check_is_200, post, processParams, progressMessage } from "../../../adapters/xhr";
import { useSelector } from "react-redux";
import { handle_change_input } from "../../../tools/HInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCog, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getItemRoute } from "../../../Routes";
import DeleteConfirmation from "../../../tools/components/popups/DeleteConfirmation";

const Client_Fiche = () => {
    
    const account = useSelector((state) => state.account );
    const history = useHistory();
    const { center_key, client_key } = useParams();
    
    const [isMounted, setIsMounted] = useState(true);

    const [title, settitle] = useState('');
    const [categorie, setCategorie] = useState('');
    const [jurd, setjurd] = useState('');
    const [refcmta, setRefcmta] = useState('');
    const [decact, setDecact] = useState('');
    const [siret, setsiret] = useState('');
    const [siren, setsiren] = useState('');
    const [tel, settel] = useState('');
    const [fixe, setfixe] = useState('');
    const [email, setemail] = useState('');
    const [adresse, setadresse] = useState('');
    const [cp, setcp] = useState('');
    const [ville, setville] = useState('');
    const [delaif, setDelaif] = useState('');
    
    const [repr_gdr, setRepr_gdr] = useState('mr');
    const [repr, setRepr] = useState('');

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    
    let progressShow = 0;
    
    const getData = () => {
        const params = processParams({
            tkn:account.info.tkn, 
            ctr: center_key,
            cli: client_key
        });
        post("ctrcli", "info", params).then(function (xhr) {
            if(check_is_200(xhr) && isMounted) {
                // console.log(xhr.data);
                const info = xhr.data.client;
                if('nom' in info)   settitle(info.nom);
                if('categorie' in info)   setCategorie(info.categorie);
                if('juridique' in info)     setjurd(info.juridique);
                if('comptable' in info)     setRefcmta(info.comptable);
                if('nsiret' in info)    setsiret(info.nsiret);
                if('nsiren' in info)    setsiren(info.nsiren);
                if('ndecact' in info)   setDecact(info.ndecact);
                if('tel' in info)   settel(info.tel);
                if('fix' in info)   setfixe(info.fix);
                if('email' in info)     setemail(info.email);
                if('adresse' in info)   setadresse(info.adresse);
                if('cp' in info)    setcp(info.cp);
                if('ville' in info)     setville(info.ville);
                setRepr((info.representant_gender || '') + ' ' + (info.representant || ''));
                if('representant_gender' in info)   setRepr_gdr(info.representant_gender);
                setDelaif(info.delai_facturation || '');
                // setlogo('');
            }
        }).catch(function(error){
            // console.log('Error', error);
        }).then(function (){
            // console.log('END');
        });
    };

    useEffect(() => {
        getData();
    }, []);

    const go_update = () => {
        history.push({
            pathname:getItemRoute('center_client_update')+center_key+'/'+client_key,
            state:{back:true}
        });
    }

    const go_delete = () => setDisplayConfirmationModal(true);
    const hideConfirmationModal = () => setDisplayConfirmationModal(false);

    const act_delete = () => {
        hideConfirmationModal();
    
        let params = processParams({
            tkn:account.info.tkn, 
            ctr:center_key,
            cli:client_key
        });
        const toastId = toast.loading('Envoi');

        post("ctrcli", "delete", params)
        .then(function (xhr) {
            if(check_is_200(xhr)) {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        goback();
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else 
                {
                    toast.error(<b>Problème d'envoi des données !</b>, { id: toastId, });
                }
            }else{
                toast.error(<b>Problème de suppression !</b>, { id: toastId, });
            }
            
        }).catch(function(error){
            toast.error(<b>Problème de suppression !</b>, { id: toastId, });
        });
    }
    
    const goback = () => history.goBack();

    return ( 
        <div>
            <Row className='headerContentPart' >
                <div className='flex_between'>
                    <div className='title'>Information client</div>
                    <div>
                        <div className='button_stand' onClick={() => go_update()}>
                            <FontAwesomeIcon icon={faCog}/>
                        </div>
                        <div className='button_stand' onClick={() => go_delete()}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </div>
                    </div>
                </div>
            </Row>

            <Form noValidate className='show_info_as_form'>
                <Row>
                    <FormGroup  as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Nom</div>
                            <div className='profil_show_item_value'>{title}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Représentant</div>
                            <div className='profil_show_item_value'>{repr}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Délai de facturation</div>
                            <div className='profil_show_item_value'>{delaif}</div>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup  as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Catégorie</div>
                            <div className='profil_show_item_value'>{categorie}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Catégorie juridique</div>
                            <div className='profil_show_item_value'>{jurd}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Référence du comptable</div>
                            <div className='profil_show_item_value'>{refcmta}</div>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>N° de déclaration d’activité</div>
                            <div className='profil_show_item_value'>{decact}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>N° SIREN</div>
                            <div className='profil_show_item_value'>{siren}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>N° SIRET</div>
                            <div className='profil_show_item_value'>{siret}</div>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Téléphone</div>
                            <div className='profil_show_item_value'>{tel}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Fixe</div>
                            <div className='profil_show_item_value'>{fixe}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Email</div>
                            <div className='profil_show_item_value'>{email}</div>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Adresse</div>
                            <div className='profil_show_item_value'>{adresse}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Code postal</div>
                            <div className='profil_show_item_value'>{cp}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Ville</div>
                            <div className='profil_show_item_value'>{ville}</div>
                        </div>
                    </FormGroup>
                </Row>
            </Form>

            <DeleteConfirmation 
                        showModal={displayConfirmationModal} 
                        confirmModal={act_delete}
                        hideModal={hideConfirmationModal} 
                        message={"Êtes-vous sûr de vouloir supprimer le client \""+title+"\" ?"}
                    />
        </div>
     );
}

export default Client_Fiche;