import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Question_Form from "./Question_Form";

const Questions_list = ({items, setItems, is_quiz, typeList=[]}) => {

    const onChange_question = (indice, e) => {
        setItems(items.map((item, key) => {
            if(key==indice){
                item.titre = e.target.value;
            }
            return item;
        }));
    };

    
    const question_setChoix = (indice, values) => {
        setItems(items.map((item, key) => {
            if(key==indice){
                item.choix = values;
            }
            return item;
        }));
    }

    const question_setType = (indice, e) => {
        setItems(items.map((item, key) => {
            if(key==indice){
                item.type = e.target.value;
            }
            return item;
        }));
    }


    const addItem = () => {
        setItems(items.concat({
            titre:'',
            type:'',
            choix:[]
        }));
    };

    const removeItem = (indice) => {
        setItems(items.filter((v, i) => i !== indice));
    }

    return ( <div>
        Liste des questions<br/>
        {items && items.map((question, key) => {
                return <Question_Form
                            is_quiz={is_quiz}
                            question={question}
                            key={key}
                            setTitle={(v)=>onChange_question(key, v)}
                            setChoix={(v)=>question_setChoix(key, v)}
                            setType={(v)=>question_setType(key, v)}
                            typeList={typeList}
                            removeItem={()=>removeItem(key)}
                            
                        />;
            })}
        <div className='button_stand' onClick={()=>addItem()}>
            <FontAwesomeIcon icon={faPlus} /> Ajouter une question
        </div>
    </div> );
}
 
export default Questions_list;