import { FormControl } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const TextQ = ({ value, setValue, required, validated }) => {

    const modules = {
        toolbar: [
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['clean']
        ],
    };
    return (
        <div>
        <ReactQuill 
            className={validated ? (required && value.replace(/<(.|\n)*?>/g, '').trim().length === 0 ? 'is-invalid' : 'is-valid') : ''} 
            modules={modules} 
            value={value} 
            onChange={setValue}
        />
        {required && <FormControl type='text' className='hidden_ele' value={value.replace(/<(.|\n)*?>/g, '').trim().length > 0 ? '.' : ''} onChange={()=>{}} required></FormControl>}
        {required && <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>}
        </div> 
    );
}
 
export default TextQ;