import { faPaperclip, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Row, FormGroup, FormControl, Col, Button, Form } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { check_is_200, downloadBlob, post, processParams, progressMessage } from "../../adapters/xhr";
import DeleteConfirmation from "../../tools/components/popups/DeleteConfirmation";
import { handle_change_file, handle_change_input, handle_textarea_chat, show_paragraph } from "../../tools/HInput";
import { Manager } from 'socket.io-client';



const Messagerie = ({session_key, account, can_add_note, to, show_name}) => {
    const buttonSubmit = useRef(null);

    let progressShow = 0;
    const messagesEndRef = useRef(null);

    var room = '';

    const [socket, setSocket] = useState('');
    const [notes, setNotes] = useState([]);
    const [ping, setPing] = useState({});
    const [havePrevious, setHavePrevious] = useState(false);
    const [validated, setValidated] = useState(false);

    const [the_first_key, setThe_first_key] = useState('');
    const [the_last_key, setThe_last_key] = useState('');

    const [new_note_msg, setNew_note_msg] = useState('');
    const [new_note_file, setNew_note_file] = useState('');

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const hideConfirmationModal = () => { setDisplayConfirmationModal(false); }
    const [msgRemoveNote, setMsgRemoveNote] = useState('');
    const [note_to_remove, setNote_to_remove] = useState({});
    const [who, setWho] = useState('');
    // const [note_to_remove_div, setnote_to_remove_div] = useState({});

    const [conversation, setConversation] = useState('');
    let user_name = account.info.name.n+' '+account.info.name.p;
    
    
    const getNotes = (by) => {
        let params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            oth: to
        };
        // // console.log(params);

        switch (by){
            case 'previous': params['prv'] = the_first_key; break;
            case 'next':     params['nxt'] = the_last_key;  break;
        }

        // // // console.log(params);
        post('message', 'getlist', processParams(params))
        .then(function (xhr){
            if(('status' in xhr) && (xhr.status===200)) {
                if(('who' in xhr.data) && ('msgs' in xhr.data))
                {
                    if('room' in xhr.data)
                    {
                        setConversation(xhr.data.room);
                    } 

                    setWho(xhr.data.who);
                    let msgs = xhr.data.msgs;
                    switch (by){
                        case 'previous':
                            // // // console.log('PREVIOUS');
                            if(msgs.length > 0){
                                setHavePrevious(true);
                                setThe_first_key(msgs[0].key);
                            }else{
                                setHavePrevious(false);
                            }
                            Array.prototype.push.apply(msgs,notes); 
                            setNotes(msgs);
                            break;
                        case 'next':
                            // // // console.log('NEXT');
                            if(msgs.length > 0){
                                setThe_last_key(msgs[msgs.length-1].key);
                            }
                            // let notes_p = notes;
                            let notes_p = notes.concat(msgs);
                            // Array.prototype.push.apply(xhr.data, notes); 
                            setNotes(notes_p);
                            // $messages.animate({ scrollTop: $messages.prop('scrollHeight') }, 300);
                            // messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
                            setTimeout(() => {
                                console.log('move');
                                let objDiv = document.getElementById("messages");
                                objDiv.scrollTop = objDiv.scrollHeight;
                            }, 100);

                            // // // console.log(notes_p);
                            break;
                        default:
                            // console.log(msgs);
                            setNotes(msgs);
                            if(msgs.length > 0){
                                setHavePrevious(true);
                                setThe_first_key(msgs[0].key);
                                setThe_last_key(msgs[msgs.length-1].key);
                            }else{
                                setHavePrevious(false);
                            }
                            break;
                    }
                }else{
                    
                }
            }else{
                
            }
        }).catch(function (error){
            
        });
    }

    useEffect(() => {
        getNotes('');

        const manager = new Manager("https://wsl.gestioncentres.fr", {
            withCredentials: true,
            transports : ['websocket']
        });
        manager.reconnectionAttempts(5);

        let socketm = manager.socket("/");
        setSocket(socketm);

        return () => {
            if(socketm)
            {
                socketm.off('connect');
                socketm.off('disconnect');
                socketm.off('pong');
                socketm.disconnect();
                socketm.close();
            }
        };
    }, []);

    const socket_access_room = () => {
        // console.log('socket_access_room', conversation, socket);
        if(conversation && socket)
        {
            // console.log('CONNECT', conversation, socket)
            socket.on('connect', () => {
                // console.log('connected to room c'+conversation);
                socket.emit('join', {room:'room:c'+conversation});
            });
            
            socket.on('disconnect', () => {
                // console.log('disconnected');
            });
            
            socket.on('new_user', function(msg) {
                // console.log(msg);
            });
            
            socket.on('new_note', function(note) {
                setPing(note);
            });
        }
    }

    useEffect(()=>{
        // console.log('conversation : ', conversation);
        socket_access_room();
    }, [socket, conversation]);

    useEffect(()=>{
        // console.log(ping);
        if('key' in ping){
            setNotes(notes.concat([ping]));
            window.scrollTo(0, document.body.scrollHeight);
        }
            
    }, [ping]);

    
    const getFile = (id_note, filename) => {
        progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        post('message', 'download', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            not: id_note,
        }), {
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                progressShow = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                toast.loading(progressMessage(progressShow), { id: toastId, });
            },
        })
        .then(function (response){
            // // // console.log(response.data);
            toast.success(<b>Fichier téléchargé</b>, { id: toastId, });
            downloadBlob(response.data, filename);
        }).catch(function (err){
            // // // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const removeNoteConfirm = (note) => {
        setNote_to_remove(note);
        setMsgRemoveNote('Êtes-vous sûr de vouloir supprimer ce message ?');
        setDisplayConfirmationModal(true);
        
    }
    const removeNote = () => {
        const toastId = toast.loading('Suppression ...');
        hideConfirmationModal();

        let params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            not: note_to_remove.key,
        });
        
        post('message', 'dlt', params)
        .then( function ( xhr ) {
            if ( ( 'status' in xhr ) && ( xhr.status===200 ) )
            {
                if ( ( 'save' in xhr.data ) && ( 'msg' in xhr.data ) )
                {
                    if ( xhr.data.save )
                    {
                        toast.success(<b>{(xhr.data.msg)}</b>, { id: toastId, });
                        const itemIndex = notes.findIndex(note_i => note_i.key === note_to_remove.key);
                        if(itemIndex > -1){
                            notes.splice(itemIndex, 1);
                            setNotes(notes);
                            getNotes('next');
                        }
                    }
                    else
                    {
                        toast.error(<b>{(xhr.data.msg)}</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else
            {
                toast.error(<b>Problème de chargement !</b>, { id: toastId, });
            }
        })
        .catch(function (err){
            // // // console.log(err);
            toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    
        
    }
    
    const show_standart = () => {
        return <div>
                <div ref={messagesEndRef} id="messages" className='messages'>
                {havePrevious && <div
                                    onClick={(e) => getNotes('previous')}>
                                    Get previous messages
                                </div>}
                {notes.map((note, key) => {
                note.delete_vis = true;
                return (<div key={key} className={'message '+(note.canr ? '': 'from_her')}>
                    {show_paragraph(note.msg)}
                    {note.file && 
                    <div className='div_file' onClick={() => getFile(note.key, note.file)}>
                        <FontAwesomeIcon
                            icon={faPaperclip}
                            style={{marginRight:'10px'}}
                        />
                        {note.file}
                    </div>}
                    <div className='msg_tools'>
                        {note.time}
                    {note.canr && <FontAwesomeIcon
                                        icon={faTimes}
                                        style={{marginLeft:'10px', 'cursor':'pointer'}}
                                        onClick={(e) => removeNoteConfirm(note)}
                                    />}
                    </div>
                </div>);

                })}</div>
            </div>;
    }

    
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        
        let progressShow = 0;
        if(form.checkValidity() === true){

            const toastId = toast.loading(progressMessage(progressShow));
            let params = processParams({
                tkn: account.info.tkn,
                ctr: account.ctr.key,
                ses: session_key,
                top: to,
                msg: new_note_msg
            });
            if(new_note_file !== ''){
                params.append('file', new_note_file, new_note_file.name);
            }
            post('message', 'add', params, {
                onUploadProgress: function (progressEvent) {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    if (totalLength !== null) {
                        progressShow = Math.round( (progressEvent.loaded * 100) / totalLength );
                        toast.loading(progressMessage(progressShow), { id: toastId, });
                    }
                }
            })
            .then( function ( xhr ) {
                if ( check_is_200(xhr) )
                {
                    // console.log(xhr.data);
                    if ( ( 'save' in xhr.data ) && ( 'msg' in xhr.data ) )
                    {
                        if ( xhr.data.save )
                        {
                            toast.success(<b>{(xhr.data.msg)}</b>, { id: toastId, });
                            setNew_note_msg('');
                            setNew_note_file('');
                            form.reset();
                            setValidated(false);

                            if('note' in xhr.data && 'key' in xhr.data.note){
                                let add_note = xhr.data.note;
                                
                                setNotes(notes.concat(add_note));
                                let pub_note = { ...add_note };
                                if('canr' in pub_note) pub_note.canr = false;
                                if('own' in pub_note) pub_note.own = false;

                                socket.emit('new_note', pub_note);
                                window.scrollTo(0, document.body.scrollHeight);
                            }
                        }
                        else
                        {
                            toast.error(<b>{(xhr.data.msg)}</b>, { id: toastId, });
                        }
                    }
                    else
                    {
                        toast.error(<b>Problème de chargement 1!</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème de chargement 2!</b>, { id: toastId, });
                }
            })
            .catch(function (err){
                // console.log(err);
                toast.error(<b>Problème de chargement 3!</b>, { id: toastId, });
            });

        }
        setValidated(true);
    }

    const get_form = () => {
        return (
            <div>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                        <FormGroup as={Col}>
                            <FormControl as="textarea" autoFocus={true}  value={new_note_msg} onChange={(e) => handle_change_input(e, setNew_note_msg)} onKeyPress={(e) => handle_textarea_chat(e, buttonSubmit)} placeholder='Message' required></FormControl>
                            {/* <FormControl.Feedback>Veuillez remplir le champ</FormControl.Feedback> */}
                        </FormGroup>
                        <FormGroup as={Col} sm="2">
                            <Button type="submit" ref={buttonSubmit}>Envoyer</Button>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup as={Col} controlId="formFile" className="mb-3">
                            <FormControl type="file" onChange={(e) => handle_change_file(e, setNew_note_file)} />
                        </FormGroup>
                    </Row>
                    
                </Form>
                {/* <Toaster
                    position="bottom-right"
                    reverseOrder={false}        /> */}
                <DeleteConfirmation 
                    showModal={displayConfirmationModal} 
                    confirmModal={removeNote}
                    hideModal={hideConfirmationModal} 
                    message={msgRemoveNote}
                />
            </div> );
    }

    return (<div>
        {(notes.length>0 || can_add_note) && <div>{show_name && <h3>{who}</h3>}<h5>Messages</h5></div>}
        {notes.length>0 && show_standart()}
        {can_add_note && get_form()}
        </div>);
}
 
export default Messagerie;