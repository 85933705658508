import { combineReducers, configureStore, createStore } from "@reduxjs/toolkit";
import accountReducer from "./accountSlice";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 



const persistConfig = {
    key: 'root',
    storage,
}

const reducer = combineReducers({account:accountReducer});

const persistedReducer = persistReducer(persistConfig, reducer)

const store = createStore(persistedReducer);

export const persistor = persistStore(store);

export default store;