import { useEffect, useState } from "react";
import { Button, Form, Col, FormControl, InputGroup, Row, FormGroup, FormLabel } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { post, processParams, progressMessage, downloadBlob, popup_show_file, check_is_200 } from "../../../adapters/xhr";
import toast, { Toaster } from "react-hot-toast";
import { handle_change_file, handle_change_input } from "../../../tools/HInput";
import DeleteConfirmation from "../../../tools/components/popups/DeleteConfirmation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faFileDownload, faPlus, faTimes, faShower, faEye, faFile } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { filter_search_check_item } from "../../../tools/DataTableOpt";
import { getItemRoute } from "../../../Routes";

const Center_Docs_Upd = () => {

    const history = useHistory();
    const account = useSelector((state) => state.account);
    
    let progressShow = 0;

    const [center_key, setCenter_key] = useState(account.ctr.key);
    const [documents, setDocuments] = useState([]);
    const [filter_by, setFilter_by] = useState('');

    const [canAdd, setCanAdd] = useState(false);
    const [canRemove, setCanRemove] = useState(false);

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [document_to_remove, setDocument_to_remove] = useState({});
    
    const [validated, setValidated] = useState(false);
    const [frm_titre, setFrm_titre] = useState('');
    const [frm_file, setFrm_file] = useState('');


    const [forp, setForp] = useState('');
    const [forList, setForList] = useState([]);

    const [showForm, setShowForm] = useState(false);


    const hideConfirmationModal = () => { setDisplayConfirmationModal(false); }

    const getDocuments = () => {
        const params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            opt: ['infs', 'typs']
        });

        post('ctrdoc', 'get', params)
        .then((xhr) => {
            if(check_is_200(xhr)) {
                // console.log(xhr.data);
                let canUpd  = xhr.data.upd || false;
                
                setDocuments(xhr.data.lst || []);
                setForList(xhr.data.typ || []);
                setCanAdd(canUpd);
                setCanRemove(canUpd);
            }
        }).catch(function (err){
            
        });
    }

    useEffect(() => {
        getDocuments();
    }, []);

    const showFile = (docm) => {
        popup_show_file(docm.file);
    }

    const getFile = (docm) => {
        progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        post('ctrdoc', 'download', processParams({
            tkn: account.info.tkn,
            ctr: center_key,
            doc: docm.key,
        }), {
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                progressShow = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                toast.loading(progressMessage(progressShow), { id: toastId, });
            },
        })
        .then(function (response){
            toast.success(<b>Fichier téléchargé</b>, { id: toastId, });
            downloadBlob(response.data, docm.name+'.'+docm.ext);
        }).catch(function (err){
            // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const confirmRemoveFile = (document) => {
        setDocument_to_remove(document);
        setDisplayConfirmationModal(true);
    }

    const removeFile = () => {

        // if(canRemove) return ''; 

        setDisplayConfirmationModal(false);

        const toastId = toast.loading("Suppression de "+document_to_remove.name);
        const params = processParams({
            tkn: account.info.tkn,
            ctr: center_key,
            doc: document_to_remove.key
        });

        
        post('ctrdoc', 'delete', params)
        .then(function (xhr){
            if(('status' in xhr) && (xhr.status===200)) {
                if(('save' in xhr.data) && ('msg' in xhr.data)) {
                    if(xhr.data.save) {
                        toast.success(<b>Fichier supprimé !</b>, { id: toastId, });
                        getDocuments();
                    }else{
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }else{
                    toast.error(<b>Problème de suppression !</b>, { id: toastId, });
                }
            }
        }).catch(function (err){
            toast.error(<b>Problème de suppression !</b>, { id: toastId, });
        });
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();


        if(canAdd && form.checkValidity() == true){
            let progressShow = 0;
            const toastId = toast.loading(progressMessage(progressShow));
            
            let params = {
                tkn: account.info.tkn,
                ctr: center_key,
                for: forp,
                titre: frm_titre,
            };
            
            params = processParams(params);
            params.append('file', frm_file, frm_file.name);
            
            post('ctrdoc', 'add', params, {
                onUploadProgress: function (progressEvent) {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    if (totalLength !== null) {
                        progressShow = Math.round( (progressEvent.loaded * 100) / totalLength );
                        toast.loading(progressMessage(progressShow), { id: toastId, });
                    }
                }
            })
            .then(function (xhr){
                if(check_is_200(xhr)) {
                    console.log(xhr.data);

                    if(('save' in xhr.data) && ('msg' in xhr.data)) {
                        if(xhr.data.save) {
                            toast.success(<b>Données enregistrées !!</b>, { id: toastId, });
                            setTimeout(() => {
                                form.reset();
                                setValidated(false);
                                setFrm_titre('');
                                setFrm_file('');

                                getDocuments();
                            }, 1000);
                        }else{
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                    }else{
                        toast.error(<b>Problème d'enregistrement !</b>, { id: toastId, });
                    }
                }
            }).catch(function (err){
                toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
            });
        }
        setValidated(true);
    }

    const goback = () => {
        history.goBack();
    }

    const show_add_form = () => setShowForm(true);
    const hide_add_form = () => setShowForm(false);
    

    const get_form = () => {
        return (
            <div>
                {showForm && <Form noValidate validated={validated} onSubmit={handleSubmit} className='is_block'>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Nom</FormLabel>
                        <FormControl type="text" value={frm_titre} onChange={(e)=>handle_change_input(e, setFrm_titre)} required/>
                        <FormControl.Feedback type="invalid">Veuillez remplir le nom</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Destinataire</FormLabel>
                        <FormControl as="select" value={forp} onChange={(e)=>{handle_change_input(e, setForp)}} custom>
                            {forList.map((opt, key) => (
                                                <option key={key} value={opt.v}>{opt.n}</option>
                                            ))}
                        </FormControl>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Fichier</FormLabel>
                        <FormControl type='file' onChange={(e)=>handle_change_file(e, setFrm_file)} feedback="Veuillez séléctionner le document" required />
                    </FormGroup>
                    <FormGroup as={Col}>
                        <br/>
                        <Button type="submit">Enregistrer</Button>
                        {showForm == true && <div className='button_stand' onClick={() => hide_add_form()}>
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                            /></div>}
                    </FormGroup>
                </Row>
                
            </Form>}
            </div>
        );
    }

    const getActions = (doc) => {
        return (<div className='buttons_table'>
                    <div></div>
                    <div className='button_stand' data-tooltip="Ouvrir" onClick={(e)=>showFile(doc)}><FontAwesomeIcon icon={faFile} /></div>
                    <div className='button_stand' data-tooltip="Télécharger" onClick={(e)=>getFile(doc)}><FontAwesomeIcon icon={faFileDownload} /></div>
                    {canRemove && <div className='button_stand' data-tooltip="Supprimer" onClick={(e)=>confirmRemoveFile(doc)}><FontAwesomeIcon icon={faTimes} /></div>}
                </div>);
    }
    const columns = [
        { name: 'Nom', selector: row => row.name, sortable: true,},
        { name: 'Déstinataire', selector: row => row.for, sortable: true,},
        { name: 'Action', selector: row => row.action, sortable: true, minWidth:1, cell:row => <div style={{width:180}}>{getActions(row)}</div>},
    ];

    const filteredData = documents.filter(item => {
        let filter_key = filter_by.toLocaleLowerCase();
        return filter_search_check_item(filter_key, item, 
            ['name', 'for']);
    });

    return ( 
        <div>

            <Row className='headerContentPart' >
                <div className='flex_between'>
                    <div className='title'>Document(s) du centre</div>
                    {documents.length > 0 && <InputGroup className="search width_50">
                                                <InputGroup.Text className="icon">
                                                    <FontAwesomeIcon
                                                        icon={faSearch}
                                                    /> 
                                                </InputGroup.Text>
                                                <FormControl
                                                    placeholder="Filtrer"
                                                    aria-label="Filtrer"
                                                    aria-describedby="filtrate"
                                                    type='text' 
                                                    value={filter_by} 
                                                    className="text_zone"
                                                    onChange={e => handle_change_input(e, setFilter_by)}
                                                />
                                            </InputGroup>}
                    {canAdd && <div>{ showForm == false && <div className='button_stand'  onClick={() => show_add_form()}>
                        <FontAwesomeIcon icon={faPlus} />
                    </div>}</div>}
                </div>

            </Row>
                

            {canAdd && get_form()}

            
            <DataTable
                    pagination
                    fixedHeader
                    columns={columns}
                    data={filteredData}
                    noDataComponent="Aucun document n'est enregistré !"
                    paginationComponentOptions={{
                                                    rowsPerPageText: 'Documents par page',
                                                    rangeSeparatorText: 'sur',
                                                    selectAllRowsItem: true,
                                                    selectAllRowsItemText: 'Tous',
                                                }}
                />
            
            <DeleteConfirmation 
                        showModal={displayConfirmationModal} 
                        confirmModal={removeFile}
                        hideModal={hideConfirmationModal} 
                        message={"Êtes-vous sûr de vouloir supprimer le document \""+document_to_remove.name+"\" ?"}
                    />
        </div>
     );
}
 
export default Center_Docs_Upd;