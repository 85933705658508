import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row, FormGroup, FormControl, InputGroup } from "react-bootstrap";
import { getPositionOfLineAndCharacter } from "typescript";
import { post, processParams } from "../../../adapters/xhr";
import { handle_change_input } from "../../../tools/HInput";
import ProfilWidget from "../../../tools/components/ProfilWidget/ProfilWidget";
import { getItemRoute } from "../../../Routes";
import Pagination_normal from "../../../tools/components/Pagination/Pagination_normal";

const Structure_Search = ({ about }) => {

    const [search_by, setSearch_by] = useState('');

    const account = useSelector((state)=>state.account);
    const history = useHistory();
    
    let for_member = about != undefined && about=='m';

    const [page_current, setPage_current] = useState(1);
    const [page_last, setPage_last] = useState(0);
    const [list, setList] = useState([]);

    const get_result = (page_num) => {
        let params = processParams({
            tkn:account.info.tkn, 
            ctr:account.ctr.key,
            for:about,
            src:search_by,
            cur:page_num
        });
        post('profil', 'search', params)
        .then(function (xhr){
            // console.log(xhr.data);
            if('search' in xhr.data)
            {
                setList(xhr.data.search.list || []);
                setPage_current(xhr.data.search.curp || 1);
                setPage_last(xhr.data.search.nbrp || 1);
            }
        })
        .catch(function (err){

        });
    };

    const get_info = (key_s) => {
        history.push({
            pathname:getItemRoute('center_member')+key_s,
            state:{back:true}
        });
    }

    return ( <div>
        <Row className='headerContentPart' >
            <div className='flex_between'>
                <div className='title'>Trouver un {for_member ? 'membre' : 'formateur'}</div>
                <InputGroup className="search width_50">
                    <InputGroup.Text className="icon">
                        <FontAwesomeIcon
                            icon={faSearch}
                        /> 
                    </InputGroup.Text>
                    <FormControl
                        placeholder="Chercher"
                        aria-label="Chercher"
                        aria-describedby="filtrate"
                        type='text' 
                        value={search_by} 
                        className="text_zone"
                        onChange={e => handle_change_input(e, setSearch_by)}
                    />
                </InputGroup>
                <div><div className='float_right button_stand' onClick={() => get_result(1)}>
                    <FontAwesomeIcon icon={faSearch} />
                </div></div>
            </div>

        </Row>
        
        {list.map((item, key) => <div key={key}><ProfilWidget width_percent={100} key={key} profil={item} action={() => get_info(item.key)}/></div>)}
        <Pagination_normal 
            current_page={page_current}
            last_page={page_last}
            go_to={get_result}
        />
    </div> );
}
 
export default Structure_Search;