import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/index.css';
import './style/index.scss';
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import Mainswitcher from './Mainswitcher';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Mainswitcher />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
