import { useEffect, useState } from "react";
import { Button, Col, Form, Row, FormGroup, FormLabel, FormControl, Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { check_is_200, post, processParams } from "../../../adapters/xhr";
import TextQ from "../../../tools/components/Texts/TextQ";
import { handle_change_input } from "../../../tools/HInput";





const Program_Form = () => {

    const account = useSelector((state) => state.account );
    const history = useHistory();
    const { center_key, program_key } = useParams();

    let is_creation = program_key == undefined;

    const [title, setTitle] = useState('');
    const [refer, setRefer] = useState('');
    const [nbrhrs, setNbrhrs] = useState('');
    const [prix, setPrix] = useState('');
    const [objectif, setObjectif] = useState('');
    const [description, setDescription] = useState('');
    const [attestation, setAttestation] = useState('');

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    const goback = () => history.goBack();

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if(form.checkValidity() === true)
        {
            const params = {
                tkn: account.info.tkn,
                ctr: center_key,
                prg: program_key,
                title,
                refer,
                obj: objectif,
                nbrh: nbrhrs,
                pri: prix,
                des: description,
                att: attestation,
                isc: is_creation
            }

            const toastId = toast.loading('Envoi');
            post("prgm", "update", processParams(params))
            .then(function (xhr) {
                if(check_is_200(xhr)) {
                    console.log(xhr.data);
                    if('state' in xhr.data && 'msg' in xhr.data)
                    {
                        if(xhr.data.state)
                        {
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setTimeout(()=> { goback(); }, 2000);
                        }
                        else
                        {
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                    }
                    else
                    {
                        toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                    }
                }else{
                    toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                }
                
            }).catch(function(error){
                toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
            }).then(function (){
                setLoading(false);
                // toast.dismiss(toastId);
            });
        }
        setValidated(true);
    }

    const getData = () => {
        const params = processParams({
            tkn:account.info.tkn, 
            ctr: center_key,
            prg: program_key
        });
        post("prgm", "info", params).then(function (xhr) {
            if(check_is_200(xhr)) {
                const info = xhr.data.program;
                setTitle(info.titre || '');
                setRefer(info.reference || '');
                setObjectif(info.objectif || '');
                setPrix(info.prix || []);
                setNbrhrs(info.nbr_heures || []);
                setDescription(info.description || '');
                setAttestation(info.attestation || '');
            }
        }).catch(function(error){
            // console.log('Error', error);
        }).then(function (){
            // console.log('END');
        });
    };

    useEffect(() => {
        if(!is_creation) getData();
        // return () => { setIsMounted(false); }; 
    }, []);

    
    return ( <div>
            <Row className='headerContentPart' >
                <div className='flex_between'>
                    <div className='title'>{is_creation ? 'Nouveau' : 'Modification' } programme</div>
                </div>
            </Row>
            
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
                <FormGroup as={Col} md={2}>
                    <FormLabel>Référence</FormLabel>
                    <FormControl type="text" value={refer} onChange={(e)=>{handle_change_input(e, setRefer)}} required/>
                    <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                </FormGroup>
                <FormGroup as={Col}>
                    <FormLabel>Titre</FormLabel>
                    <FormControl type="text" value={title} onChange={(e)=>{handle_change_input(e, setTitle)}} required/>
                    <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                </FormGroup>
            </Row>
            <Row>
                <FormGroup as={Col}>
                    <FormLabel>Objectif</FormLabel>
                    <FormControl type="text" value={objectif} onChange={(e)=>{handle_change_input(e, setObjectif)}} required/>
                    <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                </FormGroup>
            </Row>
            <Row>
                <FormGroup as={Col}>
                    <FormLabel>Nombre d'heure{nbrhrs > 1 && 's'} / stagiaire</FormLabel>
                    <FormControl type="text"  value={nbrhrs} onChange={(e)=>{handle_change_input(e, setNbrhrs)}} required/>
                    <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                </FormGroup>
                <FormGroup as={Col}>
                    <FormLabel>Prix H.T. / heure</FormLabel>
                    <FormControl type="text" value={prix} onChange={(e)=>{handle_change_input(e, setPrix)}} required/>
                    <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                </FormGroup>
            </Row>
            <Row>
                <FormGroup  as={Col}>
                    <FormLabel>Description</FormLabel>
                    <TextQ value={description} setValue={setDescription} required={true} validated={validated} />
                </FormGroup>
            </Row>
            <Row>
                <FormGroup  as={Col}>
                    <FormLabel>Attestation</FormLabel>
                    <TextQ value={attestation} setValue={setAttestation} required={true} validated={validated} />
                </FormGroup>
            </Row>


            {!loading && <Button variant="primary" type="submit">
                Enregistrer
            </Button>}

            {loading && <Button variant="primary" disabled>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                <span className="sr-only">Loading...</span>
            </Button>}
            {/* <Toaster
                position="bottom-right"
                reverseOrder={false}        /> */}
        </Form>
    </div> );
}
 
export default Program_Form;