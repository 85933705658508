import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { post, processParams } from '../../adapters/xhr';
import Session_Page from '../session/Items/Sessions/Session_Page';

const Former_Session = () => {
    const account = useSelector((state)=>state.account);
    const history = useHistory();
    const { session_key } = useParams();

    const [session, setSession] = useState([]);
    
    const getData = () => {
        post('session', 'fiche', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key
        }))
        .then(function (xhr){
            if(('status' in xhr) && (xhr.status===200)) {
                setSession(xhr.data);
            }else{
                
            }
        }).catch(function (error){
            
        });
    }

    useEffect(() => getData(), []);

    return ( <Session_Page 
                    see_documents={true}
                    see_trainees={true}
                    see_notes={true}
                    write_note={true}
                    trainee_msg={true}
                    admin_msg={true}
                    need_conf_presence={false}
                    
                /> );
}
 
export default Former_Session;