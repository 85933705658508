import { useEffect, useState } from "react";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { check_is_200, downloadBlob, post, processParams, progressMessage } from "../../../adapters/xhr";
import { useHistory, useParams } from "react-router-dom";
import DeleteConfirmation from "../../../tools/components/popups/DeleteConfirmation";
import { getItemRoute } from "../../../Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faTimes } from "@fortawesome/free-solid-svg-icons";
import Survey_Programs_List from "./Survey_Program/Survey_Programs_List";

const Survey_Fiche = () => {

    const account = useSelector((state) => state.account);
    const history = useHistory();
    const { center_key, survey_key } = useParams();

    const [survey, setSurvey] = useState([]);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const getData = () => {
        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            srv: survey_key
        };

        post('survey', 'form', processParams(params))
        .then(xhr => {
            if(check_is_200(xhr)) 
            {
                if('survey' in xhr.data)
                {
                    let surv = xhr.data.survey;
                    setSurvey(surv);
                }
            }else{
                
            }
        }).catch(function (error){
            
        });
    }

    useEffect(() => {
        getData();
    }, []);

    const go_update = () => {
        history.push({
            pathname:getItemRoute('center_survey_update')+center_key+'/'+survey_key,
            state:{back:true}
        });
    }

    const goback = () => history.goBack();

    const go_delete = (prog) => setDisplayConfirmationModal(true);

    const hideConfirmationModal = () => setDisplayConfirmationModal(false);

    const act_delete = () => {
        hideConfirmationModal();

        let params = processParams({
            tkn:account.info.tkn, 
            ctr:center_key,
            srv:survey_key
        });

        const toastId = toast.loading('Envoi');

        post("survey", "delete", params)
        .then(function (xhr) {
            if(check_is_200(xhr)) {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        goback();
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else 
                {
                    toast.error(<b>Problème d'envoi des données !</b>, { id: toastId, });
                }
            }else{
                toast.error(<b>Problème de suppression !</b>, { id: toastId, });
            }
            
        }).catch(function(error){
            toast.error(<b>Problème de suppression !</b>, { id: toastId, });
        });
    }

    
    return ( <div>
        <Row className='headerContentPart' >
                <div className='flex_between'>
                    <div className='title'>Enquête</div>
                    <div className=''>
                        {/* <div className='button_stand' onClick={() => go_download()}>
                            <FontAwesomeIcon icon={faFileDownload}/>
                        </div> */}
                        <div className='button_stand' onClick={() => go_update()}>
                            <FontAwesomeIcon icon={faCog}/>
                        </div>
                        <div className='button_stand' onClick={() => go_delete()}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </div>
                    </div>
                </div>
            </Row>

            <Form className='show_info_as_form'>
                <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Titre</div>
                            <div className='profil_show_item_value'>{survey.titre || ''}</div>
                        </div>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Type</div>
                            <div className='profil_show_item_value'>{survey.type_name || ''}</div>
                        </div>
                    </FormGroup>
                </Row>
                {survey.descr && <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item'>
                            <div className='profil_show_item_title'>Description</div>
                            <div className='profil_show_item_value nl2br'>{(survey.descr)}</div>
                        </div>
                    </FormGroup>
                </Row>}
            </Form>
        

            <Survey_Programs_List
                account={account}
                survey_key={survey_key}
            />

            <DeleteConfirmation 
                        showModal={displayConfirmationModal} 
                        confirmModal={act_delete}
                        hideModal={hideConfirmationModal} 
                        message={"Êtes-vous sûr de vouloir supprimer l'enquête \""+(survey.titre || '')+"\" ?"}
                    />
    </div> );
}
 
export default Survey_Fiche;