import { useEffect, useState } from "react";
import { Col, Form, Row, FormControl, FormGroup, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { check_is_200, post, processParams } from "../../../adapters/xhr";
import { getItemRoute } from "../../../Routes";
import { handle_change_input } from "../../../tools/HInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faInfo, faInfoCircle, faPlus, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import { filter_search_check_item } from "../../../tools/DataTableOpt";
import DeleteConfirmation from "../../../tools/components/popups/DeleteConfirmation";

const Client_List = () => {
    
    const account = useSelector((state)=>state.account);

    const history = useHistory();
    
    const [clients_list, setClients_list] = useState([]);
    const [filter_by, setFilter_by] = useState('');

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [item_to_remove, setItem_to_remove] = useState({});

    const getData = () => {
        let params = processParams({
            tkn:account.info.tkn, 
            ctr:account.ctr.key
        });

        post("ctrcli", "list", params)
        .then(function(xhr){
            if(check_is_200(xhr))
            {
                if('list' in xhr.data) setClients_list(xhr.data.list);
            }
            else
            {

            }
        });
    }

    useEffect(() => {
        getData();
    }, []);
    
    const add_action = () => {
        history.push({
            pathname:getItemRoute('center_client_novel')+account.ctr.key,
            state:{back:true}
        });
    }

    const go_info = (client_key) => {
        history.push({
            pathname:getItemRoute('center_client_info')+account.ctr.key+'/'+client_key,
            state:{back:true}
        });
    }
    
    const go_update = (client_key) => {
        history.push({
            pathname:getItemRoute('center_client_update')+account.ctr.key+'/'+client_key,
            state:{back:true}
        });
    }

    const go_delete = (client) => {
        setItem_to_remove(client);
        setDisplayConfirmationModal(true);
    }

    const hideConfirmationModal = () => setDisplayConfirmationModal(false);

    const act_delete = () => {
        hideConfirmationModal();
        let params = processParams({
            tkn:account.info.tkn, 
            ctr:account.ctr.key,
            cli:item_to_remove.key
        });
        const toastId = toast.loading('Envoi');

        post("ctrcli", "delete", params)
        .then(function (xhr) {
            if(check_is_200(xhr)) {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        getData();
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else 
                {
                    toast.error(<b>Problème d'envoi des données !</b>, { id: toastId, });
                }
            }else{
                toast.error(<b>Problème de suppression !</b>, { id: toastId, });
            }
            
        }).catch(function(error){
            toast.error(<b>Problème de suppression !</b>, { id: toastId, });
        });
    }


    const getActions = (client) => {
        return (<div className='buttons_table'>
                    <div></div>
                    <div className='button_stand' data-tooltip="Informations" onClick={() => go_info(client.key)}>
                        <FontAwesomeIcon icon={faInfoCircle}/>
                    </div>
                    <div className='button_stand' data-tooltip="Modifier" onClick={() => go_update(client.key)}>
                        <FontAwesomeIcon icon={faCog}/>
                    </div>
                    <div className='button_stand' data-tooltip="Supprimer" onClick={() => go_delete(client)}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </div>
                </div>);
    }
    const columns = [
        { name: 'Client', selector: row => row.nom, sortable: true,},
        { name: 'Responsable', selector: row => row.representant, sortable: true,},
        { name: 'Action', selector: row => row.action, sortable: true, minWidth:1, cell:row => <div style={{width:180}}>{getActions(row)}</div>},
    ];

    const filteredData = clients_list.filter(item => {
        let filter_key = filter_by.toLocaleLowerCase();
        return filter_search_check_item(filter_key, item, 
            ['nom', 'representant']);
    });

    return ( 
        <div>
        

            <Row className='headerContentPart' >
                <div className='flex_between'>
                    <div className='title'>Client(s)</div>
                    {clients_list.length > 0 && <InputGroup className="search width_50">
                                                <InputGroup.Text className="icon">
                                                    <FontAwesomeIcon
                                                        icon={faSearch}
                                                    /> 
                                                </InputGroup.Text>
                                                <FormControl
                                                    placeholder="Filtrer"
                                                    aria-label="Filtrer"
                                                    aria-describedby="filtrate"
                                                    type='text' 
                                                    value={filter_by} 
                                                    className="text_zone"
                                                    onChange={e => handle_change_input(e, setFilter_by)}
                                                />
                                            </InputGroup>}
                    <div><div className='float_right button_stand' onClick={() => add_action()}>
                        <FontAwesomeIcon icon={faPlus} />
                    </div></div>
                </div>
            </Row>

            <DataTable
                    pagination
                    fixedHeader
                    columns={columns}
                    data={filteredData}
                    noDataComponent="Aucun Client n'est enregistré !"
                    paginationComponentOptions={{
                                                    rowsPerPageText: 'Clients par page',
                                                    rangeSeparatorText: 'sur',
                                                    selectAllRowsItem: true,
                                                    selectAllRowsItemText: 'Tous',
                                                }}
                />

            <DeleteConfirmation 
                        showModal={displayConfirmationModal}
                        confirmModal={act_delete}
                        hideModal={hideConfirmationModal}
                        message={"Êtes-vous sûr de vouloir supprimer le client \""+item_to_remove.nom+"\" ?"}
                    />
                
            
        </div>
    );
}
 
export default Client_List;