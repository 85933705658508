import { useEffect, useState } from "react";
import { Col, Form, FormGroup, ProgressBar, Row, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { check_is_200, downloadBlob, popup_show_file, post, processParams, progressMessage } from "../../../adapters/xhr";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { percentage } from "../../../tools/math";
import { handle_change_input } from "../../../tools/HInput";
import Select from 'react-select';

const Survey_Synthese_Show = ({ 
    is_satisfaction, 
    groups, 
    nbr_reponse, 
    filterOption, 
    isloading, 
    getData, 
    go_download,
    form_by, setForm_by,
    form_dd, setForm_dd,
    form_df, setForm_df,

}) => {

    
    const show_groups = () => {
        
        let html = [];
        let score = false;
        
        let nbr_score = 0;
        let nbr_tot = 0;

        for(let key in groups)
        {
            let group_info = show_goup(key, groups[key]);
            html.push(group_info.html);
            if(group_info.score !== false)
            {
                nbr_score += group_info.score;
                nbr_tot++;
            }
        }
        score = nbr_score / nbr_tot;


        return <div>
            {!isloading && <div>
                {nbr_reponse ? 
                    <div className='alert alert-info flex_between'>
                        <div>
                            Nombre de réponse{nbr_reponse>1 && 's'} {is_satisfaction ? '' : 'à cet enquête '}: <b>{nbr_reponse}</b><br/>
                            <div className="button_stand" onClick={()=>go_download()}>
                                <FontAwesomeIcon icon={faDownload} />
                                Télécharger
                            </div>
                        </div>
                        <div>{show_score(score)}</div>
                     </div> : 
                    <div className='alert alert-warning'>Pas de réponse</div>}
            </div>}
            {html}
            </div>;
    }
    const show_goup = (key, grp) => {

        let html = [];
        let score = false;
        
        let nbr_score = 0;
        let nbr_tot = 0;

        for(let key in grp.qsts)
        {
            let group_qst = show_qst(key, grp.qsts[key]);
            html.push(group_qst.html);
            if(group_qst.score !== false)
            {
                nbr_score += group_qst.score;
                nbr_tot++;
            }
        }
        if(nbr_tot>0) score = (nbr_score / nbr_tot);

        html = <div key={key} style={{border:'1px solid #ddd', marginBottom:'10px'}}>
                    <div className='flex_between'>
                        <div style={{padding:'20px 10px'}}><h3>{grp.titre}</h3></div>
                        {score !== false && show_score(score)}
                    </div>
                    <div>{html}</div>
                </div>

        return {html, score};
    }

    const show_qst = (key, qst) => {

        let res_choix = show_choix(qst.choix);

        let html = res_choix.html || [];
        let score = res_choix.score;
        
        
        html = <div key={key} style={{border:'1px solid #ddd', margin:'10px', padding:'10px', }}>
            <div>
                <Row>
                    <FormGroup as={Col} md={3}>
                        <h4>{qst.titre}</h4>
                        {/* {score != false && <div>{show_score(score)}</div>} */}
                    </FormGroup>
                    {qst.choix.length>0 && <FormGroup as={Col}>
                        {html}
                    </FormGroup>}
                </Row>
                {'txt' in qst && <div>
                    {qst.txt.length==0 && <div>Pas de réponse !</div>}
                    {qst.txt.map((txt, key)=> <Row key={key} >
                        <FormGroup as={Col} style={{boxShadow:'0px 0px 6px #ddd', margin:'0px 10px 10px 30px', borderRadius:'5px'}} className='nl2br'>
                            {txt}
                        </FormGroup>
                    </Row>)}
                </div>}
            </div></div>

        return {html, score};
    }

    const show_choix = (choix) => {
        let html = [];
        let score = false;

        let adapted = [];
        let nbr_score = 0;
        let nbr_tot = 0;

        // console.log(choix);
        for(let ch of choix)
        {
            adapted.push({titre: ch.titre, score:ch.score || '', nbr:ch.nbr, color:'red', bgcolor:'yellow'}); 
            if('score' in ch)
            {
                if(score==false) score = 0;
                nbr_score += parseInt(ch.score) * ch.nbr;
            }
            nbr_tot += ch.nbr;
        }

        if(adapted.length>0) html = present_choix(adapted, nbr_tot);

        if(nbr_score > 0) score = nbr_score / nbr_tot;

        html = <div className='flex_between'>
            <FormGroup as={Col}>{html}</FormGroup>
            <FormGroup as={Col} style={{textAlign:'right'}}>{score !== false && <div>{show_score(score)}</div>}</FormGroup>
        </div>;

        return {html, score}
    }

    const present_choix = (adapted, total) => {
        let html = [];
        let cmp = 0;

        for(let choix of adapted)
            html.push(<tr key={cmp++}>
                <td style={{textAlign:'right'}}>{choix.titre}</td>
                <td style={{width:'150px', paddingLeft:'10px'}}>
                    <ProgressBar 
                            now={percentage(choix.nbr, total, 0)}
                            className={'progress_color_'+(cmp%10)}
                        />
                </td>
                <td style={{width:'50px', textAlign:'right'}}>{choix.nbr}</td>
                <td style={{width:'50px', textAlign:'right'}}>{percentage(choix.nbr, total, 0)} %</td>
            </tr>)

        return <table style={{width:'100%'}}><tbody>{html}</tbody></table>;
    }

    const show_score = (score) => <div className={'survey_item_score '+( score > 7 ? 'good_point' : ( score > 4.5 ? 'medium_point' : 'bad_point' ))}>
            {score.toFixed(1)}
            <div className='sur_10'> /10</div>
        </div>

    return ( <div>
        <div>
                <Row>
                    <FormGroup as={Col}>
                        <Form.Label>Filtrer par</Form.Label>
                        <Select
                                    defaultValue={form_by}
                                    onChange={setForm_by}
                                    options={filterOption}
                                    noOptionsMessage={()=>{ return 'Non trouvé !';}}
                                    className='select_component'
                                    placeholder='Selectionner ...'

                                />
                    </FormGroup>
                    <FormGroup as={Col}>
                        <Form.Label>Date début</Form.Label>
                        <Form.Control type='date' value={form_dd} onChange={(e)=>handle_change_input(e, setForm_dd)}></Form.Control>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <Form.Label>Date fin</Form.Label>
                        <Form.Control type='date' value={form_df} onChange={(e)=>handle_change_input(e, setForm_df)}></Form.Control>
                    </FormGroup>
                </Row>
                <FormGroup as={Col}>
                    
                    <div onClick={()=>getData()} className='button_stand' style={{marginBottom:'25px'}}>
                        {isloading && <Spinner animation="border" role="status" size='sm'/>}
                        &nbsp; Chercher &nbsp;
                    </div>
                </FormGroup>
            </div>
            {/* {score_synthese && show_score(score_synthese)} */}
            {groups.length == 0 && <div>Pas de réponse !</div>}
            {groups.length > 0 && show_groups()}
    </div> );
}
 
export default Survey_Synthese_Show;