import { faEnvelope, faInbox, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "react-avatar";
import { Button } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { getItemRoute } from "../../../Routes";
import './index.scss';

const ProfilWidget = ({ profil, title, sub_title, size, action, 
        go_center_profil, center_key, 
        go_messenger_session, session_key, without_info, 
        is_former, is_member, is_trainee,
        without_border,transparent, avatarClass, padding,
        orientation, width_percent, buttons, buttonsNoWrap
    }) => {

    const history = useHistory();

    // console.log(profil);
    const empty_fct = () => {};

    const redirect_to_profil_page = () => {
        // if(is_former || is_member){
        history.push({
            pathname: getItemRoute('center_member')+profil.key,
            state: {back:true},
        });
        // }else if(is_trainee){

        //     history.push({
        //         pathname: getItemRoute('center_trainee_fiche')+ session_key + '/' + profil.key,
        //         state: {back:true},
        //     });
        // }
    }

    const redirect_to_messenger_session = () => {
        if(go_messenger_session){
            history.push({
                pathname: getItemRoute('messenger')+session_key+'/'+profil.key,
                state: {back:true},
            });
        }
    }



    let orientationClass =  orientation != 'h' ? 'vertical_show' : 'horizontal_show';

    if(orientation == 'h' && !size)
    {
        size = 100;
    }

    if(!size) size = 60;

    const buttons_div = buttons ? buttons.map((btn, key) => <div className={'btn '+btn.class} key={key} onClick={btn.action ? () => btn.action() : ()=>{}} style={{backgroundColor:(btn.bgcolor||''), color:(btn.color||'')}} data-tooltip={btn.tooltip}>{btn.title}</div>) : '';

    return ( 
            <div className={'profil_widget transparent'} style={{width:width_percent+'%'}}>
                <div className={(action != undefined ? 'clickable_cursor' : '')+(without_border ? '' : ' with_border')} style={{padding:padding ? padding : ''}} onClick={action || empty_fct}>
                    <div className={orientationClass}>
                        <div className='profil_widget_avatar' style={{width:size}}>
                            <Avatar className={'profil_widget_avatar_Comp '+avatarClass} name={profil.nom ? profil.nom+' '+profil.pnom : ''} size={size} round={true} src={profil.avatar_img || ''} />
                        </div>
                        {without_info!=true && <div className='profil_widget_info'>
                            <div className='profil_widget_name'>
                                {profil.nom ? profil.nom+' '+profil.pnom : ''} {title && <div> {title} </div>} {buttonsNoWrap && buttons_div}
                            </div>
                            {sub_title && <div className='profil_widget_info_sub_title'>{sub_title}</div>}
                            {go_center_profil && <div className='btn' onClick={() => redirect_to_profil_page()}  data-tooltip='Informations'><FontAwesomeIcon icon={faInfoCircle}/></div>}
                            {go_messenger_session && <div className='btn' onClick={() => redirect_to_messenger_session()}  data-tooltip='Messagerie'><FontAwesomeIcon icon={faEnvelope}/></div>}
                            {!buttonsNoWrap && buttons_div}
                        </div>}
                    </div>
                </div> 
            </div>
        );
}
 
export default ProfilWidget;