import { useEffect, useState } from "react";
import { Col, Form, FormControl, InputGroup, Row, FormGroup, FormLabel } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { check_is_200, downloadBlob, popup_show_file, post, processParams, progressMessage } from "../../../adapters/xhr";
import { getItemRoute } from "../../../Routes";
import { handle_change_input } from "../../../tools/HInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCog, faFileAlt, faFileArchive, faFileDownload, faInfo, faInfoCircle, faPlus, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { datetime_sql_to_str } from "../../../tools/Dates";
import DataTable from "react-data-table-component";
import { filter_search_check_item, sort_function_by } from "../../../tools/DataTableOpt";
import DeleteConfirmation from "../../../tools/components/popups/DeleteConfirmation";

const Devis_List = () => {
    
    const account = useSelector((state)=>state.account);

    const history = useHistory();
    
    const [clients_list, setClients_list] = useState([]);
    const [filter_by, setFilter_by] = useState('');

    const [msg_avoir, setMsg_avoir] = useState('');

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [item_to_remove, setItem_to_remove] = useState({});

    const getData = () => {
        let params = processParams({tkn:account.info.tkn, ctr:account.ctr.key});
            
        post("ctrdev", "list", params)
        .then(function(xhr){
            if(check_is_200(xhr)) {
                if('list' in xhr.data)
                {
                    let list = xhr.data.list;
                    
                    let adapted_list = [];
                    for(let item of list)
                    {
                        let new_item = item;
                        new_item['date_cr_str'] = datetime_sql_to_str(item.date_cr);
                        new_item['action'] = (<div className='buttons_table'>
                                                <div></div>
                                                <div className='button_stand' data-tooltip="Informations" onClick={() => go_info(item.key)}>
                                                    <FontAwesomeIcon icon={faInfoCircle}/>
                                                </div>
                                                {item.signature_file &&  <div className='button_stand' data-tooltip="Télecharger devis signé" onClick={() => go_download_devis(item.program_name, item.key, item.signature_ext)}>
                                                    <FontAwesomeIcon icon={faFileDownload}/>
                                                </div>}
                                                {!item.signature_file && <div className='button_stand' data-tooltip="Télecharger" onClick={() => go_download(item.program_name, item.key)}>
                                                    <FontAwesomeIcon icon={faFileDownload}/>
                                                </div>}
                                                {!item.signature_file && <div className='button_stand' data-tooltip="Modifier" onClick={() => go_update(item.key)}>
                                                    <FontAwesomeIcon icon={faCog}/>
                                                </div>}
                                                {(item.was_canceled == '') && <div className='button_stand' data-tooltip="Annuler" onClick={() => go_delete(item)}>
                                                    <FontAwesomeIcon icon={faTimes}/>
                                                </div>}
                                                {(item.was_canceled != '') && <div className='button_stand' data-tooltip="Facture d'avoir" onClick={() => get_avoir(item)}>
                                                    <FontAwesomeIcon icon={faFileArchive}/>
                                                </div>}
                                            </div>);
                        adapted_list.push(new_item);
                    }
                    setClients_list(adapted_list);
                }
            }else{

            }
        });
    }

    useEffect(() => {
        getData();
    }, []);
    
    const add_action = () => {
        history.push({
            pathname:getItemRoute('center_devis_novel')+account.ctr.key,
            state:{back:true}
        });
    }

    const go_update = (devis_key) => {
        history.push({
            pathname:getItemRoute('center_devis_update')+account.ctr.key+'/'+devis_key,
            state:{back:true}
        });
    }

    const go_info = (devis_key) => {
        history.push({
            pathname:getItemRoute('center_devis_info')+account.ctr.key+'/'+devis_key,
            state:{back:true}
        });
    }

    const go_convention = () => {
        history.push({
            pathname:getItemRoute('center_devis_convention')+account.ctr.key,
            state:{back:true}
        });
    }


    const go_delete = (devis) => {
        setItem_to_remove(devis);
        setDisplayConfirmationModal(true);
    }

    const hideConfirmationModal = () => setDisplayConfirmationModal(false);

    const act_delete = () => {
        hideConfirmationModal();

        let params = processParams({
            tkn:account.info.tkn, 
            ctr:account.ctr.key,
            dev:item_to_remove.key,
            msga:msg_avoir,
        });

        const toastId = toast.loading('Envoi');

        post("ctrdev", "delete", params)
        .then(function (xhr) {
            if(check_is_200(xhr)) {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        getData();
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else 
                {
                    toast.error(<b>Problème d'envoi des données !</b>, { id: toastId, });
                }
            }else{
                toast.error(<b>Problème de suppression !</b>, { id: toastId, });
            }
            
        }).catch(function(error){
            toast.error(<b>Problème de suppression !</b>, { id: toastId, });
        });
    }

    const go_download = (devis_titre, devis_key) => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        post('ctrdev', 'getfile', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            dev: devis_key
        }))
        .then(function (xhr){
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé</b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });

        }).catch(function (err){
            toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    }

    const get_avoir = (devis_item) => {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        post('ctrdev', 'getavr', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            dev: devis_item.key
        }))
        .then(function (xhr){
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé</b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });

        }).catch(function (err){
            toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    }

    const confirmAvoir = (item_devis) => {
        if(item_devis.numfac=='') return '';

        return <div><div className="alert alert-warning">
                L'annulation générera un avoir sur la facture <b>N°{item_devis.numfac}</b>
            </div>
            <Row>
                <FormGroup as={Col}>
                    <FormLabel>Veuillez laisser un message (facultafif)</FormLabel>
                    <FormControl as="textarea" value={msg_avoir} onChange={(e)=>{handle_change_input(e, setMsg_avoir)}} placeHolder='votre message ...'/>
                </FormGroup>
            </Row>
        </div>;
    }

    const go_download_devis = (devis_titre, devis_key, file_ext) => {

        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        post('ctrdev', 'getfilesgd', processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            dev: devis_key
        }))
        .then(function (xhr)
        {
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé </b>, { id: toastId, });
                }
                else 
                {
                    toast.error(<b>Problème de chargement !</b>, { id: toastId, });
                }
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        
        }).catch(function (err){
            toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    }
    const caseInsensitiveSort = (rowA, rowB) => {
        const a = rowA.date_cr.toLowerCase();
        const b = rowB.date_cr.toLowerCase();
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };

    const columns = [
        { name: 'N° Devis', selector: row => row.num, sortable: true, maxWidth:'110px'},
        { name: 'Création', selector: row => row.date_cr_str, sortable: true, sortFunction:caseInsensitiveSort, width:'150px'},
        { name: 'Client', selector: row => row.client_name, sortable: true,},
        { name: 'N° Facture', selector: row => row.numfac, sortable: true, maxWidth:'120px'},
        { name: 'N° Avoir', selector: row => row.numavr, sortable: true, maxWidth:'110px'},
        { name: 'Montant HT', selector: row => row.devis_montant, cell:row => <div>{row.devis_montant > 0 && <div>{row.devis_montant} {(row.is_cashed && ((row.opco_montant > 0 && row.is_cashed_opco) || row.opco_montant==0)) && <FontAwesomeIcon icon={faCheck}/>}</div>}</div>, sortable: true, width:'120px'},
        { name: 'Pris en charge (opco, ...) HT', selector: row => row.opco_montant, cell:row => <div>{row.opco_montant > 0 && <div>{row.opco_montant} {row.is_cashed_opco && <FontAwesomeIcon icon={faCheck}/>}</div>}</div>, sortable: true, maxWidth:'120px'},
        { name: 'OPCO', selector: row => row.opco_name, sortable: true,},
        { name: 'Montant à payer par client ', selector: row => row.devis_montant - row.opco_montant, cell:row => <div>{row.devis_montant > 0 && <div>{row.devis_montant - row.opco_montant} {row.is_cashed && <FontAwesomeIcon icon={faCheck}/>}</div>}</div>, sortable: true,},
        { name: 'Programme', selector: row => row.program_name, sortable: true,},
        { name: 'Semaine', selector: row => getSemaine(row), sortable: true, maxWidth:'110px'},
        { name: 'Etat', selector: row => row.state, sortable: true, maxWidth:'100px', cell:row => <div className='session_mini_item' style={{backgroundColor:(row.state_clr||'')}}>{row.state}</div>},
        { name: 'Action', selector: row => row.action, sortable: true, minWidth:'185px', cell:row => <div style={{width:180}}>{row.action}</div>},
    ];

    const getSemaine = (row) => 
    {
        let stt = '';
        let sttcls = '';
        if('sesstate' in row && row.sesstate)
        {   
            switch(row.sesstate)
            {
                case 'avn': stt = " / A venir";  sttcls="session_mini_item session_mini_item_a_venir";  break;
                case 'pas': stt = " / Passée";  sttcls="session_mini_item session_mini_item_passer";    break;
                case 'enc': stt = " / En cours";  sttcls="session_mini_item session_mini_item_en_cour";     break;
                default: stt = '';
            }
        }

        return <div className={'div_according ' + sttcls}>{row.session_week} {stt}</div>
    }

    const filteredData = clients_list.filter(item => {
        let filter_key = filter_by.toLocaleLowerCase();
        return filter_search_check_item(filter_key, item, 
            ['num', 'program_name', 'session_week', 'client_name', 'state']);
    });
    return ( 
        <div>
            
            <Row className='headerContentPart'>
                <div className='flex_between'>
                    <div className='title'>Devis / Facture(s)</div>
                    {clients_list.length > 0 && <InputGroup className="search width_50">
                                                <InputGroup.Text className="icon">
                                                    <FontAwesomeIcon
                                                        icon={faSearch}
                                                    /> 
                                                </InputGroup.Text>
                                                <FormControl
                                                    placeholder="Filtrer"
                                                    aria-label="Filtrer"
                                                    aria-describedby="filtrate"
                                                    type='text' 
                                                    value={filter_by} 
                                                    className="text_zone"
                                                    onChange={e => handle_change_input(e, setFilter_by)}
                                                />
                                            </InputGroup>}
                    <div>
                        <div className='float_right button_stand' onClick={() => add_action()}>
                            <FontAwesomeIcon icon={faPlus} />
                        </div>
                    </div>
                    <div>
                        <div className='float_right button_stand' onClick={() => go_convention()}>
                            Convention de formation professionnelle
                        </div>
                    </div>
                </div>
                
                {/* <FormGroup as={Col}>
                    <div className='button_stand' 
                            onClick={() => go_avoirs()}>
                            Liste des avoirs
                    </div>
                </FormGroup> */}
            </Row>
            <div className='tableOfPage'>
                <DataTable
                    pagination
                    fixedHeader
                    columns={columns}
                    data={filteredData}
                    paginationPerPage={40}
                    paginationRowsPerPageOptions={[40, 60, 100]}
                    noDataComponent="Aucun devis n'est enregistré !"
                    paginationComponentOptions={{
                                                    rowsPerPageText: 'Devis par page',
                                                    rangeSeparatorText: 'sur',
                                                    selectAllRowsItem: true,
                                                    selectAllRowsItemText: 'Tous',
                                                }}
                />
            </div>
            <DeleteConfirmation 
                    showModal={displayConfirmationModal} 
                    titleModal="Confirmation d'annulation"
                    confirmModal={act_delete}
                    hideModal={hideConfirmationModal} 
                    message={<div>Êtes-vous sûr de vouloir annuler le devis <b>N°{item_to_remove.num}</b> ?</div>}
                    messageComplement={confirmAvoir(item_to_remove)}
                    confirmMessage='Oui, je confirme'
                />
        </div>
    );
}
 
export default Devis_List;