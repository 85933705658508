import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, FormGroup, Form, Col, FormLabel, FormControl, Button, Spinner, InputGroup } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { check_is_200, post, processParams, progressMessage } from "../../../adapters/xhr";
import { useSelector } from "react-redux";
import { handle_change_input } from "../../../tools/HInput";

const Client_Form = () => {
    
    const account = useSelector((state) => state.account );
    const history = useHistory();
    const { center_key, client_key } = useParams();

    let is_creation = client_key == undefined;
    // console.log('center_key', !center_key);
    // console.log('client_key', !client_key);
    
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isMounted, setIsMounted] = useState(true);

    const [categ_list, setCateg_list] = useState([]);

    const [title, settitle] = useState('');
    const [categ, setCateg] = useState('');
    const [jurd, setjurd] = useState('');
    const [refcmta, setRefcmta] = useState('');
    const [decact, setDecact] = useState('');
    const [siret, setsiret] = useState('');
    const [siren, setsiren] = useState('');
    const [tel, settel] = useState('');
    const [fixe, setfixe] = useState('');
    const [delaif, setDelaif] = useState('');
    const [email, setemail] = useState('');
    const [adresse, setadresse] = useState('');
    const [cp, setcp] = useState('');
    const [ville, setville] = useState('');
    
    const [repr_gdr, setRepr_gdr] = useState('mr');
    const [repr, setRepr] = useState('');
    const [delai_list, setDelai_list] = useState([]);
    

    const repr_gdr_types = [ 
        {n:'Mr', v:'mr'},
        {n:'Mme', v:'mme'}
    ];

    let progressShow = 0;
    
    const getData = () => {
        getData_client();
    }

    const getData_client = () => {
        
        const params = processParams({
            tkn:account.info.tkn, 
            ctr: center_key,
            cli: client_key
        });
        post("ctrcli", "info", params).then(function (xhr) {
            if(check_is_200(xhr) && isMounted) {
                
                setCateg_list(xhr.data.catgs || []);

                const info = xhr.data.client || {};

                if('nom' in info)   settitle(info.nom);
                if('categ' in info)   setCateg(info.categ);
                if('juridique' in info)     setjurd(info.juridique);
                if('comptable' in info)     setRefcmta(info.comptable);
                if('nsiret' in info)    setsiret(info.nsiret);
                if('nsiren' in info)    setsiren(info.nsiren);
                if('ndecact' in info)   setDecact(info.ndecact);
                if('tel' in info)   settel(info.tel);
                if('fix' in info)   setfixe(info.fix);
                if('email' in info)     setemail(info.email);
                if('adresse' in info)   setadresse(info.adresse);
                if('cp' in info)    setcp(info.cp);
                if('ville' in info)     setville(info.ville);
                if('representant' in info)  setRepr(info.representant);
                if('representant_gender' in info)   setRepr_gdr(info.representant_gender);
                setDelaif(info.delai_fact || '');
                
                if('delais' in xhr.data && Array.isArray(xhr.data.delais))
                {
                    setDelai_list(xhr.data.delais);
                }
            }
        }).catch(function(error){
            // console.log('Error', error);
        }).then(function (){
            // console.log('END');
        });
    };

    useEffect(() => {
        getData();
        
        return () => { setIsMounted(false); }; 
    }, []);

    const goback = () => history.goBack();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        progressShow = 0;
        event.preventDefault();
        if(form.checkValidity() === true){

            const form = {
                title, categ, jurd, refcmta, siret, siren, decact, tel, fixe, email, adresse, cp, ville, 
                repr, repr_gdr,
                delf: delaif,
                tkn: account.info.tkn,
                ctr: center_key,
                cli: client_key,
                isc: is_creation
            };
            // console.log(form);
            let params = processParams(form);
            
            const toastId = toast.loading(progressMessage(progressShow));
            post("ctrcli", "update", params)
            .then(function (xhr) {
                if(check_is_200(xhr)) {
                    if('state' in xhr.data && 'msg' in xhr.data)
                    {
                        if(xhr.data.state)
                        {
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setTimeout(()=> { goback(); }, 2000);
                        }
                        else
                        {
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                    }
                    else
                    {
                        toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                    }
                }else{
                    toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
                }
                
            }).catch(function(error){
                toast.error(<b>Problème d'enregistrement!</b>, { id: toastId, });
            }).then(function (){
                setLoading(false);
                // toast.dismiss(toastId);
            });
            
        }
        setValidated(true);
    };

    return ( 
        <div>
            <Row className='headerContentPart' >
                <div className='title'>{is_creation ? 'Nouveau' : 'Modification' } client</div>
            </Row>
            
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <FormGroup  as={Col}>
                        <FormLabel>Nom</FormLabel>
                        <FormControl type="text" value={title} onChange={(e)=>{handle_change_input(e, settitle)}} maxLength={100} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Représentant</FormLabel>
                        <InputGroup>
                            <div as={Col} xl={4}>
                                <FormControl as='select' value={repr_gdr} onChange={(e)=>handle_change_input(e, setRepr_gdr)} required>
                                    {repr_gdr_types.map((item, key) => <option key={key} value={item.v}>{item.n}</option>)}
                                </FormControl>
                            </div>
                            <FormControl type="text" value={repr} onChange={(e)=>{handle_change_input(e, setRepr)}} maxLength={60} required/>
                            <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Délai de facturation</FormLabel>
                        <FormControl as='select' value={delaif} onChange={(e)=>handle_change_input(e, setDelaif)}>
                            {delai_list.map((item, key) => <option key={key} value={item.r}>{item.t}</option>)}
                        </FormControl>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Catégorie</FormLabel>
                        <FormControl as='select' value={categ} onChange={(e)=>handle_change_input(e, setCateg)} required>
                            <option value=''>Choisissiez</option>
                            {categ_list.map((item, key) => <option key={key} value={item.v}>{item.n}</option>)}
                        </FormControl>
                        <FormControl.Feedback type="invalid">Veuillez choisir</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Catégorie juridique</FormLabel>
                        <FormControl type="text"  value={jurd} onChange={(e)=>{handle_change_input(e, setjurd)}} maxLength={20} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Référence du comptable</FormLabel>
                        <FormControl type="text"  value={refcmta} onChange={(e)=>{handle_change_input(e, setRefcmta)}} maxLength={30}/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>N° de déclaration d’activité (NDA)</FormLabel>
                        <FormControl type="text"  value={decact} onChange={(e)=>{handle_change_input(e, setDecact)}} maxLength={17}/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>N° SIREN</FormLabel>
                        <FormControl type="text" value={siren} onChange={(e)=>{handle_change_input(e, setsiren)}} maxLength={17} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>N° SIRET</FormLabel>
                        <FormControl type="text" value={siret} onChange={(e)=>{handle_change_input(e, setsiret)}} maxLength={17} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Téléphone</FormLabel>
                        <FormControl type="text" value={tel} onChange={(e)=>{handle_change_input(e, settel)}} maxLength={17} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Fixe</FormLabel>
                        <FormControl type="text" value={fixe} onChange={(e)=>{handle_change_input(e, setfixe)}} maxLength={17}/>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <FormLabel>Email</FormLabel>
                        <FormControl type="text" value={email} onChange={(e)=>{handle_change_input(e, setemail)}} maxLength={50} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <FormLabel>Adresse</FormLabel>
                        <FormControl type="text" value={adresse} onChange={(e)=>{handle_change_input(e, setadresse)}} maxLength={100} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} xs={2}>
                        <FormLabel>Code postal</FormLabel>
                        <FormControl type="text" value={cp} onChange={(e)=>{handle_change_input(e, setcp)}} maxLength={5} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} xs={3}>
                        <FormLabel>Ville</FormLabel>
                        <FormControl type="text" value={ville} onChange={(e)=>{handle_change_input(e, setville)}} maxLength={55} required/>
                        <FormControl.Feedback type="invalid">Remplir le champ</FormControl.Feedback>
                    </FormGroup>
                </Row>
                {!loading && <Button variant="primary" type="submit">
                    Enregistrer
                </Button>}

                {loading && <Button variant="primary" disabled>
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>}
                {/* <Toaster
                    position="bottom-right"
                    reverseOrder={false}        /> */}
            </Form>
        </div>
     );
}

export default Client_Form;