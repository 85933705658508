import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { check_is_200, post, processParams } from '../../../adapters/xhr';
import { getItemRoute } from '../../../Routes';

const Devis_Smallinfo = ({ account }) => {

    const history = useHistory();

    const [list, setList] = useState([]);
    const [nbr, setNbr] = useState(0);

    const getData = () => {
        const params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key
        });

        post("ctrdev", "listi", params)
        .then(function(xhr){
            if(check_is_200(xhr))
            {
                setList(xhr.data.list || []);
                setNbr(xhr.data.nbr || 0);
            }
        }).catch(function (err){
            
        });
    }

    useEffect(() => {
        getData();
    }, []);

    const get_more = () => {
        history.push({
            pathname:getItemRoute('center_devises'),
            state:{back:false}
        });
    }

    const get_info = (item_key) => {
        history.push({
            pathname:getItemRoute('center_devis_info')+account.ctr.key+'/'+item_key,
            state:{back:true}
        });
    }

    if(!nbr) return null;
    return ( <div className='infob_item'>
                <div className='flex_between'>
                    <div className='infob_title'>Devis / Factures</div>
                    <div className='infob_nbr'>{nbr}</div>
                </div>
                <div className='infob_content'>
                    {list.map((item, key) => {
                        return ( key>2 ? null : <div key={key} className='infob_link clickable' onClick={(e)=>get_info(item.key)}>{item.num}</div>);
                    })}
                </div>
                <div className='infob_footer clickable' onClick={()=>get_more()}>Voir tout</div>
            </div> );
}

export default Devis_Smallinfo;