export function sort_function_by(the)
{
    const caseInsensitiveSort = (rowA, rowB) => {
        const a = (rowA[the]===false ? '' : rowA[the]).toLowerCase();
        const b = (rowB[the]===false ? '' : rowB[the]).toLowerCase();
        
    
        let rtrn = a > b ? 1 : ( b > a ? -1 : 0);
        console.log(a, b, the, rtrn);
        return rtrn;
    };
    return caseInsensitiveSort;
}

export function filter_search_check_item(search_key, item, keys)
{
    for(let key of keys)
    {
        if((key in item) && (item[key]+'').toLocaleLowerCase().includes(search_key)) return true;
    }
    return false;
}