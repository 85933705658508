import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { check_is_200, downloadBlob, popup_show_file, post, processParams, progressMessage } from "../../../../adapters/xhr";
import { getItemRoute } from "../../../../Routes";
import ProfilWidget from "../../../../tools/components/ProfilWidget/ProfilWidget";
import { handle_change_checkbox, show_paragraph } from "../../../../tools/HInput";
import Documents_List from "../Documents/Documents_List";
import Notes_List from "../Notes/Notes_List";
import Stagiaires_List from "../Stagiaires/Stagiaires_List";
import Survey_List from "../Survey/Survey_List";
import Session_cnf_pre from "./Session_cnf_pre";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faCogs, faDownload } from "@fortawesome/free-solid-svg-icons";
import Session_Horaire from "../../Session_Horaire";
import { Col, Row, FormGroup, InputGroup, ButtonGroup, Dropdown, DropdownButton, Form } from "react-bootstrap";
import Session_Room from "../Rooms/Session_Room";
import Presence_Trainee_Session from "../Presences/Presence_Trainee_Session";
import Session_Survey_List from "../Survey/Survey_List";
import toast from "react-hot-toast";
import ConfirmationModel from "../../../../tools/components/popups/ConfirmationModel";

const Session_Page = () => {

    let { session_key } = useParams();
    const history = useHistory();

    const account = useSelector((state) => state.account);
    const link_info = getItemRoute('center_member');

    const [session, setSession] = useState({});
    const [former_info, setFormer_info] = useState({});
    const [admin_info, setAdmin_info] = useState({});
    const [need_conf_presence, setNeed_conf_presence] = useState('');
    const [conf_presence, setConf_presence] = useState({cnf:false, msg:'', cnfed:false});
    const [adresseSession, setAdresseSession] = useState('');

    const [see_my_signs, setSee_my_signs] = useState('');
    const [see_documents, setsee_documents] = useState('');
    const [see_documents_adm, setSee_documents_adm] = useState('');
    const [see_enquetes, setSee_enquetes] = useState('');
    const [see_trainees, setsee_trainees] = useState('');
    const [see_notes, setsee_notes] = useState('');
    const [see_attendances_list, setsee_attendances_list] = useState('');
    const [see_satisfaction, setSee_satisfaction] = useState('');
    const [write_satisfaction, setWrite_satisfaction] = useState('');
    const [update_session, setupdate_session] = useState('');
    const [gestion_session, setGestion_session] = useState('');
    const [update_documents, setupdate_documents] = useState('');
    const [update_trainees, setupdate_trainees] = useState('');
    const [write_note, setwrite_note] = useState('');
    const [check_presence, setcheck_presence] = useState('');
    const [former_more, setformer_more] = useState('');
    const [former_msg, setformer_msg] = useState('');
    const [admin_more, setadmin_more] = useState('');
    const [admin_msg, setadmin_msg] = useState('');
    const [trainee_more, settrainee_more] = useState('');
    const [trainee_msg, settrainee_msg] = useState('');
    const [trainee_cnv, settrainee_cnv] = useState('');
    const [update_session_state, setUpdate_session_state] = useState('');
    const [session_open, setSession_open] = useState('');
    
    const [displayConfirmationDeleteModal, setDisplayConfirmationDeleteModal] = useState(false);
    
    const [displayConfirmationOpenCloseModal, setDisplayConfirmationOpenCloseModal] = useState(false);
    const [openclose, setOpenclose] = useState('');
    const [openclosesms, setOpenclosesms] = useState(false);


    const location = useLocation();
    
    const getData = () => {
        
        let params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            prm: 1
        };


        // console.log('setNeed_conf_presence ddd');
        // if(check_presence === false){
        //     setNeed_conf_presence(false);
        // }else if(check_presence === true){
        //     console.log('setNeed_conf_presence');
        //     params.checkp = 1;
        // }

        // console.log(location);

        // let from_user = ((location.state != undefined) && ('from' in location.state) ? location.state.from : '' );
        // if(from_user == 't')
        // {
            params.checkp = 1;
        // }
        post('session', 'fiche', processParams(params))
        .then(xhr => {
            if(check_is_200(xhr)) {
                if('session' in xhr.data)
                {
                    const iSes = xhr.data.session;
                    
                    setSession(iSes);
                    setSession_open(iSes.open);


                    let adrshow = [ iSes.voi, iSes.voi_n, iSes.voi_t, iSes.adress, iSes.cp, iSes.ville ];
                    if(iSes.batim != '') adrshow.push("Bâtiment "+iSes.batim);
                    if(iSes.escl != '') adrshow.push("Escalier "+iSes.escl);
                    if(iSes.etag != '') adrshow.push("Êtage "+iSes.etag);
                    
                    adrshow = adrshow.filter(w => w!='');
                    setAdresseSession(adrshow.join(' '));


                    if('former_info' in iSes){ setFormer_info(iSes.former_info); }
                    if('admin_info' in iSes){ setAdmin_info(iSes.admin_info); }
                    
                    if('cnf' in iSes)
                    { 
                        setConf_presence(iSes.cnf);
                        setNeed_conf_presence(!iSes.cnf.cnf);
                    }
                    if('prm' in iSes)
                    { 
                        // console.log(iSes.prm);
                        setupdate_session(iSes.prm.upd_s || '');
                        setGestion_session(iSes.prm.gst_s || true);
                        
                        setSee_my_signs(iSes.prm.s_mys || '');
                        setsee_documents(iSes.prm.s_dcs || '');
                        setSee_documents_adm(iSes.prm.s_dcsa || '');
                        setSee_enquetes(iSes.prm.s_eqs || '');
                        setsee_trainees(iSes.prm.s_trs || '');
                        setsee_notes(iSes.prm.s_nts || '');
                        setsee_attendances_list(iSes.prm.s_lps || '');
                        setSee_satisfaction(iSes.prm.s_stf || '');
                        
                        setupdate_documents(iSes.prm.u_dcs || '');
                        setupdate_trainees(iSes.prm.u_trs || '');
                        setwrite_note(iSes.prm.u_nts || '');
                        setWrite_satisfaction(iSes.prm.u_stf || '');
                        setUpdate_session_state(iSes.prm.u_opn || '');

                        setcheck_presence(iSes.prm.c_prs || '');

                        setformer_more(iSes.prm.g_fi || '');
                        setformer_msg(iSes.prm.g_fm || '');

                        setadmin_more(iSes.prm.g_ai || '');
                        setadmin_msg(iSes.prm.g_am || '');

                        settrainee_more(iSes.prm.g_ti || '');
                        settrainee_msg(iSes.prm.g_tm || '');
                        settrainee_cnv(iSes.prm.g_tc || '');
                    }
                }
            }else{
                
            }
        }).catch(function (error){
            
        });
    }

    useEffect(()=>{
        getData();
    }, []);

    

    const go_gestion_part = () => {
        if(gestion_session){
            history.push({
                pathname: getItemRoute('center_session_gestion')+session.center+'/'+session_key,
                state: {back:true, sesname:session.titre},
            });
        }
    }

    const go_update = (profil, profil_key) => {
        if(update_session){
            history.push({
                pathname: getItemRoute('center_session_update')+session_key,
                state: {back:true, upd:true},
            });
        }
    }

    const gobacktolist = () => history.push({
        pathname: getItemRoute('center_sessions'),
        state:{back:true},
    });

    const showDeleteConfirmationModal = () => setDisplayConfirmationDeleteModal(true);
    const hideDeleteConfirmationModal = () => setDisplayConfirmationDeleteModal(false);
    
    const go_delete = () => {
        
        let params = processParams({
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key
        });

        const toastId = toast.loading('Envoi');

        post('session', 'delete', params)
        .then(function (xhr) {
            if(check_is_200(xhr)) {
                if('state' in xhr.data && 'msg' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        gobacktolist();
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }
                else 
                {
                    toast.error(<b>Problème d'envoi des données !</b>, { id: toastId, });
                }
            }else{
                toast.error(<b>Problème de suppression !</b>, { id: toastId, });
            }
            
        }).catch(function(error){
            toast.error(<b>Problème de suppression !</b>, { id: toastId, });
        });
    }


    
    const change_session_opncls = () => {
        if(update_session_state)
        {
            setOpenclose(session_open==true ? 'fermer' : 'ouvrir');
            setDisplayConfirmationOpenCloseModal(true);
        }
    }

    const hideConfirmationModal = () => {
        setDisplayConfirmationOpenCloseModal(false);
    }

    const showComplementMessage = () => {
        return <div>
            {session_open==true && <Form.Check type="checkbox" label="Informer tous les stagiaires via sms" checked={openclosesms} onChange={e => handle_change_checkbox(e, setOpenclosesms)} />}
        </div>;
    }

    const send_change_session_opncls = () => {
        hideConfirmationModal();
        if(update_session_state)
        {
            const toastId = toast.loading(progressMessage('Envoi'));

            post('session', 'opncls', processParams({
                tkn: account.info.tkn,
                ctr: account.ctr.key,
                ses: session_key,
                snd: openclosesms ? 1 : 0,
                fro: session_open!=true ? 1 : 0,
            }))
            .then(function (xhr)
                {
                if(check_is_200(xhr))
                {
                    // console.log(xhr.data);
                    if(('state' in xhr.data) && ('msg' in xhr.data))
                    {
                        if(xhr.data.state)
                        {
                            if('nst' in xhr.data)
                            {
                                toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                                setSession_open(xhr.data.nst==1);
                            }
                            else 
                            {
                                toast.error(<b>Problème de traitement !</b>, { id: toastId, });
                            }
                        }
                        else
                        {
                            toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                    }
                    else
                    {
                        toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
                    }
                }
                else
                {
                    toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
                }
            }).catch(e => {
                toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
            });
        }
    }

    const go_attendance_list = () => {
        if(see_attendances_list){
            history.push({
                pathname: getItemRoute('center_session_presences')+session_key,
                state: {back:true, sesname:session.titre},
            });
        }
    }

    const go_satisfaction_form = () => {
        if(write_satisfaction){
            history.push({
                pathname: getItemRoute('center_satisf_reply_form')+session_key,
                state: {back:true, upd:true},
            });
        }
    }

    const download_horaire = () => 
    {
        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));
        
        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session.key
        };

        // console.log(params);
        
        post('session','hrrp', processParams(params))
        .then((xhr) => {
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé</b>, { id: toastId, });
                }
                else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        }).catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }

    const go_print_synthese = () => {

        if(!update_session) return '';

        let progressShow = 0;
        const toastId = toast.loading(progressMessage(progressShow));

        const params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key
        };
        
        post('session', 'syng', processParams(params))
        .then((xhr) => {
            if(check_is_200(xhr))
            {
                if('file' in xhr.data)
                {
                    popup_show_file(xhr.data.file);
                    toast.success(<b>Terminé</b>, { id: toastId, });
                }
                else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        }).catch(function (err){
            // // console.log(err);
            toast.error(<b>Problème de téléchargement !</b>, { id: toastId, });
        });
    }


    return ( 
        <div>
            <div className='headerContentPart flex_between' >
                <div className='title div_according'>{session.titre}</div>
                <div className='div_according'>
                    {(false && session_open!=true && write_satisfaction) && 
                        <div className='div_according'>
                            <div className='button_stand button_bg' onClick={e => go_satisfaction_form()}>
                                Remplir la satisfaction
                            </div>
                        </div> }
                        
                    {session_open==true && <div className='info_to_h text_info div_according' >Session ouverte</div>}
                    {session_open==false && <div className='info_to_h text_danger div_according'>Session fermée</div>}
                    
                    
                    {update_session && 
                        <FormGroup as={Col} className='div_according'>
                            <DropdownButton
                                as={ButtonGroup}
                                dropdown={null}
                                title={<FontAwesomeIcon icon={faCogs}/>}
                                className='button_stand padding_0' >
                                <Dropdown.Item onClick={() => change_session_opncls()}>
                                    {session_open==true && <span>Fermer la session</span>}
                                    {session_open==false && <span>Ouvrir la session</span>}
                                </Dropdown.Item>
                                
                                <Dropdown.Item onClick={() => go_print_synthese()}>Synthèse</Dropdown.Item>
                                <Dropdown.Item onClick={() => go_update()}>Modifier la session</Dropdown.Item>
                                <Dropdown.Item onClick={() => showDeleteConfirmationModal()}>Supprimer la session</Dropdown.Item>
                            </DropdownButton>
                        </FormGroup>}
                </div>
                        
            </div>
            
            {(need_conf_presence==true && conf_presence.cnf===false ) && <Row>
                    <FormGroup as={Col}>
                        <div className='profil_show_item is_block'>
                            <div className='section_title_two'><div className='title_section'>Confirmation de présence</div></div>
                            <Session_cnf_pre
                                    account={account} 
                                    session_key={session_key}
                                    setNeed_conf_presence={setNeed_conf_presence}
                                    conf_presence={conf_presence}
                                    setConf_presence={setConf_presence}
                                />
                        </div>
                    </FormGroup>
                </Row>}
        
            <Session_Room 
                    account={account} 
                    session_key={session_key} 
                />
            <Row>
                
                <FormGroup as={Col}>
                    <div className='profil_show_item is_block'>
                        <div className='section_title_two'><div className='title_section'>Administration</div></div>
                        <ProfilWidget className='is_table_col' without_border width_percent={100} session_key={session_key} profil={former_info} title='Formateur' is_former={true} go_center_profil={former_more} go_messenger_session={former_msg}/>
                        <ProfilWidget className='is_table_col' without_border width_percent={100} session_key={session_key} profil={admin_info} title='Responsable administratif' is_member={true} go_center_profil={admin_more} go_messenger_session={admin_msg}/>
                    </div>
                </FormGroup>
                {(session.times && adresseSession) && 
                        <FormGroup as={Col}>
                            <div className='profil_show_item is_block'>
                                <div className='section_title_two flex_between'>
                                    <div className='title_section'>Lieu et Horaires</div>
                                    <div className='button_stand button_small_size' style={{padding:'5px', marginLeft:'20px'}} onClick={()=>download_horaire()} ><FontAwesomeIcon icon={faDownload}/></div>
                                </div>
                                {adresseSession}
                                {session.times && <Session_Horaire horaires={session.times} session_title={session.titre}/>}
                            </div>
                        </FormGroup>}
            </Row>
            <Row>
                <FormGroup as={Col}>
                    
                    <div className='profil_show_item is_block'>
                        <div className='section_title_two'><div className='title_section'>Description</div></div>
                        {show_paragraph(session.desc)} 
                    </div>
                </FormGroup>
            </Row>
            <Row>
                

                {see_trainees && 
                    <FormGroup as={Col}>
                            <Stagiaires_List 
                                session_key={session_key}
                                session={session} 
                                account={account} 
                                with_title
                                can_update={update_trainees} 
                                trainee_more={trainee_more} 
                                trainee_msg={trainee_msg} 
                                trainee_cnv={trainee_cnv}
                                class_title='session_group_title'
                                class_content='session_group_content'
                            /> 
                            
                    </FormGroup>}

                {see_my_signs && 
                    <Row><FormGroup as={Col}>
                            <Presence_Trainee_Session 
                                    center_key={account.ctr.key}
                                    session_key={session_key} 
                                    account={account} 
                                    with_title
                                    can_update={update_documents}
                                    class_title='session_group_title'
                                    class_content='session_group_content'
                                />
                    </FormGroup></Row>}

                {(see_documents || see_documents_adm || see_enquetes) && <FormGroup as={Col}>
                    {see_documents && 
                        <Row><FormGroup as={Col}>
                                <Documents_List 
                                        session_key={session_key} 
                                        account={account} 
                                        with_title
                                        can_update={update_documents}
                                        class_title='session_group_title'
                                        class_content='session_group_content'
                                    />
                        </FormGroup></Row>}

                    {see_documents_adm && 
                            <Row><FormGroup  as={Col}>
                                <div className='profil_show_item is_block'>
                                    <div className='section_title_two'><div className='title_section'>Documents administratifs</div></div>
                                    <div  className='button_stand' onClick={(e)=>go_gestion_part()}>Devis - Facture - Convention - Convocations - Attestations - Certificats</div>
                                    {see_attendances_list && <div  className='button_stand' onClick={(e)=>go_attendance_list()}>Émargement numérique</div>}
                                </div>
                            </FormGroup></Row>}

                    {see_enquetes &&
                        <Row>
                            <FormGroup as={Col}>
                                <Session_Survey_List 
                                        session_key={session_key} 
                                        account={account} 
                                        with_title='Enquêtes'
                                        class_title='session_group_title'
                                        class_content='session_group_content'
                                    />
                            </FormGroup>
                        </Row>}

                </FormGroup>}
            </Row>
            <Row>
                <FormGroup as={Col}>
                    {(see_notes && 'room' in session) && 
                    <Row>
                        <FormGroup as={Col}>
                            <div className='profil_show_item is_block'>
                                <div className='section_title_two'><div className='title_section'>Notes</div></div>
                                <Notes_List
                                        session_room={session.room}
                                        session_key={session_key} 
                                        account={account} 
                                        can_add_note={write_note}
                                        class_title='session_group_title'
                                        class_content='session_group_content'
                                    />
                            </div>
                        </FormGroup>
                    </Row>}

                </FormGroup>
            </Row>

            
            <ConfirmationModel 
                        showModal={displayConfirmationDeleteModal} 
                        confirmModal={go_delete}
                        actionMessage='Je confirme'
                        actionClass='danger'
                        hideModal={hideDeleteConfirmationModal} 
                        message={"Êtes-vous sûr de vouloir supprimer la session ?"}
                    />

            <ConfirmationModel 
                        showModal={displayConfirmationOpenCloseModal} 
                        confirmModal={send_change_session_opncls}
                        actionMessage='Je confirme'
                        actionClass='warning'
                        hideModal={hideConfirmationModal} 
                        message={"Êtes-vous sûr de vouloir "+openclose+" la session ?"}
                        complement={showComplementMessage}
                    />
            

            {/* {session_open!=true && see_satisfaction==true && <div className='session_group'>
                <div className='session_group_title'>Satisfaction</div>
                <div className='session_group_content'>
                    <Satisfaction_Stats session_key={session_key}/>
                </div>
            </div>} */}

            

            


            
        </div>
     );
}
 
export default Session_Page;