import { Button } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { check_is_200, post, processParams } from "../../../../adapters/xhr";

const Session_cnf_pre = ({ account, session_key, setNeed_conf_presence, conf_presence, setConf_presence }) => {
    
    const confirm = (value) => {
        
        const toastId = toast.loading('Enregistrement');
        var params = {
            tkn: account.info.tkn,
            ctr: account.ctr.key,
            ses: session_key,
            cnf: value
        };
        // console.log(params);

        post('trainee', 'confirm', processParams(params))
        .then(function (xhr){
            if(check_is_200(xhr)) 
            {
                if(('state' in xhr.data) && ('msg' in xhr.data)){
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        setConf_presence(value=='y');
                        setNeed_conf_presence(value=='y');
                    }
                    else
                    {
                        toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                }else{
                    toast.error(<b>Problème d'envoi !</b>, { id: toastId, });
                }
            }else{
                
            }
        }).catch(function (error){
            
        });
    }

    const getMessage = () => {
        return (
            <div>
                <div className='button_stand' onClick={(e) => confirm('y')} style={{color:'green'}} >Je confirme ma présence</div>
                <div className='button_stand' onClick={(e) => confirm('n')} style={{color:'red'}} >Je décline cette convocation</div>
                {/* <Toaster
                    position="bottom-right"
                    reverseOrder={false}        /> */}
            </div>  
        );
    }

    return ( <div>
        {!conf_presence.cnfed && getMessage()}
    </div> );
}
 
export default Session_cnf_pre;